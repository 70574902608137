import { IoMdStar } from "react-icons/io";
import { Box } from "@mui/system";
import {
  useIsLoggedIn,
  useUserContext,
  useToogleLogIn,
} from "../context/Context";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { Link, useHistory } from "react-router-dom";
import { AppLinks } from "../utils/Linking";

interface Props {
  card: any;
}
export default function Card({ card }: Props) {
  const { user } = useUserContext();
  const cacheBuster = Math.random();
  const translit = CyrillicToTranslit();
  const { handleToogleLogin } = useToogleLogIn();
  const history = useHistory();
  const { isLoggededIn } = useIsLoggedIn();
  const showPublicProfile = () => {
    history.push(`${AppLinks.PublicProfile.path}/?profileid=${card.profileid}`);
  };

  return (
    <>
      <Box
        className="cursor-pointer rounded-3xl bg-white pb-4 w-full"
        sx={{
          position: "relative",
          height: "300px",
        }}
        onClick={isLoggededIn ? showPublicProfile : handleToogleLogin}
      >
        <div className="w-full h-40 relative">
          <img
            src={card?.Card || `${card?.coverurl}?cache=${cacheBuster}`}
            alt="House Image"
            className="w-full h-full object-cover rounded-t-lg"
          />
          <div className="absolute top-0 left-0 p-2">
            <div className="rounded-full px-2 flex flex-row space-x-2 bg-white">
              <div>
                <IoMdStar className="mt-1" style={{ color: "yellow" }} />
              </div>
              <div>{card?.Rate || card?.rate}</div>
            </div>
          </div>
        </div>
        <div className="">
          <img
            className="rounded-full w-20 h-20 mx-auto -mt-10 relative"
            src={card?.Pic || `${card?.imageurl}?cache=${cacheBuster}`}
          />
        </div>
        <Box
          className="flex flex-col items-center justify-center my-4"
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {user.locale === "en" ? (
            <p className="font-bold">{card?.Name || card?.name}</p>
          ) : (
            <p className="font-bold">
              {translit.reverse(card?.Name) || translit.reverse(card?.name)}
            </p>
          )}

          <p>{card?.Country || card?.location}</p>
        </Box>
      </Box>
    </>
  );
}
