const APIS_URL = {
  SIGNUP: "/user/sign-up",
  VERIFY_CAPTCHA: "/user/verify-captcha",
  RESEND_VERIFICATION: "/user/resend-verification",
  CHECKEMAIL: "/user/check-email",
  LOGIN: "/user/sign-in",
  GOOGLEAUTH: "/social/google-auth",
  PROFILE: "/profile",
  PUBLIC_PROFILE: "/profile/public",
  FEATURED_APARTMENTS: "/apartment/featured",
  PROFILE_IMAGE_PRESIGN: "/profile/image-presign",
  COVER_IMAGE_PRESIGN: "/profile/cover-presign",
  PROFILE_LAST_ONLINE: "/profile/update/last-online",
  ADD_APARTMENT: "/apartment/add",
  UPDATE_APARTMENT: "/apartment/update",
  DELETE_APARTMENT: "/apartment/delete",
  UNAPPROVED_APARTMENT: "/apartment/get/unapproved-apartment",
  DOC_PRESIGNED_URL: "/profile/doc-presign",
  EDIT_PROFILE: "/profile/",
  POPULAR_DESTINATION: "/popular/destination",
  ALL_APARTMENTS: "/apartment/get",
  GET_APARTMENT_OWNER: "/apartment/get-owner",
  FILTER_APARTMENTS: "/apartment/filter",
  APARTMENT_PRESIGN_URL: "/apartment/presigned-url",
  GET_APARTMENT_DETAILS: "/apartment/get/",
  GET_MY_APARTMENT_DETAILS: "/apartment/get-my/",
  FORGET_PASSWORD: "/user/forget-password",
  RESET_PASSWORD: "/user/reset-password",
  CHANGE_PASSWORD: "/user/change-password",
  SUPPORT: "/mail/support",
  BEST_HOSTS: "/best-host",
  BOOK_APARTMENT: "/booking",
  APPROVE_BOOKING: "/bookInProg/approve",
  REQUEST_BOOKING: "/bookInProg/request",
  APARTMENT_ADD_IMAGE: "/apartment/add-image",
  GIVE_FEEDBACK: "/feedback",
  UPDATE_DOC_STATUS: "/profile/update/doc-status",
  MY_BOOKINGS_LIST: "/booking/list",
  MY_BOOKINGS_FILTER: "/booking/filter/my-bookings",
  MY_APARTMENT: "/apartment/get/my-apartment",
  MY_APARTMENT_FILTER: "/apartment/filter/my-apartment",
  ALL_BOOKINGS: "/booking",
  ALL_BOOKINGS_FILTER: "/booking/filter/all-booked",
  SEEN_NOTIFICATION: "/notification/seen",
  DELETE_NOTIFICATION: "/notification/delete",
  GET_ALL_NOTIFICATION: "/notification/get-all",
  WEB_CONTENT: "/webcontent",
  FAQ: "/faq",
  CALCULATE_PAYMENT: "/booking/calculate/payment",
  TOGGLE_VISIBILITY: "/apartment/toggle-visibility",
  SEND_AUDIO_MESSAGE: "/message/audio/signedurl",
  SEND_FILE_MESSAGE: "/message/file/signedurl",
  SEND_REPORT_MESSAGE: "/message/report",
};

export { APIS_URL };
