import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputBase,
  Menu,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import { Translate } from "react-translated";
import Howard from "../images/Howard.png";
import JSZip from "jszip";
import { styled } from "@mui/system";
import mammoth from "mammoth";
import Avatar from "@mui/material/Avatar";
import { FC, useState, useEffect, useRef } from "react";
import { useUserContext, useShowMsg } from "../context/Context";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "axios";
import LocationIcon from "../images/location.png";
import Flag from "../images/flag.png";
import ArrowDown from "../images/arrowdown.png";
import { saveAs } from "file-saver";
import baseUrl from "../utils/config";
import Logo from "../images/Logo.png";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import PublicIcon from "@mui/icons-material/Public";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppLinks } from "../utils/Linking";
import { APIS_URL } from "../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import {
  formatDistanceToNow,
  differenceInSeconds,
  differenceInHours,
} from "date-fns";
import {
  PictureAsPdf as PdfIcon,
  Description as DocIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import { Download } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

interface Message {
  _id?: string;
  message?: string;
  username: string;
  image: string;
  email: string;
  recipient: string;
  landlordName: string;
  landlordImage: string;
  createdAt?: string;
  read?: boolean;
  apartmentImage: string;
  apartmentTitle?: string;
  apartmentGuest: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentRooms: number;
  apartmentState: string;
  recipientLastOnline?: string;
  senderLastOnline?: string;
  recipientResponseTime?: string;
  senderResponseTime?: string;
  aptId: string;
  bookingId: string;
  audioSrc?: string;
  fileSrc?: string;
  fileName?: string;
  type: string;
  fileType?: string;
}
interface Chat {
  name: string;
  email: string;
  apartmentState: string;
  apartmentRooms: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentGuest: number;
  aptId: string;
  bookingId: string;
  apartmentTitle: string;
  recipientLastOnline: string;
  senderLastOnline: string;
  recipeint: string;
  sendermail: string;
  recipientmail: string;
  recipientResponseTime: string;
  senderResponseTime: string;
}

interface MessageChatPageProps {
  showSecondGrid: boolean;
  chatUsers: Chat[];
  chatUsersIndex: number;
  socket: any;
  messages: Message[];
  toggleSortedMessage: boolean;
  setShowSecondGrid: Function;
  recipientEmail: string;
  apartmentState: string;
  apartmentRooms: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  aptOwnerLastOnline: string;

  aptOwnerRespTime: string;
  apartmentGuest: number;
  apartmentImage: string;
  apartmentTitle: string;
  landlordName: string;
  landlordImage: string;
  apartmentid: string | null;
  aptId: string;
  bookingId: string;
  setApartmentGuest: Function;
  setApartmentImage: Function;
  setApartmentTitle: Function;

  setApartmentLocation: Function;
  setApartmentCheckin: Function;
  setApartmentCheckout: Function;
  setApartmentRooms: Function;
  setApartmentState: Function;
  setChatUsersIndex: Function;
  setAptOwnerLastOnline: Function;
  setAptOwnerRespTime: Function;
  setAptId: Function;
  setBookingId: Function;

  // setAllMessages: Function;
}

const MessageChatPage: FC<MessageChatPageProps> = ({
  showSecondGrid,
  chatUsers,
  chatUsersIndex,

  socket,
  messages,
  toggleSortedMessage,
  setShowSecondGrid,
  recipientEmail,
  apartmentGuest,
  apartmentImage,
  apartmentTitle,
  apartmentLocation,
  apartmentCheckin,
  apartmentCheckout,
  apartmentRooms,
  apartmentState,
  landlordName,
  landlordImage,
  apartmentid,
  aptId,
  bookingId,
  setApartmentGuest,
  aptOwnerLastOnline,
  aptOwnerRespTime,
  setApartmentImage,
  setApartmentTitle,
  setApartmentLocation,
  setApartmentCheckin,
  setApartmentCheckout,
  setApartmentRooms,
  setApartmentState,
  setChatUsersIndex,
  setAptId,
  setBookingId,
  setAptOwnerLastOnline,
  setAptOwnerRespTime,
  // setAllMessages,
}) => {
  const history = useHistory();
  const auth = localStorage.getItem("token");
  const [message, setMessage] = useState("");
  const messageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, updateUser } = useUserContext();
  const [recording, setRecording] = useState(false);
  const [deleteRecording, setdeleteRecording] = useState(false);
  const [showAudio, setShowAudio] = useState(true);
  const [paused, setPaused] = useState(false);
  const [selectFile, setSelectFile] = useState(false);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  ); // Declare mediaRecorder state
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const { notifyError, notifySuccess } = useShowMsg();
  // const auth = localStorage.getItem("token");
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [isFileSending, setIsFileSending] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const handleBackClick = () => {
    if (apartmentid && messages.length < 1) {
      history.push(`${AppLinks.ApartmentDetails.path}/${apartmentid}`);
    }
    setShowSecondGrid(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const handleOptionClick = () => {
    handleClose();
    setShowSecondGrid(false);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const languageHandler = (locale: any) => {
    updateUser({ ...user, locale });
  };
  const cancleFileSending = () => {
    setIsFileSending(false);
    setSelectedFile(null);
    setSelectFile(false);
    setIsSending(false);
  };
  const sortedMessages =
    chatUsers.length > 0 && chatUsers[chatUsersIndex]
      ? messages
          .sort((a, b) => {
            const dateA = new Date(a.createdAt || 0).getTime();
            const dateB = new Date(b.createdAt || 0).getTime();
            return dateA - dateB;
          })
          .filter(
            (message) =>
              (message.email === chatUsers[chatUsersIndex].email &&
                message.recipient === user.email &&
                message.aptId === chatUsers[chatUsersIndex].aptId) ||
              (message.recipient === chatUsers[chatUsersIndex].email &&
                message.email == user.email &&
                message.aptId === chatUsers[chatUsersIndex].aptId)
          )
      : [];

  // console.log("sortedMessages", sortedMessages);
  const getHeadingName = () => {
    if (!chatUsers) return;
    // console.log(chatUsers[chatUsersIndex]);
    return chatUsers[chatUsersIndex]?.name;
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    messageRefs.current = messageRefs.current.slice(0, messages.length);
  }, [messages]);

  const lastTimeOnline = () => {
    if (aptOwnerLastOnline && aptOwnerRespTime) {
      let lastOnline = formatDistanceToNow(new Date(aptOwnerLastOnline), {
        addSuffix: true,
      });
      let senderRespTime = formatDistanceToNow(new Date(aptOwnerRespTime), {
        addSuffix: false,
      });

      return (
        // <div>
        //   Response time: {senderRespTime} - was online {lastOnline}
        // </div>
        <div>
          <Translate text="was online" /> {lastOnline}
        </div>
      );
    }
    if (
      chatUsers[chatUsersIndex] &&
      chatUsers[chatUsersIndex].senderResponseTime &&
      chatUsers[chatUsersIndex].recipientResponseTime
    ) {
      if (user.email === chatUsers[chatUsersIndex].recipientmail) {
        let lastOnline = formatDistanceToNow(
          new Date(chatUsers[chatUsersIndex]?.senderLastOnline),
          {
            addSuffix: true,
          }
        );
        let senderRespTime = formatDistanceToNow(
          new Date(chatUsers[chatUsersIndex].senderResponseTime),
          { addSuffix: false }
        );

        return (
          // <div>
          //   Response time: {senderRespTime} - was online {lastOnline}
          // </div>
          <div>
            <Translate text="was online" /> {lastOnline}
          </div>
        );
      }
      if (user.email === chatUsers[chatUsersIndex].sendermail) {
        let lastOnline = formatDistanceToNow(
          new Date(chatUsers[chatUsersIndex]?.recipientLastOnline),
          {
            addSuffix: true,
          }
        );

        let recipientRespTime = formatDistanceToNow(
          new Date(chatUsers[chatUsersIndex].recipientResponseTime),
          { addSuffix: false }
        );

        return (
          // <div>
          //   Response time: {recipientRespTime} - was online {lastOnline}
          // </div>
          <div>
            <Translate text="was online" /> {lastOnline}
          </div>
        );
      }
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // console.log("-------------testing-------------------------");
        if (!sortedMessages) return;

        const lastMessageId = sortedMessages[sortedMessages.length - 1]?._id;
        // console.log("lastMessageId", lastMessageId);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const messageId = (entry.target as HTMLElement).dataset.id;
            // console.log("messageId", messageId);
            if (messageId === lastMessageId) {
              const messageContentString = (entry.target as HTMLElement).dataset
                .content;
              // console.log(
              //   `-------------Last message is in view--------${messageId},${messageContentString}`
              // );
              let recipient: string = user.email;
              let sender: string;
              let aptId: string;
              if (messageContentString) {
                const messageContent = JSON.parse(messageContentString);
                if (user.email === messageContent.recipient) {
                  sender = messageContent.email;
                  aptId = messageContent.aptId;
                } else {
                  sender = messageContent.recipient;
                  aptId = messageContent.aptId;
                }
                // console.log("recipient :", recipient, " sender :", sender);
                socket.emit("message_read", {
                  recipient,
                  aptId,
                  sender,
                });

                messageRefs.current.forEach((ref) => {
                  if (ref) observer.unobserve(ref);
                });
              }
            }
          }
        });
      },
      {
        root: chatContainerRef.current,
        threshold: 1.0,
      }
    );

    messageRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      messageRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [messages, toggleSortedMessage]);

  const handleSendMessage = () => {
    if (message === "") return;

    if (socket) {
      let apartmentId = aptId === "" ? chatUsers[chatUsersIndex].aptId : aptId;
      // console.log("bookingId", bookingId);
      let bookedId;
      //   bookingId === "" ? chatUsers[chatUsersIndex].bookingId : bookingId;

      if (chatUsers && chatUsers[chatUsersIndex]) {
        bookedId =
          bookingId === "" ? chatUsers[chatUsersIndex].bookingId : bookingId;
      } else {
        // Handle the case where chatUsers or chatUsers[chatUsersIndex] is undefined
        bookedId = bookingId;
      }
      const newMessage: Message = {
        message: message,
        email: user.email,
        image: user.imageurl,
        landlordName: landlordName,
        landlordImage: landlordImage,
        username: user.firstname,
        recipient: recipientEmail,
        type: "text",
        apartmentGuest,
        apartmentImage,
        apartmentTitle,
        apartmentLocation,
        apartmentCheckin,
        apartmentCheckout,
        apartmentRooms,
        apartmentState,
        aptId: apartmentId,
        bookingId: bookedId,
      };
      socket.emit("private_message", newMessage);

      setMessage("");
      setApartmentGuest(0);
      setApartmentImage("");
      setApartmentLocation("");
      setApartmentCheckin("");
      setApartmentCheckout("");
      setApartmentRooms(0);
      setAptOwnerLastOnline("");
      setAptOwnerRespTime("");
      setApartmentState("");
      setAptId("");
      setBookingId("");
      setAudioChunks([]);
      setSelectedFile(null);
      setApartmentTitle("");
      if (chatUsersIndex === -1) {
        setChatUsersIndex(0);
      }
      // setAllMessages(0);
    }
    // console.log(apartmentCheckin, apartmentCheckout, apartmentGuest);
  };

  const getDate = () => {
    if (
      (chatUsers &&
        chatUsers[chatUsersIndex] &&
        chatUsers[chatUsersIndex].bookingId) ||
      apartmentCheckin
    ) {
      return (
        <p className="text-[#7682A0]  xs:text-[15px] sm:text-[15px] text-[24px] text-center">
          {new Date(
            apartmentCheckin !== ""
              ? apartmentCheckin
              : chatUsers[chatUsersIndex]?.apartmentCheckin
          ).toDateString()}{" "}
          -{" "}
          {new Date(
            apartmentCheckout !== ""
              ? apartmentCheckout
              : chatUsers[chatUsersIndex]?.apartmentCheckout
          ).toDateString()}
        </p>
      );
    } else {
      return <p></p>;
    }
  };

  const getLink = () => {
    if (
      chatUsers &&
      chatUsers[chatUsersIndex] &&
      chatUsers[chatUsersIndex].bookingId
    ) {
      return (
        <p className="text-[#225FFE] underline  xs:text-[20px] sm:text-[20px] text-[25px] text-center">
          <Link
            to={`${AppLinks.ApartmentDetails.path}/${
              aptId ||
              (chatUsers[chatUsersIndex] && chatUsers[chatUsersIndex].aptId) ||
              "defaultId"
            }`}
          >
            {chatUsers[chatUsersIndex].apartmentTitle}
          </Link>
        </p>
      );
    }
    if (aptId) {
      return (
        <p className="text-[#225FFE] underline  xs:text-[20px] sm:text-[20px] text-[25px] text-center">
          <Link to={`${AppLinks.ApartmentDetails.path}/${aptId}`}>
            {/* {chatUsers[chatUsersIndex].apartmentTitle} */}
            {apartmentTitle}
          </Link>
        </p>
      );
    }
  };

  useEffect(() => {
    if (mediaRecorder) {
      // console.log("---------recording in progress-----------");
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // console.log("----pushing data-----------", event.data);
          setAudioChunks((prevChunks) => [...prevChunks, event.data]);

          // console.log("audio chunk", audioChunks);
          if (showAudio === true) {
            const audioBlob = new Blob([...audioChunks, event.data], {
              type: "audio/webm",
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            const audioElement = new Audio(audioUrl);
            audioElement.play();
          }
        }
      };
    }
  }, [mediaRecorder, deleteRecording]);

  const startRecording = async () => {
    try {
      setShowAudio(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorder.start();
      setRecording(true);
      setPaused(false);
      setAudioUrl(null);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const deleteAudio = () => {
    if (mediaRecorder) {
      setdeleteRecording(!deleteRecording);
      setShowAudio(false);
      setAudioChunks([]);
      setAudioUrl(null);
      mediaRecorder.stop();
    }
    setRecording(false);
    // setSendRecording(false);
  };

  const pauseRecording = () => {
    // console.log("audioChunks", audioChunks);
    try {
      if (mediaRecorder) {
        mediaRecorder.stop();
        setPaused(true);
        setAudioUrl(null);
      } else {
        console.error("MediaRecorder is not defined.");
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  const uploadAudio = async () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      setAudioUrl(null);
    }

    const response = await axios({
      method: "post",
      url: `${baseUrl}${APIS_URL.SEND_AUDIO_MESSAGE}`,

      data: {
        filetype: "audio/webm",
      },
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });

    // console.log("response", response);

    if (response.status === 200) {
      const audioBlob = new Blob(audioChunks);
      const reader = new FileReader();
      reader.onload = async () => {
        const result = reader.result;
        if (result instanceof ArrayBuffer) {
          const audioData = new Uint8Array(result);
          // console.log("audioData", audioData);
          const uploadedAudio = await axios.put(
            response.data.presignedUrl,
            audioData,
            {
              headers: {
                "Content-Type": "audio/webm",
              },
            }
          );

          if (uploadedAudio.status === 200) {
            if (!uploadedAudio.config.url) return;
            let audioSrc = uploadedAudio.config.url.split("?")[0];
            // console.log("audioSrc", audioSrc);

            if (socket) {
              let apartmentId =
                aptId === "" ? chatUsers[chatUsersIndex].aptId : aptId;
              // console.log("bookingId", bookingId);
              let bookedId;

              if (chatUsers && chatUsers[chatUsersIndex]) {
                bookedId =
                  bookingId === ""
                    ? chatUsers[chatUsersIndex].bookingId
                    : bookingId;
              } else {
                bookedId = bookingId;
              }
              const newMessage: Message = {
                audioSrc,
                email: user.email,
                image: user.imageurl,
                landlordName: landlordName,
                landlordImage: landlordImage,
                username: user.firstname,
                recipient: recipientEmail,
                type: "audio",
                apartmentGuest,
                apartmentImage,
                apartmentLocation,
                apartmentCheckin,
                apartmentCheckout,
                apartmentRooms,
                apartmentState,
                aptId: apartmentId,
                bookingId: bookedId,
              };
              socket.emit("private_message", newMessage);

              setApartmentGuest(0);
              setApartmentImage("");
              setApartmentLocation("");
              setApartmentCheckin("");
              setApartmentCheckout("");
              setApartmentRooms(0);
              setAptOwnerLastOnline("");
              setAptOwnerRespTime("");
              setApartmentState("");
              setAptId("");
              setBookingId("");
              setAudioChunks([]);
              setSelectedFile(null);
              setApartmentTitle("");
              if (chatUsersIndex === -1) {
                setChatUsersIndex(0);
              }
              // setAllMessages(0);
            }
          }
        } else {
          console.error("Unexpected result type:", typeof result);
        }
      };

      reader.readAsArrayBuffer(audioBlob);
    }
  };

  // console.log("selectedFile", selectedFile);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const maxFileSize = 5 * 1024 * 1024; // 5 MB limit
      let fileType = file.type;
      let fileName = file.name;
      if (file.size > maxFileSize) {
        notifyError("File size exceeds 5 MB.");
        setSelectedFile(null);
      } else {
        const isValidContentType = (filetype: string) => {
          const allowedTypes = [
            "application/pdf",
            "application/msword",
            "image/png",
            "image/jpeg",
            "image/jpg",
          ];
          return allowedTypes.includes(filetype);
        };

        if (!fileType || !isValidContentType(fileType)) {
          setSelectedFile(null);
          notifyError("Invalid file type");
          return;
        }
        previewFile(file);
        setIsFileSending(true);
        setSelectFile(true);
        setSelectedFile(file);
      }
    }
  };

  // console.log("isFileSending", isFileSending);

  const handleSendFile = async () => {
    try {
      if (selectedFile) {
        // console.log("selectedFile", selectedFile);
        setSelectFile(false);
        setIsSending(true);
        let fileType = selectedFile.type;
        let fileName = selectedFile.name;

        // console.log("fileType && fileName", fileType, fileName);
        const response = await axios({
          method: "post",
          url: `${baseUrl}${APIS_URL.SEND_FILE_MESSAGE}`,

          data: {
            filetype: fileType,
          },
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        // console.log("response", response);
        const res = await axios.put(response.data.presignedUrl, selectedFile, {
          headers: {
            "Content-Type": fileType,
          },
        });
        // console.log("res", res);
        if (res.status === 200) {
          if (!res.config.url) return;
          let fileSrc = res.config.url.split("?")[0];
          console.log("fileSrc", fileSrc);
          if (socket) {
            let apartmentId =
              aptId === "" ? chatUsers[chatUsersIndex].aptId : aptId;
            // console.log("bookingId", bookingId);
            let bookedId;

            if (chatUsers && chatUsers[chatUsersIndex]) {
              bookedId =
                bookingId === ""
                  ? chatUsers[chatUsersIndex].bookingId
                  : bookingId;
            } else {
              bookedId = bookingId;
            }
            const newMessage: Message = {
              fileSrc,
              fileName,
              fileType,
              email: user.email,
              image: user.imageurl,
              landlordName: landlordName,
              landlordImage: landlordImage,
              username: user.firstname,
              recipient: recipientEmail,
              type: "file",
              apartmentGuest,
              apartmentImage,
              apartmentLocation,
              apartmentCheckin,
              apartmentCheckout,
              apartmentRooms,
              apartmentState,
              aptId: apartmentId,
              bookingId: bookedId,
            };
            socket.emit("private_message", newMessage);

            setApartmentGuest(0);
            setApartmentImage("");
            setApartmentLocation("");
            setApartmentCheckin("");
            setApartmentCheckout("");
            setApartmentRooms(0);
            setAptOwnerLastOnline("");
            setAptOwnerRespTime("");
            setApartmentState("");
            setAptId("");
            setBookingId("");
            setSelectedFile(null);
            setAudioChunks([]);
            setIsFileSending(false);
            setIsSending(false);
            setApartmentTitle("");
            if (chatUsersIndex === -1) {
              setChatUsersIndex(0);
            }
            // setAllMessages(0);
          }
        }
      } else {
        notifyError("Please choose a file");
        return;
      }
    } catch {
      setSelectedFile(null);
      notifyError("Something went wrong Please try again");
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const response = await axios({
      method: "get",
      url: `${url}`,
      responseType: "arraybuffer",
    });

    const imageBlob = new Blob([response.data], { type: `${filename}` });

    const anchor = document.createElement("a");

    anchor.href = URL.createObjectURL(imageBlob);
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  // console.log("filePreview", filePreview);
  const previewFile = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setFilePreview(reader.result);
      } else {
        console.error("Unsupported file type or null result");
      }
    };
  };

  const renderPreview = (name: string, fileType: string, fileURL: string) => {
    switch (fileType) {
      case "application/pdf":
        return (
          <Box>
            <p>{name}</p>
            <embed
              src={fileURL}
              type="application/pdf"
              style={{
                width: "100%",
                height: "300px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              // style={{ width: "100%", height: "100%" }}
            />
          </Box>
        );
      case "application/msword":
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <a href={fileURL} target="_blank" rel="noopener noreferrer">
              <p>{name}</p>
              <DocIcon style={{ fontSize: 70 }} />
            </a>
            <IconButton
              sx={{
                position: "absolute",
                bottom: "0",
                right: "0",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "#225FFE",
                width: "25px",
                height: "25px",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#1565C0",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // downloadFile(item.fileSrc, item.name);
                downloadFile(fileURL, `${name}`);
              }}
            >
              <DownloadIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </div>
        );
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return (
          <Box sx={{ position: "relative", maxWidth: "90%" }}>
            <p> {name}</p>
            <a href={fileURL} download={`filename.${fileType.split("/")[1]}`}>
              <img src={fileURL} alt="preview" />
            </a>
            <IconButton
              sx={{
                position: "absolute",
                bottom: "0",
                right: "0",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "#225FFE",
                width: "25px",
                height: "25px",

                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#1565C0",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // downloadFile(item.fileSrc, item.name);
                downloadFile(fileURL, `${name}`);
              }}
            >
              <DownloadIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
        );
      default:
        return (
          <p>
            <Translate text="Unsupported file type" />
          </p>
        );
    }
  };
  return (
    <>
      <Grid
        item
        xs={12}
        md={7}
        lg={6}
        sx={{
          display: {
            xs: showSecondGrid ? "block" : "none",
            md: showSecondGrid ? "block" : "none",
            // md: "block",
          },
        }}
      >
        <Box
          className="flex flex-col  bg-white relative"
          sx={{
            display: "flex",
            // alignItems: "center",
            width: "100%",
            height: "800px",
            // maxHeight: "800px",
            // minHeight: "700px",
            paddinX: "2px",
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <Box className="mt-8 border-b-4 border-[#EFF1F3] pb-2">
            <div className="flex flex-row justify-between px-4">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleBackClick}
              >
                <ArrowBackIcon />
              </IconButton>
              <div className="flex flex-col">
                <p className="text-[#1E1E1E] text-[24px] font-bold">
                  {/* Natalya */}
                  {landlordName === "" ? getHeadingName() : landlordName}
                </p>
                <div className="text-[#7682A0]  xs:text-[15px] sm:text-[15px] text-[20px]">
                  {/* Response time: 1 hour - was online {lastTimeOnline()} */}
                  {lastTimeOnline()}
                </div>
              </div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <PublicIcon />
              </IconButton>

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {/* onClick={handleOptionClick} */}

                <MenuItem onClick={() => languageHandler("en")} value={1}>
                  English
                </MenuItem>
                <MenuItem onClick={() => languageHandler("ru")} value={2}>
                  Русский
                </MenuItem>
              </Menu>
            </div>
          </Box>
          <Box className="flex justify-center">
            <div className="flex flex-col">
              {getLink()}

              {getDate()}
            </div>
          </Box>

          <Box className="flex flex-col justify-center mt-6">
            <Box
              sx={{
                height: "500px",
                overflowY: "auto",
                width: "98%",
                px: "15px",
                // Custom scrollbar styles
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
              }}
              ref={chatContainerRef}
            >
              <Box
                className="flex  items-center flex-row space-x-2  py-2 px-4 bg-[#EBF2FF] mb-10"
                sx={{ borderRadius: "20px" }}
              >
                <Avatar
                  className="w-[50px] rounded-lg h-auto object-contain"
                  alt="profile"
                  src={Logo}
                />
                <div className=" text-[14px]  ">
                  <Translate text="To protect your payment, always communicate and pay through the Afro-Kiva website." />
                </div>
              </Box>
              {/* 1 */}

              {sortedMessages &&
                sortedMessages.map((item: any, index: any) => (
                  <Box
                    key={index}
                    data-id={item._id}
                    data-content={JSON.stringify(item)}
                    ref={(el: HTMLDivElement | null) =>
                      (messageRefs.current[index] = el)
                    }
                    sx={{
                      width: "100%",
                      py: "20px",
                      px: "20px",
                      backgroundColor: "white",
                      // mb: "1px",
                    }}
                  >
                    <Box className="flex flex-row space-x-2">
                      <Avatar
                        className="w-[50px] rounded-lg h-auto object-contain"
                        alt="profile"
                        // src={Howard}
                        src={item.image}
                      />
                      <Box className="flex flex-col w-[100%]">
                        <div className="flex justify-between ">
                          <p className="flex justify-center text-center text-[#1E1E1E] text-[16px] font-bold">
                            {/* Natalya (Host) */}
                            {item.username}
                          </p>
                          <div className="flex items-center space-x-1">
                            {/* <DoneAllIcon
                          style={{ color: "#225FFE", fontSize: "16px" }}
                        /> */}

                            {item.email === user.email && (
                              <DoneAllIcon
                                style={{
                                  color: item.read ? "#225FFE" : "#B0B0B0",
                                  fontSize: "16px",
                                }}
                              />
                            )}
                            <p className="text-center text-[#1E1E1E] text-[12px]">
                              {/* 4:27pm */}
                              {item.createdAt
                                ? new Date(item.createdAt).toLocaleString()
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="text-[#1E1E1E] text-[14px]  mt-2 mb-4">
                          {item.type === "text" && item.message}
                          {item.type === "audio" && (
                            <div>
                              <audio
                                style={{ maxWidth: "100%" }}
                                controls
                                src={item.audioSrc}
                              />
                            </div>
                          )}
                          {item.type === "file" && (
                            <Box
                              style={{
                                // position: "relative",
                                display: "inline-block",
                              }}
                            >
                              {renderPreview(
                                item.fileName,
                                item.fileType,
                                item.fileSrc
                              )}
                            </Box>
                          )}
                        </div>
                        {/* <div className="flex">
                    <p className="text-[#1E1E1E] text-[12px] ">
                      Let me know if this is okay ?
                    </p>
                  </div> */}
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box className="p-8 absolute w-full bottom-[0]">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // pb: "10px",
                }}
              >
                {audioUrl && (
                  <div style={{ marginBottom: "10px" }}>
                    <audio controls src={audioUrl} />
                  </div>
                )}
              </Box>
              <Box
                className="flex items-center"
                sx={{
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#EFF1F3",
                  borderRadius: "10px",
                }}
              >
                {recording && (
                  <IconButton onClick={deleteAudio}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                )}
                <InputBase
                  value={message}
                  onChange={handleMessageChange}
                  placeholder={
                    recording
                      ? `${
                          paused
                            ? "Recording paused"
                            : "Recording in progress..."
                        } `
                      : "Type something..."
                  }
                  sx={{
                    ml: 1,
                    flex: 1,
                  }}
                />

                {recording && (
                  <Box>
                    {paused && (
                      <Box>
                        <IconButton onClick={startRecording}>
                          <PlayArrowIcon sx={{ color: "orange" }} />
                        </IconButton>
                        <IconButton onClick={uploadAudio}>
                          <SendIcon sx={{ color: "#225FFE" }} />
                        </IconButton>
                      </Box>
                    )}
                    {!paused && (
                      <Box>
                        <IconButton
                          onClick={pauseRecording}
                          sx={{ color: "orange" }}
                        >
                          <PauseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                )}

                {!recording && !selectFile && (
                  <Box>
                    <IconButton>
                      <MicIcon
                        onClick={startRecording}
                        sx={{ color: "#225FFE" }}
                      />
                    </IconButton>
                    {/* <IconButton>
                      <AttachFileIcon  sx={{ color: "#225FFE" }} />
                    </IconButton> */}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="upload-file"
                    />
                    <label htmlFor="upload-file">
                      <IconButton component="span">
                        <AttachFileIcon sx={{ color: "#225FFE" }} />
                      </IconButton>
                    </label>
                    <IconButton onClick={handleSendMessage}>
                      <SendIcon sx={{ color: "#225FFE" }} />
                    </IconButton>
                  </Box>
                )}
                {/* {selectFile && (
                  <IconButton onClick={handleSendFile}>
                    <SendIcon sx={{ color: "#225FFE" }} />
                  </IconButton>
                )} */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Modal open={isFileSending} onClose={cancleFileSending}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: { xs: 500, sm: 500, lg: 650 },
              width: { xs: "90%", sm: "90%", lg: "50%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 2,
              border: "1px solid #000", // Adding border
              p: 4,
            }}
          >
            <Box sx={{ justifyContent: "center", textAlign: "center" }}>
              {filePreview && (
                <div>
                  {selectedFile?.type.startsWith("image/") ? (
                    <img
                      src={filePreview}
                      alt="Preview"
                      style={{ width: "100%", maxHeight: "350px" }}
                    />
                  ) : selectedFile?.type === "application/pdf" ? (
                    <div
                      style={{
                        maxHeight: "450px",
                        maxWidth: "650px",
                        overflow: "auto",
                      }}
                    >
                      <Document
                        file={filePreview}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ))}
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                  ) : (
                    <p>{selectedFile?.name}</p>
                  )}
                </div>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={cancleFileSending}
                  variant="contained"
                  color="secondary"
                  // sx={{ minWidth: "100px" }}
                  sx={{
                    minWidth: "100px",
                    backgroundColor: "white",
                    borderColor: "blue",
                    color: "black",
                    border: "1px solid blue",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "darkblue",
                    },
                  }}
                >
                  <Translate text="Cancel" />
                </Button>
                <button
                  className="bg-primary text-white rounded-md"
                  onClick={handleSendFile}
                  style={{ minWidth: "100px" }}
                >
                  {isSending ? (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        marginBottom: "0px",
                        marginTop: "2px",
                      }}
                    />
                  ) : (
                    <Translate text="Send" />
                  )}
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Grid>
      {!showSecondGrid && (
        <Grid
          item
          md={7}
          lg={8}
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            color: "#262626",
          }}
        >
          {chatUsers.length > 0 ? (
            <div>
              <Translate text="Select A User To Start Chatting" />
            </div>
          ) : (
            <div>
              <Translate text="No Messages" />
            </div>
          )}
        </Grid>
      )}
    </>
  );
};
export default MessageChatPage;
