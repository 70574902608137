// config.js
let baseUrl: string;

if (process.env.NODE_ENV === "development") {
  // console.log("development");
  // Development environment
  baseUrl = "http://localhost:3001/api/v1";
} else {
  // Production environment
  baseUrl = `${process.env.REACT_APP_API_HOST}`;
}

export default baseUrl;
