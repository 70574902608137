import { FC, useState, useEffect, useRef } from "react";
import { Box, Grid, Select } from "@mui/material";
import { useUserContext } from "../context/Context";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { useLocation, useHistory } from "react-router-dom";
import Container from "../components/shared/Container";
import ListAllMessages from "../components/ListAllMessages";
import { styled } from "@mui/system";
import MessageRoomApartment from "../components/MessageRoomApartment";
import MessageChatPage from "../components/MessageChatPage";
import translateText from "../utils/translationHelper";
import baseUrl from "../utils/config";
interface Message {
  _id?: string;
  message?: string;
  username: string;
  image: string;
  email: string;
  recipient: string;
  landlordName: string;
  landlordImage: string;
  createdAt?: string;
  read?: boolean;
  apartmentImage: string;
  apartmentTitle?: string;
  apartmentGuest: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentRooms: number;
  apartmentState: string;
  aptId: string;
  bookingId: string;
  audioSrc?: string;
  fileSrc?: string;
  fileName?: string;
  type: string;
  fileType?: string;
  recipientLastOnline: string;
  senderLastOnline: string;
  recipientResponseTime: string;
  senderResponseTime: string;
}

type ChatUser = {
  email: string;
  name: string;
  image: string;
  message: string;
  landlordName: string;
  username: string;
  createdAt: string;
  apartmentImage: string;
  apartmentGuest: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentRooms: number;
  apartmentState: string;
  recipeint: string;
  aptId: string;
  bookingId: string;
  apartmentTitle: string;
  unreadMsg: boolean;
  recipientLastOnline: string;
  senderLastOnline: string;
  sendermail: string;
  recipientmail: string;
  recipientResponseTime: string;
  senderResponseTime: string;
};

const Messages: FC = () => {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState(1);
  const [chatUsers, setChatUsers] = useState<ChatUser[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const auth = localStorage.getItem("token");
  const hasMounted = useRef(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [unreadCount, setUnreadCount] = useState(0);
  // const [allMessages, setAllMessages] = useState(0);
  const apartmentid = queryParams.get("id");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [landlordName, setLandlordName] = useState("");
  const [chatUsersIndex, setChatUsersIndex] = useState(-1);
  const [landlordImage, setLandlordImage] = useState("");
  const [apartmentTitle, setApartmentTitle] = useState("");
  const [apartmentImage, setApartmentImage] = useState("");
  const [apartmentGuest, setApartmentGuest] = useState(0);
  const [apartmentLocation, setApartmentLocation] = useState("");
  const [apartmentCheckin, setApartmentCheckin] = useState("");
  const [apartmentCheckout, setApartmentCheckout] = useState("");
  const [apartmentState, setApartmentState] = useState("");
  const [aptOwnerLastOnline, setAptOwnerLastOnline] = useState("");
  const [aptOwnerRespTime, setAptOwnerRespTime] = useState("");
  const [aptId, setAptId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [apartmentRooms, setApartmentRooms] = useState(0);
  const { user, updateUser, socket } = useUserContext();
  const [showSecondGrid, setShowSecondGrid] = useState(false);
  const [toggleSortedMessage, setToggleSortedMessage] = useState(false);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const userHandler = (chatUser: any, id: any) => {
    setShowSecondGrid(true);
    handleUserClick(chatUser);
    setChatUsersIndex(id);
  };

  useEffect(() => {
    const getDetails = async () => {
      const getOnwerDetails = await axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.GET_APARTMENT_OWNER}/${apartmentid}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      // console.log("getOnwerDetails", getOnwerDetails);
      if (getOnwerDetails.status === 200) {
        setRecipientEmail(getOnwerDetails.data.onwerEmail);
        setLandlordName(getOnwerDetails.data.onwerName);
        setLandlordImage(getOnwerDetails.data.onwerImage);
        setApartmentTitle(getOnwerDetails.data.apartmentTitle);
        setApartmentImage(getOnwerDetails.data.apartmentImage);
        setApartmentGuest(getOnwerDetails.data.apartmentGuests);
        setApartmentLocation(getOnwerDetails.data.apartmentLocation);
        setApartmentCheckin(getOnwerDetails.data.checkin);
        setApartmentCheckout(getOnwerDetails.data.checkout);
        setApartmentState(getOnwerDetails.data.apartmentState);
        setApartmentRooms(getOnwerDetails.data.apartmentRooms);
        setAptId(getOnwerDetails.data.aptId);
        setBookingId(getOnwerDetails.data.bookingId);
        setAptOwnerLastOnline(getOnwerDetails.data.aptOwnerLastOnline);
        setAptOwnerRespTime(getOnwerDetails.data.aptOwnerRespTime);
      }
    };

    if (apartmentid) {
      setShowSecondGrid(true);
      getDetails();
      const removeIdFromUrl = () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete("id");
        url.search = params.toString();
        history.replace(url.pathname + url.search);
      };

      removeIdFromUrl();
    }
  }, []);

  useEffect(() => {
    const translateExistingMessages = async () => {
      const translatedMessages = await Promise.all(
        messages.map((message) => translateText(message, user.locale))
      );
      // console.log("translatedMessages", translatedMessages);
      setMessages(translatedMessages);
    };

    if (hasMounted.current) {
      translateExistingMessages();
    } else {
      hasMounted.current = true;
    }
  }, [user.locale]);

  useEffect(() => {
    const handlePrivateMessage = async (data: any) => {
      if (user.locale !== "en") {
        data = await translateText(data, user.locale);
      }
      // console.log("pri-message", data);

      let isNewMessage = false;
      let isReadUpdated = false;
      setMessages((prevMessages) => {
        // Check if the incoming message is new or has updated read field

        isNewMessage = !prevMessages.some(
          (existingMessage) => existingMessage._id === data._id
        );
        isReadUpdated = prevMessages.some(
          (existingMessage) =>
            existingMessage._id === data._id &&
            existingMessage.read !== data.read
        );

        if (isNewMessage) {
          // Add new message to the state
          return [...prevMessages, data];
        } else if (isReadUpdated) {
          setToggleSortedMessage(!toggleSortedMessage);
          console.log("------------isReadUpdating-------------");
          // Update the existing message's read field
          return prevMessages.map((existingMessage) =>
            existingMessage._id === data._id
              ? { ...existingMessage, read: true }
              : existingMessage
          );
        }

        // If neither condition is met, return the original state
        return prevMessages;
      });
    };
    socket.on("private_message", handlePrivateMessage);
    const userEmail = user.email;
    socket.emit("register", userEmail);

    return () => {
      socket.off("private_message");
    };
  }, [socket, user.locale]);

  useEffect(() => {
    const unreadMessages = messages.filter(
      (message) => message.recipient === user.email && message.read === false
    );
    // console.log("unreadMessages", unreadMessages);

    updateUser({
      ...user,
      unread: unreadMessages.length,
      msg: messages.length,
    });
  }, [messages]);

  const handleUserClick = (chatUser: ChatUser) => {
    setToggleSortedMessage(!toggleSortedMessage);
    // console.log("chatUser click", chatUser);
    setRecipientEmail(chatUser.email);
  };

  // console.log("messs", messages);

  const getChatUser = (messages: any) => {
    messages.forEach((data: any) => {
      const chatUserEmail =
        data.email === user.email ? data.recipient : data.email;
      const chatUserName =
        data.email === user.email ? data.landlordName : data.username;
      const chatUserImage =
        data.email === user.email ? data.landlordImage : data.image;

      // Create a unique identifier based on email and apartment ID
      const userIdentifier = `${chatUserEmail}_${data.aptId}`;

      // console.log("userIdentifier", userIdentifier);
      if (data.aptId) {
        setChatUsers((prevChatUsers) => {
          // const existingUserIndex = prevChatUsers.findIndex(
          //   (user) => user.email === chatUserEmail
          // );

          const existingUserIndex = prevChatUsers.findIndex(
            (user) => `${user.email}_${user.aptId}` === userIdentifier
          );

          let unread = false;

          if (
            data.read === false &&
            data.recipient === user.email &&
            prevChatUsers.some((chatUser) => chatUser.aptId !== data.aptId)
          ) {
            unread = true;
          }
          // Create a copy of the previous chat users array
          const updatedChatUsers = [...prevChatUsers];
          // console.log("existingUserIndex", existingUserIndex);
          // If the user exists, update their message and createdAt
          if (existingUserIndex !== -1) {
            updatedChatUsers[existingUserIndex] = {
              ...prevChatUsers[existingUserIndex],
              message: data.message,
              createdAt: data.createdAt,
              recipientLastOnline: data.recipientLastOnline,
              senderLastOnline: data.senderLastOnline,
              recipientResponseTime: data.recipientResponseTime,
              senderResponseTime: data.senderResponseTime,

              sendermail: data.email,
              recipientmail: data.recipient,
              ...(chatUserName !== "" && {
                name: chatUserName,
              }),
              ...(data.apartmentCheckin !== "" && {
                apartmentCheckin: data.apartmentCheckin,
              }),
              ...(data.apartmentCheckout !== "" && {
                apartmentCheckout: data.apartmentCheckout,
              }),
              ...(data.apartmentTitle !== "" && {
                apartmentTitle: data.apartmentTitle,
              }),
              ...(data.apartmentLocation !== "" && {
                apartmentLocation: data.apartmentLocation,
              }),
              ...(data.apartmentTitle !== "" && {
                apartmentState: data.apartmentState,
              }),

              unreadMsg: unread,
            };
          } else {
            // If the user doesn't exist, add them to the array
            // console.log("it doenst exit");
            updatedChatUsers.push({
              email: chatUserEmail,
              name: chatUserName,
              image: chatUserImage,
              landlordName: data.landlordName,
              username: data.username,
              message: data.message,
              createdAt: data.createdAt,
              apartmentCheckin: data.apartmentCheckin,
              apartmentCheckout: data.apartmentCheckout,
              apartmentRooms: data.apartmentRooms,
              apartmentState: data.apartmentState,
              apartmentLocation: data.apartmentLocation,
              apartmentGuest: data.apartmentGuest,
              apartmentImage: data.apartmentImage,
              apartmentTitle: data.apartmentTitle,
              recipeint: data.recipient,
              aptId: data.aptId,
              bookingId: data.bookingId,
              unreadMsg: unread,
              sendermail: data.email,
              recipientmail: data.recipient,
              senderLastOnline: data.senderLastOnline,
              recipientLastOnline: data.recipientLastOnline,
              recipientResponseTime: data.recipientResponseTime,
              senderResponseTime: data.senderResponseTime,
            });

            // Find the index of the newly added user

            // const newUserIndex = updatedChatUsers.findIndex(
            //   (user) => user.email === chatUserEmail
            // );
            // console.log("------------newUserIndex------------", newUserIndex);
            // // Set the active chat to the newly added user's index
            // setChatUsersIndex(newUserIndex);
          }
          // Find the current user based on chatUsersIndex before sorting

          const currentUser = prevChatUsers[chatUsersIndex];
          updatedChatUsers.sort((a, b) => {
            const dateA = new Date(a.createdAt || 0).getTime();
            const dateB = new Date(b.createdAt || 0).getTime();
            return dateB - dateA;
          });

          if (currentUser) {
            const newChatUsersIndex = updatedChatUsers.findIndex(
              (user) => user.email === currentUser.email
            );

            // Set the new chat users index
            setChatUsersIndex(newChatUsersIndex);
          }

          return updatedChatUsers;
        });
      }
    });
  };

  useEffect(() => {
    if (messages.length > 0) {
      getChatUser(messages);
    }
  }, [messages, user.locale]);

  // console.log("chatuser", chatUsers);

  return (
    <Container>
      <Box className=" bg-[#EFF1F3] xs:pt-2 sm:pt-2 pt-8  xs:pb-2px sm:pb-2x pb-10">
        <Grid container spacing={2} className="xs:px-0 sm:px-0 px-10">
          {/* LIST ALL CHATUSER */}
          <ListAllMessages
            socket={socket}
            showSecondGrid={showSecondGrid}
            selectedValue={selectedValue}
            handleChange={handleChange}
            chatUsers={chatUsers}
            userHandler={userHandler}
            chatUsersIndex={chatUsersIndex}
          />
          {/* MESSAGE CHAT PAGE */}
          <MessageChatPage
            socket={socket}
            messages={messages}
            setShowSecondGrid={setShowSecondGrid}
            recipientEmail={recipientEmail}
            apartmentState={apartmentState}
            apartmentRooms={apartmentRooms}
            apartmentLocation={apartmentLocation}
            apartmentCheckin={apartmentCheckin}
            apartmentCheckout={apartmentCheckout}
            apartmentGuest={apartmentGuest}
            apartmentImage={apartmentImage}
            apartmentTitle={apartmentTitle}
            aptOwnerLastOnline={aptOwnerLastOnline}
            aptOwnerRespTime={aptOwnerRespTime}
            toggleSortedMessage={toggleSortedMessage}
            showSecondGrid={showSecondGrid}
            chatUsers={chatUsers}
            chatUsersIndex={chatUsersIndex}
            landlordImage={landlordImage}
            landlordName={landlordName}
            apartmentid={apartmentid}
            aptId={aptId}
            bookingId={bookingId}
            setApartmentState={setApartmentState}
            setApartmentRooms={setApartmentRooms}
            setApartmentLocation={setApartmentLocation}
            setApartmentCheckin={setApartmentCheckin}
            setApartmentCheckout={setApartmentCheckout}
            setApartmentGuest={setApartmentGuest}
            setApartmentImage={setApartmentImage}
            setApartmentTitle={setApartmentImage}
            setChatUsersIndex={setChatUsersIndex}
            setAptId={setAptId}
            setBookingId={setAptId}
            setAptOwnerLastOnline={setAptOwnerLastOnline}
            setAptOwnerRespTime={setAptOwnerRespTime}

            // setAllMessages={setAllMessages}
          />
          {/* MessageRoomApartment */}
          <MessageRoomApartment
            showSecondGrid={showSecondGrid}
            chatUsers={chatUsers}
            chatUsersIndex={chatUsersIndex}
            apartmentState={apartmentState}
            apartmentRooms={apartmentRooms}
            apartmentLocation={apartmentLocation}
            apartmentCheckin={apartmentCheckin}
            apartmentCheckout={apartmentCheckout}
            apartmentGuest={apartmentGuest}
            apartmentImage={apartmentImage}
            bookingId={bookingId}
            aptId={aptId}
            apartmentTitle={apartmentTitle}
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default Messages;
