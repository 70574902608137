import { IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

interface Props {
  onFileUpload: Function;
  text?: string;
}
export default function DragDropFileUpload({ onFileUpload, text }: Props) {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event: any) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files) {
        onFileUpload(event.dataTransfer.files);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.files && event.target.files) {
        onFileUpload(event.target.files);
      }
    },
    [onFileUpload]
  );

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? "2px dashed #aaa" : "2px dashed #000",
        height: 120,
        textAlign: "center",
        cursor: "pointer",
        background: dragOver ? "#eee" : "#fafafa",
        borderRadius: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        style={{ display: "none" }}
        id="raised-button-file"
        accept="image/*, video/*"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Box display="flex" flexDirection="column" alignItems="center">
          <p className="text-secondary sm:text-sm xs:text-sm flex gap-2 items-center">
            <IoCloudUploadOutline className="text-primary cursor-pointer  text-xl md:text-sm sm:text-sm xs:text-sm" />
            {text ?? "PNG, GIF, WEBP, MP4, Max 500Mb."}
          </p>
        </Box>
      </label>
    </Paper>
  );
}
