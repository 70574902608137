import { FC, useEffect } from "react";
import Footer from "../Footer";
import Nav from "../Nav";
import { Box } from "@mui/material";
import Translator from "../../Translator";

interface ContainerStructure {
  children: any;
}

const Container: FC<ContainerStructure> = ({ children }) => {
  return (
    <>
      <Nav />

      <Box sx={{ paddingTop: "60px" }}>{children}</Box>

      <Footer />
    </>
  );
};

export default Container;
