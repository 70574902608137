import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import preview from "../images/preview.png";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaRegBell } from "react-icons/fa6";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import apartment from "../images/apart4.png";

const Checkout = () => {
  return (
    <>
      <div className=" bg-gray-100 ">
        <div className="flex py-3 px-[6rem] md:px-[3rem] lg:px-[3rem] sm:px-[1rem] xs:px-[1rem] justify-center items-center  ">
          <div className="flex  relative gap-10 justify-between  w-full  items-center    ">
            <Link to="/">
              <div className="w-40 xs:w-24 sm:w-30  h-10">
                <img className="" src={preview} alt="miradom" />
              </div>
            </Link>

            <div className="">
              <div className="  md:my-[1rem] sm:pl-[2rem] md:pl-[2rem]   md:hover:bg-white md:text-center md:py-[1rem] sm:py-[1rem]   sm:hover:bg-white sm:my-[1rem]">
                <FaRegBell className="text-xl xs:text-base sm:text-base text-secondary w-8  hover:text-black" />
              </div>
            </div>
          </div>
        </div>

        <div className="px-[6rem] mt-[3.5rem] sm-w-full sm:px-[1rem] md:w-full lg:w-full lg:px-[3rem] md:px-[1rem] xs-w-full xs:px-[1rem]">
          <div className="flex  md:block sm:block xs:block lg:block  justify-between gap-10 ">
            <div className="basis-[90rem] ">
              <h3 className="text-[3.5rem] font-semibold xs:text-[2rem] sm:text-[2rem] md:text-[2.5rem] xl:text-[3rem]">
                {" "}
                Confirm and pay
              </h3>
              <h3 className="text-secondary text-xl py-[2rem] xs:text-base sm:text-base md:text-base ">
                {" "}
                Make an advance payment for accommodation
              </h3>
              <hr />
              <h3 className="text-base text-primary font-light py-[1rem] xs:text-[1rem] sm:text-[1rem]">
                Reservation No. 172358
              </h3>
              <div>
                <h3 className="text-base font-semibold py-[1rem] xs:text-[1rem] sm:text-[1rem]">
                  Your data
                </h3>
                <div className="flex gap-5 xs:block xs:space-y-5 sm:block sm:space-y-5 ">
                  <div className="basis-[50%]">
                    <Input
                      className="border border-[#647482] w-[100%] text-[1.2rem] px-[1.5rem] py-[1rem] rounded-[1rem] sm:text-sm xs:text-sm md:text-[1rem]"
                      placeholder="John"
                      type="text"
                      name="displayName"
                      id=""
                      value=""
                      onChange={null}
                    />
                  </div>

                  <div className="basis-[50%]">
                    <Input
                      className="border border-[#647482] w-[100%] text-[1.2rem] px-[1.5rem] py-[1rem] rounded-[1rem] sm:text-sm xs:text-sm md:text-[1rem]"
                      placeholder="infamous@gmail.com"
                      type="email"
                      name="displayName"
                      id=""
                      value=""
                      onChange={null}
                    />
                    <p className="text-sm text-secondary">
                      we will send the voucher and booking information
                    </p>
                  </div>
                </div>

                <div>
                  <h3 className="text-base font-semibold pt-[1rem] pb-[2rem] sm:text-[1rem] xs:text-[1rem]">
                    {" "}
                    Your trip{" "}
                  </h3>

                  <div className="flex justify-between gap-5 xs:block xs:space-y-5 sm:block sm:space-y-5">
                    <div className="bg-white basis-[50%] px-[1rem] py-[.3rem] rounded-2xl">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-[1.2rem] xs:text-[1rem] sm:text-[1rem] md:text-[1.1rem]">
                            Dates
                          </p>
                          <h3 className="text-base font-semibold xs:text-[1.2rem] sm:text-[1.2rem] md:text-[1.2rem]">
                            Nov 14 - Nov 15
                          </h3>
                        </div>
                        <BiEditAlt className="text-xl xs:text-base sm:text-base" />
                      </div>
                    </div>

                    <div className="bg-white basis-[50%] px-[1rem] py-[.3rem] rounded-2xl">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-[1.2rem] xs:text-[1rem] sm:text-[1rem] md:text-[1.1rem]">
                            Guests
                          </p>
                          <h3 className="text-base font-semibold xs:text-[1.2rem] sm:text-[1.2rem] md:text-[1.2rem]">
                            1 guest
                          </h3>
                        </div>
                        <BiEditAlt className="text-xl xs:text-base sm:text-base" />
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>

                <div>
                  <h3 className="text-base font-semibold py-[3rem] pt-[2rem]  sm:text-[1rem] xs:text-[1rem]">
                    Payment Methods
                  </h3>

                  <div className="flex  justify-between gap-5 xs:block sm:block">
                    <div className="basis-[50%] space-y-6 ">
                      <div className="bg-white   px-[1rem] py-[2rem] rounded-2xl">
                        <div className="flex items-center gap-7 ">
                          <BiEditAlt className="text-base" />
                          <div>
                            <p>By card online</p>
                            <h3>MIR, Visa, Mastercard</h3>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white  px-[1rem] py-[2rem] md:py-[1.29rem] rounded-2xl">
                        <div className="flex items-center gap-7 ">
                          <BiEditAlt className="text-base " />
                          <div>
                            <p>SberPay</p>
                            <h3 className="">
                              Sberbank online application payment
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="basis-[50%] space-y-6">
                      <div className="bg-white  px-[1rem] xs:mt-5 sm:mt-5 py-[2rem] rounded-2xl">
                        <div className="flex  gap-7 items-center">
                          <BiEditAlt className="text-base" />
                          <div>
                            <p>SBP</p>
                            <h3>Your bank application</h3>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white  px-[1rem] py-[2rem] rounded-2xl">
                        <div className="flex gap-7  items-center">
                          <BiEditAlt className="text-base" />
                          <div>
                            <p>TinkoffPay</p>
                            <h3>Tinkoff app</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" basis-[20rem] xs:mt-[2rem] sm:mt-[2rem] md:mt-[2rem]">
              <div className="lg:flex lg:gap-5 lg:my-[2rem]">
                <div className=" bg-white p-[2rem] shadow-2xl xs:w-full xs:p-[.5rem] sm:mx-auto sm:p-[.5rem]  md:w-[70%] md:mx-auto  md:p-[.5rem]  w-[25rem] rounded-[2rem]">
                  <div className="flex items-center py-[1rem] justify-between">
                    <div className="flex gap-5 items-center">
                      <BiEditAlt className="text-xl" />
                      <p>promo code </p>
                    </div>
                    <BiEditAlt className="text-base " />
                  </div>
                  <hr />

                  <div className="flex text-base items-center justify-between py-[1rem] xs:text-[1rem] sm:text-[1rem]  font-semibold">
                    <h3 className=""> Prepayment </h3>
                    <h3>500P</h3>
                  </div>

                  <div className="flex justify-between mx-[1rem] py-[.5rem] px-[.5rem] xs:py-[.3rem]  sm:py-[.3rem]   rounded-[1rem] bg-[#d6e3ff]">
                    <div className="flex">
                      <input
                        type="checkbox"
                        className="w-[3rem] xs:w-[1rem] sm:w-[1rem]"
                        name=""
                        id=""
                      />
                      <p className="xs:text-sm sm:text-sm"> apply bonuses</p>
                    </div>
                    <p>-200P</p>
                  </div>

                  <div className="flex text-base items-center justify-between py-[1rem] xs:text-[1rem] sm:text-[1rem]  font-semibold">
                    <h3> To pay </h3>
                    <h3>300P</h3>
                  </div>

                  <Button
                    onclick=""
                    className=" px-[2.3rem] my-[1rem] bg-primary  text-white text-base w-[100%] mt-[2rem] py-[.5rem] rounded-[.5rem] xs:text-[1rem] sm:text-[1rem] md:text-sm "
                    type=""
                    btnText="Pay"
                  />
                </div>

                <div className="my-[2rem] p-[2rem] xs:p-[1rem] md:p-[1rem] sm:mx-auto sm:p-[1rem] lg:p-[1rem] w-[25rem] xs:w-full md:w-[70%] md:mx-auto  bg-white  shadow-2xl rounded-[2rem] ">
                  <div>
                    <img
                      src={apartment}
                      className="w-full rounded-[2rem]"
                      alt=""
                    />
                  </div>

                  <h3 className="text-base pt-[1rem] xs:text-[1rem] sm:text-[1rem]">
                    Apartments on Belorusskaya
                  </h3>
                  <p className="text-secondary pb-[3rem] xs:text-sm sm:text-sm">
                    Moscow, 3rd Yamaskogo Polya street , 9{" "}
                  </p>
                  <h3>
                    Total for 1 day :{" "}
                    <span className="text-primary">20000P</span>
                  </h3>
                </div>
              </div>

              <div className="text-base text-secondary xs:text-[1.2rem] sm:text-[1.2rem]">
                <h3>
                  Make deposite as soon as possible while the property is still
                  available
                </h3>

                <h3 className="text-base font-light text-primary py-[2rem] xs:text-[1.2rem] sm:text-[1.2rem]">
                  Agree on other conditions and time of arrival/departure. Also
                  see cancellation option
                </h3>
              </div>
            </div>
          </div>
        </div>

        <hr className=" border border-[#8b96a1]" />

        <div className="pt-[3rem] pb-[5rem] px-[6rem] xs:p-[1rem] sm:p-[1rem]">
          <div className="flex items-center justify-between md:gap-5 sm:gap-10  w-full xs:w-full sm:w-full mx-auto text-[#646466]">
            <div>
              <p className="text-[1.3rem] lg:text-[1.2rem] md:text-[1rem] sm:text-[.7rem] xs:text-[.7rem]">
                All right Reserved @Miradom. 2022
              </p>
            </div>
            <div className="">
              <div className="flex gap-5 items-center text-primary text-[2.2rem] lg:text-[1.5rem] md:text-[1.3rem] sm:text-[1rem] xs:text-[1rem]">
                <FaFacebookF />
                <FaTwitter />
                <FaYoutube />
                <FaGooglePlusG />
                <FaInstagram />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
