const AppLinks = {
  Landing: {
    name: "Landing",
    path: "/",
  },
  Home: {
    name: "Home",
    path: "/home",
  },
  SignUp: {
    name: "Sign up",
    path: "/sign-up",
  },
  LogIn: {
    name: "Log In",
    path: "/login",
  },
  Profile: {
    name: "Profile",
    path: "/profile",
  },
  PublicProfile: {
    name: "PublicProfile",
    path: "/public-profile",
  },
  EditProfile: {
    name: "Edit Profile",
    path: "/edit-profile",
  },
  ApartmentListing: {
    name: "Apartment Listing",
    path: "/apartment-listing",
  },
  AllBookedApartment: {
    name: "All Booked Apartment",
    path: "/allbooked-apartment",
  },

  MyBookings: {
    name: "My Bookings",
    path: "/mybookings",
  },

  MyApartment: {
    name: "My Apartment",
    path: "/my-apartment",
  },

  UnApprovedApartment: {
    name: "Un Approved Apartment",
    path: "/unapproved-apartment",
  },

  MyApartmentDetails: {
    name: "My Apartment Details",
    path: "/myapartment-details",
  },
  ConfirmAndPay: {
    name: "Confirm and Pay",
    path: "/confirm-payment",
  },

  ApartmentDetails: {
    name: "Apartment Details",
    path: "/apartment-details",
  },
  Checkout: {
    name: "Check out",
    path: "/check-out",
  },
  ListApartment: {
    name: "List Your Apartments",
    path: "/list-your-apartment",
  },
  Support: {
    name: "Reach out to support",
    path: "/support",
  },
  Messages: {
    name: "Messages",
    path: "/messages",
  },
  Notification: {
    name: "Notification",
    path: "/notification",
  },
  ErrorPage: {
    name: "Error Page",
    path: "/",
  },
};

export { AppLinks };
