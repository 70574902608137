import Button from "../components/shared/Button";
import { FiTwitter } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TbPencil } from "react-icons/tb";
import { BiEditAlt } from "react-icons/bi";
import { IoHomeOutline } from "react-icons/io5";
import { GoCheckCircle } from "react-icons/go";
import { FaRegComment } from "react-icons/fa6";
import Container from "../components/shared/Container";
import { Link } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useShowMsg } from "../context/Context";
import { APIS_URL } from "../services/api";
import { Box } from "@mui/system";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Translate } from "react-translated";
import translateText from "../utils/translationHelper";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useLocation, useHistory } from "react-router-dom";
import { useUserContext, useNotificationContext } from "../context/Context";
import baseUrl from "../utils/config";
dayjs.extend(relativeTime);

const PublicProfile = () => {
  const { notifyError } = useShowMsg();
  const { updateNotification } = useNotificationContext();
  const translit = CyrillicToTranslit();
  const auth = localStorage.getItem("token");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const profileId = queryParams.get("profileid");

  const [reviewViewType, setReviewViewType] = useState<"about" | "by">("about");
  const [userInfos, setUserInfos] = useState<{
    reviewAboutYou: any[];
    reviewByYou: any[];
    dateofbirth: string;
    email: string;
    firstname: string;
    lastname: string;
    speak: string[];
    superhost: boolean;
    isIdentityVerified: boolean;
    imageurl: string;
    coverurl: string;
    createdAt: Date;
    bio: string;
    location: string;
  }>({
    dateofbirth: "",
    email: "",
    firstname: "",
    lastname: "",
    reviewAboutYou: [],
    reviewByYou: [],
    speak: [],
    superhost: false,
    isIdentityVerified: false,
    imageurl: "",
    coverurl: "",
    createdAt: new Date(),
    bio: "",
    location: "",
  });
  const { user, updateUser } = useUserContext();
  const cacheBuster = Math.random();
  const imageCacheBuster = Math.random();

  useEffect(() => {
    fetchInfo();
  }, [user.locale]);

  const fetchInfo = () => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.PUBLIC_PROFILE}/${profileId}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response: any) => {
          const userInfo = response.data.userData;

          if (user.locale !== "en") {
            let userDatas = await translateText(
              response.data.userData,
              user.locale
            );
            setUserInfos(userDatas);

            return;
          }

          setUserInfos(userInfo);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            bgcolor: "#eeeeee",
          }}
        >
          <Box
            className={`h-[250px] bg-gray-500 flex items-end justify-end p-5`}
            sx={{
              backgroundImage: `url(${userInfos.coverurl}?cache=${cacheBuster})`,

              px: { xs: 3, md: 10 },
            }}
          ></Box>
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: "#eeeeee",
              pt: 8,
              px: { xs: 3, md: 10 },

              pb: 20,
            }}
          >
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                display: { xs: "flex", lg: "block" },
                justifyContent: { xs: "center" },
                alignItems: { xs: "center" },
              }}
            >
              <Card
                sx={{
                  width: 300,
                  position: { xs: "static", md: "relative" },

                  top: { xs: 0, lg: "-200px" },
                  borderRadius: 6,
                  mb: "40px",
                }}
              >
                <CardContent className="flex items-center flex-col">
                  <Avatar
                    src={`${userInfos.imageurl}?cache=${imageCacheBuster}`}
                    sx={{
                      width: 100,
                      height: 100,
                    }}
                  />

                  <Typography
                    variant="h5"
                    component="p"
                    fontWeight="600"
                    className="capitalize"
                    my={3}
                  >
                    {user.locale === "en" ? (
                      <div>
                        {userInfos.firstname} {userInfos.lastname}
                      </div>
                    ) : (
                      <div className="font-bold">
                        {translit.reverse(userInfos.firstname)}{" "}
                        {translit.reverse(userInfos.lastname)}
                      </div>
                    )}
                  </Typography>
                  <Box
                    sx={
                      {
                        // width: "100%",
                      }
                    }
                  >
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs="auto">
                        {userInfos.isIdentityVerified && (
                          <Box className="flex flex-row space-x-2">
                            <IoCheckmarkCircleOutline
                              style={{
                                width: "20",
                                height: "20",
                                color: "white",
                                backgroundColor: "green",
                                borderRadius: "100%",
                              }}
                            />
                            <Typography>
                              {" "}
                              <Translate text="Identity verified" />
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs="auto">
                        {userInfos.superhost && (
                          <Chip
                            label={<Translate text="Superhost" />}
                            sx={{ width: "90px", height: "25px" }}
                            color="primary"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Link to={`${AppLinks.Messages.path}`}>
                    <Chip
                      label={<Translate text="Contact" />}
                      variant="outlined"
                      sx={{ my: 3, borderColor: "black" }}
                    />
                  </Link> */}
                  <Box className="flex gap-4 my-4">
                    <FiTwitter />
                    <BsInstagram />
                    <FiFacebook />
                  </Box>
                  <Typography
                    sx={{
                      borderTop: 1,
                      pt: 1,
                      borderColor: "lightgray",
                    }}
                  >
                    <Translate text="Member since" />{" "}
                    {new Date(userInfos?.createdAt).toDateString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="p"
                      fontWeight="600"
                      className="capitalize"
                    >
                      <Translate text="Hi, I'm" />{" "}
                      {user.locale === "en" ? (
                        <span>
                          {userInfos.firstname} {userInfos.lastname}
                        </span>
                      ) : (
                        <span className="font-bold">
                          {translit.reverse(userInfos.firstname)}{" "}
                          {translit.reverse(userInfos.lastname)}
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{userInfos.bio}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="inline-flex items-center text-gray-500"
                  >
                    <IoHomeOutline className="mr-1" />
                    <Translate text="Lives in" />
                  </Grid>
                  <Grid item xs={6} className="">
                    {userInfos.location}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="inline-flex items-center text-gray-500"
                  >
                    <GoCheckCircle className="mr-1" />
                    <Translate text="Account" />
                  </Grid>
                  <Grid item xs={6}>
                    <Translate text="Member" />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="inline-flex items-center text-gray-500"
                  >
                    <FaRegComment className="mr-1" />
                    <Translate text="Speak" />
                  </Grid>
                  <Grid item xs={6}>
                    {userInfos.speak.join(", ")}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 5,
                    }}
                    className="flex justify-between items-center gap-2"
                  >
                    {userInfos.reviewAboutYou.length > 0 && (
                      <Typography
                        variant="h5"
                        component="p"
                        fontWeight="600"
                        className="capitalize"
                      >
                        <Translate text="Reviews about him" />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {(reviewViewType === "about"
                        ? userInfos.reviewAboutYou
                        : userInfos.reviewByYou
                      ).map((review) => (
                        <>
                          <Divider component="li" />
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar alt={review.name} src={review.image} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                user.locale === "en" ? (
                                  <span>{review.name}</span>
                                ) : (
                                  <span className="font-bold">
                                    {translit.reverse(review.name)}
                                  </span>
                                )
                              }
                              primaryTypographyProps={{
                                sx: {
                                  fontWeight: 600,
                                },
                              }}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {review.review}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      mt: 1,
                                    }}
                                  >
                                    {dayjs(review.time).fromNow()}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PublicProfile;
