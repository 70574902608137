import { FormLabel, Grid, Typography } from "@mui/material";
import Input from "../shared/Input";
import { useState } from "react";
import { useShowMsg } from "../../context/Context";
import axios from "axios";
import { APIS_URL } from "../../services/api";
import { Translate } from "react-translated";
import baseUrl from "../../utils/config";

export default function ResetPassword() {
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { notifyError, notifySuccess } = useShowMsg();
  const handleChangeResetPassword = (e: any) => {
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });
  };
  const handleUpdateProfile = async () => {
    const auth = localStorage.getItem("token");
    if (resetPassword.oldPassword === resetPassword.newPassword) {
      notifyError("Old Password can't be the same as New Password");
      return;
    }
    if (
      resetPassword.newPassword === resetPassword.confirmNewPassword &&
      !!resetPassword.newPassword
    ) {
      try {
        const response = await axios({
          method: "post",
          url: `${baseUrl}${APIS_URL.CHANGE_PASSWORD}`,

          data: {
            oldpassword: resetPassword.oldPassword,
            newpassword: resetPassword.newPassword,
          },
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        try {
          if (response.status === 200) {
            notifySuccess("Success Editing Profile");
            setResetPassword({
              ...resetPassword,
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            });
          }
        } catch {
          notifyError("Invalid Old  Password");
        }
      } catch (error: any) {
        console.log("error", error);
        notifyError("Error Editing Profile");
      }
    } else {
      notifyError("Invalid Password");
    }
  };

  return (
    <>
      <Typography variant="h5" component="h1" fontWeight="bold" pb={2}>
        <Translate text="Reset Password" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Old Password" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Password"
            type="text"
            name="oldPassword"
            id=""
            value={resetPassword.oldPassword}
            onChange={handleChangeResetPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="New Password" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Password"
            type="text"
            name="newPassword"
            id=""
            value={resetPassword.newPassword}
            onChange={handleChangeResetPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Confirm Password" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Confirm Password"
            type="text"
            name="confirmNewPassword"
            id=""
            value={resetPassword.confirmNewPassword}
            onChange={handleChangeResetPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <button
            onClick={handleUpdateProfile}
            className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md inline-flex items-center gap-1"
          >
            <Translate text="Reset Password" />
          </button>
        </Grid>
      </Grid>
    </>
  );
}
