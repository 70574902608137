import { FC, useState } from "react";
import { Popover } from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { Translate } from "react-translated";

interface Filter {
  location: string;
  guest: number;
  adult: number;
  children: number;
  infant: number;
  pet: number;
  fromDate: Date | null;
  toDate: Date | null;
}

interface GuestPopupPros {
  anchorGuestEl: HTMLElement | null;
  setAnchorGuestEl: (el: HTMLDivElement | null) => void;
  filter: {
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  };

  setFilter: Function;
}

const ApartmentGuestPopup: FC<GuestPopupPros> = ({
  anchorGuestEl,
  setAnchorGuestEl,
  filter,
  setFilter,
}) => {
  // const [guestNumber, setGuestNumber] = useState(0);

  const increment = () => {
    // setGuestNumber(guestNumber + 1);

    setFilter((filter: Filter) => ({
      ...filter,
      guest: filter.guest + 1,
    }));
  };

  const decrement = () => {
    // setGuestNumber(guestNumber - 1);
    if (filter.guest !== null && filter.guest > 0) {
      setFilter((filter: Filter) => ({
        ...filter,
        guest: filter.guest - 1,
      }));
    }
  };

  const incrementAdult = () => {
    increment();
    setFilter((filter: Filter) => ({
      ...filter,
      adult: filter.adult + 1,
    }));
  };

  const incrementChildren = () => {
    increment();
    setFilter((filter: Filter) => ({
      ...filter,
      children: filter.children + 1,
    }));
  };

  const incrementInfant = () => {
    increment();
    setFilter((filter: Filter) => ({
      ...filter,
      infant: filter.infant + 1,
    }));
  };

  const decrementAdult = () => {
    if (filter.adult !== null && filter.adult > 0) {
      decrement();
      setFilter((filter: Filter) => ({
        ...filter,
        adult: filter.adult - 1,
      }));
    }
  };

  const decrementChildren = () => {
    if (filter.children !== null && filter.children > 0) {
      decrement();
      setFilter((filter: Filter) => ({
        ...filter,
        children: filter.children - 1,
      }));
    }
  };

  const decrementInfant = () => {
    if (filter.infant !== null && filter.infant > 0) {
      decrement();
      setFilter((filter: Filter) => ({
        ...filter,
        infant: filter.infant - 1,
      }));
    }
  };

  const incrementPet = () => {
    // setGuestNumber(guestNumber + 1);

    setFilter((filter: Filter) => ({
      ...filter,
      pet: filter.pet + 1,
    }));
  };

  const decrementPet = () => {
    // setGuestNumber(guestNumber - 1);
    if (filter.pet !== null && filter.pet > 0) {
      setFilter((filter: Filter) => ({
        ...filter,
        pet: filter.pet - 1,
      }));
    }
  };

  return (
    <Popover
      open={Boolean(anchorGuestEl)}
      anchorEl={anchorGuestEl}
      onClose={() => setAnchorGuestEl(null)}
    >
      <div className="shadow-2xl bg-[#eeeeee]    rounded-lg">
        <div className=" overflow-y-auto px-5 py-5 rounded-2xl max-h-[45vh]">
          <div>
            <div className="flex justify-between pb-[2.5rem] items-center">
              <div>
                <h3 className="text-xl font-semibold">
                  <Translate text="" /> Adults
                </h3>
                <h3 className="text-secondary">
                  <Translate text="" />
                  Ages 13 or above
                </h3>
              </div>
              <div className="flex items-center gap-3 text-secondary">
                <IoMdRemoveCircleOutline
                  className="text-base"
                  onClick={decrementAdult}
                />
                <h3 className="text-base">{filter.adult}</h3>
                <IoMdAddCircleOutline
                  className="text-base"
                  onClick={incrementAdult}
                />
              </div>
            </div>
            <hr />
          </div>

          <div>
            <div className="flex justify-between pb-[2.5rem] items-center">
              <div>
                <h3 className="text-xl font-semibold">
                  <Translate text="" />
                  Children
                </h3>
                <h3 className="text-secondary">
                  <Translate text="" />
                  Ages 2 - 12{" "}
                </h3>
              </div>
              <div className="flex items-center gap-3 text-secondary">
                <IoMdRemoveCircleOutline
                  className="text-base"
                  onClick={decrementAdult}
                />
                <h3 className="text-base">{filter.children}</h3>
                <IoMdAddCircleOutline
                  className="text-base"
                  onClick={incrementAdult}
                />
              </div>
            </div>
            <hr />
          </div>

          <div>
            <div className="flex justify-between pb-[2.5rem] items-center">
              <div>
                <h3 className="text-xl font-semibold">
                  <Translate text="" />
                  Infacts
                </h3>
                <h3 className="text-secondary">
                  <Translate text="" />
                  Under 2
                </h3>
              </div>
              <div className="flex items-center gap-3 text-secondary">
                <IoMdRemoveCircleOutline
                  className="text-base"
                  onClick={decrementAdult}
                />
                <h3 className="text-base">{filter.infant}</h3>
                <IoMdAddCircleOutline
                  className="text-base"
                  onClick={incrementAdult}
                />
              </div>
            </div>
            <hr />
          </div>

          <div>
            <div className="flex justify-between pb-[2.5rem] items-center">
              <div>
                <h3 className="text-xl font-semibold">
                  <Translate text="" />
                  Pets
                </h3>
                <h3 className="text-secondary underline">
                  <Translate text="" />
                  Bring a service animal?
                </h3>
              </div>
              <div className="flex items-center gap-3 text-secondary">
                <IoMdRemoveCircleOutline
                  className="text-base"
                  onClick={decrementAdult}
                />
                <h3 className="text-base">{filter.pet}</h3>
                <IoMdAddCircleOutline
                  className="text-base"
                  onClick={incrementAdult}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default ApartmentGuestPopup;
