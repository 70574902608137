import Label from "../components/Label";
import Button from "../components/shared/Button";
import Container from "../components/shared/Container";
import Input from "../components/shared/Input";
import { IoCloudUploadOutline } from "react-icons/io5";
import { GrClose } from "react-icons/gr";
import { AiOutlineLock } from "react-icons/ai";
import { RxPerson } from "react-icons/rx";
import { MdOutlinePayments } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { TbLoaderQuarter } from "react-icons/tb";
import { useShowMsg } from "../context/Context";
import { Box, useTheme } from "@mui/system";
import { useNotificationContext } from "../context/Context";
import { Translate } from "react-translated";
import { useLocation, useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DragDropFileUpload from "../components/DragDropFileUpload";
import PersonalInfo from "../components/EditProfile/PersonalInfo";
import ResetPassword from "../components/EditProfile/ResetPassword";
import Payment from "../components/EditProfile/Payment";
// import Notification from "./Notification";

const EditProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const { notification, updateNotification } = useNotificationContext();
  const theme = useTheme();
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  const [view, setView] = useState<
    "personal" | "login" | "payment" | "notification"
  >("personal");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const notificationParam = searchParams.get("notification");
    if (notificationParam === "true") {
      setView("notification");
    }
  }, [location.search]);

  const NotSeenNotificationsCount = notification.filter(
    (item: any) => !item.seen
  ).length;

  return (
    <>
      <Container>
        <Box
          bgcolor="#eeeeee"
          sx={{
            px: { xs: 1, md: 10 },
            pt: 10,
            pb: 20,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={3}></Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                component="h1"
                fontWeight="bold"
                sx={{ fontSize: { xs: "15px", sm: "15px", md: "30px" } }}
              >
                <Translate text="Personal Info" />
              </Typography>
              <Link
                className="inline-flex items-center gap-2 border-black border px-5 py-1 rounded-full text-sm text-center"
                to={AppLinks.Profile.path}
              >
                <Translate text="View Profile" />
              </Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  <List
                    sx={{
                      display: {
                        xs: "flex",
                        md: "block",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <ListItem
                      className={`${view === "personal" && "bg-[#eeeeee] "} `}
                      disablePadding
                      sx={{
                        width: { xs: "20%", sm: "100%", md: "100%" },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          flexDirection: { xs: "column", md: "row" },
                        }}
                        onClick={() => {
                          setView("personal");
                        }}
                      >
                        <ListItemIcon>
                          <RxPerson
                            className={`text-xl sm:text-[1rem] xs:text-[2rem] group-hover:text-black ${
                              view === "personal" && "text-black "
                            } `}
                          />
                        </ListItemIcon>
                        {!isSmallerThanMedium && (
                          <ListItemText
                            primary={<Translate text="Personal Info" />}
                            sx={{
                              color: view === "personal" ? "black" : "",
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      className={`${view === "login" && "bg-[#eeeeee] "} `}
                      disablePadding
                      sx={{
                        width: { xs: "20%", sm: "100%", md: "100%" },
                      }}
                    >
                      <ListItemButton
                        onClick={() => {
                          setView("login");
                        }}
                      >
                        <ListItemIcon>
                          <AiOutlineLock
                            className={`text-xl sm:text-[1rem] xs:text-[2rem] group-hover:text-black ${
                              view === "login" && "text-black"
                            } `}
                          />
                        </ListItemIcon>
                        {!isSmallerThanMedium && (
                          <ListItemText
                            primary={<Translate text="Login and security" />}
                            sx={{
                              color: view === "login" ? "black" : "",
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      className={`${view === "payment" && "bg-[#eeeeee] "} `}
                      disablePadding
                      sx={{
                        width: { xs: "20%", sm: "100%", md: "100%" },
                      }}
                    >
                      <ListItemButton
                        onClick={() => {
                          setView("payment");
                        }}
                      >
                        <ListItemIcon>
                          <MdOutlinePayments className="text-xl sm:text-[1rem] xs:text-[2rem] group-hover:text-black " />
                        </ListItemIcon>
                        {!isSmallerThanMedium && (
                          <ListItemText
                            primary={<Translate text="Payments" />}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      className={`${
                        view === "notification" && "bg-[#eeeeee] "
                      } `}
                      disablePadding
                      sx={{
                        width: { xs: "20%", sm: "100%", md: "100%" },
                      }}
                    >
                      <ListItemButton
                        onClick={() => {
                          // setView("notification");
                          history.push(AppLinks.Notification.path);
                        }}
                      >
                        <ListItemIcon className="relative">
                          <IoMdNotificationsOutline className="text-xl sm:text-[1rem] xs:text-[2rem] group-hover:text-black " />
                          {NotSeenNotificationsCount > 0 && (
                            <span className="absolute top-0 right-0 -mt-2 mr-4 px-2 py-1 text-[10px] text-white bg-green-500 rounded-full">
                              {NotSeenNotificationsCount}
                            </span>
                          )}
                        </ListItemIcon>
                        {!isSmallerThanMedium && (
                          <ListItemText
                            primary={<Translate text="Notification" />}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={9}>
              {view === "personal" && <PersonalInfo />}
              {view === "login" && <ResetPassword />}
              {view === "payment" && <Payment />}
              {/* {view === "notification" && <Notification />} */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default EditProfile;
