import { MdStar } from "react-icons/md";
// import Input from "../components/shared/Input";
import LogIn from "./LogIn";
import Container from "../components/shared/Container";
import { LuSmile } from "react-icons/lu";
import { FaArrowRightLong } from "react-icons/fa6";
import { GoPerson } from "react-icons/go";
import { FaSpeakap } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { BsLightningChargeFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { APIS_URL } from "../services/api";
import { TbLoaderQuarter } from "react-icons/tb";
import style from "./home.module.css";
import { AiOutlineMessage } from "react-icons/ai";
import { MdAccessTime } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import CheckinCheckoutPopup from "./components/CheckinCheckoutPopup";
import GuestPopup from "./components/GuestPopup";
import { CiEdit } from "react-icons/ci";
import yoomoneyLogo from "../images/yoomoneyLogo.png";
import { CiPercent } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Checkbox,
  CardMedia,
} from "@mui/material";
import baseUrl from "../utils/config";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slick";
import RuleItem from "../components/ApartmentDetails/RuleItem";
import AmenitiesItem from "../components/ApartmentDetails/AmenitiesItem";
import CustomMap from "../components/Map";
import { AppLinks } from "../utils/Linking";
import CheckinPopup from "./components/CheckinPopup";
import CheckoutPopup from "./components/CheckoutPopup";
import ApartmentGuestPopup from "./components/ApartmentGuestPopup";
import { format, utcToZonedTime } from "date-fns-tz";
import {
  useIsLoggedIn,
  useShowMsg,
  useToogleLogIn,
  useUserContext,
  useNotificationContext,
} from "../context/Context";
import Checkout from "./Checkout";
import { GiLetterBomb } from "react-icons/gi";
import { useForm, Controller } from "react-hook-form";
import { CiHeart } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { Translate } from "react-translated";

dayjs.extend(relativeTime);

const ConfirmAndPay = () => {
  const [anchorDateEl, setAnchorDateEl] = useState<null | HTMLElement>(null);
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const searchLocation = useLocation();
  const search = new URLSearchParams(searchLocation.search);
  const history = useHistory();
  const { notifyError, notifySuccess } = useShowMsg();
  const [showSpinner, setShowSpinner] = useState(false);
  const auth = localStorage.getItem("token");
  const { apartmentid } = useParams<{ apartmentid: string }>();
  const [priceToPay, setPriceToPay] = useState(0);
  const [apartmentImage, setApartmentImage] = useState("");
  const [apartmentLoc, setApartmentLoc] = useState("");
  const [apartmentDuration, setApartmentDuration] = useState("");
  const { user } = useUserContext();
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: search.get("location") ?? "",
    guest: search.get("guest") !== null ? parseInt(search.get("guest")!) : 0,
    adult: search.get("adult") !== null ? parseInt(search.get("adult")!) : 0,
    children:
      search.get("children") !== null ? parseInt(search.get("children")!) : 0,
    infant: search.get("infant") !== null ? parseInt(search.get("infant")!) : 0,
    pet: search.get("pet") !== null ? parseInt(search.get("pet")!) : 0,
    fromDate: !!search.get("fromDate")
      ? new Date(search.get("fromDate") || "")
      : null,
    toDate: !!search.get("toDate")
      ? new Date(search.get("toDate") || "")
      : null,
  });

  useEffect(() => {
    const calculatePayment = async () => {
      try {
        if (filter.fromDate === null && filter.toDate === null) {
          history.push(AppLinks.ApartmentListing.path);
        }

        if (filter.fromDate === null && filter.toDate === null) {
          notifyError("Add booking Check-in and check-out date");
          return;
        }
        let formattedFromDate;
        let formattedToDate;
        if (filter.fromDate !== null) {
          formattedFromDate = format(filter.fromDate, "yyyy-MM-dd'T'HH:mm:ss");
        }
        if (filter.toDate !== null) {
          formattedToDate = format(filter.toDate, "yyyy-MM-dd'T'HH:mm:ss");
        }

        if (formattedToDate && formattedFromDate) {
          if (formattedToDate < formattedFromDate) {
            notifyError("Check out date cannot be less than check in date");
            return;
          }
        }

        const response = await axios({
          method: "post",

          url: `${baseUrl}${APIS_URL.CALCULATE_PAYMENT}`,
          data: {
            apartment: apartmentid,
            checkin: formattedFromDate,
            checkout: formattedToDate,
            guests: filter.guest,
          },
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        })
          .then((response) => {
            // console.log("payment res", response);
            const status = response.status;
            if (response.status === 200) {
              setPriceToPay(response.data.totalPrice);
              setApartmentImage(response.data.apartmentExist.images[0]);
              setApartmentLoc(response.data.apartmentExist.location);
              setApartmentDuration(response.data.durationDays);
              return;
            }
          })
          .catch((err) => console.log(err));
      } catch (error: any) {
        notifyError("Failed to fetch apartment details");
      }
    };
    calculatePayment();
  }, [filter.fromDate, filter.toDate, filter.guest]);

  const confirmBooking = async () => {
    try {
      if (showSpinner === true) return;
      // let fromDate = new Date(valueFrom);
      // let toDate = new Date(valueTo);
      if (filter.fromDate === null && filter.toDate === null) {
        notifyError("Add booking Check-in and check-out date");
        return;
      }
      let formattedFromDate;
      let formattedToDate;
      if (filter.fromDate !== null) {
        formattedFromDate = format(filter.fromDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      if (filter.toDate !== null) {
        formattedToDate = format(filter.toDate, "yyyy-MM-dd'T'HH:mm:ss");
      }

      if (formattedToDate && formattedFromDate) {
        if (formattedToDate < formattedFromDate) {
          notifyError("Check out date cannot be less than check in date");
          return;
        }
      }

      setShowSpinner(true);
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.BOOK_APARTMENT}`,

        data: {
          apartment: apartmentid,
          checkin: formattedFromDate,
          checkout: formattedToDate,
          guests: filter.guest,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (response.status === 200) {
        const token = response.data.confirmationToken;
        paymentWidget(token);
      }
      if (response.status === 400 && response.data.booked === true) {
        notifyError("Apartment has already been booked for this period");
      }
      if (response.status === 400 && response.data.guestCapacity === false) {
        notifyError(response.data.message);
      }
      if (response.status === 400 && response.data.approve === false) {
        notifyError(response.data.message);
      }
      if (response.status === 400 && response.data.bookingData === false) {
        notifyError("Please kindly rebook");
      }
      if (response.status === 400 && response.data.owner === true) {
        notifyError("Owner's can't book there Apartment");
      }
      if (response.status === 502) {
        notifyError("Please try again something went wrong");
      }

      setShowSpinner(false);
    } catch (error: any) {
      console.log("faile error", error);
      setShowSpinner(false);
      notifyError("Failed to book apartment");
    }
  };

  const paymentWidget = (token: string) => {
    const checkout = new (window as any).YooMoneyCheckoutWidget({
      confirmation_token: token, //Token that must be obtained from YooMoney before the payment process
      // return_url: url, //URL to the payment completion page
      customization: {
        //Настройка способа отображения
        modal: true,
      },
      error_callback: function (error: any) {
        //Processing of initialization errors
      },
    });
    checkout.on("complete", () => {
      //Code to be run after payment.
      notifySuccess("Apartment Successfully Booked");
      //Deletion of an initialized widget
      checkout.destroy();
    });
    //Display of payment form in the container
    checkout
      .render("payment-form")
      //The method returns Promise. When Promise is executed, it means the payment form is fully loaded (optional use).
      .then(() => {
        //Code that must be executed after the payment form is displayed.
      });
  };

  return (
    <>
      <Container>
        <Box bgcolor="#eeeeee">
          <Box
            sx={{
              px: { xs: 3, md: 10, lg: 15 },
              pt: 15,
              pb: 20,
            }}
          >
            <Grid container spacing={10}>
              <Grid item xs={12} md={8}>
                <Box>
                  <p className="text-4xl">
                    {" "}
                    <Translate text="Confirm and pay" />{" "}
                  </p>
                  <Typography variant="h6" color="text.secondary">
                    <Translate text="Make an advance payment for accomodation" />
                  </Typography>
                  <Box className="w-[100%]">
                    <div className="border-b-2 border-gray-400 my-4"></div>
                  </Box>

                  <p className="text-blue-400 font-bold text-xl">
                    {" "}
                    <Translate text="Reservation number" />
                  </p>
                  <Box sx={{ marginTop: "20px" }}>
                    <p className="text-xl mb-5">
                      {" "}
                      <Translate text="Your data" />
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <input
                          className="border border-[#647482] text-gray-500 w-[100%]  px-6 py-[0.8rem] rounded-[1rem] mt-1"
                          type="text"
                          name="firstname"
                          value={`${user.firstname} ${user.lastname}`}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <input
                          className="border border-[#647482] text-gray-500 w-[100%]  px-6 py-[0.8rem] rounded-[1rem] mt-1"
                          type="text"
                          name="firstname"
                          value={user.email}
                          disabled
                          aria-describedby="my-helper-text"
                        />
                        <FormHelperText id="my-helper-text">
                          <Translate text="we will send the voucher and booking information" />
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ marginTop: "20px" }}>
                    <p className="text-xl mb-5">
                      {" "}
                      <Translate text="Your Trip" />
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CheckinCheckoutPopup
                          anchorDateEl={anchorDateEl}
                          setAnchorDateEl={setAnchorDateEl}
                          filter={filter}
                          setFilter={setFilter}
                        />
                        <Grid
                          sx={{
                            background: "white",
                            position: "relative",
                            // cursor: "pointer",
                          }}
                          className="flex  w-[100%]  px-6 py-2 rounded-[1rem]"
                          // onClick={(e) => {
                          //   setAnchorDateEl(
                          //     anchorDateEl ? null : e.currentTarget
                          //   );
                          // }}
                        >
                          <Box>
                            <FormLabel>
                              {" "}
                              <Translate text="Dates" />
                            </FormLabel>

                            <Typography
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              {filter.fromDate?.toDateString()} -{" "}
                              {filter.toDate?.toDateString()}
                            </Typography>
                          </Box>
                          {/* <Box
                            sx={{ position: "absolute", top: 20, right: 20 }}
                          >
                            <CiEdit style={{ width: "30px", height: "30px" }} />
                          </Box> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <GuestPopup
                          anchorGuestEl={anchorGuestEl}
                          setAnchorGuestEl={setAnchorGuestEl}
                          filter={filter}
                          setFilter={setFilter}
                          bgColor={"bg-[#eeeeee]"}
                        />
                        <Grid
                          sx={{
                            background: "white",
                            position: "relative",
                            // cursor: "pointer",
                          }}
                          className="flex  w-[100%] px-6 py-2  rounded-[1rem]"
                          // onClick={(e) => {
                          //   setAnchorGuestEl(
                          //     anchorGuestEl ? null : e.currentTarget
                          //   );
                          // }}
                        >
                          <Box>
                            <FormLabel>
                              {" "}
                              <Translate text="Guests" />
                            </FormLabel>

                            <Typography
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              {filter.guest} <Translate text="Guests" />
                            </Typography>
                          </Box>
                          {/* <Box
                            sx={{ position: "absolute", top: 20, right: 20 }}
                          >
                            <CiEdit style={{ width: "30px", height: "30px" }} />
                          </Box> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <p className="text-xl mb-5">
                      {" "}
                      <Translate text="Payment Method" />
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          onClick={() => {
                            confirmBooking();
                          }}
                          sx={{
                            backgroundColor: "white",
                            cursor: "pointer",
                          }}
                          className="flex border  w-[100%] px-4 py-2  rounded-[1rem] hover:border-blue-400"
                        >
                          <Box className="flex flex-row sm:space-x-4 md:space-x-10 lg:space-x-6 xl:space-x-6 2xl:space-x-10">
                            <img
                              style={{ width: "100px", height: "70px" }}
                              alt="yoomoney"
                              src={yoomoneyLogo}
                            />
                            <Box sx={{ marginTop: "10px" }}>
                              {showSpinner ? (
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <>
                                  <Typography>YooMoney </Typography>
                                  <Box>Visa, Mastercard</Box>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="mt-20">
                  <Box
                    sx={{ background: "white" }}
                    className="flex   w-[100%] px-6 py-4  rounded-[1rem]"
                  >
                    <Grid container sx={{}}>
                      <Grid item xs={12}>
                        <Box sx={{ position: "relative" }}>
                          <Box className="flex flex-row space-x-4">
                            <CiPercent
                              style={{
                                width: "25px",
                                height: "25px",
                                color: "green",
                              }}
                            />
                            <Typography>
                              {" "}
                              <Translate text="promo code" />
                            </Typography>
                          </Box>
                          <Box sx={{ position: "absolute", top: 4, right: 0 }}>
                            <RiArrowDropDownLine
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="border-b-2 border-gray-300 my-4"></div>
                      </Grid>

                      <Grid item xs={12}>
                        <Box className="flex justify-between mt-6">
                          <Typography>
                            {" "}
                            <Translate text="Prepayment" />
                          </Typography>
                          <Typography>{priceToPay} P</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Box className="border border-2 rounded-lg px-2 flex justify-between  mt-4 bg-blue-100">
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label={<Translate text="apply bonuses" />}
                            />
                            <Box className=" flex justify-center items-center text-green-400 justify-center">
                              -200P
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="flex justify-between mt-6">
                          <Typography>
                            {" "}
                            <Translate text="To pay" />
                          </Typography>
                          <Typography>{priceToPay} P</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <button
                          onClick={() => {
                            confirmBooking();
                          }}
                          className="flex text-center gap-5 text-white justify-center py-[.5rem]  rounded-[1rem] my-[1rem] bg-primary items-center cursor-pointer w-full"
                        >
                          {showSpinner ? (
                            <TbLoaderQuarter />
                          ) : (
                            <h3 className="md:text-[1.2rem] ">
                              {" "}
                              <Translate text="Pay" />
                            </h3>
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{ background: "white" }}
                    className="flex   w-[100%] px-6 py-4 mt-20 rounded-[2rem]"
                  >
                    <Grid container sx={{}}>
                      <Grid item xs={12}>
                        <Link
                          to={`${AppLinks.ApartmentDetails.path}/${apartmentid}`}
                        >
                          <Box>
                            <CardMedia
                              sx={{
                                minHeight: 200,
                                minWidth: 100,
                                bgcolor: "lightgray",
                                borderRadius: "10%",
                              }}
                              image={
                                apartmentImage && apartmentImage !== ""
                                  ? apartmentImage
                                  : ""
                              }
                              // image=""
                              title="Apartment"
                            />
                            <CardContent>
                              <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "3",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                <Translate text="Apartment on" /> {apartmentLoc}
                              </Typography>
                              {/* <Box color="text.secondary">moscow 3d,</Box> */}
                              <Box sx={{ paddingTop: "10px" }}>
                                <Translate text="Total for" />{" "}
                                {apartmentDuration} day: {priceToPay} P
                              </Box>
                            </CardContent>
                          </Box>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ paddingX: "24px", marginTop: "30px" }}>
                        <Box color="text.secondary" className="text-[20px]">
                          <Translate text="Make a deposite as soon as possible while the property is still available" />
                        </Box>
                        <Box
                          className="text-blue-400"
                          sx={{ marginTop: "15px" }}
                        >
                          <Translate text="Agree on other condition and time of arrive/depature." />
                          <Link
                            to={"#"}
                            style={{ textDecoration: "underline" }}
                          >
                            <Translate text="Also see cancellation options" />
                          </Link>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div id="payment-form"></div>
      </Container>
    </>
  );
};
export default ConfirmAndPay;
