import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Translate } from "react-translated";

interface PrivacyPolicy {
  privacyOpen: boolean;
  handlePrivacyClose: () => void;
}

const PrivacyPolicyModal: FC<PrivacyPolicy> = ({
  privacyOpen,
  handlePrivacyClose,
}) => {
  return (
    <Modal
      open={privacyOpen}
      onClose={handlePrivacyClose}
      aria-labelledby="privacy-policy-modal-title"
      aria-describedby="privacy-policy-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: { xs: 500, sm: 500, lg: "60%" },
          width: { xs: "90%", sm: "90%", lg: "100%" },
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handlePrivacyClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <Typography id="privacy-policy-modal-title" variant="h6" component="h2">
          Privacy Policy
        </Typography> */}
        <Box
          id="privacy-policy-modal-description"
          sx={{
            mt: 2,
            maxHeight: "400px",
            overflowY: "auto",
            padding: "12px",

            borderTop: "1px solid #D9E6FF",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            className=" text-[#225FFE] font-bold"
          >
            <Translate text="Privacy Policy" />
          </Typography>
          <Typography
            className="py-4 text-[#132F41] font-bold"
            variant="h5"
            component="h2"
          >
            <Translate text="Your Privacy Policy" />
          </Typography>
          <Typography className="pl-[20px]" component="div">
            <ol className="list-decimal">
              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Introduction" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="Mira-Dom ('we', 'our', 'us') is committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our platform." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p>
                  <Translate text="Information We Collect" />
                </p>
                <p className=" text-[16px] font-normal">
                  <ul className="list-disc my-6">
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Personal Information" />
                      </p>
                      <p className="inline text-[#777777]">
                        :
                        <Translate text="Name, email address, phone number, and identification documents (international passport for foreigners,Russian passport for locals) for verification purposes." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Usage Data" />
                      </p>
                      <p className="inline text-[#777777]">
                        :
                        <Translate text="Information about how you use our platform, including your IP address, browser type, and device information." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Transaction Data" />
                      </p>
                      <p className="inline text-[#777777]">
                        :
                        <Translate text="Details about your bookings, payments, and transactions on our platform." />
                      </p>
                    </li>
                  </ul>
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="How We Use Your Information" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] mb-6">
                  <Translate text="We use your information to" />:
                </p>
                <ul className=" text-[16px] font-normal list-disc text-[#777777]">
                  <li>
                    <Translate text="Provide and improve our services." />
                  </li>
                  <li>
                    <Translate text="Process your bookings and payments." />
                  </li>
                  <li>
                    <Translate text="Verify your identity and ensure security." />
                  </li>
                  <li>
                    <Translate text="Communicate with you regarding your account and transactions." />
                  </li>
                  <li>
                    <Translate text="Personalize your experience on our platform." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold mt-6">
                <p className="">
                  <Translate text="Disclosure of Your Information" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] mb-6">
                  <Translate text="We may disclose your information to" />:
                </p>
                <p className=" text-[16px] font-normal">
                  <ul className="list-disc my-6">
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Service Providers" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Third-party vendors who help us operate our platform and provide our services." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Legal Authorities" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="If required by law or to protect our rights and interests." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Business Transfers" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner." />
                      </p>
                    </li>
                  </ul>
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Security" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="We take appropriate measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, access controls, and secure data storage." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="User Verification" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="Users must be verified to access the platform fully. Verification includes providing proof of identity using an international passport for foreigners and a Russian passport for locals. This process helps promote trust and security within the platform." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Your Rights" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] mb-6">
                  <Translate text="You have the right to" />:
                </p>
                <ul className=" text-[16px] font-normal list-disc text-[#777777]">
                  <li>
                    <Translate text="Access the personal information we hold about you." />
                  </li>
                  <li>
                    <Translate text="Request the correction of inaccurate information." />
                  </li>
                  <li>
                    <Translate text="Request the deletion of your personal information." />
                  </li>
                  <li>
                    <Translate text="Object to the processing of your personal information." />
                  </li>
                  <li>
                    <Translate text="Withdraw your consent at any time." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Cookies" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="We use cookies and similar technologies to enhance your experience on our platform. You can manage your cookie preferences through your browser settings." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Changes to This Privacy Policy" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our platform and updating the date at the top of this page." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Complaints and Contact Information" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="For any complaints, users can send us an email or message us on Telegram or WhatsApp at their convenience. Mira-Dom is a fully registered company in Russia and follows all applicable rules, laws, and taxes." />
                </p>
              </li>
            </ol>

            <div className="text-[20px] font-bold mt-6">
              <p className="">
                <Translate text="Contact Us" />:
              </p>

              <p className=" text-[16px] font-normal">
                <ul className="list-disc my-6">
                  <li>
                    <p className="inline font-bold">
                      <Translate text="Email" />:{" "}
                    </p>
                    <p className="inline text-primary">
                      <a
                        className="underline"
                        href="mailto:support@mira-dom.com"
                        target="_blank"
                      >
                        <Translate text="support@mira-dom.com" />
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="inline font-bold">
                      <Translate text="Telegram" />:{" "}
                    </p>
                    <p className="inline text-[#777777]">
                      <Translate text="Mira-Dom Support" />
                    </p>
                  </li>
                  <li>
                    <p className="inline font-bold">
                      <Translate text="WhatsApp" />:{" "}
                    </p>
                    <p className="inline text-[#777777]">
                      <Translate text="+7 (904) 663-69-63" />
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
