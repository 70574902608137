import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import preview from "../images/preview.png";
import { IoChevronBackSharp } from "react-icons/io5";
import { FC, useState, useCallback } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  useShowMsg,
  useToogleSignUp,
  useToogleLogIn,
  useIsLoggedIn,
} from "../context/Context";
import baseUrl from "../utils/config";
import { useHistory } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import axios from "axios";
import { APIS_URL } from "../services/api";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Translate } from "react-translated";
import Checkbox from "@mui/material/Checkbox";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";
import TermsAndConditionsModal from "../components/TermsAndConditionModals";
import RefundAndCancellationModal from "../components/RefundAndCancellationModal";

const SignUp: FC = () => {
  interface SignUpForm {
    firstname: string;
    lastname: string;
    dateofbirth: string;
    email: string;
    password: string;
    confirmPassword?: string; // Marked as optional
  }
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [refundOpen, setRefundOpen] = useState(false);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const redirect = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [checked, setChecked] = useState(false);
  const { setIsLoggedIn } = useIsLoggedIn();
  const { setToogleLogin } = useToogleLogIn();
  const { toogleSignup, handleToogleSignup, setToogleSignup } =
    useToogleSignUp();
  const { notifySuccess, notifyError } = useShowMsg();
  const [showNext, setShowNext] = useState(1);
  const [signUpForm, setSignUpForm] = useState<SignUpForm>({
    firstname: "",
    lastname: "",
    dateofbirth: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [signingUp, setSigningUp] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");

  const handleLogInChange = (e: any) => {
    setSignUpForm({ ...signUpForm, [e.target.name]: e.target.value });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handlePrivacyOpen = () => {
    setPrivacyOpen(true);
  };

  const handleTermsOpen = () => {
    setTermsOpen(true);
  };

  const handleRefundOpen = () => {
    setRefundOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };
  const handleRefundClose = () => {
    setRefundOpen(false);
  };

  const isUnderage = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      return age < 18;
    }
    return age < 18;
  };

  const handleAcceptTerm = async (e: any) => {
    e.preventDefault();
    setSigningUp(true);
    console.log("form", signUpForm);

    try {
      // console.log("-------------token------------------", token);
      if (!executeRecaptcha) return;
      const tokenRefresh = await executeRecaptcha();
      // console.log("tokenRefresh", tokenRefresh);
      const captchaResponse = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.VERIFY_CAPTCHA}`,

        data: { token: tokenRefresh },
      });

      // console.log("captchaResponse", captchaResponse);
      if (captchaResponse.data.success === true) {
        delete signUpForm.confirmPassword;
        const response = await axios({
          method: "post",
          url: `${baseUrl}${APIS_URL.SIGNUP}`,

          data: signUpForm,
        });

        if (response.data.success === false) {
          setSignupError(true);
          setSignupErrorMessage(response.data.error);
          setSigningUp(false);
          return;
        }

        if (response.status === 404) {
          setSignupError(true);
          setSignupErrorMessage("Route not found");
          setSigningUp(false);
          return;
        }

        if (response.status === 401) {
          setSignupError(true);
          setSignupErrorMessage(response.data.message);
          setSigningUp(false);
          return;
        }
        setSigningUp(false);
        notifySuccess(
          "Registration Successful, A Verification Mail has been sent to you"
        );

        handleToogleSignup();

        setSignUpForm({
          firstname: "",
          lastname: "",
          dateofbirth: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        setSignupError(false);
        setSignupErrorMessage("");
        setIsLoggedIn(false);
        setToogleSignup(false);
        setToogleLogin(true);
        redirect.push(AppLinks.Home.path);
      } else {
        setSignupError(true);
        setSigningUp(false);
        setSignupErrorMessage("Your session has expired. Please try again.");
      }
    } catch (error: any) {
      setSigningUp(false);
      console.log("error", error);
      setSignupError(true);

      setSignupErrorMessage("Something went wrong");

      // notifyError(error.response.data.message);
    }
  };

  // const setTokenFunc = useCallback((token: string) => {
  //   setToken(token);
  // }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleNextPage = () => {
    setError(false);
    setErrorMessage("");
    const underAge = isUnderage(signUpForm.dateofbirth);
    if (underAge) {
      setError(true);
      setErrorMessage("You must be 18 years or older to use this service.");
      return;
    }
    if (
      signUpForm.firstname === "" ||
      signUpForm.lastname === "" ||
      signUpForm.dateofbirth === "" ||
      signUpForm.email === "" ||
      signUpForm.password === "" ||
      signUpForm.confirmPassword === ""
    ) {
      setError(true);
      setErrorMessage("All fields are required to be filled");
      // notifyError("Please fill all fields");
      return;
    }

    if (signUpForm.password !== signUpForm.confirmPassword) {
      setError(true);
      setErrorMessage("Password does not match");
      return;
    }

    setShowNext((showNext) => ++showNext);
  };

  const handlePreviousPage = () => {
    setSignupError(false);
    setSignupErrorMessage("");
    setShowNext((showNext) => --showNext);
  };

  const handleBackToHome = () => {
    handleToogleSignup();
  };

  const handleDecline = () => {
    setSignUpForm({
      firstname: "",
      lastname: "",
      dateofbirth: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    handleToogleSignup();
    setSignupError(false);
    setSignupErrorMessage("");
    setError(false);
    setErrorMessage("");
    setShowNext(1);
  };

  const clearField = () => {
    setSignUpForm({
      firstname: "",
      lastname: "",
      dateofbirth: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setError(false);
    setErrorMessage("");
    setSignupError(false);
    setSignupErrorMessage("");
    setShowNext(1);
    handleToogleSignup();
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return (
    <>
      <Dialog
        open={toogleSignup && showNext === 1}
        onClose={clearField}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle textAlign="center">
          <Translate text="Sign up or" />{" "}
          <button
            onClick={() => {
              setToogleSignup(false);
              setToogleLogin(true);
            }}
            className="text-primary underline"
          >
            <Translate text="Login" />
          </button>{" "}
          <Translate text="on" /> <Translate text="Mira" /> -
          <span className="text-primary">
            <Translate text="Dom" />
          </span>
          <br />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                placeholder="First Name"
                type="text"
                name="firstname"
                id=""
                value={signUpForm.firstname}
                onChange={handleLogInChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                placeholder="Last Name"
                type="text"
                name="lastname"
                id=""
                value={signUpForm.lastname}
                onChange={handleLogInChange}
              />
              <small>
                <Translate text="Make sure it matches the name on your intenational Passport, for easy verification" />
              </small>
            </Grid>
            <Grid item xs={12}>
              <input
                className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                placeholder="Date of birth"
                type="date"
                name="dateofbirth"
                id=""
                value={signUpForm.dateofbirth}
                onChange={handleLogInChange}
                max={yesterday.toISOString().split("T")[0]}
              />
              <small>
                <Translate text=" To sign up, you need at least 18, Birthdays are kept private and not shared." />
              </small>
            </Grid>
            <Grid item xs={12}>
              <Input
                className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                placeholder="Email"
                type="email"
                name="email"
                id=""
                value={signUpForm.email}
                onChange={handleLogInChange}
              />
              <small>
                <Translate text="We'll email trip confirmations and reciepts to this mail" />
              </small>
            </Grid>
            <Grid item xs={12}>
              <div className="relative">
                <input
                  className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                  placeholder="Password"
                  // type="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id=""
                  value={signUpForm.password}
                  onChange={handleLogInChange}
                />
                <button
                  type="button"
                  className="btn btn-link absolute top-6 right-5 translate-middle-y"
                  onClick={togglePasswordVisibility}
                  style={{ zIndex: 1 }} // Ensure the button is above the input
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="relative">
                <input
                  className="border border-[#647482] w-[100%]  px-[1.5rem] py-[1.1rem] rounded-[2.5rem] mt-1"
                  placeholder="Confirm Password"
                  // type="password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  id=""
                  value={signUpForm.confirmPassword}
                  onChange={handleLogInChange}
                />
                <button
                  type="button"
                  className="btn btn-link absolute top-6 right-5 translate-middle-y"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ zIndex: 1 }} // Ensure the button is above the input
                >
                  {showConfirmPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <p className="text-[.9rem] my-[1rem] text-[#647482]">
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Translate text="By selecting" />
                <span>
                  {" "}
                  <Translate text="Agree and Continue" />
                </span>
                , <Translate text="i agree to Miradom's" />{" "}
                {/* <a href="">
                  {" "}
                  <Translate text="Terms of Service, Payments Terms of Service" />
                </a> */}
                <span
                  className="cursor-pointer text-[#225FFE] underline"
                  onClick={handleTermsOpen}
                >
                  <Translate text="Teams & Conditions" />
                </span>
                ,{" "}
                <span
                  className="cursor-pointer text-[#225FFE] underline"
                  onClick={handleRefundOpen}
                >
                  <Translate text="Refund and Cancellation Policy" />
                </span>{" "}
                <Translate text="and" /> {/* , <Translate text="and" />{" "} */}
                <a href="/">
                  {" "}
                  <Translate text="Nondiscrimination Policy" />
                </a>{" "}
                <Translate text="and acknowledge the" />{" "}
                <span
                  className="cursor-pointer text-[#225FFE] underline "
                  onClick={handlePrivacyOpen}
                >
                  <Translate text="Privacy Policy" />
                </span>
                {/* <a href="">
                  {" "}
                  <Translate text="Private Policy." />
                </a> */}
              </p>
              <PrivacyPolicyModal
                privacyOpen={privacyOpen}
                handlePrivacyClose={handlePrivacyClose}
              />
              <TermsAndConditionsModal
                termsOpen={termsOpen}
                handleTermsClose={handleTermsClose}
              />
              <RefundAndCancellationModal
                refundOpen={refundOpen}
                handleRefundClose={handleRefundClose}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <button
            disabled={!checked}
            onClick={handleNextPage}
            type="submit"
            className={` ${
              checked
                ? "cursor-pointer bg-primary hover:bg-blue-800"
                : " bg-[#777777]  cursor-not-allowed"
            }  py-[.5rem] mx-[15px]  mb-[15px] rounded-[2.5rem] px-[2rem]  text-white  w-full`}
          >
            <Translate text="Accept and continue" />
          </button>
        </DialogActions>
        {error && (
          <Box
            sx={{
              color: "red",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            {errorMessage}
          </Box>
        )}
      </Dialog>
      <Dialog
        open={toogleSignup && showNext === 2}
        onClose={clearField}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IoChevronBackSharp
            onClick={handlePreviousPage}
            className="text-[2rem] cursor-pointer"
          />
          <img src={preview} alt="" className="h-10 mx-auto" />
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="text-[20px] md:text-[20px] sm:text-[15px] xs:text-[15px]">
                <Translate text="At Miradom, there is no bias, and differences and we treat each other property as ours and hence no vandalization of property. We believe that the mutual trust between the Host and Guest will be maintained for the best interest of everyone." />
              </h3>
            </Grid>
            <Grid item xs={12}>
              <p className="text-[15px]  sm:text-[10px] xs:text-[10px] text-[#647482]">
                <Translate text="To ensure thie, we're asking you to commit to the following:" />
              </p>
            </Grid>
            <Grid item xs={12}>
              <p className="text-[15px] sm:text-[10px] xs:text-[10px]  text-[#647482]">
                <Translate text="I agree to treat everyone in the Miradom community regardless of their race religion, national origin, ethnicity, skin color. disability, sex, gender identity, sexual orientation or age - with respect , and without judgement or bias" />
              </p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginX: "30px", marginTop: "30px" }}>
              {signupError && (
                <Box
                  sx={{
                    color: "red",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "18px",
                    marginY: "10px",
                  }}
                >
                  {signupErrorMessage}
                </Box>
              )}
              {!signingUp ? (
                <button
                  onClick={handleAcceptTerm}
                  className=" cursor-pointer bg-primary py-[.5rem] rounded-[2.5rem] px-[2rem] text-white hover:bg-blue-800 w-full"
                >
                  <Translate text="Accept and continue" />
                </button>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginX: "30px", marginBottom: "30px" }}>
              <button
                onClick={handleDecline}
                className="cursor-pointer border border-[#225ffe] hover:border-blue-800 hover:border-[3px] py-[.5rem] rounded-[2.5rem] px-[2rem] text-primary hover:shadow-md w-full"
              >
                <Translate text="Decline" />
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignUp;
