import "animate.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { FaRegBell } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import Buttons from "./shared/Button";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  useIsLoggedIn,
  useToogleLogIn,
  useUserContext,
  useNotificationContext,
} from "../context/Context";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import { AppLinks } from "../utils/Linking";
import preview from "../images/preview.png";
import {
  Box,
  Modal,
  Badge,
  Typography,
  AppBar,
  Container,
  Toolbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import style from "./nav.module.css";
import NotificationPopup from "./NotificationPopup";
import { IoIosLogOut } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { AiOutlineApartment } from "react-icons/ai";
import { SlCalender } from "react-icons/sl";
import { MdApartment } from "react-icons/md";
import { AnyARecord } from "dns";
import Drawer from "@mui/material/Drawer";
// import { useRef } from "react";
import MoreOptions from "./MoreOptions";
import LanguageOptions from "./LanguageOptions";
import { Translate } from "react-translated";
import Avatar from "@mui/material/Avatar";
import profile from "../images/profile.png";
import { fontFamily, shadows } from "@mui/system";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Nav = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isLoggededIn } = useIsLoggedIn();
  const [isOpen, setIsOpen] = useState(false);
  const { user, updateUser, socket } = useUserContext();
  const { notification, updateNotification } = useNotificationContext();
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLanguageDropDown, setIsLanguageDropDown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const languageDropdownRef = useRef<HTMLDivElement | null>(null);

  const navRef = useRef<HTMLDivElement | null>(null);

  const [showNav, setShowNav] = useState(false);

  const { setToogleLogin } = useToogleLogIn();

  const handleIconClick = () => {
    setIsNotifyOpen(!isNotifyOpen);
  };

  const handleShowProfile = () => {
    history.push(AppLinks.Profile.path);
  };

  const showBecomeAHost = () => {
    history.push(AppLinks.ListApartment.path);
  };

  const handleToogleLogin = (event: any) => {
    history.push(AppLinks.Landing.path);
    setToogleLogin(true);
  };

  // console.log("isLoggededIn", isLoggededIn);
  const handleSupportClick = () => {
    history.push(AppLinks.Support.path);
  };
  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window.document.body : undefined;

  //   // setUnreadCount(0);
  //   console.log("set count2 0");
  //   updateUser({
  //     ...user,
  //     unread: unreadCount,
  //   });
  // }, []);

  // console.log("socket", socket);

  useEffect(() => {
    if (showNav) {
      const handleOutsideClick = (event: any) => {
        // Check if the click event target is outside the navbar
        if (navRef.current && !navRef.current.contains(event.target)) {
          setShowNav(false);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [showNav]);

  useEffect(() => {
    if (showNav) {
      document.body.classList.add("overflow-hidden");
      document.body.style.overflow = "hidden";
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.overflow = "auto";
    }
  }, [showNav]);

  useEffect(() => {
    const handleResize = () => {
      if (!isOpen) {
        document.body.classList.remove("overflow-hidden");
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  const handleLogoClick = () => {
    const destination = isLoggededIn ? "/home" : "/";

    if (
      location.pathname === "/home" ||
      destination === "/home" ||
      location.pathname === "/" ||
      destination === "/"
    ) {
      history.push(destination);
      window.location.reload(); // Reload the page if on the home page or navigating to the home page
    } else {
      history.push(destination); // Navigate to the destination otherwise
    }
  };

  const imageLogic = () => {
    if (user.imageurl === "" && user.firstname !== "") {
      return (
        <div className="flex flex-col items-center">
          <Avatar
            sx={{
              width: { xs: 24, sm: 24, md: 40, lg: 40 },
              height: { xs: 24, sm: 24, md: 40, lg: 40 },
            }}
            className="cursor-pointer"
            alt="profile"
            src={profile}
          />
          <small className=" text-[10px] text-center">
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
              }}
            >
              {user.firstname} {user.lastname}
            </Box>
          </small>
        </div>
      );
    }
    if (user.imageurl !== "" && user.firstname !== "") {
      return (
        <div className="flex flex-col items-center">
          <img
            src={user.imageurl}
            alt="User Profile"
            className="rounded-full h-10 w-10 xs:h-6 xs:w-6 sm:h-6 sm:w-6 cursor-pointer"
          />
          <small className="xs:hidden sm:hidden md:hidden text-[10px] text-center">
            {user.firstname} {user.lastname}
          </small>
        </div>
      );
    }
  };

  const closeDropdown = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropDownOpen(false);
    }
  };

  const closeLanguageDropdown: any = (event: any) => {
    if (
      languageDropdownRef.current &&
      !languageDropdownRef.current.contains(event.target)
    ) {
      setIsLanguageDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    document.addEventListener("click", closeLanguageDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
      document.addEventListener("click", closeLanguageDropdown);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const toggleLanguageDropdown = (event: any) => {
    event.stopPropagation();

    toggleSideBarDropdown();

    setIsLanguageDropDown(!isLanguageDropDown);
  };

  const languageHandler = (locale: any) => {
    updateUser({ ...user, locale });
    // window.location.reload();
  };
  const handleProfileClick = () => {
    handleShowProfile();
  };
  const handleLogoutClick = () => {
    setIsLogout(true);
  };

  const ConfirmLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const closeLogout = () => {
    setIsLogout(false);
  };
  const NotSeenNotificationsCount = notification.filter(
    (item: any) => !item.seen
  ).length;

  // console.log("NotSeenNotificationsCount", NotSeenNotificationsCount);

  const [isLanguageSideBar, setIsLanguageSideBar] = useState(false);

  const toggleSideBarDropdown = () => {
    setIsLanguageSideBar(!isLanguageSideBar);
  };

  return (
    <Box className="relative">
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#EEF5FF",
          py: "10px",
          boxShadow: "none",
        }}
      >
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: "16px" } }}>
          <Toolbar>
            <Box sx={{ display: "flex", mr: 1 }}>
              <div className=" flex items-center font-semibold text-[1.8rem] w-40  h-10 ">
                <Link to={isLoggededIn ? "/home" : "/"}>
                  <img
                    className=""
                    src={preview}
                    alt="miradom"
                    onClick={handleLogoClick}
                  />
                </Link>
              </div>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                ml: "auto",
              }}
            >
              <h3
                onClick={(e) => {
                  history.push(AppLinks.Support.path);
                }}
                className="flex items-center cursor-pointer text-text-gray hover:text-black text-[20px] mx-6"
              >
                <Translate text="Contact" />
              </h3>

              <div
                onClick={toggleLanguageDropdown}
                ref={languageDropdownRef}
                className="flex flex-row items-center cursor-pointer text-text-gray hover:text-black text-[20px] gap-2 mx-4"
              >
                <TbWorld className="text-lg" />

                <h3>
                  <Translate text="Language" />
                </h3>

                <Box className={style.language_dropdown_container}>
                  {isLanguageDropDown && (
                    <div className={style.language_dropdown}>
                      <Box
                        sx={{
                          p: "15px 20px",
                          border: "none",
                          outline: "none",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          onClick={() => languageHandler("en")}
                          className="flex flex-row space-x-2 text-text-gray "
                        >
                          <button>English</button>
                        </Box>
                        <Box
                          onClick={() => languageHandler("ru")}
                          className="flex flex-row space-x-2 text-text-gray "
                        >
                          <button>Русский</button>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Box>
              </div>
              <Box>
                <button
                  onClick={isLoggededIn ? showBecomeAHost : handleToogleLogin}
                  className="border rounded-full text-[20px] bg-white px-7 py-3 text-black hover:bg-black hover:text-white whitespace-nowrap mx-2"
                  type="button"
                >
                  <Translate text="Become a host" />
                </button>
              </Box>
              <div className="flex items-center mx-6">
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <Box className="relative">
                    <FaRegBell
                      onClick={handleIconClick}
                      className="text-base text-text-gray  hover:text-black"
                    />
                    {isLoggededIn && (
                      <Box>
                        {NotSeenNotificationsCount > 0 && (
                          <span className="absolute top-0 right-0 -mt-4 -mr-2 px-2 py-1 text-[10px] text-white bg-green-500 rounded-full">
                            {NotSeenNotificationsCount}
                          </span>
                        )}
                        <NotificationPopup
                          isNotifyOpen={isNotifyOpen}
                          onClose={handleIconClick}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                ml: "20px",
                mr: "20px",
              }}
            >
              <Tooltip title="Open settings">
                {isLoggededIn ? (
                  <div className={style.dropdown_container} ref={dropdownRef}>
                    <IconButton onClick={toggleDropdown} sx={{ p: 0 }}>
                      {imageLogic()}
                    </IconButton>

                    {isDropDownOpen && (
                      <div className={style.dropdown}>
                        <Box
                          sx={{
                            p: "0px 0px",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            style={{ fontFamily: "inter" }}
                            onClick={handleProfileClick}
                            className="flex  text-[#000000] text-[24px] hover:bg-[#EFF1F3] py-[12px]  "
                          >
                            <button>
                              <Translate text="Profile" />
                            </button>
                          </Box>

                          {user.unread > 0 ? (
                            <Link
                              to={"/messages"}
                              style={{ fontFamily: "inter" }}
                              className="flex flex-row justify-between hover:bg-[#EFF1F3] py-[12px]  items-center space-x-2 text-[#000000] text-[24px]"
                            >
                              <Badge color="warning" variant="dot">
                                <button
                                  style={{
                                    fontFamily: "inter",
                                    textAlign: "left",
                                  }}
                                >
                                  <Translate text="Messages" />
                                </button>
                              </Badge>
                              <Typography
                                className="text-[#000000] text-[16px]"
                                sx={{
                                  marginLeft: "auto",
                                  fontFamily: "inter",
                                }}
                              >
                                {user.unread} <Translate text="unread" />
                              </Typography>
                            </Link>
                          ) : (
                            <Link
                              to={"/messages"}
                              style={{ fontFamily: "inter" }}
                              className="flex flex-row justify-between hover:bg-[#EFF1F3] py-[12px] items-center space-x-2 text-[#000000] text-[24px]"
                            >
                              <button
                                style={{
                                  fontFamily: "inter",
                                  textAlign: "left",
                                }}
                              >
                                <Translate text="Messages" />
                              </button>
                            </Link>
                          )}

                          <Link
                            to={"/my-apartment"}
                            style={{ fontFamily: "inter" }}
                            className="flex  hover:bg-[#EFF1F3] py-[12px]  text-[#000000] text-[24px] "
                          >
                            <button
                              style={{ fontFamily: "inter", textAlign: "left" }}
                            >
                              {" "}
                              <Translate text="My Apartment" />
                            </button>
                          </Link>

                          <Link
                            to={"/unapproved-apartment"}
                            style={{ fontFamily: "inter" }}
                            className="flex   hover:bg-[#EFF1F3] py-[12px]  text-[#000000] text-[24px] "
                          >
                            <button
                              style={{ fontFamily: "inter", textAlign: "left" }}
                            >
                              <Translate text="Under Review Apt" />
                            </button>
                          </Link>

                          <Link
                            to={"/mybookings"}
                            className="flex  hover:bg-[#EFF1F3] py-[12px]  text-[#000000] text-[24px] "
                            style={{ fontFamily: "inter" }}
                          >
                            <button>
                              <Translate text="My Bookings" />
                            </button>
                          </Link>

                          <Link
                            to={"/allbooked-apartment"}
                            className="flex  hover:bg-[#EFF1F3] py-[12px]   text-[#000000] text-[24px]"
                            style={{ fontFamily: "inter" }}
                          >
                            <button>
                              <Translate text="All Bookings" />
                            </button>
                          </Link>

                          <Box
                            onClick={handleLogoutClick}
                            className="flex  hover:bg-[#EFF1F3] py-[12px]   text-[#000000] text-[24px]"
                            style={{ fontFamily: "inter" }}
                          >
                            <button>
                              {" "}
                              <Translate text="Logout" />
                            </button>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : (
                  <IconButton
                    onClick={(event) => {
                      handleToogleLogin(event);
                    }}
                    sx={{ p: 0 }}
                  >
                    <Avatar alt="profile" src={profile} />
                  </IconButton>
                )}
              </Tooltip>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" }, ml: "auto" }}>
              <Tooltip
                title="Open sidbar"
                onClick={() => {
                  setShowNav(!showNav);
                }}
              >
                {showNav ? (
                  <IconButton>
                    <MdClose className="text-xl" />
                  </IconButton>
                ) : (
                  <IconButton>
                    <MenuIcon />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        ref={navRef}
        className={`  ${style.hide_scrollbar}`}
        sx={{
          display: { xs: showNav ? "flex" : "none", md: "none" },
          backgroundColor: "#EEF5FF",
          textAlign: "center",
          minWidth: "250px",
          height: "360px",
          position: "fixed",
          top: "60px",
          zIndex: 2,
          borderRadius: "0 0 20px 0",
          overflowY: "auto",
          filter: "drop-shadow(5px 5px 10px gray)",
        }}
      >
        <Box className="mt-[60px]  mx-auto px-10 ">
          <div
            onClick={(e) => {
              history.push(AppLinks.Support.path);
            }}
            className="flex items-center cursor-pointer text-text-gray hover:text-black text-[15px] mb-8"
          >
            <Translate text="Contact" />
          </div>

          <div className="text-[15px]  cursor-pointer flex   hover:bg-white text-text-gray  items-center flex-row gap-2 ">
            <TbWorld className=" " />
            <Box className={style.dropdown_container} ref={languageDropdownRef}>
              <h3 onClick={toggleLanguageDropdown}>
                <Translate text="Language" />
              </h3>
            </Box>
          </div>
          <Box className="pl-[10px] mt-2">
            <LanguageOptions isLanguageSideBar={isLanguageSideBar}>
              <Box className="px-3">
                <Box
                  onClick={() => languageHandler("en")}
                  className="flex border-t border-b py-2 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                >
                  <button>English</button>
                </Box>
                <Box
                  onClick={() => languageHandler("ru")}
                  className="flex  border-b py-2 mb-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                >
                  <button>Русский</button>
                </Box>
              </Box>
            </LanguageOptions>
          </Box>
          <Box>
            <button
              onClick={isLoggededIn ? showBecomeAHost : handleToogleLogin}
              className="flex  text-[15px]  border rounded-full px-6 py-4  bg-white text-black hover:bg-black hover:text-white whitespace-nowrap my-8"
              type="button"
            >
              <Translate text="Become a host" />
            </button>
          </Box>

          <div className=" ">
            {isLoggededIn ? (
              <Link to={`${AppLinks.Notification.path}`}>
                <Box className="flex flex-row space-x-2  text-[15px] ">
                  <Box className="relative">
                    <FaRegBell className=" text-text-gray  hover:text-black" />
                    {NotSeenNotificationsCount > 0 && (
                      <span className="absolute top-0 right-0 -mt-4 -mr-2 px-2 py-1 text-[10px] text-white bg-green-500 rounded-full">
                        {NotSeenNotificationsCount}
                      </span>
                    )}
                  </Box>

                  <div className="  md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray">
                    <Translate text="Notification" />
                  </div>
                </Box>
              </Link>
            ) : (
              <Box className="flex flex-row space-x-2 text-[15px] ">
                <FaRegBell className=" text-text-gray  hover:text-black" />
                <div className="md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray">
                  <Translate text="Notification" />
                </div>
              </Box>
            )}
          </div>

          <div className=" text-center my-8">
            {isLoggededIn ? (
              <Box>
                <Box
                  onClick={handleShowProfile}
                  className="flex flex-row space-x-2 text-[15px] "
                >
                  {imageLogic()}
                  <div className="text-text-gray">
                    {" "}
                    <Translate text="Profile" />
                  </div>
                </Box>
              </Box>
            ) : (
              <Box>
                {" "}
                <Box
                  className="small flex flex-row space-x-2 text-[15px] "
                  sx={{ display: { md: "none" } }}
                  onClick={(event) => {
                    handleToogleLogin(event);
                  }}
                >
                  <Avatar
                    className=" cursor-pointer"
                    alt="profile"
                    sx={{ width: 24, height: 24 }}
                    src={profile}
                  />
                  <div>
                    <Translate text="Profile" />
                  </div>
                </Box>
              </Box>
            )}
          </div>

          <Box>
            {isLoggededIn && (
              <Box>
                <Box className="">
                  <MoreOptions title={<Translate text="More Options" />}>
                    <Box className="px-3">
                      <Link
                        to={"/messages"}
                        className="flex flex-row space-x-2 border-t border-b py-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                      >
                        {user.unread > 0 ? (
                          <Badge color="warning" variant="dot">
                            <button
                              style={{ fontFamily: "inter", textAlign: "left" }}
                            >
                              <Translate text="Messages" /> {user.unread}
                            </button>
                          </Badge>
                        ) : (
                          <Translate text="Messages" />
                        )}
                      </Link>
                      <Link
                        to={"/my-apartment"}
                        className="flex flex-row space-x-2 border-t border-b py-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                      >
                        <button
                          style={{ fontFamily: "inter", textAlign: "left" }}
                        >
                          <Translate text="My Apartment" />
                        </button>
                      </Link>
                      <Link
                        to={"/unapproved-apartment"}
                        className="flex flex-row space-x-2 border-t border-b py-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                      >
                        <button
                          style={{ fontFamily: "inter", textAlign: "left" }}
                        >
                          <Translate text="Under Review Apt" />
                        </button>
                      </Link>
                      <Link
                        to={"/mybookings"}
                        className="flex flex-row space-x-2  border-b py-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                      >
                        <button
                          style={{ fontFamily: "inter", textAlign: "left" }}
                        >
                          {" "}
                          <Translate text="My Bookings" />
                        </button>
                      </Link>
                      <Link
                        to={"/allbooked-apartment"}
                        className="flex flex-row space-x-2  border-b py-4 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray"
                      >
                        <button
                          style={{ fontFamily: "inter", textAlign: "left" }}
                        >
                          {" "}
                          <Translate text="All Bookings" />
                        </button>
                      </Link>
                    </Box>
                  </MoreOptions>
                </Box>

                <Box className="pb-6 pt-[1px]">
                  <Box
                    onClick={handleLogoutClick}
                    className="flex flex-row my-5 space-x-2 cursor-pointer    hover:bg-white text-text-gray   hover:text-black"
                  >
                    <IoIosLogOut className="w-6 h-6 " />
                    <Box className="text-[15px] ">
                      <Translate text="Logout" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Modal open={isLogout} onClose={closeLogout}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: { xs: 500, sm: 500, lg: 350 },
            width: { xs: "90%", sm: "90%", lg: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography variant="h6" fontWeight="bold">
              <Translate text="Are you sure you want to logout?" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <button
                onClick={closeLogout}
                className="cursor-pointer, text-gray-800"
              >
                <Translate text="Cancel" />
              </button>
              <button
                onClick={ConfirmLogout}
                className="cursor-pointer, text-blue-800"
              >
                <Translate text="Logout" />
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Nav;
