import React from "react";
import styles from "./overlay.module.css";

interface OverlayStructure {
  isLoading: Boolean;
}
const OverlayTwo = ({ isLoading }: OverlayStructure) => {
  return (
    <div
      className={`${styles.overlay_three} ${
        isLoading ? styles.overlayVisible : ""
      }`}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

export default OverlayTwo;
