import React, { ReactNode, useEffect, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./styles/tailwind.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import Home from "./pages/home";
import { AppLinks } from "./utils/Linking";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import GlobalContext from "./context/Context";
import ApartmentListing from "./pages/ApartmentListing";
import AllBookedApartment from "./pages/AllBookedApartment";
import MyBookings from "./pages/MyBookings";
import MyApartment from "./pages/MyApartment";
import MyApartmentDetails from "./pages/MyApartmentDetails";
import Landing from "./pages/Landing";
import ApartmentDetails from "./pages/ApartmentDetails";
import Checkout from "./pages/Checkout";
import ListYourApartment from "./pages/ListYourApartment";
import Support from "./pages/support/Support";
import Messages from "./pages/messages";
import Notification from "./pages/Notification";
import LogIn from "./pages/LogIn";
import { ToastContainer } from "react-toastify";
import ErrorPage from "./pages/ErrorPage";
import ConfirmAndPay from "./pages/ConfirmAndPay";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SocialsFloating from "./components/Socials";
import { useIsLoggedIn } from "./context/Context";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";

import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import Translator from "./Translator";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { Provider } from "react-translated";
import translation from "./translation.js";
import PublicProfile from "./pages/PublicProfile";
import UnApprovedApartment from "./pages/UnApprovedApartment";

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    console.log("err1", error);
    if (error.response.status !== 403) {
      // console.log("er", error);
      return error.response;
    }
    // if (error.response.status == 403) {
    //   console.log("er2", error);
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
    if (error.response.status === 403 && !!localStorage.getItem("token")) {
      console.log("Logged out");
      localStorage.clear();
      window.location.href = "/";
    }
  }
);

const AuthChecker = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation();

  const { setIsLoggedIn } = useIsLoggedIn();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      history.push(AppLinks.Landing.path);
    }
    if (token) {
      const tokenData = JSON.parse(atob(token.split(".")[1])); // Decoding JWT payload
      const tokenExp = tokenData.exp * 1000; // Converting expiration time to milliseconds
      const currentTime = new Date().getTime();

      if (currentTime > tokenExp) {
        localStorage.clear();
        window.location.href = "/";
      } else {
        setIsLoggedIn(true);
        history.push(location.pathname + location.search);
      }
    }
  }, []);
  return <div>{children}</div>;
};

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
root.render(
  <Router>
    {/* <React.StrictMode> */}
    <SocialsFloating>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={`${siteKey}`}
          scriptProps={{ async: true, defer: true, appendTo: "body" }}
        >
          <GlobalContext>
            {/* <Provider language="ru" translation={translation}> */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            <Switch>
              <Route exact path={`${AppLinks.Landing.path}`}>
                <AuthChecker>
                  <Home />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.Home.path}`}>
                {" "}
                <AuthChecker>
                  <Home />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.SignUp.path}`} component={SignUp} />

              <Route path={`${AppLinks.LogIn.path}`}>
                <LogIn />
              </Route>

              <Route path={`${AppLinks.Profile.path}`}>
                <AuthChecker>
                  <Profile />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.PublicProfile.path}`}>
                <AuthChecker>
                  <PublicProfile />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.EditProfile.path}`}>
                <AuthChecker>
                  <EditProfile />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.ApartmentListing.path}`}>
                <AuthChecker>
                  <ApartmentListing />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.AllBookedApartment.path}`}>
                <AuthChecker>
                  <AllBookedApartment />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.MyBookings.path}`}>
                <AuthChecker>
                  <MyBookings />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.MyApartment.path}`}>
                <AuthChecker>
                  <MyApartment />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.UnApprovedApartment.path}`}>
                <AuthChecker>
                  <UnApprovedApartment />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.MyApartmentDetails.path}/:apartmentid`}>
                <AuthChecker>
                  <MyApartmentDetails />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.ApartmentDetails.path}/:apartmentid`}>
                <AuthChecker>
                  <ApartmentDetails />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.ConfirmAndPay.path}/:apartmentid`}>
                <AuthChecker>
                  <ConfirmAndPay />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.Checkout.path}`}>
                <AuthChecker>
                  <Checkout />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.ListApartment.path}`}>
                <AuthChecker>
                  <ListYourApartment />
                </AuthChecker>
              </Route>

              <Route path={`${AppLinks.Support.path}`}>
                <Support />
              </Route>
              <Route path={`${AppLinks.Messages.path}`}>
                <Messages />
              </Route>
              <Route path={`${AppLinks.Notification.path}`}>
                <Notification />
              </Route>
              <Route path="/*" component={ErrorPage} />
            </Switch>

            <ToastContainer />
            {/* </Provider> */}
          </GlobalContext>
        </GoogleReCaptchaProvider>
      </GoogleOAuthProvider>
    </SocialsFloating>
    {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
