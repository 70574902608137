import { FC } from "react";

interface InputStructure {
  className: string;
  placeholder: string;
  type: string;
  name: string;
  id: string;
  onChange: any;
  value: any;
}

const Input: FC<InputStructure> = ({
  className,
  placeholder,
  type,
  name,
  value,
  id,
  onChange,
}) => {
  return (
    <>
      <input
        className={className}
        placeholder={placeholder}
        type={type}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
      />
    </>
  );
};

export default Input;
