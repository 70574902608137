import { FC, useState } from "react";
import { Popover } from "@mui/material";
import Calendar from "react-calendar";
import style from "./landingcalender.module.css";
// import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import dayjs, { Dayjs } from "dayjs";
// import  DateRange  from "@mui/x-date-pickers-pro/models";
interface Filter {
  location: string;
  fromDate: Date | null;
  toDate: Date | null;
}
interface CheckinCheckoutPopupProps {
  anchorDateEl: HTMLElement | null;
  setAnchorDateEl: (el: HTMLDivElement | null) => void;
  filter: { location: string; fromDate: Date | null; toDate: Date | null };

  setFilter: Function;
}

const CheckinCheckoutPopup: FC<CheckinCheckoutPopupProps> = ({
  anchorDateEl,
  setAnchorDateEl,
  filter,
  setFilter,
}) => {
  const [value, setValue] = useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);

  return (
    <Popover
      open={Boolean(anchorDateEl)}
      anchorEl={anchorDateEl}
      onClose={() => setAnchorDateEl(null)}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="shadow-2xl bg-[#eeeeee]  rounded-lg">
          <div className=" overflow-y-auto p-5 rounded-2xl max-h-[50vh]">
            {/* <Calendar
            className={style.apartment_list}
            onChange={(e: any) => {
              setFilter((filter: Filter) => ({
                ...filter,
                fromDate: e[0],
                toDate: e[1],
              }));
              setAnchorDateEl(null);
            }}
            selectRange
            value={[filter.fromDate, filter.toDate]}
          /> */}
            <DemoContainer components={["DateRangeCalendar"]}>
              <DateRangeCalendar
                // value={[, dayjs(filter.toDate)]}
                value={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
                onChange={(e: any) => {
                  console.log("edate", e);
                  // console.log("date in out", e[0].$d);
                  setFilter((filter: Filter) => ({
                    ...filter,
                    fromDate: e[0].$d,
                    toDate: e[1].$d,
                    // toDate: e[0].$d,
                  }));
                  setAnchorDateEl(null);
                }}
              />
            </DemoContainer>
          </div>
        </div>
      </LocalizationProvider>
    </Popover>
  );
};

export default CheckinCheckoutPopup;
