import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext, useNotificationContext } from "../context/Context";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { Translate } from "react-translated";
import NotificationHelper from "../utils/notificationTypeHelper";
import style from "./nav.module.css";
import translateText from "../utils/translationHelper";
import baseUrl from "../utils/config";

interface NotificationPopupProps {
  isNotifyOpen: boolean;
  onClose: () => void;
}

interface NotificationItem {
  notification: any;
  // onClose: Function;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  isNotifyOpen,
  onClose,
}) => {
  const { notification, updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const firstFiveNotification = notification.slice().reverse().slice(0, 5);
  const auth = localStorage.getItem("token");

  useEffect(() => {
    const translateNotify = async () => {
      try {
        const notificationResponse = await axios({
          method: "get",
          url: `${baseUrl}${APIS_URL.GET_ALL_NOTIFICATION}`,

          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
        let status = notificationResponse.status;
        if (status === 200) {
          let notificationData = notificationResponse.data.notifications;
          if (user.locale !== "en") {
            let notifyData = await Promise.all(
              notificationData.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            updateNotification(notifyData);
            return;
          }
          updateNotification(notificationData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    translateNotify();
  }, [user.locale]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    if (isNotifyOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotifyOpen, onClose]);

  const setSeen = (id: string) => {};

  const NotificationItem: React.FC<NotificationItem> = ({
    notification,
    // onClose,
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const deleteNotification = async (id: any) => {
      // alert(id);
      try {
        const notificationResponse = await axios({
          method: "delete",
          url: `${baseUrl}${APIS_URL.DELETE_NOTIFICATION}/${id}`,

          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        let status = notificationResponse.status;
        if (status === 200) {
          let notificationData = notificationResponse.data.notifications;
          if (user.locale !== "en") {
            let notifyData = await Promise.all(
              notificationData.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            updateNotification(notifyData);
            return;
          }
          updateNotification(notificationData);
          updateUser({
            ...user,
            isCredentialUpload: notificationResponse.data.isCredentialUpload,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <div
        className={`border-b pb-2 mb-2 px-4 flex justify-between hover:bg-gray-100`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-[13px] text-black">
          {NotificationHelper(notification)}
        </div>

        {isHovered && (
          <CloseIcon
            className="cursor-pointer  font-xs"
            onClick={() => deleteNotification(notification._id)}
          />
        )}
      </div>
    );
  };

  // console.log("Noti", notification);

  return (
    <Box
      ref={notificationRef}
      className={`${isNotifyOpen ? "flex" : "hidden"} rounded-md  ${
        style.hide_scrollbar
      }`}
      sx={{
        position: "absolute",
        top: "200%",
        right: "-100%",
        minWidth: "400px",
        maxHeight: "250px",
        overflowY: "auto",
        zIndex: "9999",
        filter: "drop-shadow(5px 5px 10px gray)",
        // backgroundColor: "white",
      }}
    >
      <Box
        className="bg-white p-4 rounded shadow-lg w-full h-full "
        sx={{
          justifyContent: "center",
        }}
      >
        {firstFiveNotification.length > 0 && (
          <Box>
            {firstFiveNotification.map((notification: any) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                // onClose={onCloseNotification}
              />
            ))}
            <Link to={`${AppLinks.Notification.path}`}>
              <button
                onClick={onClose}
                className="mt-2 px-4 py-2 text-black hover:bg-gray-300 rounded"
              >
                <Translate text="See all notifications" />
              </button>
            </Link>
          </Box>
        )}
        {firstFiveNotification.length <= 0 && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box className="text-[20px] text-black">
              <Translate text="No notification yet." />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NotificationPopup;
