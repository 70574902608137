import { useState } from "react";
import style from "./nav.module.css";
import { CiSettings } from "react-icons/ci";

interface DropDownProps {
  title: any;
  children: any;
}

const MoreOptions: React.FC<DropDownProps> = ({ title, children }) => {
  const [isSideBar, setIsSideBar] = useState(false);

  const toggleSideBarDropdown = () => {
    setIsSideBar(!isSideBar);
  };
  const handleItemClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={style.side_dropdown} onClick={toggleSideBarDropdown}>
      <div className="flex flex-row  space-x-2 md:hover:bg-white sm:hover:bg-white xs:hover:bg-white text-text-gray mb-2">
        <CiSettings className="w-7 h-7" />
        <button
          className={`${style.side_dropdown_toggle} text-[15px]  `}
          onClick={toggleSideBarDropdown}
        >
          {title}
        </button>
      </div>

      <div
        className={`${style.side_dropdown_content}${isSideBar ? "show" : ""}`}
        onClick={handleItemClick}
      >
        {children}
      </div>
    </div>
  );
};

export default MoreOptions;
