import { FC, useEffect, useState } from "react";
import { Button, Pagination, Stack } from "@mui/material";
import Container from "../components/shared/Container";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdStar } from "react-icons/md";
import { AppLinks } from "../utils/Linking";
import { useHistory, useLocation, Link } from "react-router-dom";
import baseUrl from "../utils/config";
import axios, { all } from "axios";
import { APIS_URL } from "../services/api";
// import Map from "react-map-gl";
import { IoSearchCircle } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";

import { FaFilter } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { CiLocationArrow1 } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { CiCalendarDate } from "react-icons/ci";
import OverlayThree from "../components/OverlayThree";
import { Translate } from "react-translated";
import HoverableSlider from "../components/HoverableSlider";
import CheckoutPopup from "./components/CheckoutPopup";
import CheckinPopup from "./components/CheckinPopup";
import translateText from "../utils/translationHelper";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Popover,
  BottomNavigation,
} from "@mui/material";
import Location from "../components/location";
import Calendar from "react-calendar";
import { format, utcToZonedTime } from "date-fns-tz";

import ListFilter from "../components/listFilter";
import CustomMap from "../components/Map";
import LocationPopup from "./components/LocationPopup";
import CheckinCheckoutPopup from "./components/CheckinCheckoutPopup";
import GuestPopup from "./components/GuestPopup";
import style from "./apartment.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext, useNotificationContext } from "../context/Context";
import VerifyPopup from "./verify";

const MyBookings: FC = () => {
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [anchorCheckoutEl, setAnchorCheckoutEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorCheckinEl, setAnchorCheckinEl] = useState<null | HTMLElement>(
    null
  );
  const redirect = useHistory();
  const [like, setLike] = useState(false);
  const [allMyBookings, setAllMyBookings] = useState<any[]>([]);
  // const [search, setSearch] = useLocation();

  const searchLocation = useLocation();
  const search = new URLSearchParams(searchLocation.search);

  const [priceRanges, setPriceRanges] = useState<
    Array<{ min: number; max: number }>
  >([]);

  const [superHost, setSuperHost] = useState(false);
  const [freeCancellation, setFreeCancellation] = useState(false);
  const [noDeposit, setNoDeposit] = useState(false);
  const [propertyType, setPropertyType] = useState<String[]>([]);
  const [facilites, setFacilities] = useState<String[]>([]);
  const [bedPreference, setBedPreference] = useState<String[]>([]);
  const [distance, setDistance] = useState<String[]>([]);
  const [rules, setRules] = useState<String[]>([]);

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items to display per page

  const [anchorLocationEl, setAnchorLocationEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorDateEl, setAnchorDateEl] = useState<null | HTMLElement>(null);
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: "",
    guest: null,
    adult: null,
    children: null,
    infant: null,
    pet: null,
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.MY_BOOKINGS_LIST}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          const apartments: any[] = response.data.apartments;

          if (user.locale !== "en") {
            let apartmentsTranslatedData = await Promise.all(
              apartments.map((item: any) => translateText(item, user.locale))
            );
            setAllMyBookings(apartmentsTranslatedData);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            return;
          }

          setAllMyBookings(apartments);

          updateUser({
            ...user,
            isCredentialUpload: response.data.isCredentialUpload,
          });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  }, [user.locale]);

  const handleLike = () => {
    setLike(!like);
  };

  const filterApartment = () => {
    setIsLoading(true);
    setShowFilterPopup(false);
    setCurrentPage(1);
    let base = `${baseUrl}${APIS_URL.MY_BOOKINGS_FILTER}?`;

    if (filter.location !== "") {
      base += `location=${filter.location}&`;
    }
    if (filter.fromDate !== null) {
      const formattedFromDate = format(
        filter.fromDate,
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      base += `checkin=${formattedFromDate}&`;
    }
    let formattedToDate;
    if (filter.toDate !== null) {
      formattedToDate = format(filter.toDate, "yyyy-MM-dd'T'00:00:00");

      base += `checkout=${formattedToDate}&`;
    }

    if (
      filter.guest !== null &&
      String(filter.guest) !== "NaN" &&
      filter.guest !== 0
    ) {
      base += `guests=${filter.guest}&`;
    }

    if (superHost) {
      base += `superhost=true&`;
    }

    if (freeCancellation) {
      base += `freecancellation=true&`;
    }
    if (noDeposit) {
      base += `nodeposit=true&`;
    }

    if (priceRanges.length > 0) {
      const priceRangesString = JSON.stringify(priceRanges);

      base += `priceRanges=${priceRangesString}&`;
    }
    if (propertyType.length > 0) {
      base += `propertyTypes=${JSON.stringify(propertyType)}&`;
    }
    if (rules.length > 0) {
      base += `rules=${JSON.stringify(rules)}&`;
    }
    if (facilites.length > 0) {
      base += `amenities=${JSON.stringify(facilites)}&`;
    }
    if (bedPreference.length > 0) {
      base += `bedPreference=${JSON.stringify(bedPreference)}&`;
    }
    if (distance.length > 0) {
      base += `distance=${JSON.stringify(distance)}&`;
    }

    try {
      axios({
        method: "get",
        url: `${base}`,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          const filteredApartments: any[] = response.data.result;

          if (response.status === 200) {
            if (user.locale !== "en") {
              let apartmentsTranslatedData = await Promise.all(
                filteredApartments.map((item: any) =>
                  translateText(item, user.locale)
                )
              );
              setAllMyBookings(apartmentsTranslatedData);

              updateUser({
                ...user,
                isCredentialUpload: response.data.isCredentialUpload,
              });
              setIsLoading(false);
              return;
            }

            setAllMyBookings(filteredApartments);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
          } else {
            setAllMyBookings([]);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  // Guard against undefined allMyBookings
  const totalPages = allMyBookings
    ? Math.ceil(allMyBookings.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Guard against undefined allMyBookings
  const currentPageData = allMyBookings
    ? allMyBookings.slice(startIndex, endIndex)
    : [];
  const endCount = startIndex + (currentPageData ? currentPageData.length : 0);

  const handleClose = () => {
    if (!user.isCredentialUpload) {
      return;
    } else {
      setShowPopup(false);
    }
  };
  const resetFilter = () => {
    setFilter({
      location: "",
      guest: null,
      adult: null,
      children: null,
      infant: null,
      pet: null,
      fromDate: null,
      toDate: null,
    });
    setPriceRanges([]);
    setPropertyType([]);
    setRules([]);
    setFacilities([]);
    setBedPreference([]);
    setDistance([]);
    setNoDeposit(false);
    setSuperHost(false);
    setFreeCancellation(false);
  };

  const refreshData = () => {
    setIsLoading(true);
    resetFilter();
    try {
      let base = `${baseUrl}${APIS_URL.MY_BOOKINGS_FILTER}`;

      axios({
        method: "get",
        url: base,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          const filteredApartments: any[] = response.data.result;

          if (response.status === 200) {
            if (user.locale !== "en") {
              let apartmentsTranslatedData = await Promise.all(
                filteredApartments.map((item: any) =>
                  translateText(item, user.locale)
                )
              );
              setAllMyBookings(apartmentsTranslatedData);

              updateUser({
                ...user,
                isCredentialUpload: response.data.isCredentialUpload,
              });
              setIsLoading(false);
              return;
            }

            setAllMyBookings(filteredApartments);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
          } else {
            setAllMyBookings([]);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      console.log(error);
    }
  };

  const showFilter = () => {
    setShowFilterPopup(true);
    if (showFilterPopup) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(false);
  };

  console.log("user", user);

  useEffect(() => {
    const showPopup = () => {
      document.body.style.overflow = "hidden";
    };

    if (showFilterPopup) {
      showPopup();
    }

    return () => {
      document.body.style.overflow = ""; // Re-enable scrolling on the body
    };
  }, [showFilterPopup]); // Run this effect whenever showFilterPopup changes

  const userLocalTime = (utcDateString: string) => {
    // Create a Date object from the UTC date string
    const utcDate = new Date(utcDateString);

    // Get the user's local timezone offset in minutes
    const userTimezoneOffset = new Date().getTimezoneOffset();

    // Apply the user's timezone offset to get the local time
    const localTime = new Date(utcDate.getTime() - userTimezoneOffset * 60000);

    const formattedDate = localTime.toDateString();

    return formattedDate;
  };
  return (
    <>
      <Container>
        <div className="bg-[#eeeeee] pt-[60px] lg:pt-0 xl:pt-0 2xl:pt-0 pb-16">
          <div className="bg-[#eeeeee] shadow-xl p-2 flex justify-between mb-5 xs:flex-col md:flex-col sm:flex-col items-center">
            {showPopup && !user.isCredentialUpload && (
              <VerifyPopup handleClose={handleClose} />
            )}
            <div className="flex-grow" />

            {/* DESKTOP */}
            <Grid
              container
              className={`xs:!hidden sm:!hidden bg-white rounded-l-[2rem] rounded-r-[2rem] my-3 ${
                user.isCredentialUpload
                  ? "w-full px-6 mx-6 mt-10 mb-6 xs:mt:0 sm:mt-0 md:mt-0"
                  : "max-w-[800px] px-2"
              }`}
            >
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 2,
                }}
              >
                {/* LOCATION POPUP */}
                <LocationPopup
                  anchorLocationEl={anchorLocationEl}
                  setAnchorLocationEl={setAnchorLocationEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-[#eeeeee]"}
                />
                <div
                  className="flex flex-row space-x-2 "
                  onClick={(e) => {
                    setAnchorLocationEl(
                      anchorLocationEl ? null : e.currentTarget
                    );
                  }}
                >
                  <CiLocationArrow1 className="text-2xl  " />

                  <button className=" md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black  xs:border-none sm:border-none xs:text-[.7rem]  h-full">
                    {!!filter.location ? (
                      filter.location
                    ) : (
                      <Translate text="Location" />
                    )}
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 2,
                }}
              >
                <CheckinPopup
                  anchorCheckinEl={anchorCheckinEl}
                  setAnchorCheckinEl={setAnchorCheckinEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
                  fillColor={"landing"}
                />
                {/* */}
                <div
                  className="flex flex-row space-x-2"
                  onClick={(e) => {
                    setAnchorDateEl(anchorDateEl ? null : e.currentTarget);
                  }}
                >
                  <CiCalendarDate className="text-2xl" />
                  <button className="  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[2rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                    <span
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      {" "}
                      {filter.fromDate?.toDateString() ?? (
                        <Translate text="From Date" />
                      )}
                    </span>
                    -{" "}
                    <span
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      {filter.toDate?.toDateString() ?? (
                        <Translate text="To Date" />
                      )}
                    </span>
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",

                  py: 2,
                }}
              >
                {/* GUEST POPUP */}
                <GuestPopup
                  anchorGuestEl={anchorGuestEl}
                  setAnchorGuestEl={setAnchorGuestEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-[#eeeeee]"}
                />
                <div
                  className="flex flex-row space-x-1"
                  onClick={(e) => {
                    setAnchorGuestEl(anchorGuestEl ? null : e.currentTarget);
                  }}
                >
                  <GoPerson className="text-2xl " />
                  <button className="  md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                    {/* 2 Guests */}
                    {!!filter.guest ? (
                      <>
                        {filter.guest} <Translate text="Guests" />{" "}
                      </>
                    ) : (
                      <Translate text="Guests" />
                    )}
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  position: "relative",
                }}
              >
                <div>
                  <IoSearchCircle
                    onClick={() => filterApartment()}
                    className="text-primary h-full w-full  absolute right-[-18%]"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Grid>
            </Grid>

            {/* MOBILE */}

            <Grid
              container
              className=" md:!hidden  lg:!hidden xl:!hidden 2xl:!hidden bg-white rounded-l-[2rem] rounded-r-[2rem] max-w-[650px] my-3"
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 2,
                }}
              >
                {/* CHECKIN CHECKOUT POPUP */}
                {/* <CheckinCheckoutPopup
                  anchorDateEl={anchorDateEl}
                  setAnchorDateEl={setAnchorDateEl}
                  filter={filter}
                  setFilter={setFilter}
                /> */}
                <CheckinPopup
                  anchorCheckinEl={anchorCheckinEl}
                  setAnchorCheckinEl={setAnchorCheckinEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
                  fillColor={"landing"}
                />

                <div
                  className="flex flex-row space-x-2"
                  onClick={(e) => {
                    setAnchorDateEl(anchorDateEl ? null : e.currentTarget);
                  }}
                >
                  <CiCalendarDate className="text-2xl" />
                  <button className="xs:text-[13px] sm:text-[13px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                    <span
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      {" "}
                      {filter.fromDate?.toDateString() ?? (
                        <Translate text="From Date" />
                      )}
                    </span>
                    -{" "}
                    <span
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      {filter.toDate?.toDateString() ?? (
                        <Translate text="To Date" />
                      )}
                    </span>
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 2,
                }}
              >
                {/* LOCATION POPUP */}
                <LocationPopup
                  anchorLocationEl={anchorLocationEl}
                  setAnchorLocationEl={setAnchorLocationEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-[#eeeeee]"}
                />
                <div
                  className="flex flex-row space-x-2 "
                  onClick={(e) => {
                    setAnchorLocationEl(
                      anchorLocationEl ? null : e.currentTarget
                    );
                  }}
                >
                  <CiLocationArrow1 className="text-2xl  " />

                  <button className="xs:text-[13px] sm:text-[13px] md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black  xs:border-none sm:border-none xs:text-[.7rem]  h-full">
                    {!!filter.location ? (
                      filter.location
                    ) : (
                      <Translate text="Location" />
                    )}
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",

                  py: 2,
                }}
              >
                {/* GUEST POPUP */}
                <GuestPopup
                  anchorGuestEl={anchorGuestEl}
                  setAnchorGuestEl={setAnchorGuestEl}
                  filter={filter}
                  setFilter={setFilter}
                  bgColor={"bg-[#eeeeee]"}
                />
                <div
                  className="flex flex-row space-x-1"
                  onClick={(e) => {
                    setAnchorGuestEl(anchorGuestEl ? null : e.currentTarget);
                  }}
                >
                  <GoPerson className="text-2xl " />
                  <button className=" xs:text-[13px] sm:text-[13px] md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                    {/* 2 Guests */}
                    {!!filter.guest ? (
                      <>
                        {filter.guest} <Translate text="Guests" />
                      </>
                    ) : (
                      <Translate text="Guests" />
                    )}
                  </button>
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  position: "relative",
                }}
              >
                <div>
                  <IoSearchCircle
                    onClick={() => filterApartment()}
                    className="text-primary h-full w-full  absolute "
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Grid>
            </Grid>

            <Button
              onClick={() => showFilter()}
              fullWidth
              className="md:!hidden  lg:!hidden xl:!hidden 2xl:!hidden"
            >
              <CiFilter className="text-2xl" />
              <Translate text="More Filters" />
            </Button>
          </div>
          <div className="flex items-center gap-5   bg-[#eeeeee]  ">
            <div className="w-full">
              {/* px-[6rem] */}
              <div className="flex  sm:px-3 xs:px-1 md:px-[1rem] xs:justify-center sm:justify-center xs:items-center sm:items-center  xl:gap-[3rem] xl:px-[3rem]     md:gap-[3rem] justify-between w-full sm:w-full  ">
                <Grid
                  container
                  spacing={4}
                  sx={{
                    px: { xs: "10px", sm: "10px", md: "20px", lg: "10px" },
                    mt: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4.5}
                    lg={3.4}
                    sx={{ mb: { xs: "20px" } }}
                  >
                    <div>
                      <div className="rounded-xl drop-shadow-xl shadow-xl ">
                        <CustomMap
                          markers={allMyBookings.map((x) => ({
                            latitude: x.latitude,
                            longitude: x.longitude,
                            name: x.title,
                            url: `${AppLinks.ApartmentDetails.path}/${x._id}`,
                          }))}
                        />
                      </div>
                      <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <ListFilter
                          superHost={superHost}
                          setSuperHost={setSuperHost}
                          priceRanges={priceRanges}
                          setPriceRanges={setPriceRanges}
                          propertyType={propertyType}
                          setPropertyType={setPropertyType}
                          rules={rules}
                          setRules={setRules}
                          facilites={facilites}
                          setFacilities={setFacilities}
                          bedPreference={bedPreference}
                          setBedPreference={setBedPreference}
                          distance={distance}
                          setDistance={setDistance}
                          freeCancellation={freeCancellation}
                          setFreeCancellation={setFreeCancellation}
                          noDeposit={noDeposit}
                          setNoDeposit={setNoDeposit}
                          resetFilter={resetFilter}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: { xs: "none", sm: "flex", md: "flex" },
                          // display: "flex",
                          justifyContent: "center",

                          marginTop: "30px",
                          paddingX: "10px",
                        }}
                      >
                        <Button onClick={(e) => resetFilter()}>
                          <Translate text="Reset all filters" />
                        </Button>
                        <button
                          onClick={() => filterApartment()}
                          style={{ cursor: "pointer" }}
                          className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-2 px-5 "
                          type="button"
                        >
                          <Translate text="Apply" />
                          <IoSearch className="text-lg" />
                        </button>
                      </Box>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={7.5}
                    lg={8}
                    sx={{
                      position: "relative",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginBottom: { xs: "150px", md: "150px" },
                      }}
                    >
                      <div className="rounded-xl drop-shadow-xl mb-10 shadow-xl md:hidden  lg:hidden xl:hidden 2xl:hidden">
                        <CustomMap
                          markers={allMyBookings.map((x) => ({
                            latitude: x.latitude,
                            longitude: x.longitude,
                            name: x.title,
                            url: `${AppLinks.ApartmentDetails.path}/${x._id}`,
                          }))}
                        />
                      </div>
                      {currentPageData.map((apartmentCard, index) => (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: {
                              xs: "flex",
                              md: "flex",
                              // lg: "block",
                              lg: "flex",
                            },
                            justifyContent: {
                              xs: "center",
                            },
                            minHeight: {
                              xs: "450px",
                              sm: "433px",
                              md: "435px",
                              lg: "200px",
                            },

                            width: {
                              xs: "full",
                              sm: "full",
                              md: "full",
                              lg: "full",
                            },
                            padding: { xs: "0px", lg: "10px" },
                          }}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              width: "100%",
                              height: {
                                lg: "100%",
                              },
                            }}
                            className="rounded-lg"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: {
                                  xs: "column",
                                  md: "column",
                                  lg: "row",
                                },
                                width: "100%",
                                height: {
                                  lg: "100%",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  width: { sm: "100%", lg: "30%" },
                                  height: "180px",
                                }}
                              >
                                <HoverableSlider
                                  images={apartmentCard?.images}
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  width: { sm: "100%", lg: "70%" },
                                }}
                              >
                                <Link
                                  to={`${AppLinks.ApartmentDetails.path}/${apartmentCard._id}`}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      height: "100%",
                                      flexDirection: {
                                        xs: "column",
                                        md: "column",
                                        lg: "row",
                                      },
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        flexGrow: 2,
                                        pb: {
                                          xs: 0,
                                          md: 1,
                                        },
                                      }}
                                    >
                                      <Box
                                        className="text-sm"
                                        sx={{
                                          paddingTop: {
                                            xs: "15px",
                                            sm: "15px",
                                            md: "15px",
                                          },
                                        }}
                                      >
                                        {apartmentCard.bedroom} -{" "}
                                        <Translate text="room apartment" />
                                      </Box>
                                      <h3 className="text-base font-semibold  ">
                                        {apartmentCard.title}
                                      </h3>
                                      <Typography
                                        sx={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                        }}
                                      >
                                        <a
                                          href={`https://maps.google.com/?ll=${apartmentCard.latitude},${apartmentCard.longitude}`}
                                          className="text-sm text-primary underline"
                                        >
                                          {apartmentCard.location}
                                        </a>
                                      </Typography>

                                      <div className="flex gap-5">
                                        <p className="text-sm">
                                          {apartmentCard.guests}{" "}
                                          <Translate text="guests" />
                                        </p>
                                        <p className="text-sm">
                                          {apartmentCard.bed}{" "}
                                          <Translate text="beds" />
                                        </p>
                                        <p className="text-sm">
                                          {apartmentCard.bedroom}{" "}
                                          <Translate text="bedroom" />
                                        </p>
                                        <p className="text-sm">
                                          {apartmentCard.h}m2
                                        </p>
                                      </div>
                                    </CardContent>
                                    <CardContent
                                      sx={{
                                        maxWidth: "180px",
                                        minWidth: "180px",
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        borderLeft: {
                                          md: "1px solid lightgray",
                                        },
                                        pt: {
                                          xs: 0.5,
                                          md: 1,
                                        },
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <h3 className="text-sm font-semilight">
                                          {apartmentCard.totalReviews}{" "}
                                          <Translate text="reviews" />
                                        </h3>
                                        <div className="inline-flex items-center">
                                          <MdStar className="text-yellow-500" />{" "}
                                          <p className="font-semibold">
                                            {apartmentCard.averageRatings}
                                          </p>
                                        </div>
                                      </div>
                                      <div></div>
                                      <div>
                                        <span className="font-bold">
                                          {apartmentCard.discountprice}P
                                        </span>{" "}
                                        <span className="text-[0.9] font-light">
                                          {apartmentCard.duration}
                                        </span>
                                      </div>
                                    </CardContent>
                                  </Box>
                                </Link>
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      ))}

                      <OverlayThree isLoading={isLoading} />
                    </Grid>
                    {/* Pagination component */}

                    {allMyBookings.length > 0 ? (
                      <Box
                        sx={{
                          position: "absolute",
                          left: "53%",

                          bottom: "0",
                          transform: "translateX(-50%)",
                          width: "100%",
                          maxWidth: {
                            xs: "350px",
                            sm: "400px",
                            md: "500px",
                            lg: "700px",
                          },
                        }}
                      >
                        <Box
                          className="mb-3 "
                          sx={{
                            fontSize: "h6.fontSize",

                            textAlign: {
                              xs: "center",
                              md: "left",
                              lg: "left",
                            },
                          }}
                        >
                          {!!filter.location ? (
                            <>
                              {filter.location}: {allMyBookings.length}{" "}
                              <Translate text="properties found" />
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box
                          sx={{
                            justifyContent: { md: "space-between" },
                            display: "flex",
                            width: "100%",
                            // mx: "10px",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                            border: 1,
                            borderRadius: "16px",
                            borderColor: "gray",
                            py: { xs: 2, md: 4 },
                            px: { md: 4 },
                          }}
                        >
                          <Stack
                            spacing={2}
                            sx={{
                              alignSelf: { xs: "center" },
                            }}
                          >
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={handleChange}
                              shape="rounded"
                              color="primary"
                            />
                          </Stack>

                          <Box sx={{ textAlign: { xs: "center" } }}>
                            <div className="text-gray-500 mt-1">
                              <Translate text="Showing" />
                              {startIndex + 1} - {endCount}
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",

                          height: { xs: "50hv", md: "100vh" },
                          textAlign: "center",
                          justifyContent: "center",
                          margin: { sm: "auto", md: "auto", lg: "auto" },
                          fontSize: "h6.fontSize",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex flex-col",
                            width: "full",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            {" "}
                            <Translate text="No Apartment Found" />
                          </Box>

                          <Box>
                            <Translate text="Try expanding your search radius or changing filters." />
                          </Box>

                          <button
                            className="xs:w-[150px] xs:h-[50px] sm:w-[200px] sm:h-[50px] xs:rounded-md sm:rounded-md rounded-full bg-primary px-4 py-0 mb-6 mr-10 md:mr-20 text-white  sm:text-[15px] xs:text-[15px] "
                            onClick={() => refreshData()}
                            style={{ margin: "auto", marginTop: "10px" }}
                          >
                            <Translate text="Reset all filters" />
                          </button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className={` md:hidden lg:hidden xl:hidden 2xl:hidden`}>
          {showFilterPopup && (
            <div
              className={`${style.popup_container} md:hidden lg:hidden xl:hidden 2xl:hidden`}
            >
              <div className={style.popup_content}>
                <div className={`${style.filter_header} bg-[#eef5ff]`}>
                  <div className={style.close_icon} onClick={toggleFilterPopup}>
                    <CloseIcon />
                  </div>

                  <Translate text="Filters" />
                </div>
                <div>
                  <ListFilter
                    superHost={superHost}
                    setSuperHost={setSuperHost}
                    priceRanges={priceRanges}
                    setPriceRanges={setPriceRanges}
                    propertyType={propertyType}
                    setPropertyType={setPropertyType}
                    rules={rules}
                    setRules={setRules}
                    facilites={facilites}
                    setFacilities={setFacilities}
                    bedPreference={bedPreference}
                    setBedPreference={setBedPreference}
                    distance={distance}
                    setDistance={setDistance}
                    freeCancellation={freeCancellation}
                    setFreeCancellation={setFreeCancellation}
                    noDeposit={noDeposit}
                    setNoDeposit={setNoDeposit}
                    resetFilter={resetFilter}
                  />
                </div>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    py: 3,
                    backgroundColor: "white",
                    left: 0,
                  }}
                >
                  <Button onClick={(e) => resetFilter()}>
                    <Translate text="Reset all filters" />
                  </Button>
                  <button
                    onClick={() => filterApartment()}
                    style={{ cursor: "pointer" }}
                    className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-2 px-5 md:hidden lg:hidden xl:hidden 2xl:hidden"
                    type="button"
                  >
                    <Translate text="Apply" />
                    <IoSearch className="text-xl" />
                  </button>
                </Box>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default MyBookings;
