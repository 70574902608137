// GoogleTranslate.js
import axios from "axios";

const API_URL = "https://translation.googleapis.com/language/translate/v2";
const API_KEY = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;

const translateText = async (data: any, targetLanguage: any) => {
  const dataToTranslate = { ...data }; // Copy the original object

  const stringsToTranslate: any = [];
  const stringKeys = [];
  const excludedKeys = new Set([
    "apartmentCheckin",
    "apartmentCheckout",
    "apartmentGuest",
    "apartmentImage",
    "aptId",
    "apartmentRooms",
    "audioSrc",
    "bookingId",
    "createdAt",
    "fileSrc",
    "fileType",
    "landlordImage",
    "read",
    "recipient",
    "recipientLastOnline",
    "recipientResponseTime",
    "senderLastOnline",
    "senderResponseTime",
    "type",
    "updatedAt",
    "entryid",
    "options",
    "__v",
    "type",
    "credentialStatus",
    "isIdentityVerified",
    "image",
    "coverurl",
    "docurl",
    "imageurl",
    "symbol",
    "firstname",
    "lastname",
    "displayname",
    "realname",
    // "duration",
    "price",
    "discount",
    "symbol",
    "discountprice",
    "longitude",
    "latitude",
    "bedroom",
    "bed",
    "twinbed",
    "doublebed",
    "extendedsofa",
    "livingroom",
    "kitchen",
    "guests",
    "area",
    "owner",
    "featured",
    "approve",
    "freecancellation",
    "nodeposit",
    "images",
    "visibility",
    "__v",
    "totalReviews",
    "averageRatings",
    "_id",
    "read",
    "seen",
    "ratings",
    "userid",
    "apartmentid",
    "metadata",
    "name",
    "dateofbirth",
    "phone",
    "superhost",
    "besthost",
    "isActive",
    "isVerified",
    "isIdentityVerified",
    "authProvider",
    "isCredentialUpload",
    "payoutToken",
    "firstSix",
    "lastFour",
    "email",
    "password",
    "cardType",
    "type",
    undefined,
    null,
    "",
    "rules",
    "amenities",
    "corefeatures",
  ]);

  for (const [key, value] of Object.entries(data)) {
    // console.log(key, value);
    if (excludedKeys.has(key)) {
      continue; // Skip excluded keys
    }
    if (typeof value === "string") {
      stringsToTranslate.push(value); // Collect strings to translate
      stringKeys.push(key); // Store corresponding keys
    } else if (
      Array.isArray(value) &&
      value.every((item) => typeof item === "string")
    ) {
      stringsToTranslate.push(...value); // Collect strings in arrays
      stringKeys.push(key); // Corresponding key for array of strings
    }
  }

  // console.log("stringsToTranslate", stringsToTranslate);
  const translatedResults = await Promise.all(
    stringsToTranslate.map((text: any) =>
      axios.post(`${API_URL}?key=${API_KEY}`, {
        q: text,
        target: targetLanguage,
      })
    )
  );
  // console.log("translatedResults", translatedResults);
  // Step 2: Extract the translated text from the Axios responses
  const translations = translatedResults.map(
    (result) => result.data.data.translations[0].translatedText
  );

  // console.log("translations", translations);

  // Step 3: Rebuild the object with the translated strings
  let resultIndex = 0;

  for (let i = 0; i < stringKeys.length; i++) {
    const key = stringKeys[i];
    const value = data[key];

    if (typeof value === "string") {
      // Assign the translated string to the key
      dataToTranslate[key] = translations[resultIndex];
      resultIndex++;
    } else if (Array.isArray(value)) {
      // Assign an array of translated strings to the key
      dataToTranslate[key] = translations.slice(
        resultIndex,
        resultIndex + value.length
      );
      resultIndex += value.length;
    }
  }
  // console.log("dataToTranslate", dataToTranslate);
  return dataToTranslate;
};

export default translateText;
