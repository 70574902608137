import { FC } from "react";
// import { useRouteError } from "react-router-dom";
import Nav from "../components/Nav";
import { Translate } from "react-translated";

const ErrorPage: FC = () => {
  // const error: any = useRouteError();
  // console.error(error);

  return (
    <>
      <Nav />
      <div className="flex h-screen w-full text-center justify-center items-center">
        <div id="error-page">
          <h1 className="text-[5rem] font-semibold">Oops!</h1>
          <p className="text-[2rem]">
            {" "}
            <Translate text="Sorry, an unexpected error has occurred" />
          </p>
          {/* <span className="flex justify-center gap-5 text-[2rem] font-semibold">
            <p>404</p>
            <p> Internet Breakdown </p>
          </span> */}
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
