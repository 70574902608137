import { Box } from "@mui/material";
import style from "./profile.module.css";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { useShowMsg } from "../context/Context";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext, useNotificationContext } from "../context/Context";
import NotificationHelper from "../utils/notificationTypeHelper";
import { Translate } from "react-translated";
import Container from "../components/shared/Container";
import translateText from "../utils/translationHelper";
import baseUrl from "../utils/config";
const Notification = () => {
  const { notification, updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const auth = localStorage.getItem("token");

  useEffect(() => {
    const translateNotify = async () => {
      try {
        const notificationResponse = await axios({
          method: "get",
          url: `${baseUrl}${APIS_URL.GET_ALL_NOTIFICATION}`,

          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
        let status = notificationResponse.status;
        if (status === 200) {
          let notificationData = notificationResponse.data.notifications;
          if (user.locale !== "en") {
            let notifyData = await Promise.all(
              notificationData.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            updateNotification(notifyData);
            return;
          }
          updateNotification(notificationData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    translateNotify();
  }, [user.locale]);

  const deleteNotification = async (id: any) => {
    try {
      const notificationResponse = await axios({
        method: "delete",
        url: `${baseUrl}${APIS_URL.DELETE_NOTIFICATION}/${id}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      let status = notificationResponse.status;
      if (status === 200) {
        if (user.locale !== "en") {
          let notificationData = notificationResponse.data.notifications;
          let notifyData = await Promise.all(
            notificationData.map((item: any) =>
              translateText(item, user.locale)
            )
          );
          updateNotification(notifyData);
          updateUser({
            ...user,
            isCredentialUpload: notificationResponse.data.isCredentialUpload,
          });
        } else {
          updateNotification(notificationResponse.data.notifications);
          updateUser({
            ...user,
            isCredentialUpload: notificationResponse.data.isCredentialUpload,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const reverseNotification = notification.slice().reverse();
  return (
    <Container>
      <Box className="flex justify-center item-center bg-[#EFF1F3]">
        <Box
          className=" p-4 rounded xs:w-[95%] sm:w-[95%] w-[70%] h-full mb-[150px]"
          sx={{
            justifyContent: "center",
          }}
        >
          <p className="font-bold text-4xl my-10 px-4">Notifications</p>
          {reverseNotification.length > 0 &&
            reverseNotification.map((item: any) => (
              <div
                key={item._id}
                className="border-b pb-2 mb-2 px-4 flex justify-between "
              >
                <div>{NotificationHelper(item)}</div>

                <CloseIcon
                  onClick={() => deleteNotification(item._id)}
                  className={`cursor-pointer font-xs ${
                    item.seen ? "text-gray-600" : "font-bold"
                  }`}
                />
              </div>
            ))}
          {reverseNotification.length <= 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box className="text-[30px]">
                <Translate text="No notification yet." />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Notification;
