import React from "react";
import styles from "./overlay.module.css";
import { Box } from "@mui/material";

interface OverlayStructure {
  isLoading: Boolean;
}
const OverlayTwo = ({ isLoading }: OverlayStructure) => {
  return (
    <Box
      sx={{ width: { sx: "100%", md: "70%" } }}
      className={`${styles.overlay_four} ${
        isLoading ? styles.overlayVisible : ""
      }`}
    >
      <div className={styles.loader}></div>
    </Box>
  );
};

export default OverlayTwo;
