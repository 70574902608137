import {
  Box,
  Divider,
  Grid,
  Modal,
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Translate } from "react-translated";
import Howard from "../images/Howard.png";
import { styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import { APIS_URL } from "../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useState, useEffect } from "react";
import { useUserContext, useShowMsg } from "../context/Context";
import axios from "axios";
import LocationIcon from "../images/location.png";
import Flag from "../images/flag.png";
import { Controller, useForm } from "react-hook-form";
import baseUrl from "../utils/config";
interface Chat {
  email: string;
  apartmentImage: string;
  apartmentState: string;
  apartmentRooms: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentGuest: number;
  bookingId: string;
  aptId: string;
  apartmentTitle: string;
}

interface MessageRoomApartmentProps {
  showSecondGrid: boolean;
  chatUsers: Chat[];
  chatUsersIndex: number;
  apartmentImage: string;
  apartmentState: string;
  apartmentRooms: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentGuest: number;
  bookingId: string;
  aptId: string;
  apartmentTitle: string;
}

const MessageRoomApartment: FC<MessageRoomApartmentProps> = ({
  showSecondGrid,
  chatUsers,
  chatUsersIndex,
  apartmentImage,
  apartmentState,
  apartmentRooms,
  apartmentLocation,
  apartmentCheckin,
  apartmentCheckout,
  apartmentGuest,
  bookingId,
  aptId,
  apartmentTitle,
}) => {
  const { user, updateUser } = useUserContext();
  const [isReporting, setIsReporting] = useState(false);
  const [concernForm, setConcernForm] = useState({ concern: "" });
  const auth = localStorage.getItem("token");
  const [isSending, setIsSending] = useState(false);
  const { notifyError, notifySuccess } = useShowMsg();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: concernForm, mode: "onSubmit" });

  const cancleReporting = () => {
    setIsReporting(false);
  };

  // console.log("concern", aptId, apartmentTitle);
  // console.log(
  //   "concern2",
  //   chatUsers[chatUsersIndex]?.aptId,
  //   chatUsers[chatUsersIndex]?.apartmentTitle
  // );
  const submitData = async (inputData: any) => {
    // console.log("contact form", inputData);
    if (isSending === true) return;
    try {
      setIsSending(true);
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.SEND_REPORT_MESSAGE}`,

        data: {
          concern: inputData.concern,
          aptId: chatUsers[chatUsersIndex]?.aptId || aptId,
          aptName: chatUsers[chatUsersIndex]?.apartmentTitle || apartmentTitle,
        },

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      setIsSending(false);
      if (response.data.success === true) {
        setConcernForm({ concern: "" });
        setValue("concern", "");
        notifySuccess("Concern sent successfully");
        setIsReporting(false);
      } else {
        console.log("error");
        notifyError("Error occured, please try again");
      }
    } catch (error) {
      console.log(error);
      setIsSending(false);
      notifyError("Error occured, please try again");
    }
  };
  const getDate = () => {
    if (
      (chatUsers &&
        chatUsers[chatUsersIndex] &&
        chatUsers[chatUsersIndex].bookingId) ||
      apartmentCheckin
    ) {
      return (
        <p className="font-semibold text-[20px]">
          {/* 20 Jan, 16:00 - 25 Jan, 12:00 */}
          {new Date(
            apartmentCheckin !== ""
              ? apartmentCheckin
              : chatUsers[chatUsersIndex]?.apartmentCheckin
          ).toDateString()}{" "}
          -{" "}
          {new Date(
            apartmentCheckout !== ""
              ? apartmentCheckout
              : chatUsers[chatUsersIndex]?.apartmentCheckout
          ).toDateString()}
        </p>
      );
    } else {
      return <p></p>;
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={8}
      lg={3}
      sx={{
        mx: { md: "auto" },
        mt: { md: "20px", lg: "0" },
        display: {
          xs: showSecondGrid ? "block" : "none",
          md: showSecondGrid ? "block" : "none",
          // md: "block",
        },
      }}
      // className="xs:mt-4 sm:mt-4 xs:mx-2 sm:mx-2"
    >
      <Box
        className="flex flex-col  bg-white xs:mt-4 sm:mt-4 xs:mx-auto sm:mx-auto "
        sx={{
          display: "flex",
          alignItems: "center",
          width: { xs: "90%", sm: "100%", md: "100%" },
          height: "520px",
          paddinX: "2px",
          borderRadius: "20px 20px 20px 20px",
        }}
      >
        <Box
          className=" bg-cover"
          sx={{
            width: "100%",
            height: "200px",
            backgroundImage: `url(${
              apartmentImage !== ""
                ? apartmentImage
                : chatUsers[chatUsersIndex]?.apartmentImage
            }) `,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "20px 20px 0 0",
            padding: "20px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              borderRadius: "20px 20px 0 0",
              display: "flex",
              alignItems: "center",
              color: "white",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex flex-col mt-[80px]">
              <p className="text-white text-[16px] font-bold">
                <Translate text="Apartment At" />{" "}
                {apartmentState !== ""
                  ? apartmentState
                  : chatUsers[chatUsersIndex]?.apartmentState}
              </p>
              <p className="text-white text-[14px]">
                {apartmentRooms !== 0
                  ? apartmentRooms
                  : chatUsers[chatUsersIndex]?.apartmentRooms}
                -<Translate text="room apartment" />
              </p>
            </div>
          </Box>
        </Box>
        {/* border-b-4 pb-4 border-[#EFF1F3]  sx={{ mx: 1 }}*/}
        <Box className="px-4 w-[100%]">
          <Box className="flex flex-row space-x-2  mt-8">
            <div
              className="text-[20px] w-[100%]"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {/* Voronezh, 25 oktobrya street, 41. */}
              {apartmentLocation !== ""
                ? apartmentLocation
                : chatUsers[chatUsersIndex]?.apartmentLocation}
            </div>
            <div className="px-6 ">
              <img
                className="w-[20px] h-[25px] "
                src={LocationIcon}
                alt="loc"
              />
            </div>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ mt: "10px" }} />
          <Box className="flex flex-col mt-4 ">
            {getDate()}

            <p className="text-[#7682A0] text-[16px] mt-5">
              {apartmentGuest !== 0
                ? apartmentGuest
                : chatUsers[chatUsersIndex]?.apartmentGuest}{" "}
              <Translate text="guests" />
            </p>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ mt: "10px" }} />
          <Box
            onClick={() => {
              setIsReporting(true);
            }}
            className="cursor-pointer  bg-[#EFF1F3]  mt-8 py-4 px-4 w-[100%]"
            style={{ borderRadius: "10px" }}
          >
            <div className="flex flex-row">
              <p className="w-[90%] text-[16px]">
                <Translate text="Report this listing" />
              </p>
              <img className="w-[20px] h-[25px]" src={Flag} alt="flag" />
            </div>
          </Box>
        </Box>
      </Box>

      <Modal open={isReporting} onClose={cancleReporting}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: { xs: 500, sm: 500, lg: 550 },
            width: { xs: "90%", sm: "90%", lg: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: "1px solid #000", // Adding border
            p: 4,
          }}
        >
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <form onSubmit={handleSubmit(submitData)}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  borderBottom: "2px solid #000",
                  display: "inline-block",
                  paddingBottom: "4px",
                }}
              >
                <Translate text="Report a concern?" />
              </Typography>

              <textarea
                className="bg-[#F3F3F3]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-10"
                placeholder="Please type your concern"
                {...register("concern", {
                  required: "Field can't be empty",
                })}
                rows={5}
                style={{
                  resize: "none",
                  minHeight: "140px",
                  // maxHeight: "140px",
                  height: "300px",
                  overflow: "hidden",
                }}
              />
              {errors.concern && (
                <span className="text-red-400">
                  {errors.concern.message}
                  {/* <Translate text={`${errors.name.concern}`} /> */}
                </span>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={cancleReporting}
                  variant="contained"
                  color="secondary"
                  // sx={{ minWidth: "100px" }}
                  sx={{
                    minWidth: "100px",
                    backgroundColor: "white",
                    borderColor: "blue",
                    color: "black",
                    border: "1px solid blue",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "darkblue",
                    },
                  }}
                >
                  <Translate text="Cancel" />
                </Button>
                <button
                  className="bg-primary text-white rounded-md"
                  // onClick={bookApartment}
                  style={{ minWidth: "100px" }}
                >
                  {isSending ? (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        marginBottom: "0px",
                        marginTop: "2px",
                      }}
                    />
                  ) : (
                    <Translate text="Report" />
                  )}
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};
export default MessageRoomApartment;
