import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, FormLabel, Input } from "@mui/material";
import { FC } from "react";
import Container from "../../components/shared/Container";
import Contact from "../../images/contact.png";
import { Translate } from "react-translated";
import emailIcon from "../../images/emailIcon.svg";
import phoneIcon from "../../images/phone.svg";
import Faq from "./Faq";
import Form from "./Form";
import { borderColor } from "@mui/system";

const Support = () => {
  return (
    <Container>
      <Box className="bg-[#EFF1F3]  md:pt-10 lg:pt-10 xl:pt-10 2xl:pt-10  ">
        <Grid container spacing={0} className="lg:px-20 xl:px-20 2xl:px-20">
          <Grid item xs={12} md={12} lg={6.5}>
            <Box
              className=" xs:rounded-none sm:rounded-none md:rounded-none lg:rounded-lg-custom1 xl:rounded-lg-custom1  2xl:rounded-lg-custom2"
              sx={{
                backgroundImage: `url(${Contact}) `,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
              }}
            >
              <Box className="xs:p-8 sm:p-8 md:p-10 lg:p-10 xl:p-10 2xl:p-10">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "24px", md: "30px", lg: "65px" },
                    color: "white",
                    lineHeight: { xs: "50px", md: "50px", lg: "77px" },
                    fontWeight: "680px",
                  }}
                >
                  <Translate
                    // className="xs:mt-[50px] "
                    text="Contact Us For More Information"
                  />
                </Typography>
                <Box
                  sx={{
                    fontSize: { xs: "20px", md: "15px", lg: "24px" },
                    color: "#D9E6FF",
                    // lineHeight: { xs: "24px", md: "24px", lg: "24px" },
                    fontWeight: "725px",
                    marginY: "40px",
                  }}
                >
                  <Translate text="Have questions or need assistance? Whether you're a guest searching for an ideal apartment or a host ready to share your space, we're here to help. Reach out to us for more information and personalized support" />
                </Box>

                <Box
                  className="bg-[#FFDBC9] w-[75px] item-center h-[60px] p-2"
                  style={{
                    borderRadius: "10px",
                    // border: "0.5px",
                    // borderColor: "8BABFF",
                    border: "0.5px solid rgba(252, 219, 206, 0.29)",
                    backgroundColor: "rgba(255, 219, 201, 0.29)",
                  }}
                >
                  <div className="inline-flex flex-row space-x-2 item-center bg-[#FFFFFF] py-2 px-2 rounded-md w-[260px] ">
                    <img
                      src={emailIcon}
                      alt="email"
                      className="h-auto w-auto"
                    />
                    <p className="text-[#225FFE] m-auto">
                      <a href="mailto:contact@mymiradom.com">
                        contact@mymiradom.com
                      </a>
                    </p>
                  </div>
                </Box>

                <Box
                  className="bg-[#FFDBC9] w-[75px] item-center h-[60px] p-2 mt-4"
                  style={{
                    borderRadius: "10px",
                    // border: "0.5px",
                    // borderColor: "FCDBCE",
                    border: "0.5px solid rgba(252, 219, 206, 0.29)",
                    backgroundColor: "rgba(255, 219, 201, 0.29)",
                  }}
                >
                  <div className="inline-flex flex-row space-x-2  item-center bg-[#FFFFFF] py-2 px-2 rounded-md w-[200px] ">
                    <img
                      src={phoneIcon}
                      alt="email"
                      className="h-auto w-auto"
                    />
                    <p className="text-[#225FFE] m-auto">
                      <a href="tel:+7 (904) 663-69-63">+7 (904) 663-69-63</a>
                    </p>
                  </div>
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "24px", md: "35px", lg: "35px" },
                    color: "white",
                    lineHeight: { xs: "41px", md: "41px", lg: "41px" },
                    fontWeight: "700px",
                    marginTop: "40px",
                  }}
                >
                  <Translate text="Location" />
                </Typography>

                <Box
                  sx={{
                    fontSize: { xs: "20px", md: "20px", lg: "20px" },
                    color: "#D9E6FF",
                    lineHeight: { xs: "24px", md: "24px", lg: "24px" },
                    fontWeight: "400px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  {/* <Translate
                    text="Dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores."
                  /> */}
                </Box>

                <Box
                  className="bg-[#FFDBC9] w-[210px] item-center h-[100px] p-4 xs:mb-4 xl:mb-8 2xl:mb-8 "
                  style={{
                    borderRadius: "10px",
                    // border: "0.5px",
                    // borderColor: "FCDBCE",
                    border: "0.5px solid rgba(252, 219, 206, 0.29)",
                    backgroundColor: "rgba(255, 219, 201, 0.29)",
                  }}
                >
                  <div className="flex flex-col justify-center item-center bg-[#FFFFFF] py-6 px-4 rounded-md w-full h-[110px] ">
                    <p className="text-[3B3A5D] text-[25px] text-center">
                      <Translate text="Innopolis" />
                    </p>
                    <p className="text-[#456ED9] text-center ">
                      <Translate text="Universitetskaya 1, Innopolis, Kazan" />
                    </p>
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={5.5}
            // className="xs:bg-red-400 sm:bg-yellow-400 md:bg-pink-400 lg:bg-green-400 xl:bg-orange-400 2xl:bg-wine-400"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: {
                xs: "0px",
                sm: "0",
                md: "0 0 20px 20px",
                lg: "0px 20px 20px 0px",
              },
            }}
          >
            {/* FORM */}
            <Form />
          </Grid>
          <Grid item xs={12} className="flex justify-center item-center">
            {/* Faq */}
            <Faq />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            className="bg-[#D9E6FF] xs:px-[40px] xs:py-[50px] sm:px-[40px] sm:py-[50px] md:px-[130px] md:py-[50px] lg:px-[130px] lg:py-[50px] xl:px-[130px] xl:py-[50px] 2xl:px-[130px] 2xl:py-[50px]"
            sx={{ marginTop: { xs: "50px", md: "150px" } }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={5.5}></Grid>
              <Grid item xs={12} md={6.5}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "24px", md: "45px", lg: "45px" },
                    color: "#0033B7",

                    fontWeight: "700px",
                    // marginTop: "40px",
                  }}
                >
                  <Translate text="Join Host Community" />
                </Typography>
                <Box
                  sx={{
                    fontSize: { xs: "18px", md: "24px", lg: "24px" },
                    color: "#456ED9",
                    lineHeight: { xs: "28px", md: "28px", lg: "28px" },
                    fontWeight: "400px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <Translate text="Become part of a thriving community of hosts and connect with guests from around the world." />
                </Box>

                <button className="bg-[#0033B7] px-6 py-2 text-white text-[20px] rounded-md w-full">
                  <Translate text="Join Host Community" />
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Support;
