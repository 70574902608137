import { Box, Grid, MenuItem, Select, FormControl } from "@mui/material";
import Howard from "../images/Howard.png";
import { styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import { FC, useState, useEffect } from "react";
import { useUserContext } from "../context/Context";
import { Translate } from "react-translated";
import axios from "axios";
import baseUrl from "../utils/config";

interface ListAllMessagesProps {
  socket: any;
  showSecondGrid: boolean;
  selectedValue: number;
  handleChange: (event: any) => void;
  chatUsers: ChatUser[];
  userHandler: Function;
  chatUsersIndex: number;
}

interface ChatUser {
  name: string;
  unreadMsg: boolean;
}

const ListAllMessages: FC<ListAllMessagesProps> = ({
  socket,
  showSecondGrid,
  selectedValue,
  handleChange,
  chatUsers,
  userHandler,
  chatUsersIndex,
}) => {
  const { user, updateUser } = useUserContext();
  // const auth = localStorage.getItem("token");

  const NoBorderSelect = styled(Select)({
    "& .MuiSelect-outlined": {
      border: "none",
      backgroundColor: "white",
      borderRadius: "20px 20px 0 0",
    },
    "& fieldset": {
      border: "none",
    },
  });

  const unreadMsgCount = chatUsers.filter((item: any) => item.unreadMsg).length;
  console.log("unreadMsgCount", unreadMsgCount);

  return (
    <Grid
      item
      xs={12}
      md={5}
      lg={3}
      sx={{
        mx: { sm: "30px", md: 0 },
        // backgroundColor: { sm: "red" },
        display: {
          xs: showSecondGrid ? "none" : "block",
          // xs: "block",
          md: "block",
        },
      }}
    >
      <Box
        className="flex flex-col  bg-white"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "800px",
          // maxHeight: "800px",
          // minHeight: "700px",
          paddinX: "2px",
          borderRadius: "20px 20px 20px 20px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth variant="outlined">
            <NoBorderSelect
              value={selectedValue}
              onChange={handleChange}
              renderValue={() => <Translate text="Messages" />}
              label="Message"
              sx={{
                width: "100%",
                fontSize: "24px",
              }}
            >
              <MenuItem value={1}>
                <Translate text="All conversations" /> ({chatUsers.length})
              </MenuItem>
              <MenuItem value={2}>
                <Translate text="Unread conversations" /> ({unreadMsgCount})
              </MenuItem>
            </NoBorderSelect>
          </FormControl>
        </Box>
        <Box
          className="flex flex-col"
          sx={{
            height: "650px",
            // height:{xs:"100%", sm:"100%",md:"650px"},
            overflowY: "auto",
            width: "98%",
            // Custom scrollbar styles
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // IE and Edge
            scrollbarWidth: "none", // Firefox
          }}
        >
          {/* 1 */}

          {chatUsers.length === 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                color: "#262626",
                height: "100vh",
              }}
            >
              <Translate text="Start Chatting by Booking an Apartment" />
            </Box>
          )}

          {chatUsers.map((chatUser: any, index: any) => (
            <Box
              key={index}
              sx={{
                py: "20px",
                px: "20px",

                backgroundColor: index === chatUsersIndex ? "#EFF1F3" : "white",
              }}
              onClick={() => userHandler(chatUser, index)}
            >
              <Box className="flex flex-row space-x-2">
                <Avatar
                  className="w-[50px] rounded-lg h-auto object-contain"
                  alt="profile"
                  // src={Howard}
                  src={chatUser.image}
                />
                <Box className="flex flex-col">
                  <div className="flex flex-row space-x-2 ">
                    <p className="flex justify-center text-center text-[#262626] text-[13px] font-bold">
                      {/* Natalya */}
                      {chatUser.name}
                    </p>
                    <p
                      className="flex justify-center text-center text-[#7682A0] text-[8px] font-semibold"
                      style={{ alignItems: "center" }}
                    >
                      {/* 4:27pm */}
                      {chatUser.createdAt
                        ? new Date(chatUser.createdAt).toLocaleString()
                        : ""}
                    </p>
                  </div>
                  <div className="text-[#262626] text-[12px] font-semibold my-2">
                    {/* Afro-kiva update: The reservation is confirmed */}
                    {chatUser.message}
                  </div>
                  <div className="flex flex-row space-x-4">
                    {/* <p className="text-[#7682A0] text-[12px] border-l-4 border-green-500 pl-2">
                    Reservation confirmed
                  </p> */}
                    <p className="text-[#7682A0] text-[12px]">
                      {/* Jul 10 - 15 */}
                    </p>
                  </div>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};
export default ListAllMessages;

// useEffect(() => {
//   const getIds = async () => {
//     try {
//       const getBookingIds = await axios({
//         method: "get",
//         // url: `${baseUrl}${APIS_URL.GIVE_FEEDBACK}`,
//

//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
//       console.log("getBookingIds", getBookingIds);
//       let Ids = getBookingIds.data.bookingIds;
//       // Event for the landlord to join the room
//       Ids.map((id: any) => socket.emit("landlord_join", { bookingId: id }));
//       // Ids.map((id: any) =>
//       //   socket.emit("identify_landlord", { bookingId: id })
//       // );
//     } catch {}
//   };
//   getIds();
// }, [socket]);
