import React, { useState } from "react";
import { Box } from "@mui/material";
import "./GeoapifyStyle.css";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import styled from "styled-components";

interface geoProps {
  onPlaceSelect: (value: any) => void;
  value: string;
}

const geoApifyKey = process.env.REACT_APP_GEOAPIFY_SECRET_KEY;

const GeoapifySearch = ({ value = "", onPlaceSelect }: geoProps) => {
  function onSuggectionChange(value: any) {
    // console.log("value change", value);
  }

  return (
    <GeoapifyContext apiKey={geoApifyKey}>
      <div className="container_one">
        <GeoapifyGeocoderAutocomplete
          placeholder="Enter address here.."
          filterByCountryCode={["ru"]}
          value={value}
          placeSelect={onPlaceSelect}
          suggestionsChange={onSuggectionChange}
        />
      </div>
    </GeoapifyContext>
  );
};

export default GeoapifySearch;
