import { FC } from "react";
import { Popover } from "@mui/material";
import Calendar from "react-calendar";
import style from "./landingcalender.module.css";
import Landing from "../Landing";

import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRange } from "@mui/x-date-pickers-pro/models";

interface DateRange<T> {
  start: T;
  end: T;
}
interface Filter {
  location: string;
  fromDate: Date | null;
  toDate: Date | null;
  // toDate: DateRange<unknown>;
  // fromDate: DateRange<unknown>;
}
interface CheckoutPopupProps {
  anchorCheckoutEl: HTMLElement | null;
  setAnchorCheckoutEl: (el: HTMLDivElement | null) => void;
  filter: {
    location: string;
    // fromDate: DateRange<unknown>;
    // toDate: DateRange<unknown>;
    fromDate: Date | null;
    toDate: Date | null;
  };
  setFilter: Function;
  bgColor: string;
  fillColor: string;
}
const CheckoutPopup: FC<CheckoutPopupProps> = ({
  anchorCheckoutEl,
  setAnchorCheckoutEl,
  filter,
  setFilter,
  bgColor,
  fillColor,
}) => {
  const getFillColor = (fillColor: any) => {
    switch (fillColor) {
      case "landing":
        return style.landing;
      case "apartmentList":
        return style.apartment_list;
      default:
        return "bg-white";
    }
  };

  return (
    <Popover
      open={Boolean(anchorCheckoutEl)}
      anchorEl={anchorCheckoutEl}
      onClose={() => setAnchorCheckoutEl(null)}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`shadow-2xl rounded-lg ${bgColor} `}>
          <div className=" overflow-y-auto p-5  rounded-2xl max-h-[70vh]">
            {/* <Calendar
              className={getFillColor(fillColor)}
              onChange={(e: any) => {
                setFilter((filter: Filter) => ({
                  ...filter,
                  toDate: e,
                }));
                setAnchorCheckoutEl(null);
              }}
              value={[filter.fromDate, filter.toDate]}
            /> */}

            <DateRangeCalendar
              value={[dayjs(filter.fromDate), dayjs(filter.toDate)]}
              onChange={(e: any) => {
                // console.log("date out", e[0].$d);
                setFilter((filter: Filter) => ({
                  ...filter,
                  toDate: e[0].$d,
                }));
                setAnchorCheckoutEl(null);
              }}
            />
          </div>
        </div>
      </LocalizationProvider>
    </Popover>
  );
};

export default CheckoutPopup;
