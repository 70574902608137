import { FC, useEffect, useState } from "react";
import { Button, Pagination, Stack } from "@mui/material";
import Container from "../components/shared/Container";
import { MdStar } from "react-icons/md";
import { AppLinks } from "../utils/Linking";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useNotificationContext } from "../context/Context";
import axios from "axios";
import { APIS_URL } from "../services/api";
// import Map from "react-map-gl";
import { IoSearchCircle } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import OverlayThree from "../components/OverlayThree";
import { CiFilter } from "react-icons/ci";
import { CiLocationArrow1 } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { CiCalendarDate } from "react-icons/ci";
import translateText from "../utils/translationHelper";
import baseUrl from "../utils/config";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { format, utcToZonedTime } from "date-fns-tz";

import ListFilter from "../components/listFilter";
import CustomMap from "../components/Map";
import LocationPopup from "./components/LocationPopup";
import GuestPopup from "./components/GuestPopup";
import style from "./apartment.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext } from "../context/Context";
import VerifyPopup from "./verify";
import HoverableSlider from "../components/HoverableSlider";
import { Translate } from "react-translated";
import CheckinPopup from "./components/CheckinPopup";

const ApartmentListing: FC = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const [like, setLike] = useState(false);
  const [allApartments, setAllApartments] = useState<any[]>([]);
  // const [search, setSearch] = useLocation();

  const searchLocation = useLocation();
  const search = new URLSearchParams(searchLocation.search);

  const [priceRanges, setPriceRanges] = useState<
    Array<{ min: number; max: number }>
  >([]);

  const [superHost, setSuperHost] = useState(false);
  const [freeCancellation, setFreeCancellation] = useState(false);
  const [noDeposit, setNoDeposit] = useState(false);
  const [propertyType, setPropertyType] = useState<String[]>([]);
  const [facilites, setFacilities] = useState<String[]>([]);
  const [bedPreference, setBedPreference] = useState<String[]>([]);
  const [distance, setDistance] = useState<String[]>([]);
  const [rules, setRules] = useState<String[]>([]);

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items to display per page

  const [anchorLocationEl, setAnchorLocationEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const [anchorCheckinEl, setAnchorCheckinEl] = useState<null | HTMLElement>(
    null
  );
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: search.get("location") ?? "",
    guest: search.get("guest") !== null ? parseInt(search.get("guest")!) : 0,
    adult: search.get("adult") !== null ? parseInt(search.get("adult")!) : 0,
    children:
      search.get("children") !== null ? parseInt(search.get("children")!) : 0,
    infant: search.get("infant") !== null ? parseInt(search.get("infant")!) : 0,
    pet: search.get("pet") !== null ? parseInt(search.get("pet")!) : 0,
    fromDate: !!search.get("fromDate")
      ? new Date(search.get("fromDate") || "")
      : null,
    toDate: !!search.get("toDate")
      ? new Date(search.get("toDate") || "")
      : null,
  });

  useEffect(() => {
    setIsLoading(true);

    if (
      search.get("location") ||
      search.get("fromDate") ||
      search.get("toDate") ||
      search.get("guest")
    ) {
      let base = `${baseUrl}${APIS_URL.FILTER_APARTMENTS}/?`;

      if (filter.location !== "") {
        base += `location=${filter.location}&`;
      }
      if (filter.fromDate !== null) {
        const utcDate = utcToZonedTime(filter.fromDate, "UTC");
        const formattedFromDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");
        // console.log("formattedFromDate", formattedFromDate);
        base += `checkin=${formattedFromDate}&`;
      }
      if (filter.toDate !== null) {
        const utcDate = utcToZonedTime(filter.toDate, "UTC");
        const formattedToDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");

        base += `checkout=${formattedToDate}&`;
      }

      if (
        filter.guest !== null &&
        String(filter.guest) !== "NaN" &&
        filter.guest !== 0
      ) {
        base += `guests=${filter.guest}&`;
      }
      // console.log("api url", base);
      try {
        axios({
          method: "get",
          url: `${base}`,
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        })
          .then(async (response) => {
            console.log("response", response);
            const filteredApartments: any[] = response.data.result;

            if (user.locale !== "en") {
              let apartmentsTranslatedData = await Promise.all(
                filteredApartments.map((item: any) =>
                  translateText(item, user.locale)
                )
              );
              setAllApartments(apartmentsTranslatedData);

              updateUser({
                ...user,
                isCredentialUpload: response.data.isCredentialUpload,
              });
              setIsLoading(false);
              return;
            }
            setAllApartments(filteredApartments);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            // console.log("filter", filter);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err", err);
          });
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      try {
        axios({
          method: "get",
          url: `${baseUrl}${APIS_URL.ALL_APARTMENTS}`,

          headers: {
            Authorization: `Bearer ${auth}`,
          },
        })
          .then(async (response) => {
            console.log("response", response);
            const apartments: any[] = response.data.apartmentsWithReviews;

            if (user.locale !== "en") {
              let apartmentsTranslatedData = await Promise.all(
                apartments.map((item: any) => translateText(item, user.locale))
              );
              setAllApartments(apartmentsTranslatedData);
              let notificationData = await Promise.all(
                response.data.notifications.map((item: any) =>
                  translateText(item, user.locale)
                )
              );

              updateUser({
                ...user,
                isCredentialUpload: response.data.isCredentialUpload,
              });
              setIsLoading(false);
              return;
            }
            setAllApartments(apartments);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err", err);
          });
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
      }
    }
  }, [user.locale]);

  const filterApartment = () => {
    setIsLoading(true);
    setShowFilterPopup(false);
    setCurrentPage(1);
    let base = `${baseUrl}${APIS_URL.FILTER_APARTMENTS}/?`;

    if (filter.location !== "") {
      base += `location=${filter.location}&`;
    }
    if (filter.fromDate !== null) {
      const checkInDate = new Date(filter.fromDate);
      const utcDate = utcToZonedTime(checkInDate, "UTC");
      const formattedFromDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");
      // console.log("formattedFromDate", formattedFromDate);
      base += `checkin=${formattedFromDate}&`;
    }
    if (filter.toDate !== null) {
      const checkOutDate = new Date(filter.toDate);
      const utcDate = utcToZonedTime(checkOutDate, "UTC");
      const formattedToDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");
      // console.log("formattedToDate", formattedToDate);
      base += `checkout=${formattedToDate}&`;
    }

    if (
      filter.guest !== null &&
      String(filter.guest) !== "NaN" &&
      filter.guest !== 0
    ) {
      base += `guests=${filter.guest}&`;
    }

    if (superHost) {
      base += `superhost=true&`;
    }

    if (freeCancellation) {
      base += `freecancellation=true&`;
    }
    if (noDeposit) {
      base += `nodeposit=true&`;
    }

    if (priceRanges.length > 0) {
      const priceRangesString = JSON.stringify(priceRanges);

      base += `priceRanges=${priceRangesString}&`;
    }
    if (propertyType.length > 0) {
      base += `propertyTypes=${JSON.stringify(propertyType)}&`;
    }
    if (rules.length > 0) {
      base += `rules=${JSON.stringify(rules)}&`;
    }
    if (facilites.length > 0) {
      base += `amenities=${JSON.stringify(facilites)}&`;
    }
    if (bedPreference.length > 0) {
      base += `bedPreference=${JSON.stringify(bedPreference)}&`;
    }
    if (distance.length > 0) {
      base += `distance=${JSON.stringify(distance)}&`;
    }

    console.log("base", base);
    try {
      axios({
        method: "get",
        url: `${base}`,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          const filteredApartments: any[] = response.data.result;
          console.log("res", filteredApartments);

          if (user.locale !== "en") {
            let apartmentsTranslatedData = await Promise.all(
              filteredApartments.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            setAllApartments(apartmentsTranslatedData);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            return;
          }

          setAllApartments(filteredApartments);

          updateUser({
            ...user,
            isCredentialUpload: response.data.isCredentialUpload,
          });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil(allApartments.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = allApartments
    ? allApartments.slice(startIndex, endIndex)
    : [];
  const endCount = startIndex + (currentPageData ? currentPageData.length : 0);

  console.log("allApartments", allApartments);
  console.log("bedPreference", bedPreference);

  const handleClose = () => {
    if (!user.isCredentialUpload) {
      return;
    } else {
      setShowPopup(false);
    }
  };

  const resetFilter = () => {
    setFilter({
      location: "",
      guest: null,
      adult: null,
      children: null,
      infant: null,
      pet: null,
      fromDate: null,
      toDate: null,
    });
    setPriceRanges([]);
    setPropertyType([]);
    setRules([]);
    setFacilities([]);
    setBedPreference([]);
    setDistance([]);
    setNoDeposit(false);
    setSuperHost(false);
    setFreeCancellation(false);
  };

  const refreshData = () => {
    setIsLoading(true);
    resetFilter();
    try {
      let base = `${baseUrl}${APIS_URL.FILTER_APARTMENTS}`;

      axios({
        method: "get",
        url: base,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          const filteredApartments: any[] = response.data.result;
          console.log("res", filteredApartments);

          if (user.locale !== "en") {
            let apartmentsTranslatedData = await Promise.all(
              filteredApartments.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            setAllApartments(apartmentsTranslatedData);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            return;
          }

          setAllApartments(filteredApartments);

          updateUser({
            ...user,
            isCredentialUpload: response.data.isCredentialUpload,
          });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const showFilter = () => {
    setShowFilterPopup(true);
    if (showFilterPopup) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(false);
  };

  console.log("user", user);

  useEffect(() => {
    const showPopup = () => {
      document.body.style.overflow = "hidden";
    };

    if (showFilterPopup) {
      showPopup();
    }

    return () => {
      document.body.style.overflow = ""; // Re-enable scrolling on the body
    };
  }, [showFilterPopup]); // Run this effect whenever showFilterPopup changes

  return (
    <Container>
      <div className="bg-[#eeeeee] pt-[60px] lg:pt-0 xl:pt-0 2xl:pt-0 pb-16">
        <div className="bg-[#eeeeee] shadow-xl p-2 flex justify-between mb-5 xs:flex-col md:flex-col sm:flex-col items-center">
          {showPopup && !user.isCredentialUpload && (
            <VerifyPopup handleClose={handleClose} />
          )}

          <div className="flex-grow" />

          {/* DESKTOP */}
          {/* max-w-[750px] */}
          <Grid
            container
            className={`xs:!hidden sm:!hidden bg-white rounded-l-[2rem] rounded-r-[2rem] my-3 ${
              user.isCredentialUpload
                ? "w-full px-6 mx-6 mt-10 mb-6 xs:mt:0 sm:mt-0 md:mt-0"
                : "max-w-[800px] px-2"
            }`}
          >
            <Grid
              item
              xs={3.5}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                py: 2,
              }}
            >
              {/* LOCATION POPUP */}
              <LocationPopup
                anchorLocationEl={anchorLocationEl}
                setAnchorLocationEl={setAnchorLocationEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-[#eeeeee]"}
              />
              <div
                className="flex flex-row space-x-2 "
                onClick={(e) => {
                  setAnchorLocationEl(
                    anchorLocationEl ? null : e.currentTarget
                  );
                }}
              >
                <CiLocationArrow1 className="text-2xl  " />

                <button className=" md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black  xs:border-none sm:border-none xs:text-[.7rem]  h-full">
                  {!!filter.location ? (
                    filter.location
                  ) : (
                    <Translate text="Location" />
                  )}
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={3.5}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                py: 2,
              }}
            >
              {/* CHECKIN CHECKOUT POPUP */}
              {/* <CheckinCheckoutPopup
                  anchorDateEl={anchorDateEl}
                  setAnchorDateEl={setAnchorDateEl}
                  filter={filter}
                  setFilter={setFilter}
                /> */}
              <CheckinPopup
                anchorCheckinEl={anchorCheckinEl}
                setAnchorCheckinEl={setAnchorCheckinEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
                fillColor={"landing"}
              />

              <div
                className="flex flex-row space-x-2"
                // onClick={(e) => {
                //   setAnchorDateEl(anchorDateEl ? null : e.currentTarget);
                // }}
              >
                <CiCalendarDate className="text-2xl" />
                <button className="  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[2rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                  <span
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    {" "}
                    {filter.fromDate?.toDateString() ?? (
                      <Translate text="From Date" />
                    )}
                  </span>
                  -{" "}
                  <span
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    {filter.toDate?.toDateString() ?? (
                      <Translate text="To Date" />
                    )}
                  </span>
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={3.5}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",

                py: 2,
              }}
            >
              {/* GUEST POPUP */}
              <GuestPopup
                anchorGuestEl={anchorGuestEl}
                setAnchorGuestEl={setAnchorGuestEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-[#eeeeee]"}
              />
              <div
                className="flex flex-row space-x-1"
                onClick={(e) => {
                  setAnchorGuestEl(anchorGuestEl ? null : e.currentTarget);
                }}
              >
                <GoPerson className="text-2xl " />
                <button className="  md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                  {/* 2 Guests */}
                  {!!filter.guest ? (
                    <>
                      {filter.guest} <Translate text="Guests" />{" "}
                    </>
                  ) : (
                    <Translate text="Guests" />
                  )}
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                position: "relative",
              }}
            >
              <div>
                <IoSearchCircle
                  onClick={() => filterApartment()}
                  className="text-primary h-full w-full  absolute right-[-15%]"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Grid>
          </Grid>

          {/* MOBILE */}

          <Grid
            container
            className=" md:!hidden  lg:!hidden xl:!hidden 2xl:!hidden bg-white rounded-l-[2rem] rounded-r-[2rem] max-w-[650px] my-3"
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                py: 2,
              }}
            >
              {/* CHECKIN CHECKOUT POPUP */}
              {/* <CheckinCheckoutPopup
                  anchorDateEl={anchorDateEl}
                  setAnchorDateEl={setAnchorDateEl}
                  filter={filter}
                  setFilter={setFilter}
                /> */}

              <CheckinPopup
                anchorCheckinEl={anchorCheckinEl}
                setAnchorCheckinEl={setAnchorCheckinEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
                fillColor={"landing"}
              />

              <div
                className="flex flex-row space-x-2"
                //
              >
                <CiCalendarDate className="text-2xl" />
                <button className="xs:text-[13px] sm:text-[13px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                  <span
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    {" "}
                    {filter.fromDate?.toDateString() ?? (
                      <Translate text="From Date" />
                    )}
                  </span>
                  -{" "}
                  <span
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    {filter.toDate?.toDateString() ?? (
                      <Translate text="To Date" />
                    )}
                  </span>
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                py: 2,
              }}
            >
              {/* LOCATION POPUP */}
              <LocationPopup
                anchorLocationEl={anchorLocationEl}
                setAnchorLocationEl={setAnchorLocationEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-[#eeeeee]"}
              />
              <div
                className="flex flex-row space-x-2 "
                onClick={(e) => {
                  setAnchorLocationEl(
                    anchorLocationEl ? null : e.currentTarget
                  );
                }}
              >
                <CiLocationArrow1 className="text-2xl  " />

                <button className="xs:text-[13px] sm:text-[13px] md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black  xs:border-none sm:border-none xs:text-[.7rem]  h-full">
                  {!!filter.location ? (
                    filter.location
                  ) : (
                    <Translate text="Location" />
                  )}
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",

                py: 2,
              }}
            >
              {/* GUEST POPUP */}
              <GuestPopup
                anchorGuestEl={anchorGuestEl}
                setAnchorGuestEl={setAnchorGuestEl}
                filter={filter}
                setFilter={setFilter}
                bgColor={"bg-[#eeeeee]"}
              />
              <div
                className="flex flex-row space-x-1"
                onClick={(e) => {
                  setAnchorGuestEl(anchorGuestEl ? null : e.currentTarget);
                }}
              >
                <GoPerson className="text-2xl " />
                <button className=" xs:text-[13px] sm:text-[13px] md:text-[15px] lg:text-[20px] xl:text-[20px] 2xl:text-[15px]  md:pr-[4rem] lg:pr-[2rem] xl:pr-[2rem] 2xl:pr-[4rem] border-r-[1px] border-black xs:border-none sm:border-none xs:text-[.7rem] pr-4 h-full">
                  {/* 2 Guests */}
                  {!!filter.guest ? (
                    <>
                      {filter.guest} <Translate text="Guests" />
                    </>
                  ) : (
                    <Translate text="Guests" />
                  )}
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                position: "relative",
              }}
            >
              <div>
                <IoSearchCircle
                  onClick={() => filterApartment()}
                  className="text-primary h-full w-full  absolute "
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Grid>
          </Grid>

          <Button
            onClick={() => showFilter()}
            fullWidth
            className="md:!hidden  lg:!hidden xl:!hidden 2xl:!hidden"
          >
            <CiFilter className="text-2xl" />
            <Translate text="More Filters" />
          </Button>
        </div>
        <div className="flex items-center gap-5   bg-[#eeeeee]  ">
          <div className="w-full">
            <div className="flex  sm:px-3 xs:px-1 md:px-[1rem] xs:justify-center sm:justify-center xs:items-center sm:items-center  xl:gap-[3rem] xl:px-[3rem]     md:gap-[3rem] justify-between w-full sm:w-full  ">
              <Grid
                container
                spacing={4}
                sx={{
                  px: { xs: "10px", sm: "10px", md: "20px", lg: "10px" },
                  mt: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4.5}
                  lg={3.4}
                  sx={{ mb: { xs: "20px" } }}
                >
                  <div>
                    <div className="rounded-xl drop-shadow-xl shadow-xl ">
                      <CustomMap
                        markers={allApartments.map((x) => ({
                          latitude: x.latitude,
                          longitude: x.longitude,
                          name: x.title,
                          url: `${AppLinks.ApartmentDetails.path}/${x._id}`,
                        }))}
                      />
                    </div>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      <ListFilter
                        superHost={superHost}
                        setSuperHost={setSuperHost}
                        priceRanges={priceRanges}
                        setPriceRanges={setPriceRanges}
                        propertyType={propertyType}
                        setPropertyType={setPropertyType}
                        rules={rules}
                        setRules={setRules}
                        facilites={facilites}
                        setFacilities={setFacilities}
                        bedPreference={bedPreference}
                        setBedPreference={setBedPreference}
                        distance={distance}
                        setDistance={setDistance}
                        freeCancellation={freeCancellation}
                        setFreeCancellation={setFreeCancellation}
                        noDeposit={noDeposit}
                        setNoDeposit={setNoDeposit}
                        resetFilter={resetFilter}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex", md: "flex" },
                        // display: "flex",
                        justifyContent: "center",

                        marginTop: "30px",
                        paddingX: "10px",
                      }}
                    >
                      <Button onClick={(e) => resetFilter()}>
                        <Translate text="Reset all filters" />
                      </Button>
                      <button
                        onClick={() => filterApartment()}
                        style={{ cursor: "pointer" }}
                        className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-2 px-5 "
                        type="button"
                      >
                        <Translate text="Apply" />
                        <IoSearch className="text-lg" />
                      </button>
                    </Box>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7.5}
                  lg={8}
                  sx={{
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: { xs: "150px", md: "150px" },
                    }}
                  >
                    <div className="rounded-xl drop-shadow-xl mb-10 shadow-xl md:hidden  lg:hidden xl:hidden 2xl:hidden">
                      <CustomMap
                        markers={allApartments.map((x) => ({
                          latitude: x.latitude,
                          longitude: x.longitude,
                          name: x.title,
                          url: `${AppLinks.ApartmentDetails.path}/${x._id}`,
                        }))}
                      />
                    </div>
                    {currentPageData.map((apartmentCard, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sx={{
                          display: {
                            xs: "flex",
                            md: "flex",
                            // lg: "block",
                            lg: "flex",
                          },
                          justifyContent: {
                            xs: "center",
                          },
                          minHeight: {
                            xs: "450px",
                            sm: "433px",
                            md: "435px",
                            lg: "200px",
                          },

                          width: {
                            xs: "full",
                            sm: "full",
                            md: "full",
                            lg: "full",
                          },
                          padding: { xs: "0px", lg: "10px" },
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: {
                              lg: "100%",
                            },
                          }}
                          className="rounded-lg"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                md: "column",
                                lg: "row",
                              },
                              width: "100%",
                              height: {
                                lg: "100%",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: { sm: "100%", lg: "30%" },
                                height: "180px",
                              }}
                            >
                              <HoverableSlider images={apartmentCard?.images} />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                width: { sm: "100%", lg: "70%" },
                              }}
                            >
                              <Link
                                to={`${AppLinks.ApartmentDetails.path}/${apartmentCard._id}`}
                                style={{ width: "100%" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    height: "100%",
                                    flexDirection: {
                                      xs: "column",
                                      md: "column",
                                      lg: "row",
                                    },
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      flexGrow: 2,
                                      pb: {
                                        xs: 0,
                                        md: 1,
                                      },
                                    }}
                                  >
                                    <Box
                                      className="text-sm"
                                      sx={{
                                        paddingTop: {
                                          xs: "15px",
                                          sm: "15px",
                                          md: "15px",
                                        },
                                      }}
                                    >
                                      {apartmentCard.bedroom} -{" "}
                                      <Translate text="room apartment" />
                                    </Box>
                                    <h3 className="text-base font-semibold  ">
                                      {apartmentCard.title}
                                    </h3>
                                    <Typography
                                      sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      <a
                                        href={`https://maps.google.com/?ll=${apartmentCard.latitude},${apartmentCard.longitude}`}
                                        className="text-sm text-primary underline"
                                      >
                                        {apartmentCard.location}
                                      </a>
                                    </Typography>

                                    <div className="flex gap-5">
                                      <p className="text-sm">
                                        {apartmentCard.guests}{" "}
                                        <Translate text="guests" />
                                      </p>
                                      <p className="text-sm">
                                        {apartmentCard.bed}{" "}
                                        <Translate text="beds" />
                                      </p>
                                      <p className="text-sm">
                                        {apartmentCard.bedroom}{" "}
                                        <Translate text="bedroom" />
                                      </p>
                                      <p className="text-sm">
                                        {apartmentCard.h}m2
                                      </p>
                                    </div>
                                  </CardContent>
                                  <CardContent
                                    sx={{
                                      maxWidth: "180px",
                                      minWidth: "180px",
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                      borderLeft: {
                                        md: "1px solid lightgray",
                                      },
                                      pt: {
                                        xs: 0.5,
                                        md: 1,
                                      },
                                    }}
                                  >
                                    <div className="flex justify-between">
                                      <h3 className="text-sm font-semilight">
                                        {apartmentCard.totalReviews}{" "}
                                        <Translate text="reviews" />
                                      </h3>
                                      <div className="inline-flex items-center">
                                        <MdStar className="text-yellow-500" />{" "}
                                        <p className="font-semibold">
                                          {apartmentCard.averageRatings}
                                        </p>
                                      </div>
                                    </div>
                                    <div></div>
                                    <div>
                                      <span className="font-bold">
                                        {apartmentCard.discountprice}P
                                      </span>{" "}
                                      <span className="text-[0.9] font-light">
                                        {apartmentCard.duration}
                                      </span>
                                    </div>
                                  </CardContent>
                                </Box>
                              </Link>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    ))}

                    <OverlayThree isLoading={isLoading} />
                    {allApartments.length === 0 && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex flex-col",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            <Translate text="No Apartment Found" />
                          </Box>

                          <Box>
                            <Translate text="Try expanding your search radius or changing filters." />
                          </Box>

                          <button
                            className="xs:w-[150px] xs:h-[50px] sm:w-[200px] sm:h-[50px] xs:rounded-md sm:rounded-md rounded-full bg-primary px-4 py-0 mb-6 mr-10 md:mr-20 text-white  sm:text-[15px] xs:text-[15px] "
                            onClick={() => refreshData()}
                            style={{ margin: "auto", marginTop: "10px" }}
                          >
                            <Translate text="Reset all filters" />
                          </button>
                        </Box>
                      </Grid>
                    )}
                    {allApartments.length > 0 && !!filter.location && (
                      <Grid item xs={12}>
                        {filter.location}: {allApartments.length}{" "}
                        <Translate text="properties found" />
                      </Grid>
                    )}
                    {allApartments.length > 0 && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            justifyContent: { md: "space-between" },
                            display: "flex",
                            width: "100%",
                            // mx: "10px",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                            border: 1,
                            borderRadius: "16px",
                            borderColor: "gray",
                            py: { xs: 2, md: 4 },
                            px: { md: 4 },
                          }}
                        >
                          <Stack
                            spacing={2}
                            sx={{
                              alignSelf: { xs: "center" },
                            }}
                          >
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={handleChange}
                              shape="rounded"
                              color="primary"
                            />
                          </Stack>

                          <Box sx={{ textAlign: { xs: "center" } }}>
                            <div className="text-gray-500 mt-1">
                              <Translate text="Showing" />
                              {startIndex + 1} - {endCount}
                            </div>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className={` md:hidden lg:hidden xl:hidden 2xl:hidden`}>
        {showFilterPopup && (
          <div
            className={`${style.popup_container} md:hidden lg:hidden xl:hidden 2xl:hidden`}
          >
            <div className={style.popup_content}>
              <div className={`${style.filter_header} bg-[#eef5ff]`}>
                <div className={style.close_icon} onClick={toggleFilterPopup}>
                  <CloseIcon />
                </div>

                <div className={style.filter_text}>
                  {" "}
                  <Translate text="Filters" />
                </div>
              </div>
              <div>
                <ListFilter
                  superHost={superHost}
                  setSuperHost={setSuperHost}
                  priceRanges={priceRanges}
                  setPriceRanges={setPriceRanges}
                  propertyType={propertyType}
                  setPropertyType={setPropertyType}
                  rules={rules}
                  setRules={setRules}
                  facilites={facilites}
                  setFacilities={setFacilities}
                  bedPreference={bedPreference}
                  setBedPreference={setBedPreference}
                  distance={distance}
                  setDistance={setDistance}
                  freeCancellation={freeCancellation}
                  setFreeCancellation={setFreeCancellation}
                  noDeposit={noDeposit}
                  setNoDeposit={setNoDeposit}
                  resetFilter={resetFilter}
                />
              </div>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                  py: 3,
                  backgroundColor: "white",
                  left: 0,
                }}
              >
                <Button onClick={(e) => resetFilter()}>
                  <Translate text="Reset all filters" />
                </Button>
                <button
                  onClick={() => filterApartment()}
                  style={{ cursor: "pointer" }}
                  className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-2 px-5 md:hidden lg:hidden xl:hidden 2xl:hidden"
                  type="button"
                >
                  <Translate text="Apply" />
                  <IoSearch className="text-xl" />
                </button>
              </Box>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ApartmentListing;
