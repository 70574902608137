import axios from "axios";
import { useEffect, useState } from "react";
import { FcSearch } from "react-icons/fc";
import { APIS_URL } from "../services/api";
import { useShowMsg, useUserContext } from "../context/Context";
import translateText from "../utils/translationHelper";
import baseUrl from "../utils/config";

interface Props {
  onSelect: (e: any) => void;
  bgColor: any;
}

interface Location {
  location: string;
}
export default function Location({ onSelect, bgColor }: Props) {
  const { user } = useUserContext();
  const { notifyError, notifySuccess } = useShowMsg();
  const [popularDestination, setPopularDestination] = useState<Location[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSearches, setUserSearches] = useState<Location[]>([]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.POPULAR_DESTINATION}`,
      })
        .then(async (response: any) => {
          const popularDestinations: any[] = response.data.popularDestination;

          if (user.locale !== "en") {
            let popularDestData = await Promise.all(
              popularDestinations.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            setPopularDestination(popularDestData);
            return;
          }

          setPopularDestination(popularDestinations);
        })
        .then((err: any) => {})
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error);
    }
    const savedSearches = localStorage.getItem("userSearches");
    if (savedSearches) {
      setUserSearches(JSON.parse(savedSearches));
    }
  }, []);

  const handleSearch = (e: any) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (
      query &&
      !popularDestination.some(
        (item) => item.location.toLowerCase() === query.toLowerCase()
      ) &&
      !userSearches.some(
        (item) => item.location.toLowerCase() === query.toLowerCase()
      )
    ) {
      const newSearch = { location: query };

      const updatedUserSearches = [newSearch, ...userSearches];
      setUserSearches(updatedUserSearches);
    }
  };
  // console.log("userSearches", userSearches);
  const combinedDestinations = [...popularDestination, ...userSearches];

  const filteredDestinations = searchQuery
    ? combinedDestinations.filter(
        (item) =>
          item.location &&
          item.location.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : combinedDestinations;

  const handleItemClick = (item: any) => {
    onSelect(item);

    if (
      userSearches.some(
        (search) =>
          search.location.toLowerCase() === item.location.toLowerCase()
      )
    ) {
      const savedSearches = localStorage.getItem("userSearches");

      if (savedSearches) {
        const parsedSearches = JSON.parse(savedSearches);

        const itemExists = parsedSearches.some(
          (search: any) => search.location === item.location
        );
        if (!itemExists) {
          const updatedUserSearches = [item, ...JSON.parse(savedSearches)];

          setUserSearches(updatedUserSearches);

          localStorage.setItem(
            "userSearches",
            JSON.stringify(updatedUserSearches)
          );
          return;
        }
      } else {
        const updatedUserSearches = [item, ...userSearches];
        setUserSearches(updatedUserSearches);
        localStorage.setItem("userSearches", JSON.stringify([item]));
      }
    }
  };

  return (
    <div className={`shadow-2xl rounded-lg ${bgColor} `}>
      <div className=" overflow-y-auto px-5 py-5 rounded-2xl max-h-[50vh]">
        <h3 className="text-base text-secondary font-light pb-3">
          Popular destinations
        </h3>
        <input
          style={{ border: "none", borderRadius: "0" }}
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search location..."
          className="w-full mb-3 p-2 hover:border-none"
        />
        {filteredDestinations.map((item) => {
          return (
            <div
              key={item.location}
              onClick={() => {
                handleItemClick(item);
              }}
              className="cursor-pointer"
            >
              <div className="flex items-center  gap-5 py-3">
                <FcSearch className="bg-[#d6e3ff] rounded-full p-1 text-base" />
                <div className="flex justify-between basis-[100%]">
                  <h3>{item.location}</h3>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
