import { FC } from "react";
import { Popover } from "@mui/material";
import Location from "../../components/location";

interface Filter {
  location: string;
  fromDate: Date | null;
  toDate: Date | null;
}
interface LocationPopupProps {
  anchorLocationEl: HTMLElement | null;
  setAnchorLocationEl: (el: HTMLDivElement | null) => void;
  filter: { location: string; fromDate: Date | null; toDate: Date | null };

  setFilter: Function;
  bgColor: string;
}

const LocationPopup: FC<LocationPopupProps> = ({
  anchorLocationEl,
  setAnchorLocationEl,
  filter,
  setFilter,
  bgColor,
}) => {
  return (
    <Popover
      open={Boolean(anchorLocationEl)}
      anchorEl={anchorLocationEl}
      onClose={() => setAnchorLocationEl(null)}
    >
      <Location
        bgColor={bgColor}
        onSelect={function (e: any): void {
          setFilter((filter: Filter) => ({
            ...filter,
            location: e.location,
          }));
          setAnchorLocationEl(null);
        }}
      />
    </Popover>
  );
};

export default LocationPopup;
