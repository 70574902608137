import { Grid, ImageListItem, ImageListItemBar } from "@mui/material";
import ExploreRussiaContent from "./shared/ExploreRussiaContent";
import ChildrenOnSki from "../images/children on ski.jpg";
import ADarkNight from "../images/a dark night.jpg";
import PurpleSet from "../images/purple set.jpg";
import { Translate } from "react-translated";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ExploreRussia = () => {
  const CustomStyle = createTheme({
    components: {
      MuiImageListItemBar: {
        styleOverrides: {
          title: {
            display: "inline-block",
            fontSize: "20px",
            border: "2px solid",
            borderRadius: "10px",
            padding: "4px 4px 4px 4px",
          },
        },
      },
    },
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <ImageListItem>
            <img src={ChildrenOnSki} loading="lazy" className="rounded-lg" />
            <ThemeProvider theme={CustomStyle}>
              <ImageListItemBar
                title={<Translate text="Ski resorts in Russia" />}
                position="top"
                actionPosition="left"
                sx={{
                  background: "transparent",
                }}
              />
            </ThemeProvider>
          </ImageListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageListItem>
            <img src={ADarkNight} loading="lazy" className="rounded-lg" />
            <ThemeProvider theme={CustomStyle}>
              <ImageListItemBar
                title={<Translate text="Where to go for New Year" />}
                position="top"
                actionPosition="left"
                sx={{
                  background: "transparent",
                }}
              />
            </ThemeProvider>
          </ImageListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageListItem>
            <img src={PurpleSet} loading="lazy" className="rounded-lg" />
            <ThemeProvider theme={CustomStyle}>
              <ImageListItemBar
                title={<Translate text="Beautiful place in winter" />}
                position="top"
                actionPosition="left"
                sx={{
                  background: "transparent",
                }}
              />
            </ThemeProvider>
          </ImageListItem>
        </Grid>
      </Grid>
    </>
  );
};

export default ExploreRussia;
