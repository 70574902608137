import {
  Typography,
  Grid,
  FormLabel,
  Select,
  MenuItem,
  List,
  ListItemText,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, margin } from "@mui/system";
import DragDropFileUpload from "../DragDropFileUpload";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { APIS_URL } from "../../services/api";
import Input from "../shared/Input";
import MapInput from "../MapInput";
import GeoapifySearch from "../ProfileGeoapify";
import { useShowMsg } from "../../context/Context";
import Modal from "@mui/material/Modal";
import style from "./profile.module.css";
import { TbLoaderQuarter } from "react-icons/tb";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext, useNotificationContext } from "../../context/Context";
import { Translate } from "react-translated";
import baseUrl from "../../utils/config";

export default function PersonalInfo() {
  const [formVisible, setFormVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const { notifyError, notifySuccess } = useShowMsg();
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const [editProfile, setEditProfile] = useState({
    firstname: " ",
    lastname: " ",
    displayname: " ",
    realname: "  ",
    phone: " ",
    location: "",
    address: "",
    dateofbirth: " ",
    bio: " ",
    speak: [],
  });
  const [files, setFiles] = useState<FileList>();
  const handleChangeEditProfile = (e: any) => {
    setEditProfile({ ...editProfile, [e.target.name]: e.target.value });
  };

  const handleUpdateProfile = async () => {
    setUpdatingProfile(true);
    const newEditProfile = {
      firstname: editProfile.firstname,
      lastname: editProfile.lastname,
      phone: editProfile.phone,
      location: editProfile.location,
      address: editProfile.address,
      dateofbirth: editProfile.dateofbirth,
      bio: editProfile.bio,
      speak: editProfile.speak,
    };
    try {
      const response = await axios({
        method: "put",
        url: `${baseUrl}${APIS_URL.EDIT_PROFILE}`,

        data: newEditProfile,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      updateUser({
        ...user,
        firstname: newEditProfile.firstname,
        lastname: newEditProfile.lastname,
      });
      setUpdatingProfile(false);
      notifySuccess("Success Editing Profile");
    } catch (error: any) {
      setUpdatingProfile(false);
      notifyError("Error Editing Profile");
    }
  };
  const fetchInfo = () => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.PROFILE}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((response: any) => {
          const userInfo = response.data.userData;

          setEditProfile(userInfo);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };
  const handleFileUpload = async () => {
    try {
      if (files?.length && files?.length > 0) {
        setUploadingFile(true);
        setReviewing(false);
        const file = files[0];
        const response = await axios({
          method: "post",
          url: `${baseUrl}${APIS_URL.DOC_PRESIGNED_URL}`,

          data: {
            filetype: file?.type,
          },
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        const res = await axios.put(response.data.presignedUrl, file, {
          headers: {
            "Content-Type": file?.type,
          },
        });

        if (res.status === 200) {
          const updateDocStatus = await axios({
            method: "post",
            url: `${baseUrl}${APIS_URL.UPDATE_DOC_STATUS}`,

            data: {
              status: true,
            },
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          });

          if (updateDocStatus.data.success === true) {
            updateUser({ ...user, isCredentialUpload: true });
            setUploadingFile(false);
            notifySuccess("Success Uploading File");
            setReviewing(true);

            return;
          } else {
            setUploadingFile(false);
            notifyError("Please try again");
            return;
          }
        }
      } else {
        notifyError("Please choose a file");
        return;
      }
    } catch {
      setUploadingFile(false);
      notifyError("Something went wrong Please try again");
    }
  };
  useEffect(() => {
    fetchInfo();
  }, []);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return (
    <>
      <Typography variant="h5" component="h1" fontWeight="bold" pb={2}>
        <Translate text="Account Info" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="First Name" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Enter your first name"
            type="text"
            name="firstname"
            id=""
            value={editProfile.firstname}
            onChange={handleChangeEditProfile}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Last Name" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Enter your last name"
            type="text"
            name="lastname"
            id=""
            value={editProfile.lastname}
            onChange={handleChangeEditProfile}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Phone" />
          </FormLabel>
          <Input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Phone"
            type="tel"
            name="phone"
            id=""
            value={editProfile.phone}
            onChange={handleChangeEditProfile}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Date Of Birth" />
          </FormLabel>
          <input
            className="border border-[#647482] bg-white  w-[100%]  px-[0.7rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Phone"
            type="date"
            name="dateofbirth"
            id=""
            value={editProfile.dateofbirth}
            onChange={handleChangeEditProfile}
            max={yesterday.toISOString().split("T")[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Location" />
          </FormLabel>
          {editProfile.location === "" && (
            <GeoapifySearch
              onPlaceSelect={(e: any) => {
                console.log("e", e);
                setEditProfile({
                  ...editProfile,
                  location: e.properties.formatted,
                  address: e.properties.formatted,
                });
              }}
              value=""
            />
          )}

          {editProfile.location && (
            <GeoapifySearch
              onPlaceSelect={(e: any) => {
                console.log("e", e);
                setEditProfile({
                  ...editProfile,
                  location: e.properties.formatted,
                  address: e.properties.formatted,
                });
              }}
              value={editProfile.location}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Speak" />
          </FormLabel>

          <Autocomplete
            multiple
            freeSolo
            options={["English", "Russian", "French"]}
            value={editProfile.speak}
            sx={{
              // bgcolor: "red",
              ".MuiOutlinedInput-root": {
                borderRadius: "15px",
                borderColor: "black",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
                borderColor: "black",
              },
            }}
            className="  mt-4 "
            onChange={(event: any, newValue: any) => {
              handleChangeEditProfile({
                target: { name: "speak", value: newValue },
              });
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Select or type a language"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    borderRadius: "15px",
                    bgcolor: "white",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel sx={{ fontWeight: "bold" }}>
            {" "}
            <Translate text="Bio" />
          </FormLabel>
          <textarea
            className="border border-[#647482] bg-white  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="About yourself in a few words"
            name="bio"
            id=""
            value={editProfile.bio}
            onChange={handleChangeEditProfile}
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          {!updatingProfile ? (
            <button
              onClick={handleUpdateProfile}
              className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md inline-flex items-center gap-1"
            >
              <Translate text="Update Profile" />
            </button>
          ) : (
            // sx={{ display: "flex", justifyContent: "center" }}
            <Box className="  py-[.5rem] rounded-lg px-[2rem]  inline-flex items-center gap-1">
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            </Box>
          )}
        </Grid>
      </Grid>
      <Typography variant="h5" component="h1" fontWeight="bold" mt={5}>
        <span className="text-primary">
          {" "}
          <Translate text="Verification" />{" "}
        </span>{" "}
        ( <Translate text="Recommended" />)
      </Typography>
      <List>
        <ListItemText>
          <Translate text="Upload your International Passport (for guest)" />
        </ListItemText>
        <ListItemText>
          <Translate text="Upload your National Id or License (for host)" />
        </ListItemText>
        {reviewing && (
          <Box>
            <ListItemText className="text-green-600">
              <Translate text="File under review" />
            </ListItemText>
            <ListItemText>
              <small className="text-green-600">
                <Translate text="An alert will notify you about your credential status." />
              </small>
            </ListItemText>
          </Box>
        )}
      </List>
      <DragDropFileUpload
        onFileUpload={(e: FileList) => {
          setFiles(e);
        }}
        text={(files?.length ?? 0) > 0 ? files?.[0].name : undefined}
      />
      <Box mt={2}>
        {!uploadingFile ? (
          <button
            onClick={handleFileUpload}
            className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md inline-flex items-center gap-1"
          >
            <Translate text="Send for verification" />
          </button>
        ) : (
          // sx={{ display: "flex", justifyContent: "center" }}
          <Box className="  py-[.5rem] rounded-lg px-[2rem]  inline-flex items-center gap-1">
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          </Box>
        )}
      </Box>
    </>
  );
}
function notifyError(message: any) {
  throw new Error("Function not implemented.");
}
