import { useState } from "react";
import style from "./nav.module.css";
import { CiSettings } from "react-icons/ci";

interface DropDownProps {
  isLanguageSideBar: boolean;
  children: any;
}

const LanguageOptions: React.FC<DropDownProps> = ({
  isLanguageSideBar,
  children,
}) => {
  const handleItemClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={style.side_dropdown}>
      <div
        className={`${style.side_dropdown_content}${
          isLanguageSideBar ? "show" : ""
        }`}
        onClick={handleItemClick}
      >
        {children}
      </div>
    </div>
  );
};

export default LanguageOptions;
