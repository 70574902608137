import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Translate } from "react-translated";

interface PrivacyPolicy {
  refundOpen: boolean;
  handleRefundClose: () => void;
}

const RefundAndCancellationModal: FC<PrivacyPolicy> = ({
  refundOpen,
  handleRefundClose,
}) => {
  return (
    <Modal
      open={refundOpen}
      onClose={handleRefundClose}
      aria-labelledby="privacy-policy-modal-title"
      aria-describedby="privacy-policy-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: { xs: 500, sm: 500, lg: "60%" },
          width: { xs: "90%", sm: "90%", lg: "100%" },
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleRefundClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          id="privacy-policy-modal-description"
          sx={{
            mt: 2,
            maxHeight: "400px",
            overflowY: "auto",
            padding: "12px",

            borderTop: "1px solid #D9E6FF",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            className=" text-[#225FFE] font-bold"
          >
            <Translate text="Refund and Cancellation Policy" />
          </Typography>
          <Typography
            className="py-4 text-[#132F41] font-bold"
            variant="h5"
            component="h2"
          >
            <Translate text="Your Refund Policy" />
          </Typography>
          <Typography className="pl-[20px]" component="div">
            <ol className="list-decimal">
              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Introduction" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="At Mira-Dom, we strive to ensure a satisfactory experience for both hosts and guests. This Refund and Cancellation Policy outlines the terms and conditions for cancellations and refunds." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="User Verification" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="Users must be verified to access the platform fully. Verification includes providing proof of identity using an international passport for foreigners and a Russian passport for locals. This process helps promote trust and security within the platform." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p>
                  <Translate text="Guest Cancellations" />
                </p>
                <p className=" text-[16px] font-normal">
                  <ul className="list-disc my-6">
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Before Check-In" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Guests may cancel their booking up to 14 days before the check-in date for a full refund." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Within 14 Days of Check-In" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Cancellations made within 14 days of the check-in date will be subject to a 50% refund." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="After Check-In" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="No refunds will be issued after the check-in date." />
                      </p>
                    </li>
                  </ul>
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Host Cancellations" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Hosts must notify Mira-Dom and the guest at least 14 days before the check-in date if they need to cancel a booking. Failure to do so may result in penalties, including potential suspension of the host's account." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Refund Process" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Refunds will be processed within 10 business days of the cancellation request." />
                  </li>
                  <li>
                    <Translate text="Refunds will be issued to the original payment method used during booking." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Complaints and Dispute Resolution" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Users can submit complaints via email, Telegram, or WhatsApp. Mira-Dom will review and address complaints promptly." />
                  </li>
                  <li>
                    <Translate text="Any disputes will be resolved through binding arbitration in accordance with the rules of the Russian Federation." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Amendments" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Mira-Dom reserves the right to amend this Refund and Cancellation Policy at any time. Users will be notified of significant changes through email or notifications on the platform." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Contact Information" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="For any questions or concerns regarding this policy, users can contact us via email, Telegram, or WhatsApp. Mira-Dom is a fully registered company in Russia and adheres to all applicable rules, laws, and taxes." />
                  </li>
                </ul>
              </li>
            </ol>
            <div className="text-[20px] font-bold mt-6">
              <p className="">
                <Translate text="Contact Us" />:
              </p>

              <p className=" text-[16px] font-normal">
                <ul className="list-disc my-6">
                  <li>
                    <p className="inline font-bold">
                      <Translate text="Email" />:{" "}
                    </p>
                    <p className="inline text-primary">
                      <a
                        className="underline"
                        href="mailto:support@mira-dom.com"
                        target="_blank"
                      >
                        <Translate text="support@mira-dom.com" />
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="inline font-bold">
                      <Translate text="Telegram" />:{" "}
                    </p>
                    <p className="inline text-[#777777]">
                      <Translate text="Mira-Dom Support" />
                    </p>
                  </li>
                  <li>
                    <p className="inline font-bold">
                      <Translate text="WhatsApp" />:{" "}
                    </p>
                    <p className="inline text-[#777777]">
                      <Translate text="+7 (904) 663-69-63" />
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default RefundAndCancellationModal;
