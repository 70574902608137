import CloseIcon from "@mui/icons-material/Close";
import { RiErrorWarningLine } from "react-icons/ri";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import { Translate } from "react-translated";

interface VerifyPopupStructure {
  handleClose: Function;
}
const VerifyPopup: React.FC<VerifyPopupStructure> = ({ handleClose }) => {
  return (
    <Box>
      <div className="relative m-3">
        <div className="top-0 left-0 p-4">
          <div className="bg-white xs:pr-6 sm:pr-6 xs:pl-1 sm:pl-1 xs:py-4 sm:py-4 p-8  rounded-lg max-w-md relative">
            <button
              className="absolute top-2 right-2 text-red-600 hover:text-red-800"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </button>
            <div className="flex gap-5 items-start">
              <RiErrorWarningLine className="text-red-700 w-12 h-12" />
              <p>
                <Link
                  className="text-blue-800 underline"
                  to={AppLinks.EditProfile.path}
                >
                  {" "}
                  <Translate text="Verify your account" />
                </Link>{" "}
                <Translate text="in 2 mins to activate more options for a better experience" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default VerifyPopup;
