import { FC, useEffect } from "react";
import style from "./home.module.css";
import house from "../images/house.png";
import chooseMiradom1 from "../images/why-choose-us/choose-miradom-1.png";
import chooseMiradom2 from "../images/why-choose-us/choose-miradom-2.png";
import booking from "../images/sponsors/booking.png";
import airbnb from "../images/sponsors/airbnb.png";
import figma from "../images/sponsors/figma.png";
import ebay from "../images/sponsors/ebay.png";
import spotify from "../images/sponsors/spotify.png";
import chooseMiradom3 from "../images/why-choose-us/choose-miradom-3.png";
import { IoMdStar } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { CiHeart } from "react-icons/ci";
import { CiCalendarDate } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { CiLocationArrow1 } from "react-icons/ci";
import { useState } from "react";
import DestinationCard from "../components/DestinationCard";
import ExploreRussia from "../components/ExploreRussias";
import { FaCheck } from "react-icons/fa";
import Button from "../components/shared/Button";
import phoneInBlue from "../images/phoneInBlue.jpg";
import phoneInBrown from "../images/phoneInBrown.jpg";
import Container from "../components/shared/Container";
import SignUp from "./SignUp";
import LogIn from "./LogIn";
import AddToFavorite from "../components/AddToFavorite";
import { FaApple } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
import {
  useIsLoggedIn,
  useShowMsg,
  useToogleLogIn,
  useUserContext,
} from "../context/Context";
import Card from "../components/Card";
import OneCard from "../components/OneCard";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import axios from "axios";
import { APIS_URL } from "../services/api";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./home.css";
import baseUrl from "../utils/config";
import translateText from "../utils/translationHelper";
// import { transliterate, slugify } from "transliteration";
// import styled from "styled-components";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Card as MuiCard,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Modal,
  Popper,
  Popover,
  Divider,
  CardActionArea,
} from "@mui/material";
import Slider from "react-slick";

import GuestPopup from "./components/GuestPopup";
import CheckoutPopup from "./components/CheckoutPopup";
import CheckinPopup from "./components/CheckinPopup";
import LocationPopup from "./components/LocationPopup";
import Overlay from "../components/Overlay";
import { Translate } from "react-translated";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import nextArrow from "../images/nextArrow.png";
import previousArrow from "../images/previousArrow.png";
import HomeImage from "../images/Home-img.png";
import kiwi from "../images/kiwi.png";
import Solution from "../images/solution.png";
import PayTrybe from "../images/trybe.png";
import Uni from "../images/uni.png";
import Frame from "../images/Frame.png";
import coloredHouse2 from "../images/coloredHouses2.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { promiseHooks } from "v8";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Home: FC = () => {
  const redirect = useHistory();
  const searchLocation = useLocation();
  const { user } = useUserContext();
  // console.log("User Locale", user);
  const search = new URLSearchParams(searchLocation.search);
  // const { CyrillicToTranslit } = require("cyrillic-to-translit-js");
  const translit = CyrillicToTranslit();
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: "",
    guest: 0,
    adult: 0,
    children: 0,
    infant: 0,
    pet: 0,
    fromDate: null,
    toDate: null,
  });
  const [anchorLocationEl, setAnchorLocationEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const [anchorCheckoutEl, setAnchorCheckoutEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorCheckinEl, setAnchorCheckinEl] = useState<null | HTMLElement>(
    null
  );

  const [apartmentLists, setApartmentList] = useState<any[]>([]);
  const [favorites, setFavorites] = useState(new Set());

  const [popularDestination, setPopularDestination] = useState<any[]>([]);
  const [bestHosts, setBestHosts] = useState<any[]>([]);
  const { handleToogleLogin } = useToogleLogIn();
  const { isLoggededIn } = useIsLoggedIn();
  const { notifyError, notifySuccess } = useShowMsg();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const HostslideShowLength = bestHosts.length >= 3 ? 3 : 2;
  const auth = localStorage.getItem("token");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:1200px)"
  );
  const isLargeScreen = useMediaQuery("(min-width:1201px)");
  let imageHeight: string;
  if (isSmallScreen) {
    imageHeight = "200px";
  } else if (isMediumScreen) {
    imageHeight = "300px";
  } else if (isLargeScreen) {
    imageHeight = "410px";
  }

  const CustomStyle = createTheme({
    components: {
      MuiImageListItemBar: {
        styleOverrides: {
          title: {
            "@media (max-width: 600px)": {
              display: "flex",
            },
            "@media (min-width: 600px)": {
              display: "inline-block",
            },
            fontSize: "15px",
            border: "2px solid",
            borderRadius: "10px",
            padding: "4px 4px 4px 4px",
          },
        },
      },
    },
  });

  useEffect(() => {}, []);
  const ApartmentslideShowLength = () => {
    if (apartmentLists.length === 1) {
      return 1;
    }
    if (apartmentLists.length === 2) {
      return 2;
    }
    if (apartmentLists.length === 3) {
      return 3;
    } else {
      return 3;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      let verify = search.get("verify");
      console.log("verify", verify);
      if (verify === "true") {
        notifySuccess("Email Verification Successful");
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const showBecomeAHost = () => {
    history.push(AppLinks.ListApartment.path);
  };

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.FEATURED_APARTMENTS}`,
      })
        .then(async (response: any) => {
          // console.log("feature apt", response);
          const apartmentList: any[] = response.data.featuredApartment;
          // console.log("apartmentList", apartmentList);
          let translatedData;
          if (user.locale !== "en") {
            translatedData = await Promise.all(
              apartmentList.map((item: any) => translateText(item, user.locale))
            );
            setApartmentList(translatedData);
            return;
          }
          setApartmentList(apartmentList);
        })
        .then((err: any) => {})
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error);
    }
  }, [user.locale]);

  console.log("favorites", favorites);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.POPULAR_DESTINATION}`,
      })
        .then(async (response: any) => {
          const popularDestinations: any[] = response.data.popularDestination;
          // console.log("popularDestinations", popularDestinations);
          if (user.locale !== "en") {
            let popularDestData = await Promise.all(
              popularDestinations.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            setPopularDestination(popularDestData);
            return;
          }

          setPopularDestination(popularDestinations);
        })
        .then((err: any) => {})
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error);
    }
  }, [user.locale]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.BEST_HOSTS}`,
      })
        .then(async (response: any) => {
          const data: any[] = response.data.bestHost;
          // console.log("data", data);
          if (user.locale !== "en") {
            let besthostData = await Promise.all(
              data.map((item: any) => translateText(item, user.locale))
            );
            setBestHosts(besthostData);
            return;
          }
          setBestHosts(data);
        })
        .then((err: any) => {})
        .catch((err) => console.log(err));
    } catch (error: any) {
      notifyError(error);
    }
  }, [user.locale]);

  // if (isLoading) {
  //   return <div>Loading</div>;
  // }

  const handleClick = () => {
    if (!isLoggededIn) {
      handleToogleLogin();
    }
  };

  const url = `apartment-listing?${new URLSearchParams({
    location: filter.location,
    guest: `${filter.guest}`,
    adult: `${filter.adult}`,
    children: `${filter.children}`,
    infant: `${filter.infant}`,
    pet: `${filter.pet}`,
    fromDate: filter.fromDate?.toDateString() ?? "",
    toDate: filter.toDate?.toDateString() ?? "",
  })}`;

  console.log("apartmentLists", apartmentLists);

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          borderRadius: "100%",
          height: "50px",
          width: "50px",
          marginRight: "-15px",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <img
          src={nextArrow}
          alt="Next"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: "#00b33c",
          borderRadius: "100%",
          height: "50px",
          width: "50px",
          marginLeft: "-15px",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <img
          src={previousArrow}
          alt="Previous"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    );
  }

  const handlePopularDestClick = (x: any) => {
    if (isLoggededIn) {
      history.push(
        `apartment-listing?${new URLSearchParams({
          location: x.location,
        })}`
      );
    } else {
      handleToogleLogin();
    }
  };

  const handleFeatureAptClick = (apartment: any) => {
    if (isLoggededIn) {
      history.push(`${AppLinks.ApartmentDetails.path}/${apartment._id}`);
    } else {
      handleToogleLogin();
    }
  };

  return (
    <Container>
      <SignUp />
      <LogIn />

      <div className="flex justify-center items-center bg-[#EBF2FF] pb-10 ">
        <div className="xs:w-full sm:w-full md:w-full lg:w-full xl:w-full w-full ">
          <div
            className="md:m-10 lg:m-10 xl:m-10 2xl:m-10 mt-20 xs:mt-0 sm:mt-0 bg-center bg-cover xs:h-[700px] sm:h-[700px]  md:h-[600px] lg:h-[600px] xl:h-[600px] 2xl:h-screen   px-[6rem] md:px-[3rem] lg:px-[3rem] xl:px-[3rem] xs:px-[1rem] sm:px-[1rem]"
            style={{
              backgroundImage: `url(${HomeImage}) `,
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <div className="pt-[8rem]">
              <div className=" flex justify-center item-center">
                <p className="2xl:text-5xl xs:text-[20px] sm:text-[20px] md:text-[30px] lg:text-[30px] xl:text-[30px] xs:pl-[20px] sm:pl-[20px] font-bold text-white">
                  <Translate text="Same Booking,Different Experience" />
                </p>
              </div>
              <div className=" flex justify-center item-center">
                <p className="mt-4 2xl:text-2xl xs:text-sm sm:text-sm font-bold text-white">
                  <Translate text="Tailored for Foreigners, Embraced by All" />
                </p>
              </div>
            </div>

            {/* LOCATION POPUP */}
            <LocationPopup
              anchorLocationEl={anchorLocationEl}
              setAnchorLocationEl={setAnchorLocationEl}
              filter={filter}
              setFilter={setFilter}
              bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
            />

            {/* GUEST POPUP */}
            <GuestPopup
              anchorGuestEl={anchorGuestEl}
              setAnchorGuestEl={setAnchorGuestEl}
              filter={filter}
              setFilter={setFilter}
              bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
            />
            {/* CHECKOUT POPUP */}
            <CheckoutPopup
              anchorCheckoutEl={anchorCheckoutEl}
              setAnchorCheckoutEl={setAnchorCheckoutEl}
              filter={filter}
              setFilter={setFilter}
              bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
              fillColor={"landing"}
            />
            {/* CHECKIN POPUP */}
            <CheckinPopup
              anchorCheckinEl={anchorCheckinEl}
              setAnchorCheckinEl={setAnchorCheckinEl}
              filter={filter}
              setFilter={setFilter}
              bgColor={"bg-gradient-to-b from-[#e6f1fe] to-white"}
              fillColor={"landing"}
            />
            {/* <div className=" bg-gray-200 "> */}
            {/* <p className="xs:bg-red-900 sm:bg-blue-900 md:bg-orange-900 lg:bg-green-900 xl:bg-pink-900 2xl:bg-purple-900">
                good
              </p> */}
            <div className="">
              {/* xs:w-11/12 sm:w-10/12 md:w-11/12 lg:w-12/12 xl:w-10/12 2xl:w-8/12 */}
              <div className="shadow-2xl mt-10 bg-gradient-to-b from-[#e6f1fe] to-white xs:w-11/12 sm:w-10/12 md:w-11/12 lg:w-[100%] xl:w-[100%] 2xl:w-[80%] flex mx-auto justify-center items-center xs:rounded-[3rem] sm:rounded-[3rem] md:rounded-[3rem] lg:rounded-[3rem] xl:rounded-[3rem] 2xl:rounded-[3rem] py-2">
                {/* SMALL SIZE */}
                <Grid
                  container
                  mx="auto"
                  px={3}
                  pt={2}
                  sx={{
                    display: {
                      sm: "none",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <div
                      className="flex cursor-pointer rounded-md"
                      onClick={(e) => {
                        setAnchorLocationEl(
                          anchorLocationEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <CiLocationArrow1 className="text-2xl mt-[10px] " />
                      {!!filter.location ? (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-xl md:text-base sm:text-sm xs:text-sm lg:text-base">
                            <Translate text="Location" />
                          </p>
                          <p className="mt-2 text-md font-light">
                            {filter.location}
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5 ">
                          <p className="font-bold text-xl md:text-base sm:text-sm xs:text-sm lg:text-base">
                            <Translate text="Location" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Where to ?" />
                          </p>
                        </div>
                      )}
                    </div>
                    <Divider sx={{ my: 1 }} />
                    <div className="rounded-md">
                      <Grid item xs={6} sx={{ display: "flex" }}>
                        <div
                          className="flex cursor-pointer "
                          onClick={(e) => {
                            setAnchorCheckinEl(
                              anchorCheckinEl ? null : e.currentTarget
                            );
                          }}
                        >
                          <CiCalendarDate className="text-2xl mt-[10px]" />
                          {!!filter.fromDate ? (
                            <div className="flex flex-col mx-5 ">
                              <p className={style.custom_check}>
                                {" "}
                                <Translate text="Check in" />
                              </p>
                              <p className={style.custom_date}>
                                {filter.fromDate.toLocaleDateString()}
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col mx-5">
                              <p className={style.custom_check}>
                                <Translate text="Check in" />
                              </p>
                              <p className="mt-2 text-sm font-light">
                                <Translate text="Add date" />
                              </p>
                            </div>
                          )}

                          <Divider
                            sx={{ mx: 1, color: "red" }}
                            orientation={"vertical"}
                          />
                        </div>

                        <div
                          className="flex cursor-pointer "
                          // onClick={(e) => {
                          //   setAnchorCheckoutEl(
                          //     anchorCheckoutEl ? null : e.currentTarget
                          //   );
                          // }}
                          onClick={(e) => {
                            setAnchorCheckinEl(
                              anchorCheckinEl ? null : e.currentTarget
                            );
                          }}
                        >
                          <CiCalendarDate className="text-2xl mt-[10px]" />
                          {!!filter.toDate ? (
                            <div className="flex flex-col mx-5">
                              <p className={style.custom_check}>
                                <Translate text="Check out" />
                              </p>
                              <p className={style.custom_date}>
                                {filter.toDate.toLocaleDateString()}
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col mx-5">
                              <p className={style.custom_check}>
                                <Translate text="Check out" />
                              </p>
                              <p className="mt-2 text-sm font-light">
                                <Translate text="Add date" />
                              </p>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </div>
                    <Divider sx={{ my: 1 }} />
                    <div
                      className="flex cursor-pointer items-start rounded-md"
                      onClick={(e) => {
                        setAnchorGuestEl(
                          anchorGuestEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <GoPerson className="text-2xl mt-[10px]" />
                      {!!filter.guest ? (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-xl md:text-base sm:text-sm xs:text-sm lg:text-base align-top">
                            <Translate text="Guest" />
                          </p>
                          <p className="mt-2 text-md font-light">
                            <>
                              {" "}
                              {filter.guest} <Translate text="Guest" />{" "}
                              {filter.pet ? `${filter.pet} Pet` : ""}
                            </>
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-xl md:text-base sm:text-sm xs:text-sm lg:text-base align-top">
                            <Translate text="Guest" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Add Guests" />
                          </p>
                        </div>
                      )}
                    </div>
                    <Divider sx={{ my: 1 }} />
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          lg: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                    >
                      {isLoggededIn ? (
                        <Link
                          to={url}
                          className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-3 px-5"
                          type="button"
                        >
                          <Translate text="Search" />
                          <IoSearch />
                        </Link>
                      ) : (
                        <button
                          onClick={handleClick}
                          className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-3 px-5"
                          type="button"
                        >
                          <Translate text="Search" />
                          <IoSearch />
                        </button>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* LARGE SIZE */}
                <Grid
                  container
                  spacing={2}
                  px={1}
                  mx="auto"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <Grid item xs={6} md={10.5 / 4}>
                    <div
                      className="flex cursor-pointer"
                      onClick={(e) => {
                        setAnchorLocationEl(
                          anchorLocationEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <CiLocationArrow1 className="text-2xl mt-[10px]" />
                      {!!filter.location ? (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Location" />
                          </p>
                          <p className="mt-2 text-md font-bold">
                            {filter.location}
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Location" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Where to ?" />
                          </p>
                        </div>
                      )}
                      <Box
                        className="border-l-2 h-10 border-gray-500"
                        sx={{
                          display: { xs: "none", lg: "block" },
                        }}
                      ></Box>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={10.5 / 4}>
                    <div
                      className="flex cursor-pointer"
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <CiCalendarDate className="text-2xl mt-[10px]" />
                      {!!filter.fromDate ? (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Check in" />
                          </p>
                          <p className="mt-2 font-bold text-md md:text-base sm:text-sm xs:text-sm lg:text-md">
                            {filter.fromDate.toLocaleDateString()}
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Check in" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Add date" />
                          </p>
                        </div>
                      )}

                      <Box
                        className="border-l-2 h-10 border-gray-500"
                        sx={{
                          display: { xs: "none", lg: "block" },
                        }}
                      ></Box>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={10.5 / 4}>
                    <div
                      className="flex cursor-pointer"
                      // onClick={(e) => {
                      //   setAnchorCheckoutEl(
                      //     anchorCheckoutEl ? null : e.currentTarget
                      //   );
                      // }}
                      onClick={(e) => {
                        setAnchorCheckinEl(
                          anchorCheckinEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <CiCalendarDate className="text-2xl mt-[10px]" />
                      {!!filter.toDate ? (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Check out" />
                          </p>
                          <p className="mt-2 font-bold text-md md:text-base sm:text-sm xs:text-sm lg:text-md">
                            {filter.toDate.toLocaleDateString()}
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md">
                            <Translate text="Check out" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Add date" />
                          </p>
                        </div>
                      )}

                      <Box
                        className="border-l-2 h-10 border-gray-500"
                        sx={{
                          display: { xs: "none", lg: "block" },
                        }}
                      ></Box>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={12 / 5}>
                    <div
                      className="flex cursor-pointer items-start"
                      onClick={(e) => {
                        setAnchorGuestEl(
                          anchorGuestEl ? null : e.currentTarget
                        );
                      }}
                    >
                      <GoPerson className="text-2xl mt-[10px]" />
                      {!!filter.guest ? (
                        <div className="flex flex-col mx-2">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md align-top">
                            <Translate text="Guest" />
                          </p>
                          <p className="mt-2 text-md font-bold">
                            <>
                              {" "}
                              {filter.guest} <Translate text="Guest" />{" "}
                              {filter.pet ? `${filter.pet} Pet` : ""}
                            </>
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mx-5">
                          <p className="font-bold text-[20px] md:text-base sm:text-sm xs:text-sm lg:text-md align-top">
                            <Translate text="Guest" />
                          </p>
                          <p className="mt-2 text-sm font-light">
                            <Translate text="Add Guests" />
                          </p>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={1.5}>
                    <Box
                      // className="relative"
                      sx={{
                        display: {
                          xs: "none",
                          md: "flex",
                          position: "relative",
                          height: "100%",
                        },
                      }}
                    >
                      {isLoggededIn ? (
                        <Link
                          to={url}
                          className="bg-primary w-[65px] rounded-full h-[65px] text-white items-center justify-center flex gap-3 py-3"
                          type="button"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-15px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <IoSearch className="text-xl" />
                        </Link>
                      ) : (
                        <button
                          // absolute top-[0] right-[-15px]
                          onClick={handleClick}
                          className=" bg-primary w-[65px] rounded-full h-[65px] text-white items-center justify-center flex  gap-3 py-3"
                          type="button"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-15px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <IoSearch className="text-xl" />
                        </button>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          md: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                    >
                      {isLoggededIn ? (
                        <Link
                          to={url}
                          className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-3 px-5"
                          type="button"
                        >
                          <Translate text="Search" />
                          <IoSearch className="text-xl" />
                        </Link>
                      ) : (
                        <button
                          onClick={handleClick}
                          className="bg-primary rounded-full text-white items-center justify-center flex gap-3 py-3 px-5"
                          type="button"
                        >
                          <Translate text="Search" />
                          <IoSearch className="text-xl" />
                        </button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <div className="xs:mt-[4rem] sm:mt[4rem] mt-[6rem] item-center justify-center mx-auto w-[90%]">
            <p className="flex item-center justify-center font-bold text-4xl xs:text-[20px] sm:text-[20px]">
              <Translate text="Our Trusted Partners" />
            </p>
            <div className="w-full flex justify-center mt-10">
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={6} md={3} className="flex justify-center">
                  <img src={kiwi} alt="kiwi" />
                </Grid>
                <Grid item xs={6} md={3} className="flex justify-center">
                  <img src={Solution} alt="Solution" />
                </Grid>
                <Grid item xs={6} md={3} className="flex justify-center">
                  <img src={PayTrybe} alt="PayTrybe" />
                </Grid>
                <Grid item xs={6} md={3} className="flex justify-center">
                  <img src={Uni} alt="Uni" />
                </Grid>
              </Grid>
            </div>
          </div>
          {/* bg-[#ececec] */}
          <div className=" pb-10">
            <div className="pb-0 xs:pt-[3rem] sm:pt-[3rem] pt-[6rem] 2xl:pt-[6em] bg-[#EBF2FF]">
              <Box
                className="bg-[#EBF2FF] py-10"
                sx={{
                  borderRadius: "20px",
                }}
              >
                {apartmentLists.length > 0 && (
                  <div className="flex flex-row   justify-between items-center xs:mb-2 md:mb-10 mb-10 xs:mx-4 mx-10">
                    <p className=" xs:mb-10 sm:mb-10  font-bold text-4xl xs:text-[20px] sm:text-[20px]  sm:w-[100px] ">
                      <Translate text="Featured Apartments" />
                    </p>

                    <button
                      className="xs:w-[150px]  xs:h-[50px] sm:w-[200px] sm:h-[50px] w-[150px] h-[50px] xs:rounded-md sm:rounded-md rounded-full bg-white px-4 py-0 mb-6  text-primary hover:bg-black hover:text-white"
                      onClick={() => {
                        isLoggededIn === true
                          ? redirect.push(AppLinks.ApartmentListing.path)
                          : handleToogleLogin();
                      }}
                    >
                      <Translate text="View all" />
                    </button>
                  </div>
                )}
                {apartmentLists.length > 1 && (
                  <div className={`${style.feature_slide}`}>
                    <Slider
                      autoplay={true}
                      slidesToScroll={1}
                      infinite
                      slidesToShow={ApartmentslideShowLength()}
                      // nextArrow={<SampleNextArrow />}
                      // prevArrow={<SamplePrevArrow />}
                      centerMode={true}
                      centerPadding={"100px"}
                      swipeToSlide
                      responsive={[
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: ApartmentslideShowLength(),
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: "100px",
                          },
                        },
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: "100px",
                          },
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            centerMode: false,
                            centerPadding: "0px",
                          },
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            centerMode: false,
                            centerPadding: "0px",
                          },
                        },
                      ]}
                    >
                      {apartmentLists.map((apartment, i) => (
                        <div
                          className="px-5"
                          onClick={() => handleFeatureAptClick(apartment)}
                          key={apartment._id}
                        >
                          <MuiCard
                            sx={{
                              borderRadius: 5,
                              // height: "400px",
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                image={apartment.images[0]}
                                sx={{
                                  height: "180px",
                                  bgcolor: "gray",
                                  width: "100%",
                                  // top: 0,
                                  position: "relative",
                                }}
                              >
                                {/* <div className="rounded-full bg-white text-2xl absolute top-2 right-2 p-2">
                                    <CiHeart />
                                  </div> */}
                                {/* <AddToFavorite
                                    apartment={apartment}
                                   
                                  /> */}
                              </CardMedia>

                              <Box
                                sx={{
                                  height: {
                                    xs: "180px",
                                    sm: "250px",
                                    md: "250px",
                                    lg: "200px",
                                  },
                                  position: "relative",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="p"
                                    >
                                      {apartment.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {apartment.location}
                                    </Typography>
                                  </Box>
                                  <div className="flex flex-col border-2 border-green-500 px-1 py-1 min-w-[70px]">
                                    <p className="line-through text-gray-500">
                                      {apartment.price}P
                                    </p>
                                    <p className="text-green-500">
                                      {apartment.discountprice}P
                                    </p>
                                  </div>
                                </CardContent>

                                <CardActions
                                  sx={{
                                    position: "absolute", // Keeps it at the bottom of the card
                                    bottom: 0, // Anchors to the bottom
                                    width: "100%", // Takes the full width of the card
                                    px: 2,
                                    mt: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    // marginTop: "100px",
                                    // backgroundColor: "red",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {apartment.bedroom} -{" "}
                                    <Translate text="room Apartment" />
                                  </span>

                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IoMdStar
                                      // className="mt-1"
                                      style={{
                                        color: "#dbb80b",
                                      }}
                                    />
                                    <p className="font-bold">
                                      {apartment.Rating}
                                    </p>
                                  </span>
                                </CardActions>
                              </Box>
                            </CardActionArea>
                          </MuiCard>
                        </div>
                      ))}
                    </Slider>
                  </div>
                )}

                {apartmentLists.length === 1 && (
                  <div className={style.feature_slide}>
                    <Box
                      className="justify-around"
                      sx={{
                        display: "flex !important",
                      }}
                    >
                      {apartmentLists.map((apartment, i) => (
                        <div
                          className="px-5"
                          onClick={() => handleFeatureAptClick(apartment)}
                          key={apartment._id}
                        >
                          <MuiCard
                            sx={{
                              borderRadius: 5,
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                image={apartment.images[0]}
                                sx={{
                                  height: 180,
                                  bgcolor: "gray",
                                  position: "relative",
                                }}
                              >
                                <div className="rounded-full bg-white text-2xl absolute top-2 right-2 p-2">
                                  <CiHeart />
                                </div>
                              </CardMedia>
                              <CardContent
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="p"
                                  >
                                    {apartment.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {apartment.location}
                                  </Typography>
                                </Box>
                                <div className="flex flex-col border-2 border-green-500 px-1 py-1">
                                  <p className="line-through text-gray-500">
                                    {apartment.price}P
                                  </p>
                                  <p className="text-green-500">
                                    {apartment.discountprice}P
                                  </p>
                                </div>
                              </CardContent>
                              <CardActions
                                sx={{
                                  px: 2,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  pt: 2,
                                }}
                              >
                                <span>
                                  {apartment.bedroom} -{" "}
                                  <Translate text="room Apartment" />
                                </span>

                                <span>
                                  <IoMdStar
                                    className="mt-1"
                                    style={{ color: "#dbb80b" }}
                                  />
                                  <p className="font-bold">
                                    {apartment.Rating}
                                  </p>
                                </span>
                              </CardActions>
                            </CardActionArea>
                          </MuiCard>
                        </div>
                      ))}
                    </Box>
                  </div>
                )}
              </Box>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                pb: 5,

                // background: "linear-gradient(#eff1f3, #cfd8dc)",
                background: "linear-gradient(#EFF1F3, #EEF5FF)",
                borderRadius: "10px 10px 20px 20px",
              }}
            >
              <Grid
                container
                maxWidth="xl"
                rowSpacing={5}
                justifyContent="center"
              >
                <Grid item xs={12}>
                  {/* text-4xl lg:text-5xl */}
                  <p className="xs:text-[20px] sm:text-[20px]  md:text-3xl  lg:text-5xl text-4xl font-bold text-center mt-6">
                    <Translate text="Why Choose" /> <Translate text="Mira" />-
                    <span className="text-primary">
                      <Translate text="Dom" />
                    </span>
                    ?
                  </p>
                  <p
                    className="text-center xs:text-[15px] md:text-2xl 2xl:text-md font-light  mt-4 mb-6 "
                    style={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      display: "flex",
                      textAlign: "center",
                      width: "60%",
                      justifyContent: "center",
                      fontWeight: "400",
                    }}
                  >
                    <Translate text="With us, rent or host without worries. Every guest is vetted for your peace of mind, and hosts are welcoming and accepting of foreigners. Everything you need, right where you need it" />
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="w-48 text-sm">
                    <img src={chooseMiradom3} />
                    <p className="mt-2" style={{ textAlign: "center" }}>
                      <Translate text="Experience hassle-free booking and listing with MiraDom, ensuring no disappointments for hosts and guests" />
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="w-48 text-sm">
                    <img src={chooseMiradom2} />
                    <p className="mt-2" style={{ textAlign: "center" }}>
                      <Translate text="Guests and hosts are thoroughly vetted to ensure a safe and secure experience for everyone" />{" "}
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="w-48 text-sm">
                    <img src={chooseMiradom1} />
                    <p className="mt-2" style={{ textAlign: "center" }}>
                      <Translate text="Our support team is available around the clock to assist both hosts and guests through various means of communication." />
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {/* xs:pt-[40px] sm:pt-[50px] md:pt-[50px] lg:pt-[50px] 2xl:pt-[50px] */}
            <div
              className=" flex justify-center"
              // style={{ backgroundColor: "#ececec" }}
            >
              <Box
                sx={{
                  width: {
                    xs: "95%",
                    sm: "95%",
                    md: "95%",
                    lg: "90%",
                  },

                  // width: "95%",
                  margin: "auto",
                  marginX: "10px",
                }}
              >
                <Grid
                  container
                  spacing={4}
                  sx={{
                    px: { xs: 2, md: 0 },
                  }}
                >
                  {bestHosts.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      className=""
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // alignItems: "center",
                        marginTop: "50px",
                        gap: "30px",
                        marginBottom: "20px",
                      }}
                    >
                      {/* xs:bg-red-600 sm:bg-blue-600 md:bg-yellow-600 xl:bg-puple-600 2xl:bg-gray-600 lg:bg-green-600 */}
                      <h1 className="font-bold text-4xl xs:text-[22px] sm:text-[23px]    ">
                        <Translate text="Best hosts of the" />{" "}
                        <span className="text-primary">
                          <Translate text="month" />
                        </span>
                      </h1>
                      <button
                        onClick={
                          isLoggededIn ? showBecomeAHost : handleToogleLogin
                        }
                        className="   xs:w-[150px] xs:text-[15px]  sm:text-[20px] xs:h-[55px] sm:w-[150px] sm:h-[55px]  w-[155px] text-[26px] xs:rounded-md sm:rounded-md rounded-full bg-primary xs:px-2 px-4 py-2 text-white  hover:bg-blue-900 hover:text-white"
                      >
                        <Translate text="Be a host" />!
                      </button>
                    </Grid>
                  )}

                  <Box
                    // className={style.host_slide}
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                      marginTop: "30px",
                      marginLeft: {
                        xs: "15px",
                        sm: "15px",
                        md: "auto",
                        lg: "auto",
                      },
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "auto",
                        lg: "auto",
                      },
                    }}
                  >
                    {bestHosts.length > 0 ? (
                      bestHosts.length > 1 ? (
                        <Slider
                          autoplay
                          slidesToScroll={1}
                          infinite
                          slidesToShow={HostslideShowLength}
                          swipeToSlide
                          nextArrow={<SampleNextArrow />}
                          prevArrow={<SamplePrevArrow />}
                          responsive={[
                            {
                              breakpoint: 1200,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                              },
                            },
                            {
                              breakpoint: 1024,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                arrows: false,
                              },
                            },

                            {
                              breakpoint: 820,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                arrows: false,
                              },
                            },
                            {
                              breakpoint: 768,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                arrows: false,
                              },
                            },

                            {
                              breakpoint: 480,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                              },
                            },
                          ]}
                        >
                          {bestHosts.map((x, i) => (
                            <Box className="px-6">
                              <Box
                                key={i}
                                // className="justify-around"
                                sx={{
                                  display: "flex !important",
                                }}
                              >
                                <Card card={x} />
                              </Box>
                            </Box>
                          ))}
                        </Slider>
                      ) : (
                        <Box
                          className="justify-around"
                          sx={{ display: "flex !important" }}
                        >
                          <OneCard card={bestHosts[0]} />
                        </Box>
                      )
                    ) : (
                      <></>
                    )}
                  </Box>

                  <Grid item xs={12} mt={5}>
                    <h1 className="xs:text-[20px] sm:text-[20px]  text-4xl lg:text-5xl font-bold text-center mb-[50px]">
                      <Translate text="These popular destinations have a lot to offer" />
                    </h1>
                    <Grid container spacing={4}>
                      {popularDestination.map((x, i) => (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          onClick={() => handlePopularDestClick(x)}
                        >
                          <CardActionArea>
                            <ImageListItem
                              key={i}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={x.image}
                                alt={x.location}
                                loading="lazy"
                                style={{
                                  height: imageHeight,
                                  borderRadius: "15px", // Add the desired border radius here
                                }}
                              />

                              <ThemeProvider theme={CustomStyle}>
                                <ImageListItemBar
                                  title={x.location}
                                  position="top"
                                  actionPosition="left"
                                  // subtitle={x.options}
                                  sx={{
                                    background: "transparent",
                                  }}
                                />
                              </ThemeProvider>
                            </ImageListItem>
                            {/* </ThemeProvider> */}
                          </CardActionArea>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={5}>
                    <h1 className="xs:text-[20px] sm:text-[20px]  text-4xl lg:text-5xl font-bold text-center mb-7">
                      <Translate text="Explore Russia" />
                    </h1>
                    <ExploreRussia />
                  </Grid>
                  <Grid item xs={12} mt={5} pb={10}>
                    <div className=" w-full  ">
                      <Box
                        style={{
                          backgroundImage: `url(${coloredHouse2}) `,
                          borderRadius: "20px",
                          backgroundSize: "100% 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      >
                        <Box className="flex flex-col">
                          <h1 className="xs:text-[20px] sm:text-[20px] xs:px-[14px] sm:px-[16px]   text-4xl lg:text-5xl font-bold text-center mb-7 pt-10">
                            <span>
                              <Translate text="Join" />{" "}
                            </span>
                            <span>
                              {" "}
                              <Translate text="Mira" /> -
                            </span>
                            <span className="text-primary">
                              <Translate text="Dom" />
                            </span>{" "}
                            <span>
                              <Translate text="Now For Unbeatable" />
                            </span>{" "}
                            <span className="text-primary">
                              {" "}
                              <Translate text="Offers" /> !!!
                            </span>
                          </h1>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Box
                              sx={{
                                width: { md: "50%", xs: "100%" },
                                textAlign: "center",
                              }}
                            >
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <FaCheck className=" text-[#267d44] text-[2rem] " />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Translate text="Free Accomodation" />
                                    }
                                    secondary={
                                      <Translate text="Zero commission for all properties listed and bought in the first month." />
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemAvatar>
                                    <FaCheck className=" text-[#267d44] text-[2rem] " />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Translate text="Vetted Guest and Host" />
                                    }
                                    secondary={
                                      <Translate text="Piority access to our top-tier, vetted guests and host plus free hosting consultation." />
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemAvatar>
                                    <FaCheck className=" text-[#267d44] text-[2rem] " />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Translate text="Premium Listing" />
                                    }
                                    secondary={
                                      <Translate text="Premium listing ensuring your property is in the spotlight always." />
                                    }
                                  />
                                </ListItem>
                              </List>
                              <button className="bg-primary text-white px-7 py-3 mt-4 mx-6 rounded-3xl shadow-lg font-bold">
                                <Translate text="Join our host community and stay updated" />
                              </button>
                            </Box>
                          </Box>
                          <Box className="mt-auto">
                            <img
                              className="w-[200px] h-[150px] mr-auto mt-10"
                              src={Frame}
                              alt="frame"
                              style={{
                                borderRadius: "0px 0px 0px 15px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          {/* <Box
              className="bg-white bg-cover min-h-[600px] rounded-2xl w-full p-5 xs:px-[1rem] px-[6rem]"
              sx={{ borderRadius: "30px 30px 0px 0px" }}
            >
              <h1 className="text-4xl lg:text-5xl font-bold text-center">
                <Translate text="Book Faster with you phone!" />
              </h1>
              <p className="text-center mb-7 text-2xl font-light">
                <Translate text="Download our free app for cheaper offer and more discounts." />
              </p>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <img src={phoneInBlue} alt="A phone interface" />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="flex items-center bg-[#014117] md:gap-2  gap-3 rounded-xl px-5 md:px-2  py-2 text-white mt-10 cursor-pointer">
                    <FaApple className="text-[3rem] md:text-[2.5rem] sm:text-[2.5rem] xs:text-[2.5rem]" />
                    <div className="text-start basis-[100%] py-3 ">
                      <h3 className="md:text-[.6rem] md:px-5 sm:text-[.6rem] sm:px-5 xs:text-[.6rem] xs:px-5">
                        <Translate text="Available in" />{" "}
                      </h3>
                      <h3 className="text-xl md:text-[1rem] sm:text-[1rem] xs:text-[1rem] ">
                        <Translate text="App Store" />
                      </h3>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <div className="flex items-center bg-[#014117] gap-3 rounded-xl px-5 py-2 text-white mb-10 cursor-pointer w-full">
                    <FaApple className="text-[3rem] md:text-[2.5rem] sm:text-[2.5rem] xs:text-[2.5rem]" />
                    <div className="text-start basis-[100%] w-full py-3">
                      <h3 className="md:text-[.6rem] md:px-5 sm:text-[.6rem] sm:px-5 xs:text-[.6rem] xs:px-5">
                        <Translate text="Available in" />{" "}
                      </h3>
                      <h3 className="text-xl  md:text-[1rem] sm:text-[1rem] xs:text-[1rem]">
                        <Translate text="Google Play" />
                      </h3>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <img src={phoneInBrown} alt="A phone interface" />
                </Grid>
              </Grid>
            </Box> */}
        </div>
      </div>
      <Overlay isLoading={isLoading} />
    </Container>
  );
};

export default Home;
