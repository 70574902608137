import { useState, ChangeEvent, FC } from "react";
import {
  Box,
  Button,
  Checkbox,
  Radio,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import IOSSwitch from "../utils/iosSwitch";
import style from "./listfilter.module.css";
import { Translate } from "react-translated";
interface LabelClickEvent extends React.ChangeEvent<HTMLInputElement> {
  checked: boolean | undefined;
}

interface ListFilterProps {
  superHost: any;
  setSuperHost: Function;
  priceRanges: Array<{ min: number; max: number }>;
  setPriceRanges: Function;
  propertyType: String[];
  setPropertyType: Function;
  facilites: String[];
  setFacilities: Function;
  bedPreference: String[];
  setBedPreference: Function;
  distance: String[];
  setDistance: Function;
  rules: String[];
  setRules: Function;
  freeCancellation: any;
  setFreeCancellation: Function;
  noDeposit: any;
  setNoDeposit: Function;
  resetFilter: Function;
}

const ListFilter: FC<ListFilterProps> = ({
  superHost,
  setSuperHost,
  priceRanges,
  setPriceRanges,
  propertyType,
  setPropertyType,
  facilites,
  setFacilities,
  bedPreference,
  setBedPreference,
  distance,
  setDistance,
  rules,
  setRules,
  freeCancellation,
  setFreeCancellation,
  noDeposit,
  setNoDeposit,
  resetFilter,
}) => {
  const handlePriceCheckbox = (event: any, range: any) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // If checkbox is checked, add the range to the state array
      setPriceRanges([...priceRanges, range]);
    } else {
      // If checkbox is unchecked, remove the range from the state array
      setPriceRanges(
        priceRanges.filter(
          (item) => item.min !== range.min || item.max !== range.max
        )
      );
    }
  };

  const handlePropertyTypeCheckbox = (event: any, type: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setPropertyType([...propertyType, type]);
    } else {
      setPropertyType(propertyType.filter((item) => item !== type));
    }
  };

  const handleRulesCheckbox = (event: any, factor: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setRules([...rules, factor]);
    } else {
      setRules(rules.filter((item) => item !== factor));
    }
  };

  const handleFacilitiesCheckbox = (event: any, factor: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setFacilities([...facilites, factor]);
    } else {
      setFacilities(facilites.filter((item) => item !== factor));
    }
  };

  const handleBedCheckbox = (event: any, factor: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setBedPreference([...bedPreference, factor]);
    } else {
      setBedPreference(bedPreference.filter((item) => item !== factor));
    }
  };

  const handleDistanceCheckbox = (event: any, factor: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setDistance([...distance, factor]);
    } else {
      setDistance(distance.filter((item) => item !== factor));
    }
  };

  return (
    // overflow-y-scroll
    <Box
      sx={{ maxHeight: "1000px" }}
      className={`w-full  rounded-tr-2xl mt-[2rem] rounded-tl-2xl bg-white pb-[1rem] ${style.hide_scrollbar}`}
    >
      {/* overflow-y-auto */}
      <div className={`px-5 `}>
        <div>
          <h3 className="text-[1rem] font-semibold py-[1rem]">
            <Translate text="Choose the best" />
          </h3>
          <div className="flex py-[1rem]">
            <div>
              <h3 className="text-[1rem] flex justify-between items-center">
                <Translate text="Quick Booking" /> <IOSSwitch defaultChecked />
              </h3>
              <h3 className="font-extralight">
                <Translate text="Book immediately without waiting for response from the host(1500)" />
              </h3>
            </div>
          </div>

          <div className="flex py-[1rem]">
            <div className="w-full">
              <h3 className="text-[1rem] flex justify-between items-center">
                <Translate text="Super Host" />{" "}
                <IOSSwitch
                  checked={superHost}
                  onChange={(event) => {
                    setSuperHost(!superHost);
                  }}
                />
              </h3>
              <h3 className="font-extralight">
                {" "}
                <Translate text="View the best Hosts" />
              </h3>
            </div>
          </div>

          <div className="flex py-[1rem]">
            <h3 className="text-[1rem] flex justify-between items-center w-full">
              <Translate text="Free Cancellation" />{" "}
              <IOSSwitch
                checked={freeCancellation}
                onChange={(event) => {
                  setFreeCancellation(!freeCancellation);
                }}
              />
            </h3>
          </div>
        </div>

        <div>
          <h3 className="text-[1rem] font-semibold py-[1rem]">
            <Translate text="Your Budget per day" />
          </h3>

          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={priceRanges.some(
                  (item) => item.min === 0 && item.max === 2000
                )}
                // control={<Checkbox checked={priceCheckboxState["0-2000"]} />}
                onChange={(event) => {
                  handlePriceCheckbox(event, { min: 0, max: 2000 });
                }}
                label="0P - 2,000P"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={priceRanges.some(
                  (item) => item.min === 2000 && item.max === 4000
                )}
                onChange={(event) => {
                  handlePriceCheckbox(event, { min: 2000, max: 4000 });
                }}
                label="2,000P - 4,000p"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={priceRanges.some(
                  (item) => item.min === 4000 && item.max === 6000
                )}
                onChange={(event) => {
                  handlePriceCheckbox(event, { min: 4000, max: 6000 });
                }}
                label="4,000P - 6,000P"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={priceRanges.some(
                  (item) => item.min === 6000 && item.max === 8000
                )}
                onChange={(event) => {
                  handlePriceCheckbox(event, { min: 6000, max: 8000 });
                }}
                label="6,000P - 8,000P"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={priceRanges.some((item) => item.min === 8000)}
                onChange={(event) => {
                  handlePriceCheckbox(event, { min: 8000 });
                }}
                label="8,000P +"
              />
            </FormGroup>
          </div>
        </div>

        <div>
          <h3 className="text-[1rem] font-semibold py-[1rem]">
            {" "}
            <Translate text="Property Type" />
          </h3>
          <FormGroup>
            <FormControlLabel
              // control={<Checkbox defaultChecked />}
              control={<Checkbox />}
              checked={propertyType.includes("apartment")}
              onChange={(event) => {
                handlePropertyTypeCheckbox(event, "apartment");
              }}
              label={<Translate text="Apartment" />}
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={propertyType.includes("houses-cottages")}
              onChange={(event) => {
                handlePropertyTypeCheckbox(event, "houses-cottages");
              }}
              label={<Translate text="Houses, Cottages" />}
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={propertyType.includes("rooms")}
              onChange={(event) => {
                handlePropertyTypeCheckbox(event, "rooms");
              }}
              label={<Translate text="Rooms" />}
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={propertyType.includes("guest-houses")}
              onChange={(event) => {
                handlePropertyTypeCheckbox(event, "guest-houses");
              }}
              label={<Translate text="Guest House" />}
            />
          </FormGroup>
          <div>
            <h3 className=" text-[1rem] font-semibold py-[1rem]">
              <Translate text="Accomodation Rules" />{" "}
            </h3>
            <h3 className="text-[1rem] flex justify-between items-center w-full">
              <Translate text="Smoking allowed" />{" "}
              <IOSSwitch
                checked={rules.includes("smoking")}
                onChange={(event) => {
                  handleRulesCheckbox(event, "smoking");
                }}
              />
            </h3>
            <h3 className="text-[1rem] flex justify-between items-center w-full">
              <Translate text="Parties are allowed" />{" "}
              <IOSSwitch
                checked={rules.includes("parties")}
                onChange={(event) => {
                  handleRulesCheckbox(event, "parties");
                }}
              />
            </h3>

            <h3 className="text-[1rem] flex justify-between items-center w-full">
              <Translate text="Pet allowed" />{" "}
              <IOSSwitch
                checked={rules.includes("pet")}
                onChange={(event) => {
                  handleRulesCheckbox(event, "pet");
                }}
              />
            </h3>
          </div>

          <div className="py-[1rem]">
            <h3 className="text-[1rem] font-semibold py-[.5rem] space-x-4">
              <Translate text="No deposit" />
            </h3>
            <h3 className="text-[1rem] flex justify-between items-center w-full">
              <Translate text="No deposit required" />{" "}
              <IOSSwitch
                checked={noDeposit}
                onChange={(event) => {
                  setNoDeposit(!noDeposit);
                }}
              />
            </h3>
          </div>

          <div>
            <h3 className="text-[1rem] font-semibold py-[1rem]">
              <Translate text="Room Facilities" />
            </h3>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("wifi")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "wifi");
                }}
                label={<Translate text="Internet, Wifi" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("airConditioner")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "airConditioner");
                }}
                label={<Translate text="Air Conditioner" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("fridge")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "fridge");
                }}
                label={<Translate text="Fridge" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("balcony")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "balcony");
                }}
                label={<Translate text="Balcony" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("microwave")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "microwave");
                }}
                label={<Translate text="Microwave" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("television")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "television");
                }}
                label={<Translate text="Television" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("electricKettle")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "electricKettle");
                }}
                label={<Translate text="Electric Kettle" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("towels")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "towels");
                }}
                label={<Translate text="Towels" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("hairDryer")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "hairDryer");
                }}
                label={<Translate text="Hair dryer" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("saunaBath")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "saunaBath");
                }}
                label={<Translate text="Sauna/bath" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("jacuzzi")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "jacuzzi");
                }}
                label={<Translate text="Jacuzzi" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={facilites.includes("pots")}
                onChange={(event) => {
                  handleFacilitiesCheckbox(event, "pots");
                }}
                label={<Translate text="Pots" />}
              />
            </FormGroup>
          </div>

          <div>
            <h3 className="text-[1rem] font-semibold py-[1rem]">
              <Translate text="Bed Preference" />
            </h3>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={bedPreference.includes("twinbed")}
                onChange={(event) => {
                  handleBedCheckbox(event, "twinbed");
                }}
                label={<Translate text="Twin Bed" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={bedPreference.includes("doublebed")}
                onChange={(event) => {
                  handleBedCheckbox(event, "doublebed");
                }}
                label={<Translate text="Double Bed" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={bedPreference.includes("extendedsofa")}
                onChange={(event) => {
                  handleBedCheckbox(event, "extendedsofa");
                }}
                label={<Translate text="Extendable Sofa" />}
              />
            </FormGroup>
          </div>

          <div>
            <h3 className="text-[1rem] font-semibold py-[1rem]">
              <Translate text="Distance from center city" />
            </h3>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={distance.includes("1km")}
                onChange={(event) => {
                  handleDistanceCheckbox(event, "1km");
                }}
                label={<Translate text="Less than 1km" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={distance.includes("2km")}
                onChange={(event) => {
                  handleDistanceCheckbox(event, "2km");
                }}
                label={<Translate text="Less than 3km" />}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={distance.includes("3km")}
                onChange={(event) => {
                  handleDistanceCheckbox(event, "3km");
                }}
                label={<Translate text="Less than 5km" />}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ListFilter;
