import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState, useEffect } from "react";

import { Box, Grid, Typography, FormLabel, Input } from "@mui/material";
import { Translate } from "react-translated";
import PlusIcon from "../../images/plus.svg";
import CrossIcon from "../../images/cross.svg";
import { APIS_URL } from "../../services/api";
import axios from "axios";
import { useUserContext } from "../../context/Context";
import "./supportStyle.css";
import baseUrl from "../../utils/config";
interface FAQItem {
  questionEn: string;
  questionRu: string;
  answerEn: string;
  answerRu: string;
}

export default function Faq() {
  const [expanded, setExpanded] = useState<number | false>(false);
  const [faqDatas, setFaqDatas] = useState<FAQItem[]>([]);
  const { user } = useUserContext();

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.FAQ}`,
      })
        .then((response) => {
          setFaqDatas(response.data.faqContent);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      console.log(error);
    }
  }, [user.locale]);

  const filteredFaqContent = faqDatas
    .filter((item) => {
      if (user.locale === "en") {
        return (
          (item.questionEn && item.questionEn.trim() !== "") ||
          (item.answerEn && item.answerEn.trim() !== "")
        );
      } else if (user.locale === "ru") {
        return (
          (item.questionRu && item.questionRu.trim() !== "") ||
          (item.answerRu && item.answerRu.trim() !== "")
        );
      }
      return false;
    })
    .map((item) => {
      if (user.locale === "en") {
        return { question: item.questionEn, answer: item.answerEn };
      } else if (user.locale === "ru") {
        return { question: item.questionRu, answer: item.answerRu };
      }
      return { question: "", answer: "" };
    });

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const ExpandIcon = () => {
    return <img className="w-[30px] h-[30px]" src={PlusIcon} alt="+" />;
  };

  const CollapseIcon = () => {
    return <img className="w-[30px] h-[30px]" src={CrossIcon} alt="*" />;
  };

  return (
    <div>
      <Typography
        className="flex justify-center item-center"
        variant="body1"
        sx={{
          fontSize: { xs: "24px", md: "45px", lg: "45px" },
          color: "#001D68",

          fontWeight: "700px",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <Translate text="Frequently Asked Questions" />
      </Typography>
      <Box className="xs:w-[90%] sm:w-[90%] md:w-[80%] lg:w-[90%] xl:w-[90%] 2xl:w-[90%]   justify-center item-center m-auto">
        {filteredFaqContent.map((item, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0 ? true : false}
            // expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              background: "#EFF1F3",
              border: "none",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded === index ? <CollapseIcon /> : <ExpandIcon />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                borderBottom: "1px solid #D9E6FF", // Border only below the header
                "& .MuiAccordionSummary-content": {
                  marginBottom: 5,
                },
              }}
            >
              <Typography
                className="xs:w-[250px] sm:w-[250px] md:w-[400px] lg:w-[500px] xl:w-[500px] 2xl:w-[900px]"
                sx={{
                  fontSize: { xs: "20px", md: "30px", lg: "30px" },
                  color: "#001D68",
                  fontWeight: "500px",
                }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "24px", lg: "24px" },
                  color: "#7682A0",
                  fontWeight: "400px",
                }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
}
