import { height } from "@mui/system";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { FaMapMarker } from "react-icons/fa";
import { Link } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

interface Props {
  markers: MarkerData[];
  centreLat?: number;
  centreLon?: number;
}
interface MarkerData {
  latitude: number;
  longitude: number;
  name: string;
  url: string;
}
const CustomMarker = ({ marker }: { marker: MarkerData }) => {
  return (
    <Marker longitude={marker.longitude} latitude={marker.latitude}>
      <Link to={marker.url}>
        <FaMapMarker />
      </Link>
    </Marker>
  );
};
export default function CustomMap({ markers, centreLat, centreLon }: Props) {
  const map = useRef<any>(null);
  const [latLon, setLatLon] = useState<
    [number | undefined, number | undefined]
  >([centreLon, centreLat]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!latLon[0] && !latLon[1]) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((e) => {
          setLatLon([e.coords.longitude, e.coords.latitude]);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!!centreLat && !!centreLon) {
      setLatLon([centreLon, centreLat]);
    }
  }, [centreLat, centreLon]);

  return (
    <>
      <ReactMapGL
        {...(!!latLon[0] &&
          !!latLon[1] && {
            longitude: latLon[0],
            latitude: latLon[1],
          })}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        initialViewState={{
          longitude: latLon[0],
          latitude: latLon[1],
          zoom: 9,
        }}
        style={{
          height: 400,
          width: "100%",
        }}
        onMove={(e) => setLatLon([e.viewState.longitude, e.viewState.latitude])}
      >
        {markers.map((x, i) => (
          <CustomMarker
            key={i}
            marker={{
              latitude: x.latitude,
              longitude: x.longitude,
              name: x.name,
              url: x.url,
            }}
          />
        ))}
      </ReactMapGL>{" "}
    </>
  );
}
