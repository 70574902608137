import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-translated";
import { AppLinks } from "../utils/Linking";
import { APIS_URL } from "../services/api";
import io from "socket.io-client";
import baseUrl from "../utils/config";
import axios from "axios";
import translateText from "../utils/translationHelper";
interface ContextStructure {
  children: any;
}

interface ToogleSignUpStructure {
  toogleSignup: boolean;
  handleToogleSignup: any;
  setToogleSignup: any;
}

interface ToogleLoginStructure {
  toogleLogin: boolean;
  handleToogleLogin: any;
  setToogleLogin: any;
}

interface isLoggedInStructure {
  isLoggededIn: boolean;
  handleIsLoggedIn: any;
  setIsLoggedIn: any;
}

interface ShowMsgStructure {
  notifyError: any;
  notifySuccess: any;
}

interface UserContextStructure {
  // locale: string;
  socket: any;
  user: any;
  updateUser: Function;
  // UpdateLocale: Function;
}

interface UserNotificationContextStructure {
  notification: any;
  updateNotification: Function;
}

interface Message {
  _id?: string;
  message: string;
  username: string;
  image: string;
  email: string;
  recipient: string;
  landlordName: string;
  landlordImage: string;
  createdAt?: string;
  read?: boolean;
  apartmentImage: string;
  apartmentGuest: number;
  apartmentLocation: string;
  apartmentCheckin: string;
  apartmentCheckout: string;
  apartmentRooms: number;
  apartmentState: string;
}

interface UserData {
  imageurl: string;
  isCredentialUpload: boolean;
  email: string;
  firstname: string;
  lastname: string;
  cardType: string;
  firstSix: string;
  lastFour: string;
  locale: string;
  unread: number;
  msg: number;
}

interface NotificationData {
  _id: string;
  recipient: string;
  read: boolean;
  seen: boolean;
  message: string;
  type: string;
  metadata: Record<string, any>;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const UserContext = createContext<UserContextStructure>(
  {} as UserContextStructure
);

const NotificationContext = createContext<UserNotificationContextStructure>(
  {} as UserNotificationContextStructure
);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

const ToogleSignUp = createContext<ToogleSignUpStructure>(
  {} as ToogleSignUpStructure
);

export const useToogleSignUp = () => {
  return useContext(ToogleSignUp);
};

const ToogleLogin = createContext<ToogleLoginStructure>(
  {} as ToogleLoginStructure
);

export const useToogleLogIn = () => {
  return useContext(ToogleLogin);
};

const IsLoggededIn = createContext<isLoggedInStructure>(
  {} as isLoggedInStructure
);

export const useIsLoggedIn = () => {
  return useContext(IsLoggededIn);
};

const ShowMsg = createContext<ShowMsgStructure>({} as ShowMsgStructure);

export const useShowMsg = () => {
  return useContext(ShowMsg);
};

const GlobalContext: FC<ContextStructure> = ({ children }) => {
  const inititalUserState: UserData = {
    imageurl: "",
    firstname: "",
    lastname: "",
    isCredentialUpload: false,
    email: "",
    cardType: "",
    firstSix: "",
    lastFour: "",
    locale: "en",
    unread: 0,
    msg: 0,
  };
  const initialNotifyState: NotificationData[] = [];
  const [unreadCount, setUnreadCount] = useState(0);
  const [allMessages, setAllMessages] = useState(0);
  const [toogleSignup, setToogleSignup] = useState(false);
  const [isLoggededIn, setIsLoggedIn] = useState(false);
  const [toogleLogin, setToogleLogin] = useState(false);
  const token = localStorage.getItem("token");

  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem("userData");
    return userData ? JSON.parse(userData) : inititalUserState;
  });

  const [notification, setNotification] = useState(() => {
    const userNotify = localStorage.getItem("userNotify");
    // return userNotify ? JSON.parse(userNotify) : initialNotifyState;
    try {
      return userNotify ? JSON.parse(userNotify) : initialNotifyState;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return initialNotifyState;
    }
  });

  const handleToogleSignup = () => {
    setToogleSignup(!toogleSignup);
  };

  const handleIsLoggedIn = () => {
    setIsLoggedIn(!isLoggededIn);
  };

  const handleToogleLogin = () => {
    setToogleLogin(!toogleLogin);
  };

  const updateUser = (newUserData: UserData) => {
    setUser(newUserData);

    localStorage.setItem("userData", JSON.stringify(newUserData));
  };
  // console.log("user", user);

  const updateNotification = (newNotification: NotificationData) => {
    setNotification(newNotification);
    localStorage.setItem("userNotify", JSON.stringify(newNotification));
  };
  const notifyError = (msg: string) => toast.error(`${msg} `);
  const notifySuccess = (msg: string) => toast.success(`${msg} `);

  const [translations, setTranslations] = useState({});

  // const socket = io("http://localhost:3001");

  const socket = io("https://miradom-backend.onrender.com");
  // console.log("translations", translations);
  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      setIsLoggedIn(false);
    }
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      console.log("connected to server......");
    });

    if (user.email !== "") {
      socket.emit("register", user.email);
      // console.log("-----------registering user id-------------");
    }

    socket.on("disconnect", () => {
      console.log("disconnected from server");
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    socket.on("private_message", (data: any) => {
      // console.log("contex data", data);

      if (data.recipient === user.email) {
        if (data.read === false) {
          setUnreadCount((unreadCount) => unreadCount + 1);
        }
      }
      // setAllMessages((read) => read + 1);
    });

    return () => {
      socket.off("private_message");
    };
  }, []);

  useEffect(() => {
    updateUser({
      ...user,
      unread: unreadCount,
    });
  }, [unreadCount, allMessages]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.WEB_CONTENT}`,
      })
        .then((response) => {
          // console.log("local response", response);
          setTranslations(response.data.combinedContent);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      console.log(error);
    }
  }, [user.locale]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const auth = localStorage.getItem("token");
      if (auth) {
        try {
          axios({
            method: "get",
            url: `${baseUrl}${APIS_URL.PROFILE_LAST_ONLINE}`,

            headers: {
              Authorization: `Bearer ${auth}`,
            },
          })
            .then((response) => {
              // console.log("online response", response);
            })
            .catch((err) => console.log(err));
        } catch (error: any) {
          console.log(error);
        }
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const auth = localStorage.getItem("token");
      if (auth) {
        const translateNotify = async () => {
          try {
            const notificationResponse = await axios({
              method: "get",
              url: `${baseUrl}${APIS_URL.GET_ALL_NOTIFICATION}`,

              headers: {
                Authorization: `Bearer ${auth}`,
              },
            });
            let status = notificationResponse.status;
            if (status === 200) {
              let notificationData = notificationResponse.data.notifications;
              if (user.locale === "ru") {
                let notifyData: any = await Promise.all(
                  notificationData.map((item: any) =>
                    translateText(item, user.locale)
                  )
                );
                updateNotification(notifyData);
              }
              if (user.locale === "en") {
                updateNotification(notificationData);
              }
            }
          } catch (err) {
            console.log(err);
          }
        };
        translateNotify();
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, [user.locale]);
  return (
    <>
      <ToogleSignUp.Provider
        value={{ toogleSignup, handleToogleSignup, setToogleSignup }}
      >
        <IsLoggededIn.Provider
          value={{ isLoggededIn, handleIsLoggedIn, setIsLoggedIn }}
        >
          <UserContext.Provider value={{ user, updateUser, socket }}>
            <Provider language={user.locale || "en"} translation={translations}>
              <NotificationContext.Provider
                value={{ notification, updateNotification }}
              >
                <ToogleLogin.Provider
                  value={{ toogleLogin, handleToogleLogin, setToogleLogin }}
                >
                  <ShowMsg.Provider value={{ notifyError, notifySuccess }}>
                    {children}
                  </ShowMsg.Provider>
                </ToogleLogin.Provider>
              </NotificationContext.Provider>
            </Provider>
          </UserContext.Provider>
        </IsLoggededIn.Provider>
      </ToogleSignUp.Provider>
    </>
  );
};

export default GlobalContext;
