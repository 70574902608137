import { FC, useState, useEffect } from "react";
import { Popover } from "@mui/material";
import Calendar from "react-calendar";
import style from "./landingcalender.module.css";
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface Filter {
  location: string;

  fromDate: Date | null;
  // fromDate: DateRange<Date> | undefined;
  toDate: Date | null;
}
interface CheckinPopupProps {
  anchorCheckinEl: HTMLElement | null;
  setAnchorCheckinEl: (el: HTMLDivElement | null) => void;
  filter: { location: string; fromDate: Date | null; toDate: Date | null };
  setFilter: Function;
  bgColor: string;
  fillColor: string;
}
const CheckinPopup: FC<CheckinPopupProps> = ({
  anchorCheckinEl,
  setAnchorCheckinEl,
  filter,
  bgColor,
  fillColor,
  setFilter,
}) => {
  // useEffect(() => {
  //   // Reset the filter input when the popover opens
  //   if (anchorCheckinEl) {
  //     setFilter((filter: Filter) => ({
  //       ...filter,
  //       fromDate: null,
  //       toDate: null,
  //     }));
  //   }
  // }, [anchorCheckinEl]);

  const handleDateChange = (newValue: any, context: any) => {
    const [start, end] = newValue;

    setFilter((filter: Filter) => ({
      ...filter,
      fromDate: start?.isValid() ? start.$d : end?.$d,
      toDate: end?.isValid() ? end.$d : start?.$d,
    }));

    // if (filter.fromDate !== null && filter.toDate !== null) {
    //   setAnchorCheckinEl(null);
    // }
  };

  return (
    <Popover
      open={Boolean(anchorCheckinEl)}
      anchorEl={anchorCheckinEl}
      onClose={() => setAnchorCheckinEl(null)}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`shadow-2xl rounded-lg ${bgColor} `}>
          <div className=" overflow-y-auto p-5 rounded-2xl max-h-[70vh]">
            <DateRangeCalendar
              defaultValue={[dayjs(filter.fromDate), dayjs(filter.toDate)]}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </LocalizationProvider>
    </Popover>
  );
};

export default CheckinPopup;

// const getFillColor = (fillColor: any) => {
//   console.log("fillColor", fillColor);
//   switch (fillColor) {
//     case "landing":
//       return style.landing;
//     case "apartmentList":
//       return style.apartment_list;
//     default:
//       return "bg-white";
//   }
// };
