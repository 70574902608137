import { Box, Grid, Typography, FormLabel, Input } from "@mui/material";

import { useState } from "react";
import axios from "axios";

//
import { APIS_URL } from "../../services/api";
import { useShowMsg } from "../../context/Context";
import { Provider, Translate, Translator } from "react-translated";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useForm } from "react-hook-form";
import baseUrl from "../../utils/config";
export default function Form() {
  const auth = localStorage.getItem("token");
  const { notifyError, notifySuccess } = useShowMsg();
  const [isSending, setIsSending] = useState(false);
  const [supportForm, setSupportForm] = useState<{
    name: string;
    email: string;
    phone: string;
    message: string;
  }>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: supportForm, mode: "onSubmit" });

  const submitData = async (inputData: any) => {
    if (isSending === true) return;
    try {
      setIsSending(true);
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.SUPPORT}`,

        data: inputData,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      setIsSending(false);
      if (response.data.success === true) {
        setSupportForm({ name: "", email: "", phone: "", message: "" });
        setValue("name", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("message", "");
        notifySuccess("Message sent successfully");
      } else {
        notifyError("Error occured, please try again");
      }
    } catch (error) {
      setIsSending(false);
      notifyError("Error occured, please try again");
    }
  };

  return (
    <Grid
      container
      spacing={0}
      className="xs:px-[25px] sm:px-[25px] md:px-[50px] lg:px-[50px] xl:px-[50px] 2xl:px-[50px]"
      sx={{
        paddingTop: "75px",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "24px", md: "35px", lg: "35px" },
            color: "#225FFE",
            fontWeight: "600px",
          }}
        >
          <Translate text="Quick Contact" />
        </Typography>
        <Box
          sx={{
            fontSize: { xs: "20px", md: "20px", lg: "20px" },
            color: "#7D7D7D",
            // lineHeight: { xs: "24px", md: "24px", lg: "24px" },
            fontWeight: "400px",
          }}
        >
          <Translate text="Get in Touch Instantly" />
        </Box>
      </Grid>
      <form className="w-full" onSubmit={handleSubmit(submitData)}>
        <Grid xs={12} sx={{ marginTop: "30px" }}>
          <FormLabel
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#225FFE",
            }}
          >
            <Translate text="Name" />
          </FormLabel>
          <input
            className=" bg-[#F3F3F3]  w-[100%]  px-[1rem] py-[1.5rem] rounded-[1rem] mt-1"
            placeholder="Jogn Kooper"
            type="text"
            {...register("name")}
          />
          {errors.name && (
            <span className="text-red-400">
              <Translate text={`${errors.name.message}`} />
            </span>
          )}
        </Grid>
        <Grid xs={12} sx={{ marginTop: "20px" }}>
          <FormLabel
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#225FFE",
            }}
          >
            <Translate text="Email" />
          </FormLabel>
          <input
            className=" bg-[#F3F3F3]  w-[100%]  px-[1rem] py-[1.5rem] rounded-[1rem] mt-1"
            placeholder="your@mail.com"
            type="text"
            {...register("email", {
              required: "Your email is require",
            })}
          />
          {errors.email && (
            <span className="text-red-400">
              <Translate text={`${errors.email.message}`} />
            </span>
          )}
        </Grid>
        <Grid xs={12} sx={{ marginTop: "20px" }}>
          <FormLabel
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#225FFE",
            }}
          >
            <Translate text="Phone Number" />
          </FormLabel>
          <input
            className=" bg-[#F3F3F3]  w-[100%]  px-[1rem] py-[1.5rem] rounded-[1rem] mt-1"
            placeholder="+7 951 366 82 20"
            type="text"
            {...register("phone")}
          />
          {errors.phone && (
            <span className="text-red-400">
              <Translate text={`${errors.phone.message}`} />
            </span>
          )}
        </Grid>
        <Grid xs={12} sx={{ marginTop: "20px" }}>
          <FormLabel
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#225FFE",
            }}
          >
            <Translate text="Message" />
          </FormLabel>
          <textarea
            className="bg-[#F3F3F3]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
            placeholder="Please enter your message"
            {...register("message", {
              required: "Field can't be empty",
            })}
            rows={5}
            style={{
              resize: "none",
              minHeight: "140px",
              maxHeight: "140px",
              overflow: "hidden",
            }}
          />
          {errors.message && (
            <span className="text-red-400">
              <Translate text={`${errors.message.message}`} />
            </span>
          )}
        </Grid>
        <Grid xs={4} sx={{ marginTop: "20px" }}>
          <button
            type="submit"
            className="bg-[#225FFE] px-6 py-2 text-white text-[20px] rounded-md xs:mb-20 sm:mb-20 md:mb-20 lg:mb-20 xl:mb-20 "
          >
            {isSending ? (
              <CircularProgress
                size={24}
                color="inherit"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  marginBottom: "0px",
                  marginTop: "2px",
                }}
              />
            ) : (
              <Translate text="Submit" />
            )}
          </button>
        </Grid>
      </form>
    </Grid>
  );
}
