import React from "react";
import styles from "./overlay.module.css";

interface OverlayStructure {
  isLoading: Boolean;
}
const Overlay = ({ isLoading }: OverlayStructure) => {
  return (
    <div
      className={`${styles.overlay} ${isLoading ? styles.overlayVisible : ""}`}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

export default Overlay;
