import { Box } from "@mui/material";
import style from "./profile.module.css";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { APIS_URL } from "../../services/api";
import { useShowMsg } from "../../context/Context";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext } from "../../context/Context";
import { Translate } from "react-translated";
import baseUrl from "../../utils/config";

const Payment = () => {
  const { user, updateUser } = useUserContext();
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const auth = localStorage.getItem("token");
  const { notifyError, notifySuccess } = useShowMsg();

  const formatCardNumber = (first: string, last: string) => {
    const hiddenDigits = "••••";
    return `${first} ${hiddenDigits} ${hiddenDigits} ${last}`;
  };

  let payoutsData: any;
  const handleSuccess = async (data: any) => {
    // Handle successful response with card token
    // console.log("widget data", data);

    try {
      const payout = {
        payoutToken: data.payout_token,
        cardType: data.card_type,
        firstSix: data.first6,
        lastFour: data.last4,
      };

      //   console.log("payout", payout);
      const response = await axios({
        method: "put",
        url: `${baseUrl}${APIS_URL.EDIT_PROFILE}`,

        data: payout,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      // console.log("card response", response);

      if (response.status === 200) {
        notifySuccess("Card Successfully Updated");
        updateUser({
          ...user,
          cardType: data.card_type,
          firstSix: data.first6,
          lastFour: data.last4,
        });
        payoutsData.clearListeners();
      }

      setShowPopup(false);
      setFormVisible(false);
      setShowCloseBtn(false);
      setShowSpinner(true);
    } catch (error: any) {
      notifyError("Error Updating Card");

      payoutsData.clearListeners();
    }
  };

  const handleError = (error: any) => {
    // Handle errors when getting card token
    // console.log("widget error", error);
    notifyError("Error Updating Card");
    payoutsData.clearListeners();
  };

  const handleFormClick = () => {
    setShowPopup(true);
    setFormVisible(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    setFormVisible(false);
    setShowCloseBtn(false);
    setShowSpinner(true);
  };

  function onCloseForm() {
    payoutsData.clearListeners();
  }

  useEffect(() => {
    if (formVisible) {
      payoutsData = new (window as any).PayoutsData({
        type: "payout",
        account_id: `${process.env.REACT_APP_AGENT_ID}`,
        success_callback: handleSuccess,
        error_callback: handleError,
      });

      payoutsData.render("payout-form").then(() => {
        // Code to execute after the form is rendered

        setShowCloseBtn(true);
        setShowSpinner(false);
        const closeButton = document.getElementById("close-form-button");
        if (closeButton) {
          closeButton.addEventListener("click", onCloseForm);
        } else {
        }
      });
    }
  }, [formVisible]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {user.cardType !== "" && user.firstSix !== "" && (
          <Box className={style.card}>
            <Box className={style.card_header}>
              <h2 className={style.card_title}>
                {" "}
                <Translate text="Your Account Details" />{" "}
              </h2>
            </Box>
            <Box className={style.card_body}>
              <div className={style.card_type}>{user.cardType}</div>
              <Box className={style.card_number}>
                {formatCardNumber(user.firstSix, user.lastFour)}
              </Box>
            </Box>
          </Box>
        )}

        {user.cardType === "" && user.firstSix === "" && (
          <Box>
            {" "}
            <Translate text="No Account Details Available" />
          </Box>
        )}

        <Box
          sx={{
            marginTop: "10px",
          }}
        >
          <button
            onClick={handleFormClick}
            className="cursor-pointer   bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md  items-center "
          >
            <Translate text="Update Account Details" />
          </button>
        </Box>
      </Box>

      {showPopup && (
        <div className={style.popup_overlay}>
          <div className={style.popup_content}>
            {showSpinner && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <div id="close-form-button">
              {showCloseBtn && (
                <button
                  className={style.close_button}
                  onClick={handleClosePopup}
                >
                  <CloseIcon />
                </button>
              )}
            </div>

            {formVisible && <div id="payout-form"></div>}
          </div>
        </div>
      )}
    </Box>
  );
};

export default Payment;
