import { FC } from "react";

interface ButtonStructure {
  onclick: any;
  className: string;
  type: any;
  btnText: string;
}

const Button: FC<ButtonStructure> = ({ onclick, className, type, btnText }) => {
  return (
    <>
      <button onClick={onclick} className={className} type={type}>
        {btnText}
      </button>
    </>
  );
};

export default Button;
