import { AppLinks } from "./Linking";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  useNotificationContext,
  useUserContext,
  useShowMsg,
} from "../context/Context";
import axios from "axios";
import Logo from "../images/Logo.png";
import { APIS_URL } from "../services/api";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { Translate } from "react-translated";
import translateText from "../utils/translationHelper";
import { useEffect } from "react";
import baseUrl from "../utils/config";
const NotificationHelper = (notify: any) => {
  const history = useHistory();
  const { notifySuccess, notifyError } = useShowMsg();
  const { notification, updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const auth = localStorage.getItem("token");
  const userLocalTime = (utcDateString: string) => {
    // Create a Date object from the UTC date string
    const utcDate = new Date(utcDateString);

    // Get the user's local timezone offset in minutes
    const userTimezoneOffset = new Date().getTimezoneOffset();

    // Apply the user's timezone offset to get the local time
    const localTime = new Date(utcDate.getTime() - userTimezoneOffset * 60000);

    const formattedDate = localTime.toDateString();

    return formattedDate;
  };

  // console.log("notify", notify);
  const setSeen = async (id: any) => {
    // alert(id);
    try {
      const notificationResponse = await axios({
        method: "put",
        url: `${baseUrl}${APIS_URL.SEEN_NOTIFICATION}/${id}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      // console.log("notificationResponse", notificationResponse);
      let status = notificationResponse.status;
      if (status === 200) {
        if (user.locale! == "en") {
          let notificationData = notificationResponse.data.notifications;
          let notifyData = await Promise.all(
            notificationData.map((item: any) =>
              translateText(item, user.locale)
            )
          );
          updateNotification(notifyData);
          updateUser({
            ...user,
            isCredentialUpload: notificationResponse.data.isCredentialUpload,
          });
        } else {
          updateNotification(notificationResponse.data.notifications);
          updateUser({
            ...user,
            isCredentialUpload: notificationResponse.data.isCredentialUpload,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const { apartment, checkin, checkout, approve } = req.body;
  const ApproveBooking = async (notify: any, status: boolean) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.APPROVE_BOOKING}`,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
        data: {
          apartment: notify.metadata.apartmentid,
          checkin: notify.metadata.fromDate,
          checkout: notify.metadata.toDate,
          approve: status,
          userid: notify.metadata.userid,
          guests: notify.metadata.guests,
        },
      });

      if (response.data.approve === true) {
        notifySuccess("Successfully Approved");
        return;
      }
      if (response.data.approve === false) {
        notifySuccess("Successfully Declined");
        return;
      } else {
        notifyError("Something went wrong please try again");
        return;
      }
    } catch (err) {
      notifyError("Something went wrong please try again");
      console.log(err);
    }
  };

  const bookApartment = async (notify: any) => {
    try {
      history.push(
        `${AppLinks.ConfirmAndPay.path}/${
          notify.metadata.apartmentid
        }/?${new URLSearchParams({
          // location: filter.location,
          guest: `${notify.metadata.guests}`,
          // adult: `${filter.adult}`,
          // children: `${filter.children}`,
          // infant: `${filter.infant}`,
          // pet: `${filter.pet}`,
          fromDate: notify.metadata.fromDate,
          toDate: notify.metadata.toDate,
        })}`
      );
    } catch (error: any) {
      console.log("faile error", error);
      notifyError("Payment Failed");
    }
  };

  const debouncedUpdateNotification = debounce(setSeen, 20000);

  switch (notify.type) {
    case "Approved Apartment":
    case "Unapproved Apartment":
    case "Featured Apartment":
    case "Unfeatured Apartment":
      return (
        <Link
          to={`${AppLinks.MyApartmentDetails.path}/${notify.metadata.apartmentid}`}
        >
          <div
            className="font-sm  "
            onMouseEnter={() => debouncedUpdateNotification(notify._id)}
          >
            <div className="flex flex-row space-x-2 py-2">
              <Avatar
                src={Logo}
                className="w-[50px] rounded-lg h-auto object-contain"
                alt="logo"
              />
              <div>
                <h3>{notify.message}</h3>
                <p className="font-[10px] text-[#717171] font-light">
                  {userLocalTime(notify.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </Link>
      );
    case "MyBooking":
      return (
        <Link
          to={`${AppLinks.MyApartmentDetails.path}/${notify.metadata.apartmentid}`}
        >
          <div
            className="font-sm  "
            onMouseEnter={() => debouncedUpdateNotification(notify._id)}
          >
            <div className="flex flex-row space-x-2 py-2">
              <Avatar
                src={notify.metadata.imageurl}
                className="w-[50px] rounded-lg h-auto object-contain"
                alt="logo"
              />
              <div>
                <h3>
                  <Translate text="Your" />{" "}
                  <Link
                    className="underline text-blue-400"
                    to={`${AppLinks.MyApartmentDetails.path}/${notify.metadata.apartmentid}`}
                  >
                    <Translate text="apartment" />
                  </Link>{" "}
                  <Translate text="was booked" />
                </h3>
                <p className="font-[10px] text-[#717171] font-light">
                  {userLocalTime(notify.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </Link>
      );
    case "Activate Account":
    case "Deactivate Account":
    case "Setsuper Host":
    case "Unsetsuper Host":
    case "Approved Credentials":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>{notify.message}</h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    case "Payout":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>
                <Translate text="Payment for your" />{" "}
                <Link
                  className="underline text-blue-400"
                  to={`${AppLinks.MyApartmentDetails.path}/${notify.metadata.apartmentid}`}
                >
                  <Translate text="Booked" />
                </Link>{" "}
                <Translate text="apartment has been released" />
              </h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    case "Booking":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>
                <Translate text="Your" />{" "}
                <Link
                  className="underline text-blue-400"
                  to={`${AppLinks.ApartmentDetails.path}/${notify.metadata?.apartmentid}`}
                >
                  <Translate text="Booking" />
                </Link>{" "}
                <Translate text="has been reserved" />
              </h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    case "Rejected Credentials":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>{notify.message}</h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    case "Best Host":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>{notify.message}</h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    case "Verification":
      return (
        <Link to={`${AppLinks.EditProfile.path}`}>
          <div
            className={`font-sm ${
              notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
            } `}
            onMouseEnter={() => debouncedUpdateNotification(notify._id)}
          >
            <div className="flex flex-row space-x-2 py-2">
              <Avatar
                src={Logo}
                className="w-[50px] rounded-lg h-auto object-contain"
                alt="logo"
              />
              <div>
                <h3>{notify.message}</h3>
                <p className="font-[10px] text-[#717171] font-light">
                  {userLocalTime(notify.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </Link>
      );
    case "BookingInProg":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Link
              to={`${AppLinks.PublicProfile.path}/?profileid=${notify.metadata.userid}`}
            >
              <Avatar
                src={notify.metadata.imageurl}
                className="w-[50px] rounded-lg h-auto object-contain"
                alt="logo"
              />
            </Link>
            <div>
              <span>
                <Link
                  className="underline text-blue-400"
                  to={`${AppLinks.PublicProfile.path}/?profileid=${notify.metadata.userid}`}
                >
                  {notify.metadata.username}
                </Link>{" "}
                <Translate text="is trying to book your" />{" "}
                <Link
                  className="underline text-blue-400"
                  to={`${AppLinks.MyApartmentDetails.path}/${notify.metadata.apartmentid}`}
                >
                  <Translate text="apartment" />
                </Link>{" "}
                <Translate text="at" />{" "}
                <span className="inline-block truncate w-40 align-bottom">
                  {notify.metadata.location}
                </span>{" "}
                <Translate text="from" />{" "}
                {notify.metadata.fromDate.split("T")[0]} <Translate text="to" />{" "}
                {notify.metadata.toDate.split("T")[0]}
              </span>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
              <div className="flex flex-row space-x-2">
                <button
                  onClick={() => ApproveBooking(notify, false)}
                  className="cursor-pointer, text-gray-800"
                >
                  <Translate text="Decline" />
                </button>
                <button
                  onClick={() => ApproveBooking(notify, true)}
                  className="cursor-pointer, text-blue-800"
                >
                  <Translate text="Approve" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "ApproveBooking":
      return (
        <div
          className={`font-sm ${notify.seen ? "text-gray-600" : "font-bold "} `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={notify.metadata.imageurl}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>
                <Translate text="Host approve your request" />
              </h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
              <button
                onClick={() => {
                  bookApartment(notify);
                }}
                className="cursor-pointer, text-blue-800"
              >
                <Translate text="Proceed to Payment" />
              </button>
            </div>
          </div>
        </div>
      );
    case "DeclineBooking":
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={notify.metadata.imageurl}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>{notify.message}</h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div
          className={`font-sm ${
            notify.seen ? "text-[#717171]" : "font-bold text-[#222222] "
          } `}
          onMouseEnter={() => debouncedUpdateNotification(notify._id)}
        >
          <div className="flex flex-row space-x-2 py-2">
            <Avatar
              src={Logo}
              className="w-[50px] rounded-lg h-auto object-contain"
              alt="logo"
            />
            <div>
              <h3>{notify.message}</h3>
              <p className="font-[10px] text-[#717171] font-light">
                {userLocalTime(notify.createdAt)}
              </p>
            </div>
          </div>
        </div>
      );
  }
};
export default NotificationHelper;
