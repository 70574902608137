import { MdStar } from "react-icons/md";
import LogIn from "./LogIn";
import Container from "../components/shared/Container";
import { FaArrowRightLong } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { APIS_URL } from "../services/api";
import { useHistory } from "react-router-dom";
import DragDropFileUpload from "../components/DragDropFileUpload";
import GeoapifySearch from "../components/Geoapify";
import OverlayTwo from "../components/OverlayTwo";
import { TbLoaderQuarter } from "react-icons/tb";
import { Translate } from "react-translated";
import translateText from "../utils/translationHelper";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import OverlayThree from "../components/OverlayThree";
import baseUrl from "../utils/config";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
  List,
  CardActions,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slick";
import RuleItem from "../components/ApartmentDetails/RuleItem";
import AmenitiesItem from "../components/ApartmentDetails/AmenitiesItem";
import CustomMap from "../components/Map";
import { AppLinks } from "../utils/Linking";

import {
  useIsLoggedIn,
  useShowMsg,
  useToogleLogIn,
  useUserContext,
  useNotificationContext,
} from "../context/Context";
import { useForm } from "react-hook-form";

dayjs.extend(relativeTime);

interface ApartmentDetailsInterface {
  title: string;
  price: number;
  duration: string;
  discountprice: number;
  location: string;
  longitude: string;
  latitude: string;
  bedroom: string;
  bed: string;
  livingroom: string;
  kitchen: string;
  guests: number;
  area: number;
  propertytype: string;
  rules: string[];
  description: string;
  amenities: string[];
  corefeatures: string[];
  owner: string;
  featured: boolean;
  approve: boolean;
  images: string[];
}

const MyApartmentDetails = () => {
  let today = new Date();
  const [isOverlay, setIsOverlay] = useState(true);
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const { apartmentid } = useParams<{ apartmentid: string }>();
  const redirect = useHistory();
  const [subbmitting, setSubbmitting] = useState(false);
  const [location, setLocation] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [files, setFiles] = useState<FileList>();
  const [nextOrPrev, setNextOrPrev] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [visibilityState, setVisibilityState] = useState(true);
  const [listingForm, setListingForm] = useState<{
    amenities: string[];
    title: string;
    price: number;
    discount: number;
    longitude: number;
    latitude: number;
    duration: string;
    symbol: string;
    guests: number;
    twinbed: number;
    doublebed: number;
    extendedsofa: number;
    area: number;
    bedroom: number;
    livingroom: number;
    kitchen: number;
    description: string;
    images: string[];
    bed: number;
    rules: string[];
    propertytype: string;
  }>({
    title: "",
    price: 0,
    discount: 0,
    longitude: 0,
    latitude: 0,
    duration: "per Night",
    symbol: "%",
    guests: 0,
    twinbed: 0,
    doublebed: 0,
    extendedsofa: 0,
    area: 0,
    bedroom: 0,
    livingroom: 0,
    kitchen: 0,
    description: "",
    amenities: [],
    images: [],
    bed: 0,
    rules: [],
    propertytype: "",
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: listingForm, mode: "onSubmit" });

  const [apartmentDetails, setApartmentDetails] = useState<
    ApartmentDetailsInterface | any
  >({});

  const [apartmentDetailsForEdit, setApartmentDetailsForEdit] = useState<
    ApartmentDetailsInterface | any
  >({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [feedback, setFeedback] = useState({
    averageRatings: 0,
    totalReviews: 0,
  });
  const { notifyError, notifySuccess } = useShowMsg();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (token) {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      const tokenExp = tokenData.exp * 1000;
      const currentTime = new Date().getTime();

      if (currentTime > tokenExp) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    axios({
      method: "get",

      url: `${baseUrl}${APIS_URL.GET_MY_APARTMENT_DETAILS}${apartmentid}`,

      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }).then(async (response) => {
      // console.log("res", response);
      const status = response.status;
      if (response.status === 200) {
        setApartmentDetailsForEdit(response.data.apartmentDetail);
        if (user.locale !== "en") {
          let apartmentsTranslatedData = await translateText(
            response.data.apartmentDetail,
            user.locale
          );
          setApartmentDetails(apartmentsTranslatedData);

          // let rulesData = await Promise.all(
          //   response.data.apartmentDetail.rules.map((item: any) =>
          //     translateText(item, user.locale)
          //   )
          // );
          // setApartmentDetails((prev: any) => ({
          //   ...prev,
          //   rules: rulesData,
          // }));
          setFeedback({
            averageRatings: response.data.averageRatings,
            totalReviews: response.data.totalReviews,
          });

          updateUser({
            ...user,
            isCredentialUpload: response.data.isCredentialUpload,
          });
          setIsOverlay(false);
          return;
        }

        setApartmentDetails(response.data.apartmentDetail);
        setFeedback({
          averageRatings: response.data.averageRatings,
          totalReviews: response.data.totalReviews,
        });

        updateUser({
          ...user,
          isCredentialUpload: response.data.isCredentialUpload,
        });
        setIsOverlay(false);
        return;
      }

      setApartmentDetails(response.data.apartmentDetail);
      setFeedback({
        averageRatings: response.data.averageRatings,
        totalReviews: response.data.totalReviews,
      });
      // setHost(response.data.host);

      updateUser({
        ...user,
        isCredentialUpload: response.data.isCredentialUpload,
      });
      setIsOverlay(false);
    });
  }, [visibilityState, user.locale]);


  const handleNext = () => {
    if (nextOrPrev === 3) return setNextOrPrev(3);
    return setNextOrPrev((nextOrPrev) => ++nextOrPrev);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeVisibilityModal = () => {
    setIsVisibilityModalOpen(false);
  };

  const handlePrev = () => {
    if (nextOrPrev === 1) return setNextOrPrev(1);
    return setNextOrPrev((nextOrPrev) => --nextOrPrev);
  };

  useEffect(() => {
    if (apartmentDetailsForEdit) {
      setValue("title", apartmentDetailsForEdit.title);
      setValue("price", apartmentDetailsForEdit.price);
      setValue("duration", apartmentDetailsForEdit.duration);
      setValue("discount", apartmentDetailsForEdit.discount);
      setValue("longitude", apartmentDetailsForEdit.longitude);
      setValue("latitude", apartmentDetailsForEdit.latitude);
      setValue("symbol", apartmentDetailsForEdit.symbol);
      setValue("guests", apartmentDetailsForEdit.guests);
      setValue("bedroom", apartmentDetailsForEdit.bedroom);
      setValue("livingroom", apartmentDetailsForEdit.livingroom);
      setValue("kitchen", apartmentDetailsForEdit.kitchen);
      setValue("twinbed", apartmentDetailsForEdit.twinbed);
      setValue("doublebed", apartmentDetailsForEdit.doublebed);
      setValue("extendedsofa", apartmentDetailsForEdit.extendedsofa);
      setValue("description", apartmentDetailsForEdit.description);
      setValue("amenities", apartmentDetailsForEdit.amenities);
      setValue("rules", apartmentDetailsForEdit.rules);
      setValue("propertytype", apartmentDetailsForEdit.propertytype);
    }
  }, [edit]);

  const uploadImage = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let image;

        if (files && files.length > 0) {
          let newImageUrlArray: Array<string> = [];
          let promises = []; // Array to hold promises

          for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Push the promise returned by axios to the promises array
            promises.push(
              new Promise(async (resolveInner, rejectInner) => {
                try {
                  const response = await axios({
                    method: "post",
                    url: `${baseUrl}${APIS_URL.APARTMENT_PRESIGN_URL}`,

                    data: {
                      filetype: file.type,
                    },
                    headers: {
                      Authorization: `Bearer ${auth}`,
                    },
                  });

                  if (response.status === 404) {
                    notifyError("Failed to upload apartment image");
                    setSubbmitting(false);
                    setIsLoading(false);
                    redirect.push(AppLinks.ApartmentListing.path);
                    return;
                  }
                  const url = response.data.presignedUrl;

                  let reader = new FileReader();
                  reader.onload = async (e) => {
                    if (
                      typeof e.target?.result === "string" &&
                      (e.target?.result.includes("data:image/jpeg") ||
                        e.target?.result.includes("data:image/png") ||
                        e.target?.result.includes("data:image/jpg"))
                    ) {
                      image = e.target?.result;
                      let binary = atob(image.split(",")[1]);
                      let array = [];
                      for (var i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                      }
                      let blobData = new Blob([new Uint8Array(array)], {
                        type: file.type,
                      });

                      await fetch(response.data.presignedUrl, {
                        method: "PUT",
                        body: blobData,
                      });

                      const newImageUrl = response.data.image_url;

                      // notifySuccess("File upload Successful");
                      newImageUrlArray.push(newImageUrl);
                    }
                    resolveInner(""); // Resolve the inner promise
                  };
                  reader.readAsDataURL(file);
                } catch (error) {
                  console.error("Error uploading image:", error);
                  rejectInner(error); // Reject the inner promise if an error occurs
                }
              })
            );
          }

          // Wait for all promises to resolve
          await Promise.all(promises);

          resolve(newImageUrlArray); // Resolve the main promise
        } else {
          resolve([]);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        reject(error); // Reject the main promise if an error occurs
      }
    });
  };

  const saveData = (data: any) => {
    setLocationError("");
    setLocation(false);
    setFileErrorMessage("");
    setFileError(false);

    setNextOrPrev((nextOrPrev) => ++nextOrPrev);
  };

  const submitData = async (data: any) => {
    setIsLoading(true);

    const price = parseFloat(data.price);
    const discount = parseFloat(data.discount);
    const longitude = parseFloat(data.longitude);
    const latitude = parseFloat(data.latitude);
    const guests = parseFloat(data.guests);
    const twinbed = parseFloat(data.twinbed);
    const doublebed = parseFloat(data.doublebed);
    const extendedsofa = parseFloat(data.extendedsofa);
    const area = parseFloat(data.area);
    const bedroom = parseFloat(data.bedroom);
    const livingroom = parseFloat(data.livingroom);
    const kitchen = parseFloat(data.kitchen);
    const bed = parseFloat(data.bed);
    let duration = data.duration.toLowerCase();
    duration.replace(/\s/g, "");

    const images = (await uploadImage()) as any[];

    const newData = {
      ...data,
      price,
      discount,
      longitude,
      latitude,
      guests,
      twinbed,
      doublebed,
      extendedsofa,
      area,
      bedroom,
      livingroom,
      kitchen,
      bed,
      duration,
    };

    if (Array.isArray(images) && images.length > 0) {
      newData.images = images;
    } else {
      delete newData.images;
    }

    // console.log("newData", newData);
    setIsLoading(true);
    setSubbmitting(true);

    try {
      const updateApartment = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.UPDATE_APARTMENT}/${apartmentid}`,

        data: {
          ...newData,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      // console.log("updateApartment", updateApartment);
      if (updateApartment.status === 400) {
        notifyError("Failed to add apartment");
        setSubbmitting(false);
        setIsLoading(false);
        redirect.push(`${AppLinks.MyApartmentDetails.path}/${apartmentid}`);
        return;
      }

      if (updateApartment.status === 200) {
        if (user.locale !== "en") {
          let apartmentsTranslatedData = await translateText(
            updateApartment.data.apartmentDetail,
            user.locale
          );
          setApartmentDetails(apartmentsTranslatedData);

          setFeedback({
            averageRatings: updateApartment.data.averageRatings,
            totalReviews: updateApartment.data.totalReviews,
          });

          // setHost(updateApartment.data.host);
          setSubbmitting(false);
          setIsLoading(false);

          notifySuccess("Apartment upadated successfully");
          setNextOrPrev(1);
          setEdit(false);

          return;
        }

        setApartmentDetails(updateApartment.data.apartmentDetail);
        setFeedback({
          averageRatings: updateApartment.data.averageRatings,
          totalReviews: updateApartment.data.totalReviews,
        });
        // setHost(updateApartment.data.host);
        setSubbmitting(false);
        setIsLoading(false);

        notifySuccess("Apartment upadated successfully");
        setNextOrPrev(1);
        setEdit(false);
        return;
      }
    } catch (error: any) {
      console.log("error", error);
      setSubbmitting(false);
      setIsLoading(false);
      notifyError(error?.message || "Failed to update apartment!");
    }
  };

  const handleAmenitiesChange = (e: any, checked: any) => {
    const amenity = e.currentTarget.value;

    let updatedAmenities = [...getValues("amenities")];
    if (checked) {
      updatedAmenities.push(amenity);
    }
    if (!checked) {
      updatedAmenities = updatedAmenities.filter((x) => x !== amenity);
    }

    setValue("amenities", updatedAmenities);
  };

  const handleRulesChange = (e: any, checked: any) => {
    const amenity = e.currentTarget.value;

    let updatedRules = [...getValues("rules")];
    if (checked) {
      updatedRules.push(amenity);
    }
    if (!checked) {
      updatedRules = updatedRules.filter((x) => x !== amenity);
    }

    setValue("rules", updatedRules);
  };

  const handlePropertyType = (e: any) => {
    setValue("propertytype", e.target.value);
  };

  const propetyType = watch("propertytype");

  const handleEditToggle = () => {
    setEdit(!edit); // Call your setEdit function when clicking the element
    window.scrollTo({ top: 0 }); // Scroll to the top of the page
  };

  const deleteApartment = async () => {
    setDeleting(true);
    try {
      const deleteApart = await axios({
        method: "delete",
        url: `${baseUrl}${APIS_URL.DELETE_APARTMENT}/${apartmentid}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      // console.log("deleteApart", deleteApart);

      if (deleteApart.status === 200) {
        setDeleting(false);
        redirect.push(AppLinks.MyApartment.path);
        notifySuccess("Apartment Successfully Deleted");
        return;
      } else {
        setDeleting(false);
        notifySuccess("Failed to delete Apartment");
      }
    } catch {
      setDeleting(false);
      notifySuccess("Failed to delete Apartment");
    }
  };

  const apartmentVisibility = async () => {
    try {
      const toggleVisibility = await axios({
        method: "put",
        url: `${baseUrl}${APIS_URL.TOGGLE_VISIBILITY}/${apartmentid}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      // console.log("toggleVisibility", toggleVisibility);

      if (toggleVisibility.status === 200) {
        if (toggleVisibility.data.visibility === true) {
          // redirect.push(AppLinks.MyApartment.path);
          setVisibilityState(!visibilityState);
          notifySuccess("Apartment Visibility set to true");
          closeVisibilityModal();
          return;
        }
        if (toggleVisibility.data.visibility === false) {
          // redirect.push(AppLinks.MyApartment.path);
          setVisibilityState(!visibilityState);
          notifySuccess("Apartment Visibility set to false");
          closeVisibilityModal();
          return;
        }

        return;
      } else {
        closeVisibilityModal();
        notifySuccess("Failed to update Apartment");
      }
    } catch {
      closeVisibilityModal();
      notifySuccess("Failed to update Apartment");
    }
  };

  const bedroomNum = watch("bedroom");
  const apartmentTitle = watch("title");

  return (
    <Container>
      <LogIn />
      {!edit ? (
        <Box
          bgcolor="#eeeeee"
          sx={{
            position: "relative",
            px: { xs: 3, md: 10 },
            pt: 10,
            pb: 20,
          }}
        >
          <OverlayThree isLoading={isOverlay} />
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "90%",
              },
              margin: { md: "auto" },
              // display: "flex",
              justifyContent: { md: "center" },
            }}
          >
            <Typography variant="h3" component="h1" fontWeight="bold">
              {apartmentDetails.title}
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} className="flex gap-4 items-start">
                  <span className="inline-flex items-center gap-1">
                    <MdStar className="text-yellow-500" />
                    <span className="font-bold">
                      {apartmentDetails.averageRatings}
                    </span>
                  </span>
                  <span className="text-secondary">
                    {apartmentDetails.totalReviews} <Translate text="reviews" />
                  </span>
                  <span className="text-secondary">
                    {apartmentDetails.location}
                  </span>
                  <span className="text-secondary">
                    <a
                      href={`https://maps.google.com/?ll=${apartmentDetails.latitude},${apartmentDetails.longitude}`}
                      className="text-sm text-primary underline"
                    >
                      <Translate text="To show on the map" />
                    </a>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="best-host-month"
                  sx={{ marginBottom: "10px" }}
                >
                  <Slider
                    {...{
                      dots: true,
                      infinite: false,
                      speed: 500,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      arrows: true,
                    }}
                  >
                    {apartmentDetails?.images?.map((x: string) => (
                      <Paper
                        sx={{
                          bgcolor: "#eeeeee",
                          borderRadius: "30px",
                        }}
                        key={x}
                      >
                        <img src={x} className="w-full h-[500px] rounded-2xl" />
                      </Paper>
                    ))}
                  </Slider>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent
                      sx={{
                        p: "32px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                          >
                            <span className="capitalize">
                              {apartmentDetails.propertytype}
                            </span>{" "}
                            {apartmentDetails.area}m<sup>2</sup>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="flex gap-3">
                          <span className="inline-flex">
                            {apartmentDetails.guests}{" "}
                            <Translate text="guests" />
                          </span>
                          <span className="inline-flex">
                            {apartmentDetails.bed} <Translate text="beds" />
                          </span>
                          <span className="inline-flex">
                            {apartmentDetails.bedroom}{" "}
                            <Translate text="bedroom" />
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          {apartmentDetails.description}
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            <Translate text="Sleeping Places" />
                          </FormLabel>
                          <Box>
                            <span>
                              {apartmentDetails.twinbed}{" "}
                              <Translate text="Twin Bed" />{" "}
                            </span>
                            ,{" "}
                            <span>
                              {apartmentDetails.doublebed}{" "}
                              <Translate text="Double Bed" />
                            </span>
                            ,{" "}
                            <span>
                              {apartmentDetails.extendedsofa}{" "}
                              <Translate text="Extended Sofa" />
                            </span>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="flex gap-3">
                          <span className="inline-flex">
                            {apartmentDetails.guests}{" "}
                            <Translate text="guests" />
                          </span>
                          <span className="inline-flex">
                            {apartmentDetails.twinbed +
                              apartmentDetails.doublebed}{" "}
                            <Translate text="beds" />
                          </span>
                          <span className="inline-flex">
                            {apartmentDetails.bedroom}{" "}
                            <Translate text="bedroom" />
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          {apartmentDetails.description}
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            <Translate text="Sleeping Places" />
                          </FormLabel>
                          <Box>
                            <span>
                              {apartmentDetails.twinbed}{" "}
                              <Translate text="Twin Bed" />{" "}
                            </span>
                            ,{" "}
                            <span>
                              {apartmentDetails.doublebed}{" "}
                              <Translate text="Double Bed" />
                            </span>
                            ,{" "}
                            <span>
                              {apartmentDetails.extendedsofa}{" "}
                              <Translate text="Extended Sofa" />
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent
                      sx={{
                        p: "32px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                          >
                            <Translate text="Placement rules" />
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <span className="font-bold">
                            {" "}
                            <Translate text="Check in" />
                          </span>
                          <br />
                          <span>
                            <Translate text="after 14:00" />
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <span className="font-bold">
                            <Translate text="Departure" />
                          </span>
                          <br />
                          <span>
                            <Translate text="until 12:00" />
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <span className="font-bold">
                            <Translate text="Minimum stay" />
                          </span>
                          <br />
                          <span>
                            <Translate text="from 1 day" />
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          {apartmentDetails.rules &&
                          apartmentDetails.rules.length > 0 ? (
                            <List>
                              {["smoking", "party", "pet"].map((x) => {
                                return (
                                  <RuleItem
                                    rule={x}
                                    key={x}
                                    allowed={apartmentDetails.rules?.find(
                                      (y: string) => y === x
                                    )}
                                  />
                                );
                              })}
                            </List>
                          ) : (
                            <p></p>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent
                      sx={{
                        p: "32px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                          >
                            <Translate text="Basic amenities" />
                          </Typography>
                        </Grid>

                        {apartmentDetails.amenities &&
                          apartmentDetails.amenities.map((x: string) => (
                            <Grid item xs={6} key={x}>
                              <AmenitiesItem name={x} />
                            </Grid>
                          ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item xs={12}>
                    <Card>
                      <CardContent
                        sx={{
                          p: "32px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              component="h1"
                              fontWeight="bold"
                            >
                              Reviews
                            </Typography>
                            <List
                              sx={{
                                maxHeight: "400px",
                                overflow: "auto",
                              }}
                            >
                              {apartmentDetails.feedback &&
                                apartmentDetails.feedback.length === 0 && (
                                  <Box
                                    sx={{
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    No Reviews yet
                                  </Box>
                                )}
                              {apartmentDetails.feedback &&
                                apartmentDetails.feedback.map((review: any) => (
                                  <>
                                    <Divider component="li" />
                                    <ListItem alignItems="flex-start">
                                      <ListItemAvatar>
                                        <Avatar
                                          alt={review.name}
                                          src={review.image}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Box className="flex justify-between">
                                            <span>{review.name}</span>
                                            <Rating
                                              name="rating"
                                              value={review.ratings}
                                            />
                                          </Box>
                                        }
                                        primaryTypographyProps={{
                                          sx: {
                                            fontWeight: 600,
                                          },
                                        }}
                                        secondary={
                                          <Fragment>
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {review.review}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: 12,
                                                mt: 1,
                                              }}
                                            >
                                              {dayjs(
                                                review.updatedAt
                                              ).fromNow()}
                                            </Typography>
                                          </Fragment>
                                        }
                                      />
                                    </ListItem>
                                  </>
                                ))}
                            </List>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                <Grid item xs={12}>
                  <Card>
                    <CardContent
                      sx={{
                        p: "32px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                          >
                            {apartmentDetails.location}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="flex items-center justify-between"
                        >
                          <FormLabel>
                            {apartmentDetails.city}, {apartmentDetails.country}
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomMap
                            centreLat={apartmentDetails?.latitude}
                            centreLon={apartmentDetails?.longitude}
                            markers={
                              !!apartmentDetails?.latitude &&
                              !!apartmentDetails.longitude
                                ? [
                                    {
                                      latitude: apartmentDetails?.latitude,
                                      longitude: apartmentDetails?.longitude,
                                      name: apartmentDetails.location,
                                      url: `${AppLinks.ApartmentDetails.path}/${apartmentDetails._id}`,
                                    },
                                  ]
                                : []
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  {/* {apartmentDetails.host && (
                      <Card>
                        <CardHeader
                          sx={{
                            px: "32px",
                            pt: "32px",
                          }}
                          avatar={
                            <Avatar
                              src={apartmentDetails.host.imageurl}
                              aria-label="recipe"
                              sx={{
                                width: {
                                  md: 60,
                                },
                                height: {
                                  md: 60,
                                },
                              }}
                            />
                          }
                          title={
                            <Box>
                              <Typography
                                variant="h5"
                                component="h1"
                                fontWeight="bold"
                              >
                                {apartmentDetails.host.firstname}{" "}
                                {apartmentDetails.host.lastname}
                              </Typography>
                            </Box>
                          }
                          subheader={`member since ${dayjs(
                            apartmentDetails.host.createdAt
                          ).fromNow()}`}
                          // subheader={host.firstname}
                        />
                        <CardContent
                          sx={{
                            px: "32px",
                            pb: "32px",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              {apartmentDetails.host.superhost && (
                                <Chip label="Superhost" color="primary" />
                              )}
                            </Grid>
                            {apartmentDetails.host.besthost && (
                              <Grid item xs={12}>
                                The host is one of the best on Mira
                                <span className="text-primary">dom</span>
                              </Grid>
                            )}
                            <Grid item xs={12} md={9}>
                              {apartmentDetails.host.bio}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className="flex items-center justify-start"
                            >
                              <FaSpeakap className="text-primary mr-2" />
                              Speaks
                              {apartmentDetails.host.speak.join(", ")}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )} */}
                  <Box className="flex  xs:flex-col sm:flex-row xs:space-y-2 sm:space-x-2 md:space-x-2 lg:space-x-2 xl:space-x-2 2xl:space-x-2 mt-4 xs:items-center xs:justify-center">
                    <Box
                      onClick={() => setIsVisibilityModalOpen(true)}
                      className="flex flex-row space-x-2 items-center justify-center xs:w-[150px] cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md"
                    >
                      <div
                        // type="submit"

                        className=""
                      >
                        <h3 className="md:text-[1.2rem] ">
                          <Translate text="Visibility" />
                        </h3>
                      </div>
                      {apartmentDetails.visibility ? (
                        <FaRegEye />
                      ) : (
                        <FaRegEyeSlash />
                      )}
                    </Box>

                    <button
                      type="submit"
                      onClick={() => setIsModalOpen(true)}
                      className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] xs:w-[150px] text-white hover:shadow-md"
                    >
                      {deleting ? (
                        <TbLoaderQuarter />
                      ) : (
                        <h3 className="md:text-[1.2rem] ">
                          <Translate text="Delete" />
                        </h3>
                      )}
                    </button>

                    <button
                      type="submit"
                      onClick={handleEditToggle}
                      className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] xs:w-[150px] text-white hover:shadow-md"
                    >
                      <Translate text="Edit" />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div id="payment-form"></div>
        </Box>
      ) : (
        <Box
          bgcolor="#eeeeee"
          sx={{
            px: { xs: 3, md: 10 },
            pt: 10,
            pb: 20,
          }}
        >
          <Typography variant="h3" component="h1" fontWeight="bold">
            <Translate text="Edit your property" />
          </Typography>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "space-between",
              gap: 5,
              flexDirection: {
                xs: "column-reverse",
                md: "row",
              },
            }}
          >
            <Box>
              {nextOrPrev === 1 && (
                <form onSubmit={handleSubmit(saveData)}>
                  <Typography
                    variant="h5"
                    component="h1"
                    fontWeight="bold"
                    pb={2}
                  >
                    <Translate text="Upload Photos" />
                  </Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Drag or choose your file to upload" />
                      </FormLabel>
                      <Box sx={{ mt: 1 }}>
                        <DragDropFileUpload
                          onFileUpload={(e: FileList) => {
                            setFileError(false);
                            setFileErrorMessage("");
                            console.log("e", e);
                            setFiles(e);
                          }}
                          text={
                            files?.length && files?.length > 0
                              ? `${files?.length} selected`
                              : undefined
                          }
                        />
                      </Box>
                    </Grid>
                    {fileError && (
                      <Translate text="Please upload an apartment image" />
                    )}
                  </Grid>
                  <Typography
                    variant="h5"
                    component="h1"
                    fontWeight="bold"
                    pb={1}
                    mt={4}
                  >
                    <Translate text="Property Details" />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Title" />
                      </FormLabel>

                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "Apartments in Boloruskaya"'
                        type="text"
                        {...register("title", {
                          required: "Apartment title is required",
                        })}
                      />
                      {errors.title && (
                        <span className="text-red-400">
                          <Translate text={`${errors.title.message}`} />
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Price" />
                      </FormLabel>
                      <div className="flex border border-[#647482] px-2  py-[.8rem] rounded-[1rem] mt-1 ">
                        <input
                          className=" border-r-2 outline-none bg-[#eeeeee]  w-[100%] px-[0.5rem] "
                          placeholder='"2500P" '
                          type="number"
                          {...register("price", {
                            required: "Price is required",
                            validate: {
                              greaterThanZero: (value) =>
                                parseFloat(`${value}`) > 0 ||
                                "Price must be greater than 0",
                            },
                          })}
                        />

                        <select
                          className="border outline-none text-[#647482] border-none bg-[#eeeeee] border-gray-300 rounded-lg  block w-full"
                          {...register("duration", {
                            required: false,
                          })}
                        >
                          <option value="per night">
                            <Translate text="per night" />
                          </option>
                          <option value="per week">
                            {" "}
                            <Translate text="per week" />
                          </option>
                          <option value="per month">
                            {" "}
                            <Translate text="per month" />
                          </option>
                        </select>
                      </div>
                      {errors.price && (
                        <span className="text-red-400">
                          <Translate text={`${errors.price.message}`} />
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Discount" />
                      </FormLabel>
                      <div className="flex border border-[#647482] px-2  py-[.8rem] rounded-[1rem] mt-1">
                        <input
                          className=" border-r-2 outline-none bg-[#eeeeee] w-[100%] px-[0.5rem]"
                          placeholder="10"
                          type="number"
                          {...register("discount", {
                            required: true,
                            validate: {
                              greaterThanEqualZero: (value) =>
                                parseFloat(`${value}`) >= 0 ||
                                "dicount can't be negative number 0",
                            },
                          })}
                        />

                        <select
                          className="border outline-none text-[#647482] border-none bg-[#eeeeee] border-gray-300 rounded-lg  block w-full "
                          {...register("symbol", {
                            required: false,
                          })}
                        >
                          <option value="%">%</option>
                          <option value="P">P</option>
                        </select>
                      </div>
                      {errors.discount && (
                        <span className="text-red-400">
                          <Translate text={`${errors.discount.message}`} />
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Location" />
                      </FormLabel>

                      <Box>
                        <GeoapifySearch
                          onPlaceSelect={(e: any) => {
                            console.log("e", e);
                            setLocation(false);
                            setLocationError("");
                            setValue("latitude", e.properties.lat);
                            setValue("longitude", e.properties.lon);
                          }}
                          value={apartmentDetails.location}
                        />
                      </Box>
                      {location && (
                        <Translate text="Please type in your location" />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Guests" />
                      </FormLabel>

                      <div style={{ marginTop: "0px" }}>
                        <input
                          className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                          placeholder="e.g. 3 "
                          type="number"
                          {...register("guests", {
                            required: true,
                            validate: {
                              greaterThanZero: (value) =>
                                parseFloat(`${value}`) > 0 ||
                                "Guest must be greater than 0",
                            },
                          })}
                        />
                        {errors.guests && (
                          <span className="text-red-400 text-[12px]">
                            <Translate text={`${errors.guests.message}`} />
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Bed Room" />
                      </FormLabel>
                      <div>
                        <input
                          className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                          placeholder='e.g. "2" '
                          type="number"
                          {...register("bedroom", {
                            required: true,
                            validate: {
                              greaterThanZero: (value) =>
                                parseFloat(`${value}`) > 0 ||
                                "Bedroom must be greater than 0",
                            },
                          })}
                        />
                        {errors.bedroom && (
                          <span className="text-red-400 text-[12px]">
                            <Translate text={`${errors.bedroom.message}`} />
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Living Room" />
                      </FormLabel>
                      <div>
                        <input
                          className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                          placeholder='e.g. "3" '
                          type="number"
                          {...register("livingroom", {
                            required: true,
                            validate: {
                              greaterThanZero: (value) =>
                                parseFloat(`${value}`) > 0 ||
                                "Livingroom must be greater than 0",
                            },
                          })}
                        />
                        {errors.livingroom && (
                          <span className="text-red-400 text-[12px]">
                            <Translate text={`${errors.livingroom.message}`} />
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Kitchen" />
                      </FormLabel>
                      <div>
                        <input
                          className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                          placeholder='e.g. "1" '
                          type="number"
                          {...register("kitchen", {
                            required: true,
                            validate: {
                              greaterThanZero: (value) =>
                                parseFloat(`${value}`) > 0 ||
                                "Kitchen must be greater than 0",
                            },
                          })}
                        />
                        {errors.kitchen && (
                          <span className="text-red-400 text-[12px]">
                            <Translate text={`${errors.kitchen.message}`} />
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Twin Beds" />
                      </FormLabel>

                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "1" '
                        type="number"
                        {...register("twinbed", {
                          required: false,
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Double Bed" />
                      </FormLabel>

                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "1" '
                        type="number"
                        {...register("doublebed", {
                          required: false,
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Extendable Sofa" />
                      </FormLabel>

                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "1" '
                        type="number"
                        {...register("extendedsofa", {
                          required: false,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <button
                        onClick={handleEditToggle}
                        className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md"
                      >
                        <Translate text="Back" />
                      </button>
                      <button
                        type="submit"
                        className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md ml-4"
                      >
                        <Translate text="Next" />
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
              {nextOrPrev === 2 && (
                <form onSubmit={handleSubmit(submitData)}>
                  <Typography
                    variant="h5"
                    component="h1"
                    fontWeight="bold"
                    pb={2}
                  >
                    <Translate text="Property Details" />
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Description" />
                      </FormLabel>
                      <textarea
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g "Apartments in Beloruskaya"'
                        {...register("description", {
                          required: "Description is required",
                        })}
                      />
                      {errors.description && (
                        <span className="text-red-400 ">
                          <Translate text={`${errors.description.message}`} />
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Amenities" />
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("internet")}
                            />
                          }
                          label={<Translate text="Internet, Wifi" />}
                          value="internet"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes(
                                "air-conditioner"
                              )}
                            />
                          }
                          label={<Translate text="Air Conditioner" />}
                          value="air-conditioner"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("fridge")}
                            />
                          }
                          label={<Translate text="Fridge" />}
                          value="fridge"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("balcony")}
                            />
                          }
                          label={<Translate text="Balcony" />}
                          value="balcony"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("microwave")}
                            />
                          }
                          label={<Translate text="Microwave" />}
                          value="microwave"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes(
                                "television"
                              )}
                            />
                          }
                          label={<Translate text="Television" />}
                          value="television"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes(
                                "electric-kettle"
                              )}
                            />
                          }
                          label={<Translate text="Electric Kettle" />}
                          value="electric-kettle"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("towels")}
                            />
                          }
                          label={<Translate text="Towels" />}
                          value="towels"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes(
                                "hair-dryer"
                              )}
                            />
                          }
                          label={<Translate text="Hair dryer" />}
                          value="hair-dryer"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("sauna")}
                            />
                          }
                          label={<Translate text="Sauna/bath" />}
                          value="sauna"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("jacuzzi")}
                            />
                          }
                          label={<Translate text="Jacuzzi" />}
                          value="jacuzzi"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("amenities").includes("pots")}
                            />
                          }
                          label={<Translate text="Pots" />}
                          value="pots"
                          onChange={(e, checked) => {
                            handleAmenitiesChange(e, checked);
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel sx={{ fontWeight: "bold" }}>
                        <Translate text="Rules" />
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("rules").includes("smoking")}
                            />
                          }
                          label={<Translate text="Smoking Allowed" />}
                          value="smoking"
                          onChange={(e, checked) => {
                            handleRulesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("rules").includes("party")}
                            />
                          }
                          label={<Translate text="Parties Allowed" />}
                          value="party"
                          onChange={(e, checked) => {
                            handleRulesChange(e, checked);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch("rules").includes("pet")}
                            />
                          }
                          label={<Translate text="Pets Allowed" />}
                          value="pet"
                          onChange={(e, checked) => {
                            handleRulesChange(e, checked);
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel
                          sx={{ fontWeight: "bold" }}
                          id="demo-controlled-radio-buttons-group"
                        >
                          <Translate text="Property Type" />
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={propetyType}
                          onChange={handlePropertyType}
                        >
                          <FormControlLabel
                            value="apartment"
                            control={<Radio />}
                            label={<Translate text="Apartment" />}
                          />
                          <FormControlLabel
                            value="houses-cottages"
                            control={<Radio />}
                            label={<Translate text="Houses, Cottages" />}
                          />
                          <FormControlLabel
                            value="rooms"
                            control={<Radio />}
                            label={<Translate text="Rooms" />}
                          />
                          <FormControlLabel
                            value="guest-houses"
                            control={<Radio />}
                            label={<Translate text="Guest House" />}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <button
                        onClick={handlePrev}
                        className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md"
                      >
                        <Translate text="Prev" />
                      </button>
                      <button
                        // onClick={() => {
                        //   submitApartment();
                        // }}
                        type="submit"
                        style={{
                          backgroundColor:
                            showSpinner === true || subbmitting === true
                              ? "gray"
                              : "#2560fe",
                        }}
                        className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md inline-flex items-center gap-1"
                      >
                        <Translate text="Submit your review" />
                        <FaArrowRightLong />
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Box>
            <Box>
              <Typography variant="h5" component="h1" fontWeight="bold">
                <Translate text="Preview" />
              </Typography>

              <Card sx={{ maxWidth: 345, mt: 5 }}>
                <CardMedia
                  sx={{ minHeight: 200, minWidth: 250, bgcolor: "lightgray" }}
                  image={
                    files && files.length > 0
                      ? URL.createObjectURL(files[0])
                      : apartmentDetails.images[0]
                  }
                  title="Apartment"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {apartmentTitle ?? "-"}
                  </Typography>
                  <Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        {bedroomNum ?? ""}-<Translate text="room apartment" />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {/* {listingForm.place} */}
                      </Typography>
                    </Box>
                    <Box></Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    borderTop: 1,
                    borderTopColor: "lightgray",
                    mx: 1,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <span className="inline-flex items-center">
                    <MdStar className="text-yellow-500" /> 5
                  </span>
                  <span className="inline-flex items-center text-gray-500">
                    (30 reviews)
                  </span>
                </CardActions>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: { xs: 500, sm: 500, lg: 350 },
            width: { xs: "90%", sm: "90%", lg: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography variant="h6" fontWeight="bold">
              <Translate text="Please confirm apartment deletion?" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <button
                onClick={closeModal}
                className="cursor-pointer, text-gray-800"
              >
                <Translate text="Cancel" />
              </button>
              <button
                onClick={deleteApartment}
                className="cursor-pointer, text-blue-800"
              >
                <Translate text="Confirm" />
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={isVisibilityModalOpen} onClose={closeVisibilityModal}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: { xs: 500, sm: 500, lg: 350 },
            width: { xs: "90%", sm: "90%", lg: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography variant="h6" fontWeight="bold">
              {apartmentDetails.visibility ? (
                <Translate text="Hide your apartment from other users? Please confirm." />
              ) : (
                <Translate text="Make the apartment visible to everyone? Please confirm." />
              )}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <button
                onClick={closeVisibilityModal}
                className="cursor-pointer, text-gray-800"
              >
                <Translate text="Cancel" />
              </button>
              <button
                onClick={apartmentVisibility}
                className="cursor-pointer, text-blue-800"
              >
                <Translate text="Confirm" />
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <OverlayTwo isLoading={isLoading} />
    </Container>
  );
};

export default MyApartmentDetails;
