import { FC, useEffect, useState, useCallback } from "react";
import { FaApple } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { RiCellphoneLine } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import Input from "../components/shared/Input";
import {
  useShowMsg,
  useToogleLogIn,
  useToogleSignUp,
  useIsLoggedIn,
  useUserContext,
  useNotificationContext,
} from "../context/Context";
import baseUrl from "../utils/config";
import { TbLoaderQuarter } from "react-icons/tb";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { Link, useHistory } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import { IoChevronBackSharp } from "react-icons/io5";
import { useGoogleLogin } from "@react-oauth/google";
import Label from "../components/Label";
import translateText from "../utils/translationHelper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Checkbox from "@mui/material/Checkbox";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";
import TermsAndConditionsModal from "../components/TermsAndConditionModals";
import RefundAndCancellationModal from "../components/RefundAndCancellationModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Divider,
  FormLabel,
  Grid,
  Modal,
  Typography,
  Button,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { Translate } from "react-translated";

const LogIn: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [checked, setChecked] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [refundOpen, setRefundOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const { setIsLoggedIn } = useIsLoggedIn();
  const { toogleLogin, handleToogleLogin, setToogleLogin } = useToogleLogIn();
  const { user, updateUser, socket } = useUserContext();
  const { notification, updateNotification } = useNotificationContext();
  const [showPassword, setShowPassword] = useState(false);
  const { notifyError, notifySuccess } = useShowMsg();
  const { setToogleSignup } = useToogleSignUp();

  const [showPasswordField, setShowPasswordField] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const [showSpinner1, setShowSpinner1] = useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [checkEmail, setCheckEmail] = useState("");

  const [checkPassword, setCheckPassword] = useState("");

  const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");
  const [resetPassword, setResetPassword] = useState({
    token: "",
    email: "",
    newpassword: "",
    newpassword1: "",
  });

  const [move, setMove] = useState(1);

  const [resetInput, setResetInput] = useState(1);

  const clearField = () => {
    setCheckEmail("");
    setCheckPassword("");
    setForgetPasswordEmail("");
    setEmailError(false);
    setPasswordError(false);
    setShowPasswordField(false);
    handleToogleLogin();
    setResetPassword({
      token: "",
      email: "",
      newpassword: "",
      newpassword1: "",
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const login = useGoogleLogin({
    onSuccess: (tokenResponse: { access_token: any }) => {
      (async () => {
        // console.log(tokenResponse);
        try {
          setIsEmailVerified(true);
          setToogleLogin(false);
          const res = await fetch(
            `${baseUrl}${APIS_URL.GOOGLEAUTH}/?lang=${user.locale}`,

            {
              method: "POST",
              body: JSON.stringify({
                token: tokenResponse.access_token,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const response = await res.json();

          // console.log("login res", response);

          if (response.success === false && response.isVerified === false) {
            setIsEmailVerified(false);
            setEmailError(true);
            setCheckEmail(response.email);
            setEmailErrorMessage("Email verification is required");
            return;
          }

          localStorage.setItem("token", response.token);
          const userData = response.user;

          setToogleSignup(false);

          setIsLoggedIn(true);
          updateUser({
            ...user,
            imageurl: userData.imageurl,
            firstname: userData.firstname,
            lastname: userData.lastname,
            isCredentialUpload: userData.isCredentialUpload,
            email: userData.email,
            cardType: userData.cardType,
            firstSix: userData.firstSix,
            lastFour: userData.lastFour,
          });

          // updateNotification(response.notifications);
          if (user.locale !== "en") {
            let notificationData = response.notifications;
            let notifyData = await Promise.all(
              notificationData.map((item: any) =>
                translateText(item, user.locale)
              )
            );
            updateNotification(notifyData);
          } else {
            updateNotification(response.notifications);
          }

          const userEmail = userData.email;
          socket.emit("register", userEmail);
          console.log("-----------registering user id-------------");

          notifySuccess("Logged in successfully.");
          history.push(location.pathname + location.search);
        } catch (error) {
          // console.log(error);
          setIsEmailVerified(true);
          notifySuccess("Something went wrong.");
        }
      })();
    },
  });

  const handleNextMove = () => {
    if (move === 3) {
      return 3;
    }
    setMove((move) => ++move);
    // console.log(move);
  };

  const handlePrevMove = () => {
    setResetPassword({
      token: "",
      email: "",
      newpassword: "",
      newpassword1: "",
    });
    setMove((move) => --move);
  };

  const handleEmailChange = (e: any) => {
    e.preventDefault();

    setCheckEmail(e.target.value);
  };

  const handleForgetPasswordChange = (e: any) => {
    e.preventDefault();

    setForgetPasswordEmail(e.target.value);

    // console.log(forgetPasswordEmail);
  };

  const handlePasswordChange = (e: any) => {
    e.preventDefault();

    setCheckPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    if (
      resetPassword.token === "" ||
      resetPassword.email === "" ||
      resetPassword.newpassword === "" ||
      resetPassword.newpassword1 === ""
    ) {
      notifyError("Field cannot be empty.");

      return;
    }

    if (resetPassword.newpassword !== resetPassword.newpassword1) {
      notifyError("Passwords do not match");

      return;
    }
    setShowSpinner(true);

    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.RESET_PASSWORD}`,
        data: {
          token: resetPassword.token,
          email: resetPassword.email,
          newpassword: resetPassword.newpassword,
        },
      });
      setShowSpinner(false);

      if (!response?.data?.success) throw new Error(response.data.message);

      setResetPassword({
        token: "",
        email: "",
        newpassword: "",
        newpassword1: "",
      });

      notifySuccess(response.data.message);
      setMove(1);
    } catch (error: any) {
      setShowSpinner(false);
      notifyError("Error resetting password");
    }
  };

  const handleResetChange = (e: any) => {
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });
  };

  const handleForgetPassword = async () => {
    if (!forgetPasswordEmail) {
      notifyError("Field cannot be empty.");

      return;
    }
    setShowSpinner(true);

    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.FORGET_PASSWORD}`,

        data: {
          email: forgetPasswordEmail,
        },
      });

      setShowSpinner(false);

      // console.log("response pas", response);
      if (!response?.data?.success) throw new Error(response.data.message);

      notifySuccess(response.data.message);

      setShowPasswordField(true);
      setForgetPasswordEmail("");

      handleNextMove();
    } catch (error: any) {
      setShowSpinner(false);
      notifyError("Error sending email. Please check password.");
    }
  };

  const handleCheckEmail = async () => {
    setEmailError(false);
    setEmailErrorMessage("");

    if (!checkEmail) {
      setEmailError(true);
      setEmailErrorMessage("Field cannot be empty.");
      // notifyError("Field cannot be empty.");

      return;
    }
    setShowSpinner(true);

    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.CHECKEMAIL}`,
        data: {
          email: checkEmail,
        },
      });
      setShowSpinner(false);
      if (!response?.data?.success) throw new Error(response.data.message);

      setShowPasswordField(true);
    } catch (error: any) {
      setIsEmailVerified(true);
      setShowSpinner(false);
      setEmailError(true);
      setEmailErrorMessage("Error Verifying Email");

      // notifyError("Error Verifying Email");
    }
  };

  const handleLoginWithPassword = async () => {
    setIsEmailVerified(true);
    setPasswordError(false);
    setPasswordErrorMessage("");
    if (!checkEmail || !checkPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Field cannot be empty.");
      // notifyError("Field cannot be empty.");

      return;
    }
    setShowSpinner1(true);

    try {
      if (!executeRecaptcha) return;
      const tokenRefresh = await executeRecaptcha();
      const captchaResponse = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.VERIFY_CAPTCHA}`,

        data: { token: tokenRefresh },
      });
      if (captchaResponse.data.success === true) {
        const response = await axios({
          method: "post",
          url: `${baseUrl}${APIS_URL.LOGIN}`,

          data: {
            email: checkEmail,
            password: checkPassword,
          },
        });

        setShowSpinner1(false);

        console.log("login res", response);

        if (response.status === 401 && response.data.isVerified === false) {
          setIsEmailVerified(false);
          setShowSpinner1(false);
          setPasswordError(true);

          setPasswordErrorMessage("Email verification is required");
          return;
        }

        if (response.status === 401) {
          setShowSpinner1(false);
          setPasswordError(true);
          setPasswordErrorMessage(response.data.message);
          return;
        }

        const userData = response.data.user;
        localStorage.setItem("token", response.data.token);

        setToogleSignup(false);
        setToogleLogin(false);
        setIsLoggedIn(true);

        updateUser({
          ...user,
          imageurl: userData.imageurl,
          firstname: userData.firstname,
          lastname: userData.lastname,
          isCredentialUpload: userData.isCredentialUpload,
          email: userData.email,
          cardType: userData.cardType,
          firstSix: userData.firstSix,
          lastFour: userData.lastFour,
        });
        // updateNotification(response.data.notifications);
        if (user.locale !== "en") {
          let notificationData = response.data.notifications;
          let notifyData = await Promise.all(
            notificationData.map((item: any) =>
              translateText(item, user.locale)
            )
          );
          updateNotification(notifyData);
        } else {
          updateNotification(response.data.notifications);
        }
        notifySuccess("Logged in successfully.");

        const userEmail = userData.email;
        socket.emit("register", userEmail);
        console.log("-----------registering user id-------------");

        history.push(location.pathname + location.search);
      } else {
        setIsEmailVerified(true);
        setShowSpinner1(false);
        setPasswordError(true);
        setPasswordErrorMessage("Your session has expired. Please try again.");
      }
    } catch (error: any) {
      setIsEmailVerified(true);
      setShowSpinner1(false);
      setPasswordError(true);
      setPasswordErrorMessage("Error logging in");
      // notifyError("Error logging in");
    }
  };

  const resendVerification = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.RESEND_VERIFICATION}`,

        data: {
          email: checkEmail,
        },
      });
      // console.log("login res", response);
      notifySuccess("Verification Email Resent");
      setEmailError(false);
      setPasswordError(false);
      setIsEmailVerified(true);
    } catch (error) {
      // console.log(error);
      notifyError("Please try again!");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handlePrivacyOpen = () => {
    setPrivacyOpen(true);
  };

  const handleTermsOpen = () => {
    setTermsOpen(true);
  };

  const handleRefundOpen = () => {
    setRefundOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };
  const handleRefundClose = () => {
    setRefundOpen(false);
  };

  const placeholderText = <Translate text="Enter your email" />;
  return (
    <>
      <Modal open={toogleLogin && move === 1} onClose={clearField}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 600,
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} className="text-center">
              <Translate text="Log in or" />{" "}
              <button
                onClick={() => {
                  setToogleSignup(true);
                  setToogleLogin(false);
                }}
                className="text-primary underline"
              >
                <Translate text="Sign up" />
              </button>{" "}
              <Translate text="on" /> <Translate text="Mira" />-
              <span className="text-primary">
                <Translate text="Dom" />
              </span>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="bold"
                className="text-center"
              >
                <span className="text-primary">
                  <Translate text="Welcome to" /> <Translate text="Mira" />-
                </span>
                <Translate text="Dom" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="relative">
                <input
                  className={`border border-[#647482]  w-[100%]  pl-[0.5rem] ${
                    showPasswordField ? "pr-[0.5rem]" : "pr-[3rem]"
                  } py-[0.8rem] rounded-[1rem] mt-1`}
                  placeholder={placeholderText.props.text}
                  type="email"
                  name="email"
                  id=""
                  value={checkEmail}
                  onChange={handleEmailChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (checked) {
                        handleCheckEmail();
                      }
                    }
                  }}
                />
                {emailError && (
                  <Box
                    className="flex flex-row space-x-6"
                    sx={{
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        color: "red",
                        marginTop: "6px",
                        fontSize: { xs: "10px", sm: "20px" },
                      }}
                    >
                      {emailErrorMessage}
                    </Box>
                    {!isEmailVerified && (
                      <Box
                        onClick={resendVerification}
                        className="cursor-pointer rounded-md bg-primary text-white px-4 py-2"
                      >
                        <Translate text="Resend" />
                      </Box>
                    )}
                  </Box>
                )}

                {showSpinner ? (
                  <TbLoaderQuarter className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]" />
                ) : (
                  <button disabled={!checked}>
                    <IoArrowForwardCircleSharp
                      onClick={handleCheckEmail}
                      className={
                        showPasswordField
                          ? "hidden "
                          : `${
                              checked
                                ? "text-primary cursor-pointer"
                                : "text-[#777777]  cursor-not-allowed"
                            }  absolute right-[0.1rem] top-[.4rem] text-[3rem]`
                      }
                    />
                  </button>
                )}
              </div>
            </Grid>
            {showPasswordField && (
              <Grid item xs={12}>
                <div className="relative">
                  <input
                    className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id=""
                    value={checkPassword}
                    onChange={handlePasswordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (checked) {
                          handleLoginWithPassword();
                        }
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-link absolute top-5 right-[55px] translate-middle-y"
                    onClick={togglePasswordVisibility}
                    style={{ zIndex: 1 }} // Ensure the button is above the input
                  >
                    {showPassword ? (
                      // <small className="underline decoration-black">hide</small>
                      <VisibilityOffIcon />
                    ) : (
                      // <small className="underline decoration-black">show</small>
                      <VisibilityIcon />
                    )}
                  </button>
                  {passwordError && (
                    <Box
                      className="flex flex-row space-x-6"
                      sx={{
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          color: "red",
                          marginTop: "6px",
                          fontSize: { xs: "10px", sm: "20px" },
                        }}
                      >
                        {passwordErrorMessage}
                      </Box>
                      {!isEmailVerified && (
                        <Box
                          onClick={resendVerification}
                          className="cursor-pointer rounded-md bg-primary text-white px-4 py-2"
                        >
                          <Translate text="Resend" />
                        </Box>
                      )}
                    </Box>
                  )}

                  {showSpinner1 ? (
                    <TbLoaderQuarter className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]" />
                  ) : (
                    <button disabled={!checked}>
                      <IoArrowForwardCircleSharp
                        onClick={handleLoginWithPassword}
                        className={` ${
                          checked
                            ? "text-primary cursor-pointer"
                            : "text-[#777777]  cursor-not-allowed"
                        }  absolute right-[0.1rem] top-[.4rem] text-[3rem]`}
                      />
                    </button>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="flex flex-col ">
                <div
                  className="flex text-[#777777] justify-center item-center"
                  style={{ alignItems: "center" }}
                >
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span className="text-[18px] xs:text-[15px] sm:text-[15px]">
                    <Translate text="I agree to the" />{" "}
                    <span
                      className="cursor-pointer text-[#225FFE] underline "
                      onClick={handlePrivacyOpen}
                    >
                      <Translate text="Privacy Policy" />
                    </span>{" "}
                    <Translate text="and" />{" "}
                    <span
                      className="cursor-pointer text-[#225FFE] underline"
                      onClick={handleTermsOpen}
                    >
                      <Translate text="Teams & Conditions" />
                    </span>
                  </span>
                </div>
                <div className="flex justify-center ml-2 xs:mt-2 sm:mt-2 text-[18px] xs:text-[15px] sm:text-[15px]">
                  <span className="text-[#777777]">
                    <Translate text="By signing in, you agree to our" />{" "}
                    <span
                      className="cursor-pointer text-[#225FFE] underline"
                      onClick={handleRefundOpen}
                    >
                      <Translate text="Refund and Cancellation Policy" />
                    </span>
                  </span>
                </div>
              </div>

              <PrivacyPolicyModal
                privacyOpen={privacyOpen}
                handlePrivacyClose={handlePrivacyClose}
              />
              <TermsAndConditionsModal
                termsOpen={termsOpen}
                handleTermsClose={handleTermsClose}
              />
              <RefundAndCancellationModal
                refundOpen={refundOpen}
                handleRefundClose={handleRefundClose}
              />
            </Grid>

            <Grid item xs={12} className="text-center">
              <button
                onClick={() => {
                  handleNextMove();
                }}
                className="text-red-700 hover:text-black"
              >
                <Translate text="Forgot Password?" />
              </button>
            </Grid>
            <Grid item xs={12}>
              <Divider className="text-gray-500">
                <Translate text="Or Continue With" />
              </Divider>
            </Grid>
            <Grid item xs={12} className="text-center flex justify-center">
              <button
                disabled={!checked}
                className={`${
                  checked ? "bg-primary" : "bg-[#777777] cursor-not-allowed"
                } flex  items-center rounded-[2rem] gap-2  text-white p-3`}
                onClick={() => login()}
              >
                <FaGoogle />
                <span className="text-sm sm:text-[.5rem] xs:text-[.5rem]">
                  Google
                </span>
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={toogleLogin && move === 2} onClose={clearField}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 500,
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} className="text-center">
              <Translate text="Back to" />{" "}
              <button
                onClick={() => {
                  handlePrevMove();
                }}
                className="text-primary underline ml-1"
              >
                <Translate text="Login" />
              </button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="bold"
                className="text-center"
              >
                <Translate text="Forgot Password?" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Translate text="Enter an email to recover your password" />
              </FormLabel>
              <div className="relative">
                <input
                  className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  id=""
                  value={forgetPasswordEmail}
                  onChange={handleForgetPasswordChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleForgetPassword();
                    }
                  }}
                />

                {showSpinner ? (
                  <TbLoaderQuarter className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]" />
                ) : (
                  <IoArrowForwardCircleSharp
                    onClick={handleForgetPassword}
                    className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={toogleLogin && move === 3} onClose={clearField}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 500,
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: 0,
            p: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className="text-center">
              <Translate text="Back to" />{" "}
              <button
                onClick={() => {
                  handlePrevMove();
                }}
                className="text-primary underline ml-1"
              >
                <Translate text="Password Reset" />
              </button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="bold"
                className="text-center"
              >
                <Translate text="Forgot Password?" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Translate text="Check your email for a token" />
              </FormLabel>
              <Input
                className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                placeholder="Enter your token"
                type="text"
                name="token"
                id=""
                value={resetPassword.token}
                onChange={handleResetChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Translate text="Email" />
              </FormLabel>
              <Input
                className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                placeholder="Enter email"
                type="email"
                name="email"
                id=""
                value={resetPassword.email}
                onChange={handleResetChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Translate text="New Password" />
              </FormLabel>
              <Input
                className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                placeholder="Enter your New Password"
                type="password"
                name="newpassword"
                id=""
                value={resetPassword.newpassword}
                onChange={handleResetChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Translate text="Confirm New Password" />
              </FormLabel>
              <div className="relative">
                <input
                  className="border border-[#647482]  w-[100%]  pl-[0.5rem] pr-[3rem] py-[0.8rem] rounded-[1rem] mt-1"
                  placeholder="Re-enter password"
                  type="password"
                  name="newpassword1"
                  id=""
                  value={resetPassword.newpassword1}
                  onChange={handleResetChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleResetPassword();
                    }
                  }}
                />
                {showSpinner ? (
                  <TbLoaderQuarter className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]" />
                ) : (
                  <IoArrowForwardCircleSharp
                    onClick={handleResetPassword}
                    className="text-primary cursor-pointer absolute right-[0.1rem] top-[.4rem] text-[3rem]"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default LogIn;
