import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Translate } from "react-translated";

interface PrivacyPolicy {
  termsOpen: boolean;
  handleTermsClose: () => void;
}

const TermsAndConditionsModal: FC<PrivacyPolicy> = ({
  termsOpen,
  handleTermsClose,
}) => {
  return (
    <Modal open={termsOpen} onClose={handleTermsClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: { xs: 500, sm: 500, lg: "60%" },
          width: { xs: "90%", sm: "90%", lg: "100%" },
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleTermsClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <Typography id="privacy-policy-modal-title" variant="h6" component="h2">
          Privacy Policy
        </Typography> */}
        <Box
          sx={{
            mt: 2,
            maxHeight: "400px",
            overflowY: "auto",
            padding: "12px",

            borderTop: "1px solid #D9E6FF",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            className=" text-[#225FFE] font-bold"
          >
            <Translate text="Terms and Conditions" />
          </Typography>
          <Typography
            className="py-4 text-[#132F41] font-bold"
            variant="h5"
            component="h2"
          >
            <Translate text="Your Agreement" />
          </Typography>
          <Typography className="pl-[20px]" component="div">
            <ol className="list-decimal">
              <li className="text-[20px] font-bold">
                <p className="">
                  <Translate text="Introduction" />
                </p>
                <p className=" text-[16px] font-normal text-[#777777] my-6">
                  <Translate text="Welcome to Mira-Dom. By accessing and using our platform, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use our services." />
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p>
                  <Translate text="Definitions" />
                </p>
                <p className=" text-[16px] font-normal">
                  <ul className="list-disc my-6">
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Platform" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Refers to Mira-Dom's website and mobile application." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="User" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Refers to anyone using the platform, including hosts and guests." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Host" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Refers to users who list properties for rent." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Guest" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Refers to users who book rental properties." />
                      </p>
                    </li>
                  </ul>
                </p>
              </li>

              <li className="text-[20px] font-bold">
                <p>
                  <Translate text="User Obligations" />
                </p>
                <p className=" text-[16px] font-normal">
                  <ul className="list-disc my-6">
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Eligibility" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Users must be at least 18 years old." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Account Information" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Users must provide accurate and up-to-date information during registration. Any fraudulent activity will result in termination of services." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Compliance" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Users agree to comply with all applicable laws and regulations." />
                      </p>
                    </li>
                    <li>
                      <p className="inline font-bold">
                        <Translate text="Verification" />
                      </p>
                      <p className="inline text-[#777777]">
                        :{" "}
                        <Translate text="Users must be verified to access the platform fully. Verification includes providing proof of identity using an international passport for foreigners and a Russian passport for locals." />
                      </p>
                    </li>
                  </ul>
                </p>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Account Registration" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Users must register for an account to use our services." />
                  </li>
                  <li>
                    <Translate text="Users are responsible for maintaining the confidentiality of their account information." />
                  </li>
                  <li>
                    <Translate text="Mira-Dom reserves the right to suspend or terminate accounts that violate these terms." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Booking and Payments" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="All bookings must be made through the Mira-Dom platform." />
                  </li>
                  <li>
                    <Translate text="Payments are processed via secure payment gateways." />
                  </li>
                  <li>
                    <Translate text="Mira-Dom charges a commission fee of 5% on all transactions." />
                  </li>
                  <li>
                    <Translate text="Guests are responsible for any additional fees specified by the host." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Property Listings" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Hosts must ensure the accuracy of their property listings." />
                  </li>
                  <li>
                    <Translate text="Mira-Dom reserves the right to remove any listings that violate our terms." />
                  </li>
                  <li>
                    <Translate text="Hosts must disclose any defects or issues with the property." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Cancellations and Refunds" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Guest Cancellations: Refer to our Refund and Cancellation Policy for detailed information." />
                  </li>
                  <li>
                    <Translate text="Host Cancellations: Hosts must notify Mira-Dom and the guest at least 14 days before the check-in date. Failure to do so may result in penalties." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Privacy" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="User data is collected and processed in accordance with our Privacy Policy." />
                  </li>
                  <li>
                    <Translate text="We are committed to protecting your privacy and ensuring data security." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Intellectual Property" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="All content on the Mira-Dom platform, including text, graphics, logos, and images, is the property of Mira-Dom or its content suppliers and is protected by intellectual property laws." />
                  </li>
                  <li>
                    <Translate text="Users may not use any content from the platform without prior written permission from Mira-Dom" />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Limitation of Liability" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Mira-Dom is not liable for any damages arising from the use of our platform." />
                  </li>
                  <li>
                    <Translate text="Users agree to indemnify Mira-Dom against any claims resulting from their use of the service." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Dispute Resolution" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Any disputes arising from these terms will be resolved through binding arbitration in accordance with the rules of the Russian Federation." />
                  </li>
                  <li>
                    <Translate text="Users agree to waive their right to a jury trial." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Amendments" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="Mira-Dom reserves the right to amend these terms at any time." />
                  </li>
                  <li>
                    <Translate text="Users will be notified of significant changes through email or notifications on the platform." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Governing Law" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="These terms are governed by the laws of the Russian Federation." />
                  </li>
                  <li>
                    <Translate text="Any disputes will be resolved in the courts of Russia." />
                  </li>
                </ul>
              </li>

              <li className="text-[20px] font-bold my-6">
                <p className="">
                  <Translate text="Complaints and Contact Information" />
                </p>

                <ul className=" text-[16px] font-normal list-disc text-[#777777] mt-6">
                  <li>
                    <Translate text="For any complaints, users can send us an email or message us on Telegram or WhatsApp, at their convenience. Mira-Dom is a fully registered company in Russia and follows all applicable rules, laws, and taxes." />
                  </li>
                </ul>
              </li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsModal;
