import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FaSmoking } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlinePets } from "react-icons/md";
import { useUserContext } from "../../context/Context";
import CyrillicToTranslit from "cyrillic-to-translit-js";

interface Props {
  rule: string;
  allowed: boolean;
}
export default function RuleItem({ rule, allowed }: Props) {
  const translit = CyrillicToTranslit();
  const { user } = useUserContext();
  switch (rule) {
    case "smoking":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <FaSmoking className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              allowed
                ? `${
                    user.locale === "en"
                      ? "Smoking Allowed"
                      : translit.reverse("Smoking Allowed")
                  }`
                : `${
                    user.locale === "en"
                      ? "No Smoking Allowed"
                      : translit.reverse("No Smoking Allowed")
                  }`
            }
          />
        </ListItem>
      );
    case "pet":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <MdOutlinePets className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              allowed
                ? `${
                    user.locale === "en"
                      ? "Pets Allowed"
                      : translit.reverse("Pets Allowed")
                  }`
                : `${
                    user.locale === "en"
                      ? "No Pet Allowed"
                      : translit.reverse("No Pet Allowed")
                  }`
            }
          />
        </ListItem>
      );
    case "party":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <GiPartyPopper className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              allowed
                ? `${
                    user.locale === "en"
                      ? "Party Allowed"
                      : translit.reverse("Pets Allowed")
                  }`
                : `${
                    user.locale === "en"
                      ? "No Party Allowed"
                      : translit.reverse("No Party Allowed")
                  }`
            }
          />
        </ListItem>
      );
  }
  return <></>;
}
