import { useState } from "react";
import Slider from "react-slick";
import { CardMedia, Box } from "@mui/material";
import { CiHeart } from "react-icons/ci";
import nextArrow from "../images/nextArrow.png";
import previousArrow from "../images/previousArrow.png";

function SampleNextArrow(props: any) {
  const { className, style, onClick, isVisible } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        // display: "flex",
        display: isVisible ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "#00b33c",
        borderRadius: "100%",
        height: "20px",
        width: "20px",
        marginRight: "25px",
        zIndex: 9999,
      }}
      onClick={onClick}
    >
      {" "}
      <img
        src={nextArrow}
        alt="Next"
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick, isVisible } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        // display: "flex",
        display: isVisible ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "#00b33c",
        borderRadius: "100%",
        height: "20px",
        width: "20px",
        marginLeft: "25px",
        zIndex: 9999,
      }}
      onClick={onClick}
    >
      <img
        src={previousArrow}
        alt="Previous"
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
}

const HoverableSlider = (images: any) => {
  // console.log("images", images);
  const [isHovered, setIsHovered] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const addToFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ position: "relative" }}
    >
      <Slider
        {...{
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: true,
          nextArrow: <SampleNextArrow isVisible={isHovered} />,
          prevArrow: <SamplePrevArrow isVisible={isHovered} />,
        }}
      >
        {images?.images.map((x: string) => (
          <CardMedia
            image={x}
            sx={{
              bgcolor: "gray",
              height: "180px",
            }}
          ></CardMedia>
        ))}
      </Slider>
      {/* <div
        onClick={() => addToFavorite()}
        className={`rounded-full  text-md absolute top-2 right-2 p-2 ${
          isFavorite ? "bg-red-600" : "bg-white"
        }`}
      >
        <CiHeart />
      </div> */}
    </Box>
  );
};

export default HoverableSlider;
