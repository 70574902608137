import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FaBath, FaFan, FaSmoking, FaWifi } from "react-icons/fa";
import { GiCookingPot, GiJug, GiTowel } from "react-icons/gi";
import { LuMicrowave } from "react-icons/lu";
import { MdBalcony, MdBathroom } from "react-icons/md";
import { PiTelevision } from "react-icons/pi";
import { RiFridgeFill } from "react-icons/ri";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { useUserContext } from "../../context/Context";
interface Props {
  name: string;
}
export default function AmenitiesItem({ name }: Props) {
  const translit = CyrillicToTranslit();
  const { user } = useUserContext();
  switch (name) {
    case "internet":
    case "wifi":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <FaWifi className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en"
                ? "Wireless Internet / Wi-Fi"
                : translit.reverse("Wireless Internet / Wi-Fi")
            }
          />
        </ListItem>
      );
    case "television":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <PiTelevision className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en"
                ? "Television"
                : translit.reverse("Television")
            }
          />
        </ListItem>
      );
    case "air-conditioner":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <FaFan className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en"
                ? "Air Conditioner"
                : translit.reverse("Air Conditioner")
            }
          />
        </ListItem>
      );
    case "fridge":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <RiFridgeFill className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en" ? "Fridge" : translit.reverse("Fridge")
            }
          />
        </ListItem>
      );
    case "balcony":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <MdBalcony className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en" ? "Balcony" : translit.reverse("Balcony")
            }
          />
        </ListItem>
      );
    case "microwave":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <LuMicrowave className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en" ? "Microwave" : translit.reverse("Microwave")
            }
          />
        </ListItem>
      );
    case "electric-kettle":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <GiJug className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en"
                ? "Electric Kettle"
                : translit.reverse("Electric Kettle")
            }
          />
        </ListItem>
      );
    case "towel":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <GiTowel className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={user.locale === "en" ? "Towel" : translit.reverse("Towel")}
          />
        </ListItem>
      );
    case "hair-dryer":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <GiTowel className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en"
                ? "Hair Dryer"
                : translit.reverse("Hair Dryer")
            }
          />
        </ListItem>
      );
    case "sauna":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <MdBathroom className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={user.locale === "en" ? "Sauna" : translit.reverse("Sauna")}
          />
        </ListItem>
      );
    case "jacuzzi":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <FaBath className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={
              user.locale === "en" ? "Jacuzzi" : translit.reverse("Jacuzzi")
            }
          />
        </ListItem>
      );
    case "pots":
      return (
        <ListItem disablePadding>
          <ListItemIcon>
            <GiCookingPot className="text-[20px]" />
          </ListItemIcon>
          <ListItemText
            primary={user.locale === "en" ? "Pot" : translit.reverse("Pot")}
          />
        </ListItem>
      );
  }
  return <></>;
}
