import { MdStar } from "react-icons/md";
import LogIn from "./LogIn";
import Container from "../components/shared/Container";
import { FaArrowRightLong } from "react-icons/fa6";
import { GoPerson } from "react-icons/go";
import { FaSpeakap } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Fragment, FC, useEffect, useState } from "react";
import { APIS_URL } from "../services/api";
import { AiOutlineMessage } from "react-icons/ai";
import { MdAccessTime } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Translate } from "react-translated";
import OverlayFour from "../components/OverlayFour";
import baseUrl from "../utils/config";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Badge,
  Typography,
  FormLabel,
  List,
  Modal,
  ListItem,
  ListItemText,
  Rating,
  Divider,
  ListItemAvatar,
  Avatar,
  CardHeader,
  Chip,
  CardActionArea,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slick";
import RuleItem from "../components/ApartmentDetails/RuleItem";
import AmenitiesItem from "../components/ApartmentDetails/AmenitiesItem";
import CustomMap from "../components/Map";
import { AppLinks } from "../utils/Linking";
import CheckinPopup from "./components/CheckinPopup";
import CheckoutPopup from "./components/CheckoutPopup";
import ApartmentGuestPopup from "./components/ApartmentGuestPopup";
import { format } from "date-fns-tz";
import {
  useIsLoggedIn,
  useShowMsg,
  useToogleLogIn,
  useUserContext,
  useNotificationContext,
} from "../context/Context";
import { useForm, Controller } from "react-hook-form";
import translateText from "../utils/translationHelper";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import nextArrow from "../images/nextArrow.png";
import previousArrow from "../images/previousArrow.png";
dayjs.extend(relativeTime);

interface ApartmentDetailsInterface {
  title: string;
  price: number;
  duration: string;
  discountprice: number;
  location: string;
  longitude: string;
  latitude: string;
  bedroom: string;
  bed: string;
  livingroom: string;
  kitchen: string;
  guests: number;
  area: number;
  propertytype: string;
  rules: string[];
  description: string;
  amenities: string[];
  corefeatures: string[];
  owner: string;
  featured: boolean;
  approve: boolean;
  images: string[];
  feedback: any[];
}
interface SocketInterface {
  socket: any;
}
interface Message {
  message: string;
  username: string;
  __createdtime__: number;
}
const ApartmentDetails: FC = () => {
  let today = new Date();
  const [messagesReceived, setMessagesReceived] = useState<Message[]>([]);
  const translit = CyrillicToTranslit();
  let aWeek = new Date(today.setDate(today.getDate() + 3));
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser, socket } = useUserContext();
  const { apartmentid } = useParams<{ apartmentid: string }>();
  const [valueFrom, onChangeFrom] = useState(new Date());
  const [valueTo, onChangeTo] = useState(aWeek);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckOut, setShowCheckUp] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [rating, setRating] = useState<number>();
  const [showPopup, setShowPopup] = useState(false);
  const { handleToogleLogin } = useToogleLogIn();
  const { isLoggededIn } = useIsLoggedIn();
  const [isFavoriteHover, setIsFavoriteHover] = useState(false);
  const [isShareHover, setIsShareHover] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    control,
    handleSubmit,
  } = useForm();

  const [host, setHost] = useState({
    displayname: "",
    realname: "",
    bio: "",
    imageurl: "",
    firstname: "",
    lastname: "",
    createdAt: "",
    speak: [],
    superhost: false,
    besthost: false,
    isIdentityVerified: false,
  });

  interface DateRange<T> {
    start: T;
    end: T;
  }
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: "",
    guest: 0,
    adult: 0,
    children: 0,
    infant: 0,
    pet: 0,
    fromDate: null,
    toDate: null,
  });

  const [apartmentDetails, setApartmentDetails] = useState<
    ApartmentDetailsInterface | any
  >({
    title: "",
    price: 0,
    duration: "",
    discountprice: 0,
    location: "",
    longitude: "",
    latitude: "",
    bedroom: "",
    bed: "",
    livingroom: "",
    kitchen: "",
    guests: 0,
    area: 0,
    propertytype: "",
    rules: [],
    description: "",
    amenities: [],
    corefeatures: [],
    owner: "",
    featured: false,
    approve: false,
    images: [],
    feedback: [],
  });
  const [apartmentReviews, setApartmentReviews] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [feedback, setFeedback] = useState({
    averageRatings: 0,
    totalReviews: 0,
  });
  const { notifyError, notifySuccess } = useShowMsg();

  const [anchorCheckinEl, setAnchorCheckinEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorCheckoutEl, setAnchorCheckoutEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (token) {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      const tokenExp = tokenData.exp * 1000;
      const currentTime = new Date().getTime();

      if (currentTime > tokenExp) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    try {
      axios({
        method: "get",

        url: `${baseUrl}${APIS_URL.GET_APARTMENT_DETAILS}${apartmentid}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          // console.log("res", response);
          const status = response.status;
          if (response.status === 200) {
            if (user.locale !== "en") {
              let apartmentsTranslatedData = await translateText(
                response.data.apartmentDetail,
                user.locale
              );
              setApartmentDetails(apartmentsTranslatedData);

              // let feedbackData: any[] = await Promise.all(
              //   response.data.apartmentDetail.feedback.map((item: any) =>
              //     translateText(item, user.locale)
              //   )
              // );
              // setApartmentDetails(
              //   ...apartmentDetails,
              //   apartmentDetails.feedback:feedbackData
              // );
              let hostData = await translateText(
                response.data.apartmentDetail.host,
                user.locale
              );
              setHost(hostData);
              setApartmentDetails((prevDetails: any) => ({
                ...prevDetails,
                host: hostData,
              }));

              setFeedback({
                averageRatings: response.data.averageRatings,
                totalReviews: response.data.totalReviews,
              });
              updateUser({
                ...user,
                isCredentialUpload: response.data.isCredentialUpload,
              });
              setIsLoading(false);

              return;
            }

            setApartmentDetails(response.data.apartmentDetail);
            setFeedback({
              averageRatings: response.data.averageRatings,
              totalReviews: response.data.totalReviews,
            });
            setHost(response.data.host);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            return;
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (error: any) {
      setIsLoading(false);
      notifyError("Failed to fetch apartment details");
    }
  }, [user.locale]);

  // console.log("apartmentdetails", apartmentDetails);

  const changeFromDate = (e: any) => {
    onChangeFrom(e);
    setShowCheckIn(false);
    setShowCheckUp(true);
  };

  const onChangeToData = (e: any) => {
    onChangeTo(e);
    setShowCheckUp(false);
  };

  const handleToogleShowCheckIn = (e: any) => {
    e.preventDefault();
    setShowCheckUp(false);
    setShowCheckIn(!showCheckIn);
  };

  const handleToogleShowCheckOut = (e: any) => {
    e.preventDefault();
    setShowCheckIn(false);
    setShowCheckUp(!showCheckOut);
  };

  const paymentWidget = (token: string) => {
    const checkout = new (window as any).YooMoneyCheckoutWidget({
      confirmation_token: token, //Token that must be obtained from YooMoney before the payment process
      // return_url: url, //URL to the payment completion page
      customization: {
        //Настройка способа отображения
        modal: true,
      },
      error_callback: function (error: any) {
        //Processing of initialization errors
      },
    });
    checkout.on("complete", () => {
      //Code to be run after payment.
      notifySuccess("Apartment Successfully Booked");
      //Deletion of an initialized widget
      checkout.destroy();
    });
    //Display of payment form in the container
    checkout
      .render("payment-form")
      //The method returns Promise. When Promise is executed, it means the payment form is fully loaded (optional use).
      .then(() => {
        //Code that must be executed after the payment form is displayed.
      });
  };
  const cancleBooking = () => {
    setIsBooking(false);
  };
  const initiateBooking = () => {
    if (filter.fromDate === null && filter.toDate === null) {
      notifyError("Add booking Check-in and check-out date");
      return;
    }
    let formattedFromDate;
    let formattedToDate;
    if (filter.fromDate !== null) {
      formattedFromDate = format(filter.fromDate, "yyyy-MM-dd'T'HH:mm:ss");
    }
    if (filter.toDate !== null) {
      formattedToDate = format(filter.toDate, "yyyy-MM-dd'T'HH:mm:ss");
    }

    if (formattedToDate && formattedFromDate) {
      if (formattedToDate < formattedFromDate) {
        notifyError("Check out date cannot be less than check in date");
        return;
      }
    }
    setIsBooking(true);
  };

  const bookApartment = async () => {
    try {
      setIsBooking(false);
      if (showSpinner === true) return;
      let formattedFromDate;
      let formattedToDate;
      if (filter.fromDate !== null) {
        formattedFromDate = format(filter.fromDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      if (filter.toDate !== null) {
        formattedToDate = format(filter.toDate, "yyyy-MM-dd'T'HH:mm:ss");
      }

      if (formattedToDate && formattedFromDate) {
        if (formattedToDate < formattedFromDate) {
          notifyError("Check out date cannot be less than check in date");
          return;
        }
      }
      const response = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.REQUEST_BOOKING}`,

        data: {
          apartment: apartmentid,
          checkin: formattedFromDate,
          checkout: formattedToDate,
          guests: filter.guest,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      if (response.status === 200) {
        notifySuccess(
          "Booking submitted. You will be notified of your booking status."
        );
      }
      if (response.status === 400 && response.data.booked === true) {
        notifyError("Apartment has already been booked for this period");
      }
      if (response.status === 400 && response.data.guestCapacity === false) {
        notifyError(response.data.message);
      }

      if (response.status === 400 && response.data.owner === true) {
        notifyError("Owner's can't book there Apartment");
      }
      if (response.status === 502) {
        notifyError("Please try again something went wrong");
      }

      setShowSpinner(false);
    } catch (error: any) {
      console.log("faile error", error);
      notifyError("Failed to book apartment");
    }
  };

  const onSubmit = async (data: any) => {
    // console.log("reviews", data);

    setValue("review", "");
    setValue("rating", "");
    try {
      const newFeedback = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.GIVE_FEEDBACK}`,

        data: {
          review: data.review,
          rating: data.rating,
          apartmentid: apartmentid,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      console.log("newFeedback", newFeedback);
      if (newFeedback.status === 201) {
        notifySuccess("Feedback Successfully Created.");
        return;
      }
      if (newFeedback.status === 400) {
        notifyError(newFeedback.data.message);
        return;
      } else {
        notifyError("Something went wrong.");
        return;
      }
    } catch (error) {
      console.log(error);
      notifyError("Something went wrong.");
      return;
    }
  };

  const rate = watch("rating");

  console.log("apartment", apartmentDetails);

  const contactHost = async () => {
    try {
      // const generateRoomName = await axios({
      //   method: "get",
      //   // url: `${baseUrl}${APIS_URL.GIVE_FEEDBACK}`,
      //
      //   headers: {
      //     Authorization: `Bearer ${auth}`,
      //   },
      // });
      // console.log("generateRoomName", generateRoomName);
      // if (generateRoomName.status === 200) {
      //   const guestName = user.firstname;
      //   const landlordName = apartmentDetails.host.firstname;
      //   const apartmentTitle = apartmentDetails.title;
      //   const roomName = generateRoomName.data.roomName;
      //   // Emit event to server to contact landlord
      //   socket.emit("contact_landlord", {
      //     guestName,
      //     landlordName,
      //     apartmentTitle,
      //     roomName,
      //   });

      // let content = "make enquiring about my booking";
      // socket.emit("private message", {
      //   content,
      //   to: apartmentDetails.owner,
      // });
      history.push(`${AppLinks.Messages.path}/?id=${apartmentDetails._id}`);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    socket.on("receive_message", (data: any) => {
      console.log(data);
      setMessagesReceived((state) => [
        ...state,
        {
          message: data.message,
          username: data.username,
          __createdtime__: data.__createdtime__,
        },
      ]);
    });

    // Remove event listener on component unmount
    return () => socket.off("receive_message");
  }, [socket]);

  // console.log("message recieved", messagesReceived);

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          borderRadius: "100%",
          height: "20px",
          width: "20px",
          marginRight: "-15px",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <img
          src={nextArrow}
          alt="Next"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: "#00b33c",
          borderRadius: "100%",
          height: "20px",
          width: "20px",
          marginLeft: "-15px",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <img
          src={previousArrow}
          alt="Previous"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    );
  }
  return (
    <Container>
      <LogIn />
      <Box
        bgcolor="#eeeeee"
        sx={{
          position: "relative",
          px: { xs: 3, md: 10 },
          pt: 5,
          pb: 20,
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "70%",
            },
          }}
        >
          <Typography variant="h3" component="h1" fontWeight="bold">
            {apartmentDetails.title}
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <OverlayFour isLoading={isLoading} />

              <Grid item xs={12} className="flex gap-4 items-start">
                <span className="inline-flex items-center gap-1">
                  <MdStar className="text-yellow-500" />
                  <span className="font-bold">
                    {apartmentDetails.averageRatings}
                  </span>
                </span>
                <span className="text-secondary">
                  {apartmentDetails.totalReviews} <Translate text="reviews" />
                </span>
                <span className="text-secondary">
                  {apartmentDetails.location}
                </span>
                <span className="text-secondary">
                  <a
                    href={`https://maps.google.com/?ll=${apartmentDetails.latitude},${apartmentDetails.longitude}`}
                    className="text-sm text-primary underline"
                  >
                    <Translate text="To show on the map" />
                  </a>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="best-host-month"
                sx={{ marginBottom: "10px" }}
              >
                <Slider
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                  {...{
                    dots: true,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                    // arrows: true,
                  }}
                  responsive={[
                    {
                      breakpoint: 1200,
                      settings: {},
                    },
                    {
                      breakpoint: 1024,
                      settings: {
                        arrows: false,
                      },
                    },

                    {
                      breakpoint: 820,
                      settings: {
                        arrows: false,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        arrows: false,
                      },
                    },

                    {
                      breakpoint: 480,
                      settings: {
                        arrows: false,
                      },
                    },
                  ]}
                >
                  {apartmentDetails?.images?.map((x: string) => (
                    <Paper
                      sx={{
                        bgcolor: "#eeeeee",
                        borderRadius: "30px",
                      }}
                      key={x}
                    >
                      <img src={x} className="w-full rounded-2xl h-[450px]" />
                    </Paper>
                  ))}
                </Slider>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      p: "32px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          component="h1"
                          fontWeight="bold"
                        >
                          <span className="capitalize">
                            {apartmentDetails.propertytype}
                          </span>{" "}
                          {apartmentDetails.area}m<sup>2</sup>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="flex gap-3">
                        <span className="inline-flex">
                          {apartmentDetails.guests} <Translate text="guests" />
                        </span>
                        <span className="inline-flex">
                          {apartmentDetails.bed} <Translate text="beds" />
                        </span>
                        <span className="inline-flex">
                          {apartmentDetails.bedroom}{" "}
                          <Translate text="bedroom" />
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        {apartmentDetails.description}
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel sx={{ fontWeight: "bold" }}>
                          <Translate text="Sleeping Places" />
                        </FormLabel>
                        <Box>
                          <span>
                            {apartmentDetails.twinbed}{" "}
                            <Translate text="Twin Bed" />
                          </span>
                          ,{" "}
                          <span>
                            {apartmentDetails.doublebed}{" "}
                            <Translate text="Double Bed" />
                          </span>
                          ,{" "}
                          <span>
                            {apartmentDetails.extendedsofa}{" "}
                            <Translate text="Extended Sofa" />
                          </span>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      p: "32px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          component="h1"
                          fontWeight="bold"
                        >
                          <Translate text="Placement rules" />
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <span className="font-bold">
                          {" "}
                          <Translate text="Check in" />
                        </span>
                        <br />
                        <span>
                          {" "}
                          <Translate text="after 14:00" />
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span className="font-bold">
                          {" "}
                          <Translate text="Departure" />
                        </span>
                        <br />
                        <span>
                          {" "}
                          <Translate text="until 12:00" />
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span className="font-bold">
                          {" "}
                          <Translate text="Minimum stay" />
                        </span>
                        <br />
                        <span>
                          {" "}
                          <Translate text="from 1 day" />
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        {apartmentDetails.rules &&
                        apartmentDetails.rules.length > 0 ? (
                          <List>
                            {["smoking", "party", "pet"].map((x) => {
                              return (
                                <RuleItem
                                  rule={x}
                                  key={x}
                                  allowed={apartmentDetails.rules?.find(
                                    (y: string) => y === x
                                  )}
                                />
                              );
                            })}
                          </List>
                        ) : (
                          <p></p>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      p: "32px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          component="h1"
                          fontWeight="bold"
                        >
                          <Translate text="Basic amenities" />
                        </Typography>
                      </Grid>

                      {apartmentDetails.amenities &&
                        apartmentDetails.amenities.map((x: string) => (
                          <Grid item xs={6} key={x}>
                            <AmenitiesItem name={x} />
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      p: "32px",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit(
                        isLoggededIn ? onSubmit : handleToogleLogin
                      )}
                    >
                      <Grid container spacing={2}>
                        {apartmentDetails.bookedByme && (
                          <Box className="w-full px-[15px]">
                            <Grid item xs={12}>
                              <Typography
                                variant="h4"
                                component="h1"
                                fontWeight="bold"
                              >
                                <Translate text="Add a review" />
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className="flex items-center justify-between"
                            >
                              <FormLabel sx={{ marginBottom: "6px" }}>
                                {" "}
                                <Translate text="Give your review" />
                              </FormLabel>

                              <Controller
                                name="rating"
                                control={control}
                                defaultValue={0}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Rating
                                    {...field}
                                    onChange={(_, value) =>
                                      field.onChange(value)
                                    }
                                    value={Number(field.value)}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <div className="relative">
                                <input
                                  className="border-2 py-[1rem] rounded-[1rem] px-[0.5rem] border-secondary w-[100%] mb-2"
                                  placeholder="Share your thought"
                                  {...register("review", { required: true })}
                                />
                                {errors.review && (
                                  <span className="text-red-400">
                                    <Translate text="This field is required" />
                                  </span>
                                )}
                                <button type="submit">
                                  <div className="flex absolute gap-2 right-0 top-[10px] pr-2 items-center">
                                    {/* <LuSmile /> */}
                                    <div className="bg-primary xs:text-sm sm:text-sm flex items-center text-white gap-3 xs:gap-2 px-[1rem] py-[.5rem]  rounded-2xl">
                                      <h3>
                                        {" "}
                                        <Translate text="Post it!" />
                                      </h3>
                                      <FaArrowRightLong />
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </Grid>
                          </Box>
                        )}

                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                          >
                            <Translate text="Reviews" />
                          </Typography>
                          <List
                            sx={{
                              maxHeight: "400px",
                              overflow: "auto",
                            }}
                          >
                            {apartmentDetails.feedback &&
                              apartmentDetails.feedback.length === 0 && (
                                <Box
                                  sx={{
                                    fontSize: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Translate text="No Reviews yet" />
                                </Box>
                              )}
                            {apartmentDetails.feedback &&
                              apartmentDetails.feedback.map((review: any) => (
                                <>
                                  <Divider component="li" />
                                  <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={review.name}
                                        src={review.image}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <Box className="flex justify-between">
                                          {user.locale === "en" ? (
                                            <span>{review.name}</span>
                                          ) : (
                                            <span>
                                              {translit.reverse(review.name)}
                                            </span>
                                          )}
                                          <Rating
                                            name="rating"
                                            value={review.ratings}
                                          />
                                        </Box>
                                      }
                                      primaryTypographyProps={{
                                        sx: {
                                          fontWeight: 600,
                                        },
                                      }}
                                      secondary={
                                        <Fragment>
                                          <Typography
                                            sx={{ display: "inline" }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {review.review}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: 12,
                                              mt: 1,
                                            }}
                                          >
                                            {dayjs(review.updatedAt).fromNow()}
                                          </Typography>
                                        </Fragment>
                                      }
                                    />
                                  </ListItem>
                                </>
                              ))}
                          </List>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      p: "32px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          component="h1"
                          fontWeight="bold"
                        >
                          {apartmentDetails.location}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="flex items-center justify-between"
                      >
                        <FormLabel>
                          {apartmentDetails.city}, {apartmentDetails.country}
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomMap
                          centreLat={apartmentDetails?.latitude}
                          centreLon={apartmentDetails?.longitude}
                          markers={
                            !!apartmentDetails?.latitude &&
                            !!apartmentDetails.longitude
                              ? [
                                  {
                                    latitude: apartmentDetails?.latitude,
                                    longitude: apartmentDetails?.longitude,
                                    name: apartmentDetails.location,
                                    url: `${AppLinks.ApartmentDetails.path}/${apartmentDetails._id}`,
                                  },
                                ]
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {apartmentDetails.host && (
                  <Card>
                    <CardHeader
                      sx={{
                        px: "32px",
                        pt: "32px",
                      }}
                      avatar={
                        <Box>
                          {apartmentDetails.host.isIdentityVerified ? (
                            <Badge
                              badgeContent={
                                <IoCheckmarkCircleOutline
                                  style={{
                                    width: "20",
                                    height: "20",
                                    color: "white",
                                    backgroundColor: "green",
                                    borderRadius: "100%",
                                  }}
                                />
                              }
                            >
                              {
                                <Avatar
                                  src={apartmentDetails.host.imageurl}
                                  aria-label="recipe"
                                  sx={{
                                    width: {
                                      md: 60,
                                    },
                                    height: {
                                      md: 60,
                                    },
                                  }}
                                />
                              }
                            </Badge>
                          ) : (
                            <Avatar
                              src={apartmentDetails.host.imageurl}
                              aria-label="recipe"
                              sx={{
                                width: {
                                  md: 60,
                                },
                                height: {
                                  md: 60,
                                },
                              }}
                            />
                          )}
                          <Box className="flex gap-2 mt-4">
                            <span className="inline-flex items-center gap-1">
                              <MdStar className="text-yellow-500" />
                            </span>
                            <span className="text-secondary">
                              {apartmentDetails.totalReviews}{" "}
                              <Translate text="Reviews" />
                            </span>
                          </Box>
                        </Box>
                      }
                      title={
                        <Box sx={{ paddingLeft: "10px" }}>
                          <Typography
                            variant="h5"
                            component="h1"
                            fontWeight="bold"
                          >
                            {user.locale === "en" ? (
                              <div>
                                {" "}
                                {apartmentDetails.host.firstname}{" "}
                                {apartmentDetails.host.lastname}
                              </div>
                            ) : (
                              <div>
                                {translit.reverse(
                                  apartmentDetails.host.firstname
                                )}{" "}
                                {translit.reverse(
                                  apartmentDetails.host.lastname
                                )}
                              </div>
                            )}
                          </Typography>
                        </Box>
                      }
                      subheader={
                        <Box sx={{ paddingLeft: "10px" }}>
                          <Translate text="member since" />{" "}
                          {`${dayjs(
                            apartmentDetails.host.createdAt
                          ).fromNow()}`}
                          <Grid item xs={12} sx={{ marginTop: "15px" }}>
                            <Grid container spacing={1}>
                              <Grid item xs="auto">
                                {apartmentDetails.host.superhost && (
                                  <Chip
                                    label="Superhost"
                                    sx={{ width: "90px", height: "25px" }}
                                    color="primary"
                                  />
                                )}
                              </Grid>
                              <Grid item xs="auto">
                                {apartmentDetails.host.isIdentityVerified && (
                                  <Box className="flex flex-row space-x-2">
                                    <IoCheckmarkCircleOutline
                                      style={{
                                        width: "20",
                                        height: "20",
                                        color: "white",
                                        backgroundColor: "green",
                                        borderRadius: "100%",
                                      }}
                                    />
                                    <Typography>
                                      {" "}
                                      <Translate text="Identity verified" />
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      }
                    />

                    <CardContent
                      sx={{
                        px: "32px",
                        pb: "32px",
                      }}
                    >
                      <Grid container spacing={1}>
                        {apartmentDetails.host.besthost && (
                          <Grid item xs={12}>
                            <Translate text="The host is one of the best on" />{" "}
                            Mira
                            <span className="text-primary">dom</span>
                          </Grid>
                        )}
                        <Grid item xs={12} md={7}>
                          {apartmentDetails.host.bio}
                        </Grid>
                        {/* <Grid container spacing={1}></Grid> */}
                        <Grid
                          item
                          xs={12}
                          md={5}
                          className="flex items-center justify-start"
                        >
                          <Box className="flex flex-col space-y-2">
                            <Box className="flex flex-row space-x-4">
                              <FaSpeakap
                                style={{
                                  color: "blue",
                                  background: "white",
                                  width: "20px",
                                  height: "20px",
                                }}
                                className=""
                              />
                              <Box>
                                {" "}
                                <Translate text="Speaks" />{" "}
                                {apartmentDetails.host.speak.join(", ")}
                              </Box>
                            </Box>
                            <Box className="flex flex-row space-x-4">
                              <AiOutlineMessage
                                style={{
                                  color: "blue",
                                  background: "white",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              <Typography>
                                <Translate text="Processes request always" />
                              </Typography>
                            </Box>
                            <Box className="flex flex-row space-x-4">
                              <MdAccessTime
                                style={{
                                  color: "blue",
                                  background: "white",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              <Typography>
                                <Translate text="Response time - within an hour" />
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "15px",
                                  marginTop: "25px",
                                  color: "black",
                                }}
                                onClick={() => contactHost()}
                              >
                                <Translate text="Contact host" />
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "27%",
            },
            position: { md: "fixed" },
            right: 0,
            top: 0,
            mt: { xs: 6, md: 17 },
            pr: { md: 2 },
          }}
        >
          {/* <Box
              // bgcolor="#eeeeee"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <Box
                onMouseEnter={() => setIsFavoriteHover(true)}
                onMouseLeave={() => setIsFavoriteHover(false)}
                className="flex flex-row space-x-2 text-secondary cursor-pointer"
              >
                <Box
                  className={`rounded-full  text-2xl ${
                    isFavoriteHover ? "text-red-600" : ""
                  } `}
                >
                  <CiHeart />
                </Box>

                <Box>
                  {" "}
                  <Translate text="To favorite" />
                </Box>
              </Box>
              <Box
                onMouseEnter={() => setIsShareHover(true)}
                onMouseLeave={() => setIsShareHover(false)}
                className="flex flex-row space-x-2 text-secondary cursor-pointer"
              >
                <Box
                  className={`rounded-full  text-2xl ${
                    isShareHover ? "text-blue-600" : ""
                  } `}
                >
                  <IoShareSocialOutline />
                </Box>
                <Box>
                  {" "}
                  <Translate text="Share" />
                </Box>
              </Box>
            </Box> */}
          <Card>
            <CardHeader
              sx={{
                px: 3,
              }}
              action={
                <Avatar
                  aria-label="host"
                  src={apartmentDetails?.host?.imageurl}
                />
              }
              title={
                <>
                  <span className="line-through text-gray-400 mr-2">
                    {apartmentDetails.price}P
                  </span>
                  <span>{apartmentDetails.discountprice}P</span>
                  <small className="ml-1 text-gray-500">
                    /{apartmentDetails.duration}
                  </small>
                </>
              }
              subheader={
                <Box className="flex gap-2">
                  <span className="inline-flex items-center gap-1">
                    <MdStar className="text-yellow-500" />
                    <span className="font-bold">
                      {apartmentDetails.averageRatings}
                    </span>
                  </span>
                  <span className="text-secondary">
                    {apartmentDetails.totalReviews} <Translate text="Reviews" />
                  </span>
                </Box>
              }
            />
            <CardContent
              sx={{
                px: 3,
              }}
            >
              <Box className="bg-gray-100 px-3 py-1 rounded-md">
                <Grid container spacing={2}>
                  {/* CHECKIN POPUP */}
                  <CheckinPopup
                    anchorCheckinEl={anchorCheckinEl}
                    setAnchorCheckinEl={setAnchorCheckinEl}
                    filter={filter}
                    setFilter={setFilter}
                    bgColor={"bg-[#eeeeee]"}
                    fillColor={"apartmentList"}
                  />
                  {/* CHECKOUT POPUP */}
                  <CheckoutPopup
                    anchorCheckoutEl={anchorCheckoutEl}
                    setAnchorCheckoutEl={setAnchorCheckoutEl}
                    filter={filter}
                    setFilter={setFilter}
                    bgColor={"bg-[#eeeeee]"}
                    fillColor={"apartmentList"}
                  />
                  <ApartmentGuestPopup
                    anchorGuestEl={anchorGuestEl}
                    setAnchorGuestEl={setAnchorGuestEl}
                    filter={filter}
                    setFilter={setFilter}
                  />
                  <Grid
                    item
                    xs={6}
                    className="flex gap-2 items-center"
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    <SlCalender className=" text-secondary" />
                    <Box>
                      <FormLabel>
                        {" "}
                        <Translate text="Check-in" />
                      </FormLabel>
                      {!!filter.fromDate ? (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {filter.fromDate?.toLocaleDateString()}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          <Translate text="Add date" />
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="flex gap-2 items-center"
                    onClick={(e) => {
                      setAnchorCheckinEl(
                        anchorCheckinEl ? null : e.currentTarget
                      );
                    }}
                  >
                    <SlCalender className=" text-secondary" />
                    <Box>
                      <FormLabel>
                        {" "}
                        <Translate text="Check-out" />
                      </FormLabel>
                      {!!filter.toDate ? (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {filter.toDate?.toLocaleDateString()}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          <Translate text="Add date" />
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="flex gap-2 items-center"
                    onClick={(e) => {
                      setAnchorGuestEl(anchorGuestEl ? null : e.currentTarget);
                    }}
                  >
                    <GoPerson className="text-secondary" />
                    <Box>
                      <FormLabel>
                        {" "}
                        <Translate text="Guest" />
                      </FormLabel>
                      {/* <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          2 guests
                        </Typography> */}
                      {!!filter.guest ? (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {filter.guest} <Translate text="Guest" />
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          <Translate text="Add guest" />
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="bg-gray-100 px-3 py-3 mt-2 gap-1 rounded-md flex justify-between">
                <Translate text="Make an advance payment today" />
                <span className="font-bold">
                  {(0.1 * apartmentDetails.discountprice).toFixed(0)}P
                </span>
              </Box>
              <Box className="bg-gray-100 px-3 py-3 mt-2 rounded-md flex gap-1 justify-between">
                <Translate text="Payment upon check-in" />
                <span className="font-bold">
                  {apartmentDetails.discountprice -
                    parseInt((0.1 * apartmentDetails.discountprice).toFixed(0))}
                  P
                </span>
              </Box>
            </CardContent>
            <CardActionArea
              sx={{
                px: 3,
                pb: 3,
              }}
            >
              <button
                onClick={() => {
                  initiateBooking();
                }}
                className="flex text-center gap-5 text-white justify-center py-[.5rem]  rounded-[1rem] my-[1rem] bg-primary items-center cursor-pointer w-full"
              >
                <h3 className="md:text-[1.2rem] ">
                  {" "}
                  <Translate text="Book" />
                </h3>
              </button>
            </CardActionArea>
            {/* <div id="payment-form"></div> */}
          </Card>
        </Box>
      </Box>

      <Modal open={isBooking} onClose={cancleBooking}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: { xs: 500, sm: 500, lg: 450 },
            width: { xs: "90%", sm: "90%", lg: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            border: "1px solid #000", // Adding border
            p: 4,
          }}
        >
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                borderBottom: "2px solid #000",
                display: "inline-block",
                paddingBottom: "4px",
              }}
            >
              <Translate text="Confirm Your Booking" />
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <Translate text="Your booking will be sent for approval to the landlord. You will be notified once it's approved." />
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={cancleBooking}
                variant="contained"
                color="secondary"
                // sx={{ minWidth: "100px" }}
                sx={{
                  minWidth: "100px",
                  backgroundColor: "white",
                  borderColor: "blue",
                  color: "black",
                  border: "1px solid blue",
                  "&:hover": {
                    backgroundColor: "white",
                    borderColor: "darkblue",
                  },
                }}
              >
                <Translate text="Cancel" />
              </Button>
              <button
                className="bg-primary text-white rounded-md"
                onClick={bookApartment}
                style={{ minWidth: "100px" }}
              >
                <Translate text="Book" />
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default ApartmentDetails;
