import Label from "../components/Label";
import Container from "../components/shared/Container";
import Input from "../components/shared/Input";
import Select from "../components/shared/Select";
import { RiAddLine } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import apartments1 from "../images/apart1.png";
import { MdStar } from "react-icons/md";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIS_URL } from "../services/api";
import { useHistory } from "react-router-dom";
import { AppLinks } from "../utils/Linking";
import MapInput from "../components/MapInput";
import baseUrl from "../utils/config";
import GeoapifySearch from "../components/Geoapify";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useShowMsg, useToogleLogIn } from "../context/Context";
import { TbLoaderQuarter } from "react-icons/tb";
import OverlayTwo from "../components/OverlayTwo";
import { Translate } from "react-translated";
import {
  Box,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DragDropFileUpload from "../components/DragDropFileUpload";
import { Controller, useForm } from "react-hook-form";
import { margin } from "@mui/system";

const ListYourApartment = () => {
  const auth = localStorage.getItem("token");
  const redirect = useHistory();
  const { notifyError, notifySuccess } = useShowMsg();
  const inputRef = useRef<HTMLInputElement>(null);
  const [nextOrPrev, setNextOrPrev] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [listingForm, setListingForm] = useState<{
    amenities: string[];
    title: string;
    price: number;
    discount: number;
    longitude: number;
    latitude: number;
    duration: string;
    symbol: string;
    guests: number;
    twinbed: number;
    doublebed: number;
    extendedsofa: number;
    area: number;
    bedroom: number;
    livingroom: number;
    kitchen: number;
    description: string;
    images: string[];
    bed: number;
    rules: string[];
    propertytype: string;
  }>({
    title: "",
    price: 0,
    discount: 0,
    longitude: 0,
    latitude: 0,
    duration: "per Night",
    symbol: "%",
    guests: 0,
    twinbed: 0,
    doublebed: 0,
    extendedsofa: 0,
    area: 0,
    bedroom: 0,
    livingroom: 0,
    kitchen: 0,
    description: "",
    amenities: [],
    images: [],
    bed: 0,
    rules: [],
    propertytype: "apartment",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [subbmitting, setSubbmitting] = useState(false);
  const [location, setLocation] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [files, setFiles] = useState<FileList>();

  const handleNext = () => {
    if (nextOrPrev === 3) return setNextOrPrev(3);
    return setNextOrPrev((nextOrPrev) => ++nextOrPrev);
  };

  const handlePrev = () => {
    setValue("amenities", []);
    setValue("rules", []);
    if (nextOrPrev === 1) return setNextOrPrev(1);
    return setNextOrPrev((nextOrPrev) => --nextOrPrev);
  };

  const handleChange = (e: any) => {
    setListingForm({ ...listingForm, [e.target.name]: e.target.value });
  };

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: listingForm, mode: "onSubmit" });

  const uploadImage = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let image;

        if (files && files.length > 0) {
          let newImageUrlArray: Array<string> = [];
          let promises = []; // Array to hold promises

          for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Push the promise returned by axios to the promises array
            promises.push(
              new Promise(async (resolveInner, rejectInner) => {
                try {
                  const response = await axios({
                    method: "post",
                    url: `${baseUrl}${APIS_URL.APARTMENT_PRESIGN_URL}`,

                    data: {
                      filetype: file.type,
                    },
                    headers: {
                      Authorization: `Bearer ${auth}`,
                    },
                  });

                  if (response.status === 404) {
                    notifyError("Failed to upload apartment image");
                    setSubbmitting(false);
                    setIsLoading(false);
                    redirect.push(AppLinks.ApartmentListing.path);
                    return;
                  }
                  const url = response.data.presignedUrl;

                  let reader = new FileReader();
                  reader.onload = async (e) => {
                    if (
                      typeof e.target?.result === "string" &&
                      (e.target?.result.includes("data:image/jpeg") ||
                        e.target?.result.includes("data:image/png") ||
                        e.target?.result.includes("data:image/jpg"))
                    ) {
                      image = e.target?.result;
                      let binary = atob(image.split(",")[1]);
                      let array = [];
                      for (var i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                      }
                      let blobData = new Blob([new Uint8Array(array)], {
                        type: file.type,
                      });

                      await fetch(response.data.presignedUrl, {
                        method: "PUT",
                        body: blobData,
                      });

                      const newImageUrl = response.data.image_url;

                      // notifySuccess("File upload Successful");
                      newImageUrlArray.push(newImageUrl);
                    }
                    resolveInner(""); // Resolve the inner promise
                  };
                  reader.readAsDataURL(file);
                } catch (error) {
                  console.error("Error uploading image:", error);
                  rejectInner(error); // Reject the inner promise if an error occurs
                }
              })
            );
          }

          // Wait for all promises to resolve
          await Promise.all(promises);

          resolve(newImageUrlArray); // Resolve the main promise
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        reject(error); // Reject the main promise if an error occurs
      }
    });
  };

  const saveData = (data: any) => {
    // console.log("newda", data);
    setLocationError("");
    setLocation(false);
    setFileErrorMessage("");
    setFileError(false);
    if (!files) {
      // setFileErrorMessage(
      //   `${(<Translate text="Please upload an apartment image" />)}`
      // );
      setFileError(true);
      return;
    }
    if (data.latitude === 0) {
      // setLocationError(
      //   `${(<Translate text="Please type in your location" />)}`
      // );
      setLocation(true);
      return;
    }

    setNextOrPrev((nextOrPrev) => ++nextOrPrev);
  };

  const submitData = async (data: any) => {
    const price = parseFloat(data.price);
    const discount = parseFloat(data.discount);
    const longitude = parseFloat(data.longitude);
    const latitude = parseFloat(data.latitude);
    const guests = parseFloat(data.guests);
    const twinbed = parseFloat(data.twinbed);
    const doublebed = parseFloat(data.doublebed);
    const extendedsofa = parseFloat(data.extendedsofa);
    const area = parseFloat(data.area);
    const bedroom = parseFloat(data.bedroom);
    const livingroom = parseFloat(data.livingroom);
    const kitchen = parseFloat(data.kitchen);
    const bed = parseFloat(data.bed);
    let duration = data.duration.toLowerCase();
    duration.replace(/\s/g, "");

    const newData = {
      ...data,
      price,
      discount,
      longitude,
      latitude,
      guests,
      twinbed,
      doublebed,
      extendedsofa,
      area,
      bedroom,
      livingroom,
      kitchen,
      bed,
      duration,
    };

    setIsLoading(true);
    setSubbmitting(true);
    const ans = await uploadImage();

    try {
      const newApartment = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.ADD_APARTMENT}`,

        data: {
          ...newData,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      // console.log("newApartment", newApartment);
      if (newApartment.status === 404) {
        notifyError("Failed to add apartment");
        setSubbmitting(false);
        setIsLoading(false);
        redirect.push(AppLinks.MyApartment.path);
        return;
      }

      const img = await axios({
        method: "post",
        url: `${baseUrl}${APIS_URL.APARTMENT_ADD_IMAGE}`,

        data: {
          apartmentid: newApartment.data.apartmentid,
          imageurl: ans,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (img.status === 404) {
        notifyError("Failed to update apartment image");
        setSubbmitting(false);
        setIsLoading(false);
        redirect.push(AppLinks.MyApartment.path);
        return;
      }

      setSubbmitting(false);
      setIsLoading(false);
      notifySuccess("Apartment successfully submitted for review");
      redirect.push(AppLinks.UnApprovedApartment.path);
    } catch (error: any) {
      console.log("error", error);
      setSubbmitting(false);
      setIsLoading(false);
      notifyError(error?.message || "Failed to add apartment!");
    }
  };

  const handleAmenitiesChange = (e: any, checked: any) => {
    const amenity = e.currentTarget.value;

    let updatedAmenities = [...getValues("amenities")];
    if (checked) {
      updatedAmenities.push(amenity);
    }
    if (!checked) {
      updatedAmenities = updatedAmenities.filter((x) => x !== amenity);
    }

    setValue("amenities", updatedAmenities);
  };

  const handleRulesChange = (e: any, checked: any) => {
    const amenity = e.currentTarget.value;

    let updatedRules = [...getValues("rules")];
    if (checked) {
      updatedRules.push(amenity);
    }
    if (!checked) {
      updatedRules = updatedRules.filter((x) => x !== amenity);
    }

    setValue("rules", updatedRules);
  };

  const handlePropertyType = (e: any) => {
    setValue("propertytype", e.target.value);
  };

  const propetyType = watch("propertytype");
  const bedroomNum = watch("bedroom");
  const apartmentTitle = watch("title");

  // const handlePlaceSelected = (place: any) => {
  //   console.log("Place selected:", place); // Handle the place details
  // };

  return (
    <Container>
      <Box
        bgcolor="#eeeeee"
        sx={{
          px: { xs: 3, md: 10 },
          pt: 10,
          pb: 20,
        }}
      >
        <Typography variant="h3" component="h1" fontWeight="bold">
          <Translate text="List your property" />
        </Typography>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box>
            {nextOrPrev === 1 && (
              <form onSubmit={handleSubmit(saveData)}>
                <Typography
                  variant="h5"
                  component="h1"
                  fontWeight="bold"
                  pb={2}
                >
                  <Translate text="Upload Photos" />
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Drag or choose your file to upload" />
                    </FormLabel>
                    <Box sx={{ mt: 1 }}>
                      <DragDropFileUpload
                        onFileUpload={(e: FileList) => {
                          setFileError(false);
                          setFileErrorMessage("");
                          console.log("e", e);
                          setFiles(e);
                        }}
                        text={
                          files?.length && files?.length > 0
                            ? `${files?.length} selected`
                            : undefined
                        }
                      />
                    </Box>
                  </Grid>
                  {fileError && (
                    <span className="text-red-400">
                      <Translate text="Please upload an apartment image" />
                    </span>
                  )}
                </Grid>
                <Typography
                  variant="h5"
                  component="h1"
                  fontWeight="bold"
                  pb={1}
                  mt={4}
                >
                  <Translate text="Property Details" />
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      {" "}
                      <Translate text="Title" />
                    </FormLabel>

                    <input
                      className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                      placeholder='e.g. "Apartments in Boloruskaya"'
                      type="text"
                      {...register("title", {
                        required: "Apartment title is required",
                      })}
                    />
                    {errors.title && (
                      <span className="text-red-400">
                        <Translate text={`${errors.title.message}`} />
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      {" "}
                      <Translate text="Price" />
                    </FormLabel>
                    <div className="flex border border-[#647482] px-2  py-[.8rem] rounded-[1rem] mt-1 ">
                      <input
                        className=" border-r-2 outline-none bg-[#eeeeee]  w-[100%] px-[0.5rem] "
                        placeholder='"2500P" '
                        type="number"
                        {...register("price", {
                          required: "Price is required",
                          validate: {
                            greaterThanZero: (value) =>
                              parseFloat(`${value}`) > 0 ||
                              "Price must be greater than 0",
                          },
                        })}
                      />

                      <select
                        className="border outline-none text-[#647482] border-none bg-[#eeeeee] border-gray-300 rounded-lg  block w-full"
                        {...register("duration", {
                          required: false,
                        })}
                      >
                        <option value="per night" selected>
                          <Translate text="per night" />
                        </option>
                        <option value="per week">
                          <Translate text="per week" />
                        </option>
                        <option value="per month">
                          <Translate text="per month" />
                        </option>
                      </select>
                    </div>
                    {errors.price && (
                      <span className="text-red-400">
                        <Translate text={`${errors.price.message}`} />
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Discount" />
                    </FormLabel>
                    <div className="flex border border-[#647482] px-2  py-[.8rem] rounded-[1rem] mt-1">
                      <input
                        className=" border-r-2 outline-none bg-[#eeeeee] w-[100%] px-[0.5rem]"
                        placeholder="10"
                        type="number"
                        {...register("discount", {
                          required: true,
                          validate: {
                            greaterThanEqualZero: (value) =>
                              parseFloat(`${value}`) >= 0 ||
                              "dicount can't be negative number 0",
                          },
                        })}
                      />

                      <select
                        className="border outline-none text-[#647482] border-none bg-[#eeeeee] border-gray-300 rounded-lg  block w-full "
                        {...register("symbol", {
                          required: false,
                        })}
                      >
                        <option value="%" selected>
                          %
                        </option>
                        <option value="P">P</option>
                      </select>
                    </div>
                    {errors.discount && (
                      <span className="text-red-400">
                        <Translate text={`${errors.discount.message}`} />
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Location" />
                    </FormLabel>

                    <Box>
                      <GeoapifySearch
                        onPlaceSelect={(e: any) => {
                          setLocation(false);
                          setLocationError("");
                          setValue("latitude", e.properties.lat);
                          setValue("longitude", e.properties.lon);
                        }}
                        value=""
                      />
                    </Box>

                    {location && (
                      <span className="text-red-400">
                        <Translate text="Please type in your location" />
                      </span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Guests" />
                    </FormLabel>

                    <div style={{ marginTop: "0px" }}>
                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder="e.g. 3 "
                        type="number"
                        {...register("guests", {
                          required: true,
                          validate: {
                            greaterThanZero: (value) =>
                              parseFloat(`${value}`) > 0 ||
                              "Guest must be greater than 0",
                          },
                        })}
                      />
                      {errors.guests && (
                        <span className="text-red-400 text-[12px]">
                          <Translate text={`${errors.guests.message}`} />
                        </span>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Bed Room" />
                    </FormLabel>
                    <div>
                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "2" '
                        type="number"
                        {...register("bedroom", {
                          required: false,
                        })}
                      />
                      {errors.bedroom && (
                        <span className="text-red-400 text-[12px]">
                          <Translate text={`${errors.bedroom.message}`} />
                        </span>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Living Room" />
                    </FormLabel>
                    <div>
                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "3" '
                        type="number"
                        {...register("livingroom", {
                          required: false,
                        })}
                      />
                      {errors.livingroom && (
                        <span className="text-red-400 text-[12px]">
                          <Translate text={`${errors.livingroom.message}`} />
                        </span>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Kitchen" />
                    </FormLabel>
                    <div>
                      <input
                        className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                        placeholder='e.g. "1" '
                        type="number"
                        {...register("kitchen", {
                          required: false,
                        })}
                      />
                      {errors.kitchen && (
                        <span className="text-red-400 text-[12px]">
                          <Translate text={`${errors.kitchen.message}`} />
                        </span>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Twin Beds" />
                    </FormLabel>

                    <input
                      className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                      placeholder='e.g. "1" '
                      type="number"
                      {...register("twinbed", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Double Bed" />
                    </FormLabel>

                    <input
                      className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                      placeholder='e.g. "1" '
                      type="number"
                      {...register("doublebed", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Extendable Sofa" />
                    </FormLabel>

                    <input
                      className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                      placeholder='e.g. "1" '
                      type="number"
                      {...register("extendedsofa", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      type="submit"
                      // onClick={handleNext}
                      className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md"
                    >
                      <Translate text="Next" />
                    </button>
                  </Grid>
                </Grid>
              </form>
            )}
            {nextOrPrev === 2 && (
              <form onSubmit={handleSubmit(submitData)}>
                <Typography
                  variant="h5"
                  component="h1"
                  fontWeight="bold"
                  pb={2}
                >
                  <Translate text="Property Details" />
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Description" />
                    </FormLabel>
                    <textarea
                      className="border border-[#647482] bg-[#eeeeee]  w-[100%]  px-[1.5rem] py-[0.8rem] rounded-[1rem] mt-1"
                      placeholder='e.g "Apartments in Beloruskaya"'
                      {...register("description", {
                        required: "Description is required",
                      })}
                    />
                    {errors.description && (
                      <span className="text-red-400 ">
                        <Translate text={`${errors.description.message}`} />
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Amenities" />
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Internet, Wifi" />}
                        value="internet"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Air Conditioner" />}
                        value="air-conditioner"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Fridge" />}
                        value="fridge"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Balcony" />}
                        value="balcony"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Microwave" />}
                        value="microwave"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Television" />}
                        value="television"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Electric Kettle" />}
                        value="electric-kettle"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Towels" />}
                        value="towels"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Hair dryer" />}
                        value="hair-dryer"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Sauna/bath" />}
                        value="sauna"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Jacuzzi" />}
                        value="jacuzzi"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Pots" />}
                        value="pots"
                        onChange={(e, checked) => {
                          handleAmenitiesChange(e, checked);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormLabel sx={{ fontWeight: "bold" }}>
                      <Translate text="Rules" />
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Smoking Allowed" />}
                        value="smoking"
                        onChange={(e, checked) => {
                          handleRulesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Parties Allowed" />}
                        value="party"
                        onChange={(e, checked) => {
                          handleRulesChange(e, checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<Translate text="Pets Allowed" />}
                        value="pet"
                        onChange={(e, checked) => {
                          handleRulesChange(e, checked);
                        }}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl>
                      <FormLabel
                        sx={{ fontWeight: "bold" }}
                        id="demo-controlled-radio-buttons-group"
                      >
                        <Translate text="Property Type" />
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={propetyType}
                        onChange={handlePropertyType}
                      >
                        <FormControlLabel
                          value="apartment"
                          control={<Radio />}
                          label={<Translate text="Apartment" />}
                        />
                        <FormControlLabel
                          value="houses-cottages"
                          control={<Radio />}
                          label={<Translate text="Houses, Cottages" />}
                        />
                        <FormControlLabel
                          value="rooms"
                          control={<Radio />}
                          label={<Translate text="Rooms" />}
                        />
                        <FormControlLabel
                          value="guest-houses"
                          control={<Radio />}
                          label={<Translate text="Guest House" />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <button
                      onClick={handlePrev}
                      className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md"
                    >
                      <Translate text="Prev" />
                    </button>
                    <button
                      // onClick={() => {
                      //   submitApartment();
                      // }}
                      type="submit"
                      style={{
                        backgroundColor:
                          showSpinner === true || subbmitting === true
                            ? "gray"
                            : "#2560fe",
                      }}
                      className="cursor-pointer bg-primary py-[.5rem] rounded-lg px-[2rem] text-white hover:shadow-md inline-flex items-center gap-1"
                    >
                      <Translate text="Submit your review" />
                      <FaArrowRightLong />
                    </button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Box>
          <Box>
            <Typography variant="h5" component="h1" fontWeight="bold">
              <Translate text="Preview" />
            </Typography>

            <Card sx={{ maxWidth: 345, mt: 5 }}>
              <CardMedia
                sx={{ minHeight: 200, minWidth: 250, bgcolor: "lightgray" }}
                image={
                  files && files.length > 0 ? URL.createObjectURL(files[0]) : ""
                }
                title="Apartment"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {apartmentTitle ?? "-"}
                </Typography>
                <Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      {bedroomNum ?? ""}-<Translate text="room apartment" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {/* {listingForm.place} */}
                    </Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  borderTop: 1,
                  borderTopColor: "lightgray",
                  mx: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <span className="inline-flex items-center">
                  <MdStar className="text-yellow-500" /> 5
                </span>
                <span className="inline-flex items-center text-gray-500">
                  (30 reviews)
                </span>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Box>
      <OverlayTwo isLoading={isLoading} />
    </Container>
  );
};

export default ListYourApartment;
