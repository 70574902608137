import { FC, useEffect, useState } from "react";
import { Button, Pagination, Stack } from "@mui/material";
import Container from "../components/shared/Container";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdStar } from "react-icons/md";
import { AppLinks } from "../utils/Linking";
import { useHistory, useLocation, Link } from "react-router-dom";
import baseUrl from "../utils/config";
import axios, { all } from "axios";
import { APIS_URL } from "../services/api";
// import Map from "react-map-gl";
import { IoSearchCircle } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";

import { FaFilter } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { CiLocationArrow1 } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { CiCalendarDate } from "react-icons/ci";
import OverlayThree from "../components/OverlayThree";
import translateText from "../utils/translationHelper";
import HoverableSliderTwo from "../components/HoverableSliderTwo";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Popover,
  BottomNavigation,
} from "@mui/material";
import Location from "../components/location";
import Calendar from "react-calendar";
import { format, utcToZonedTime } from "date-fns-tz";

import ListFilter from "../components/listFilter";
import CustomMap from "../components/Map";
import LocationPopup from "./components/LocationPopup";
import CheckinCheckoutPopup from "./components/CheckinCheckoutPopup";

import GuestPopup from "./components/GuestPopup";
import style from "./apartment.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext, useNotificationContext } from "../context/Context";
import VerifyPopup from "./verify";
import { Translate } from "react-translated";

const UnApprovedApartment: FC = () => {
  const auth = localStorage.getItem("token");
  const { updateNotification } = useNotificationContext();
  const { user, updateUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  const redirect = useHistory();
  const [like, setLike] = useState(false);
  const [myApartment, setMyApartment] = useState<any[]>([]);
  // const [search, setSearch] = useLocation();

  const searchLocation = useLocation();
  const search = new URLSearchParams(searchLocation.search);

  const [priceRanges, setPriceRanges] = useState<
    Array<{ min: number; max: number }>
  >([]);

  const [superHost, setSuperHost] = useState(false);
  const [freeCancellation, setFreeCancellation] = useState(false);
  const [noDeposit, setNoDeposit] = useState(false);
  const [propertyType, setPropertyType] = useState<String[]>([]);
  const [facilites, setFacilities] = useState<String[]>([]);
  const [bedPreference, setBedPreference] = useState<String[]>([]);
  const [distance, setDistance] = useState<String[]>([]);
  const [rules, setRules] = useState<String[]>([]);

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items to display per page

  const [anchorLocationEl, setAnchorLocationEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorDateEl, setAnchorDateEl] = useState<null | HTMLElement>(null);
  const [anchorGuestEl, setAnchorGuestEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<{
    location: string;
    guest: number | null;
    adult: number | null;
    children: number | null;
    infant: number | null;
    pet: number | null;
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    location: "",
    guest: null,
    adult: null,
    children: null,
    infant: null,
    pet: null,
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      axios({
        method: "get",
        url: `${baseUrl}${APIS_URL.UNAPPROVED_APARTMENT}`,

        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then(async (response) => {
          // console.log("res", response);
          const apartments: any[] = response.data.apartmentsWithReviews;

          if (user.locale !== "en") {
            let apartmentsTranslatedData = await Promise.all(
              apartments.map((item: any) => translateText(item, user.locale))
            );
            setMyApartment(apartmentsTranslatedData);

            updateUser({
              ...user,
              isCredentialUpload: response.data.isCredentialUpload,
            });
            setIsLoading(false);
            return;
          }

          setMyApartment(apartments);

          updateUser({
            ...user,
            isCredentialUpload: response.data.isCredentialUpload,
          });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  }, [user.locale]);

  // console.log("apartments", myApartment);

  const handleLike = () => {
    setLike(!like);
  };

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  // Guard against undefined myApartment
  const totalPages = myApartment
    ? Math.ceil(myApartment.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Guard against undefined myApartment
  const currentPageData = myApartment
    ? myApartment.slice(startIndex, endIndex)
    : [];
  const endCount = startIndex + (currentPageData ? currentPageData.length : 0);

  useEffect(() => {
    const showPopup = () => {
      document.body.style.overflow = "hidden";
    };

    if (showFilterPopup) {
      showPopup();
    }

    return () => {
      document.body.style.overflow = ""; // Re-enable scrolling on the body
    };
  }, [showFilterPopup]); // Run this effect whenever showFilterPopup changes

  const userLocalTime = (utcDateString: string) => {
    // Create a Date object from the UTC date string
    const utcDate = new Date(utcDateString);

    // Get the user's local timezone offset in minutes
    const userTimezoneOffset = new Date().getTimezoneOffset();

    // Apply the user's timezone offset to get the local time
    const localTime = new Date(utcDate.getTime() - userTimezoneOffset * 60000);

    const formattedDate = localTime.toDateString();

    return formattedDate;
  };
  return (
    <>
      <Container>
        <div className="bg-[#eeeeee]  pb-16">
          <div className="flex items-center    bg-[#eeeeee]  ">
            <div className="flex  w-full  ">
              <Box
                className=" h-100px w-full  mt-5"
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  // paddingX: { sm: "10px" },
                }}
              >
                <Grid
                  // container
                  // spacing={4}
                  // className={style.apartment_list}
                  sx={{
                    marginBottom: { xs: "150px", md: "150px" },
                    marginTop: { sm: "0px", md: "50px" },
                  }}
                >
                  {myApartment.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <p className="xs:text-[30px] sm:text-[30px] text-4xl xs:px-2 sm:px-2">
                        {" "}
                        <Translate text="Under Review Apartment" />{" "}
                      </p>
                    </Box>
                  )}

                  {currentPageData.map((apartmentCard, index) => (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                        },
                        minHeight: {
                          xs: "415px",
                          sm: "200px",
                          md: "200px",
                          lg: "180px",
                        },
                        marginBottom: {
                          xs: "40px",
                          sm: "20px",
                          md: "20px",
                          lg: "15px",
                        },

                        width: {
                          xs: "full",
                          sm: "600px",
                          md: "750px",
                          lg: "1200px",
                        },
                        // paddingX: { sm: "10px" },
                      }}
                    >
                      <Card
                        sx={{
                          display: "flex",

                          width: "100%",
                          maxWidth: {
                            xs: 330,
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          },
                          height: {
                            lg: "100%",
                          },
                        }}
                        className="rounded-lg"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                              md: "row",
                              lg: "row",
                            },
                            width: "100%",
                            height: {
                              lg: "100%",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "30%",
                                md: "30%",
                                lg: "30%",
                              },
                              height: "180px",
                            }}
                          >
                            <HoverableSliderTwo
                              images={apartmentCard?.images}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              width: {
                                xs: "100%",
                                sm: "70%",
                                md: "70%",
                                lg: "70%",
                              },
                            }}
                          >
                            <Link
                              to={`${AppLinks.MyApartmentDetails.path}/${apartmentCard._id}`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  height: "100%",
                                  maxWidth: { lg: "850px" },
                                  minWidth: { lg: "850px" },

                                  flexDirection: {
                                    xs: "column",
                                    sm: "row",
                                    md: "row",
                                    lg: "row",
                                  },
                                }}
                              >
                                <CardContent
                                  sx={{
                                    flexGrow: 2,
                                    pb: {
                                      xs: 0,
                                      md: 1,
                                    },
                                  }}
                                >
                                  <Box
                                    className="text-sm"
                                    sx={{
                                      paddingTop: {
                                        xs: "35px",
                                        sm: "10px",
                                        md: "15px",
                                      },
                                    }}
                                  >
                                    {apartmentCard.bedroom} -{" "}
                                    <Translate text="room apartment" />
                                  </Box>
                                  <h3 className="text-base font-semibold">
                                    {apartmentCard.title}
                                  </h3>
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    <a
                                      href={`https://maps.google.com/?ll=${apartmentCard.latitude},${apartmentCard.longitude}`}
                                      className="text-sm text-primary underline"
                                    >
                                      {apartmentCard.location}
                                    </a>
                                  </Typography>
                                  <div className="flex gap-5">
                                    <p className="text-sm">
                                      {apartmentCard.guests}{" "}
                                      <Translate text="guests" />
                                    </p>
                                    <p className="text-sm">
                                      {apartmentCard.bed}{" "}
                                      <Translate text="beds" />
                                    </p>
                                    <p className="text-sm">
                                      {apartmentCard.bedroom}{" "}
                                      <Translate text="bedroom" />
                                    </p>
                                    <p className="text-sm">
                                      {apartmentCard.h}m2
                                    </p>
                                  </div>
                                </CardContent>
                                <CardContent
                                  sx={{
                                    maxWidth: "180px",
                                    minWidth: "180px",
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    borderLeft: {
                                      md: "1px solid lightgray",
                                    },
                                    pt: {
                                      xs: 0.5,
                                      md: 1,
                                    },
                                  }}
                                >
                                  <div className="flex justify-between">
                                    <h3 className="text-sm font-semilight">
                                      {apartmentCard.totalReviews}{" "}
                                      <Translate text="reviews" />
                                    </h3>
                                    <div className="inline-flex items-center">
                                      <MdStar className="text-yellow-500" />{" "}
                                      <p className="font-semibold">
                                        {apartmentCard.averageRatings}
                                      </p>
                                    </div>
                                  </div>
                                  <div></div>
                                  <div>
                                    <span className="font-bold">
                                      {apartmentCard.discountprice}P
                                    </span>{" "}
                                    <span className="text-[0.9] font-light">
                                      {apartmentCard.duration}
                                    </span>
                                  </div>
                                </CardContent>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                  <OverlayThree isLoading={isLoading} />
                </Grid>
                {/* Pagination component */}

                {myApartment.length > 0 ? (
                  <Box
                    className={style.apartment_pag}
                    sx={{
                      position: "absolute",

                      bottom: 0,
                      width: {
                        xs: "300px",
                        sm: "360px",
                        md: "500px",
                        lg: "800px",
                      },
                    }}
                  >
                    <Box
                      className="mb-3 "
                      sx={{
                        fontSize: "h6.fontSize",

                        textAlign: { xs: "center", md: "left", lg: "left" },
                      }}
                    >
                      {!!filter.location ? (
                        <>
                          {filter.location}: {myApartment.length}{" "}
                          <Translate text="properties found" />
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box
                      sx={{
                        justifyContent: { md: "space-between" },
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "row",
                        },
                        border: 1,
                        borderRadius: "16px",
                        borderColor: "gray",
                        py: { xs: 2, md: 4 },
                        px: { md: 4 },
                      }}
                    >
                      <Stack
                        spacing={2}
                        sx={{
                          alignSelf: { xs: "center" },
                        }}
                      >
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handleChange}
                          shape="rounded"
                          color="primary"
                        />
                      </Stack>

                      <Box sx={{ textAlign: { xs: "center" } }}>
                        <div className="text-gray-500 mt-1">
                          <Translate text="Showing" /> {startIndex + 1} -{" "}
                          {endCount}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",

                      height: { xs: "50hv", md: "100vh", lg: "100vh" },
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",

                      fontSize: "h6.fontSize",
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        display: "flex flex-col",
                        width: "full",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Translate text="No Apartment Found" />
                    </Box>
                  </Box>
                )}
              </Box>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UnApprovedApartment;
